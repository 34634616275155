import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgIconFileBig from '@components/framework/icons/IconFileBig.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import { ListCustomsInvoicesInnerResponseOutDTO } from '@api/logsteo-api.v2.tsx';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { useCreateAddCustomsInvoiceSidebar } from '@app/pages/Customs/sidebars/AddCustomsInvoiceSidebar.tsx';
import TabularDeleteButton from '@components/ringil3/Buttons/TabularDeleteButton.tsx';
import InvoiceTotalValueBadge from '@components/ringil3/Badges/InvoiceTotalValueBadge.tsx';
import { Row, RowForTagsAndBadges } from '@components/styles.tsx';

interface ComponentProps {
  expeditionId?: string;
  supplierNotificationId?: string;
  applicationId: string;
  readOnly?: boolean;
  showPrices?: boolean;
}

const InvoicePanel: React.FC<ComponentProps> = ({
                                                  expeditionId,
                                                  applicationId,
                                                  supplierNotificationId,
                                                  readOnly = false,
                                                  showPrices = true,
                                                }) => {
  const { listExpeditionCustomsInvoices, listSNCustomsInvoices, deleteCustomsInvoice } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [invoices, setInvoices] = useState<ListCustomsInvoicesInnerResponseOutDTO[]>();
  const abortControllerRef = useRef(null);

  const { visible, setVisible, AddCustomsInvoiceSidebar } = useCreateAddCustomsInvoiceSidebar();

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;
    load();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const deleteInvoice = (invoiceId: string) => {
    deleteCustomsInvoice(invoiceId, () => {
      load();
    });
  };

  const load = () => {
    if (isNotNullOrUndefined(expeditionId)) {
      listExpeditionCustomsInvoices(expeditionId, d => {
        if (abortControllerRef.current.signal.aborted) return;
        setInvoices(d.invoices);
      });
    } else if (isNotNullOrUndefined(supplierNotificationId)) {
      listSNCustomsInvoices(supplierNotificationId, d => {
        if (abortControllerRef.current.signal.aborted) return;
        setInvoices(d.invoices);
      });
    }
  };

  const computeTotalByCurrency = (invoices: ListCustomsInvoicesInnerResponseOutDTO[]) => {
    const totals = new Map<string, number>();

    invoices.forEach(invoice => {
      if (invoice.price && invoice.price.currency) {
        const { price, currency } = invoice.price;
        if (totals.has(currency)) {
          totals.set(currency, totals.get(currency) + price);
        } else {
          totals.set(currency, price);
        }
      }
    });

    return Object.fromEntries(totals);
  };

  const totalsByCurrency = useMemo(() => computeTotalByCurrency(invoices || []), [invoices]);

  const { Table, Column } = useCreateTable<ListCustomsInvoicesInnerResponseOutDTO>();
  return (
    <>
      <div className={'flex flex-col gap-4'}>
        <COHeadline variant={'h2'} title={tr('InvoicePanel.faktury', 'Faktury')} icon={<SvgIconFileBig />} />
        <RowForTagsAndBadges>
          <Row>
            <InvoiceTotalValueBadge totalsByCurrency={totalsByCurrency} />
          </Row>
        </RowForTagsAndBadges>

        <Table value={invoices || []}>
          <Column header={tr('InvoicePanel.cisloFaktury', 'Číslo faktury')} body={r => <div>{r.invoiceNumber}</div>} />
          <Column
            header={tr('InvoicePanel.value', 'Value')}
            body={r =>
              <div>{isNotNullOrUndefined(r.price) && showPrices === true ? formatPrice(r.price.price, r.price.currency) : ''}</div>}
          />
          <Column header={tr('InvoicePanel.note', 'Note')} body={r => <div>{r.invoiceNote}</div>} />
          <Column
            header={''}
            body={r => (
              <div className={'flex flex-row justify-end'}>
                {readOnly === false && (
                  <TabularDeleteButton
                    onClick={() => {
                      deleteInvoice(r.id);
                    }}
                  />
                )}
              </div>
            )}
          />
        </Table>
        {readOnly !== true && (
          <AddButton
            title={tr('InvoicePanel.faktura', 'Faktura')}
            onClick={() => {
              setVisible(true);
            }}
            fullWidth
          ></AddButton>
        )}
      </div>
      {visible === true && isNotNullOrUndefined(expeditionId) && (
        <AddCustomsInvoiceSidebar entityType={'EXPEDITION'} entityId={expeditionId} applicationId={applicationId}
                                  onCompleted={() => load()} />
      )}
      {visible === true && isNotNullOrUndefined(supplierNotificationId) && (
        <AddCustomsInvoiceSidebar entityType={'SUPPLIER_NOTIFICATION'} entityId={supplierNotificationId}
                                  applicationId={applicationId} onCompleted={() => load()} />
      )}
    </>
  );
};

export default InvoicePanel;
