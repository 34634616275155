import React, { useContext } from 'react';
import styled from 'styled-components';
import { Ringil3H3 } from '@components/ringil3/styles';
import { isNotNullOrUndefined } from '@utils/utils';
import EditButton from '@components/ringil3/Buttons/EditButton';
import DiscrepancyButton from '@components/ringil3/Buttons/DiscrepancyButton.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { FeaturesEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  icon?: JSX.Element;
  title: string;
  editable: boolean;
  onEditClick?: () => void;
  onDiscrepancyUpdateClick?: () => void;
}

const DiscrepancyEditHeading3: React.FC<ComponentProps> = ({ title, icon, editable, onEditClick = () => {}, onDiscrepancyUpdateClick = () => {} }) => {

  const { loggedUser } = useContext(AuthenticatedUserContext);

  const orderDiscrepancyEnabled = loggedUser?.features?.some(t => t === FeaturesEnum.ORDER_DISCREPANCY)

  return (
    <RingilRowWithButtons>
      <ButtonRow>
        {isNotNullOrUndefined(icon) && icon}
        <Ringil3H3>{title}</Ringil3H3>
      </ButtonRow>
      <ButtonRow>
        {editable && <EditButton onClick={() => onEditClick()} />}
        {editable && orderDiscrepancyEnabled && <DiscrepancyButton onClick={() => onDiscrepancyUpdateClick()} />}
      </ButtonRow>
    </RingilRowWithButtons>
  );
};

const RingilRowWithButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export default DiscrepancyEditHeading3;
