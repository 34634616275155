import React, { useContext, useEffect, useId, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import LocationSelect from '@components/framework/locationselect/LocationSelect.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import ManageTimeslot, { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import ReadonlyInput from '@components/framework/input/ReadonlyInput/ReadonlyInput.tsx';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import SvgRoute from '@components/ringil3/icons/heading/Route.tsx';
import { ExpeditionLocality, LocalityEntryModeEnum, NewExpeditionForm } from '@app/pages/expedition/create/types.ts';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import { newExpeditionInitialData } from '@app/pages/expedition/create/data.ts';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { AnimatePresence, motion } from 'framer-motion';
import { generateUUID, isNotBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import {
  AccessTypeEnum,
  EntityTypeEnum,
  ExpeditionStatusEnum,
  FeaturesEnum,
  GlobalOrdersRequest,
  OpeningEntityTypeEnum,
  SaveDistributionRequestInDTO,
  SaveExpeditionLocationRequestInDTO,
  SaveExpeditionRequestInDTO,
} from '@api/logsteo-api.v2.tsx';
import { createOrdersComponentValidationSchema, expeditionConfig } from '@components/framework/orders/validation.ts';
import SvgLoadDown from '@icons/LoadDown.tsx';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import InputContact from '@components/framework/InputContact/InputContact.tsx';
import CreateDistributionOrAssign from '@app/pages/expedition/create/panels/CreateDistributionOrAssign.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import StateTag from '@components/framework/tags/StateTag.tsx';
import TransportationTypeTag from '@components/framework/tags/TransportationTypeTag.tsx';
import ExpeditionTypeTag from '@components/framework/tags/ExpeditionTypeTag.tsx';
import ManipulationTag from '@components/framework/tags/ManipulationTag.tsx';
import CargoTypeTag from '@components/framework/tags/CargoTypeTag.tsx';
import TruckTag from '@components/framework/tags/TruckTag.tsx';
import RequirementsTag from '@components/framework/tags/RequirementsTag.tsx';
import CarrierNoteTag from '@components/framework/tags/CarrierNoteTag.tsx';
import ResponsiblePersonTag from '@components/framework/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/framework/tags/InternalInfoNotifyTag.tsx';
import InternalInfoNotifyByRoleTag from '@components/framework/tags/InternalInfoNotifyByRoleTag.tsx';
import { RoleEnum } from '@type/enums.ts';
import InternalInfoInvoiceState from '@components/framework/tags/InternalInfoInvoiceStateTag.tsx';
import InternalInfoDisponentTag from '@components/framework/tags/InternalInfoDisponentTag.tsx';
import InternalInfoPairingSymbol from '@components/framework/tags/InternalInfoPairingSymbol.tsx';
import InternalNoteTag from '@components/framework/tags/InternalNoteTag.tsx';
import InternalInfoRebillingTag from '@components/framework/tags/InternalInfoRebillingTag.tsx';
import AttachmentUploadPanel from '@app/pages/attachment/AttachmentUploadPanel.tsx';
import AttachmentsList from '@app/pages/attachment/AttachmentsList.tsx';
import { mapToAPIDateTime } from '@utils/date.tsx';
import dayjs from 'dayjs';
import InputCountry from '@components/framework/input/InputCountry/InputCountry.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { formatAddress } from '@utils/formators.tsx';
import InternalInfoFolderTag from '@components/framework/tags/InternalInfoFolderTag.tsx';
import CustomerApplicationIdTag from '@components/framework/tags/CustomerApplicationIdTag.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import NavigationBreadCrumb from '@components/ringil3/BreadCrumb/NavigationBreadCrumb.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { Checkbox } from 'primereact/checkbox';

interface ComponentProps {}

const CreateExpedition: React.FC<ComponentProps> = () => {
  const { createExpedition } = useContext(ApiContext);
  const [hasMounted, setHasMounted] = useState(false);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { showToastMessage } = useContext(ToastContext);
  const { clear, BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const orderSchema = createOrdersComponentValidationSchema(expeditionConfig, false, tr);

  const manuallyEnteredLocationValidation = yup.object().shape({
    name: yup.string().required(),
    city: yup.string().required(),
    streetNr: yup.string().required(),
  });

  const validationRules = yup.object().shape({
    locations: yup.array().of(
      yup.object().shape({
        localityMode: yup.string().required(),
        timeslots: timeslotValidationSchema(),
        manualAddress: yup.object().when('localityMode', {
          is: LocalityEntryModeEnum.MANUALLY,
          then: manuallyEnteredLocationValidation,
        }),
        selectedPerson: yup.object().when('localityMode', {
          is: LocalityEntryModeEnum.FROM_LOCATIONS,
          then: yup.object().shape({}).required(),
        }),
        companyLocationId: yup.string().when('localityMode', {
          is: LocalityEntryModeEnum.FROM_LOCATIONS,
          then: yup.string().required().min(1),
        }),
        orders: yup.array().nullable().min(0).of(orderSchema),
      }),
    ),
  });

  const form = useForm<NewExpeditionForm>(validationRules, newExpeditionInitialData(), d => save(d), false, false);

  const mapDistribution = (data: NewExpeditionForm): SaveDistributionRequestInDTO => {
    if (data.distribution == null) return null;

    if (data.distribution.distributionType === 'FIXED') {
      return {
        fixedRequest: {
          price: data.distribution.price,
          responseDeadlineLocalTime: data.distribution.responseDeadlineLocalTime,
          responseDeadlineTimezone: data.distribution.responseDeadlineTimezone,
          selectedItems: data.distribution.selectedItems,
        },
      } as SaveDistributionRequestInDTO;
    }

    if (data.distribution.distributionType === 'ASSIGN') {
      return {
        directAssign: {
          price: data.distribution.price,
          carrierId: data.distribution.assigningCarrierId,
          notifyAssignedCarrier: data.distribution.notifyAssignedCarrier,
        },
      } as SaveDistributionRequestInDTO;
    }

    if (data.distribution.distributionType === 'SPOT') {
      return {
        spotRequest: {
          price: data.distribution.price,
          responseDeadlineLocalTime: data.distribution.responseDeadlineLocalTime,
          responseDeadlineTimezone: data.distribution.responseDeadlineTimezone,
          selectedItems: data.distribution.selectedItems,
        },
      } as SaveDistributionRequestInDTO;
    }
  };

  const mapLocation = (l: ExpeditionLocality): SaveExpeditionLocationRequestInDTO => {
    if (l.localityMode == LocalityEntryModeEnum.FROM_LOCATIONS) {
      return {
        companyLocationId: l.location.locationId,
        additionalContacts: l.additionalContacts,
        address: l.address,
        loadingReference: l.loadingReference,
        selectedPerson: l.selectedPerson,
        orders: l.orders,
        timeslots: l.timeslots,
        unloadingOrderIds: l.unloadingOrderIds,
        notifySelectedPerson: true,
      };
    } else {
      return {
        companyLocationId: null,
        additionalContacts: l.manualAddress.additionalPersons?.map(t => ({
          contactEmail: t.email,
          contactName: t.name,
        })),
        address: {
          name: l.manualAddress.name,
          city: l.manualAddress.city,
          country: l.manualAddress.countryCode3,
          postalCode: l.manualAddress.postalCode,
          streetNr: l.manualAddress.streetNr,
        },
        loadingReference: l.loadingReference,
        selectedPerson:
          [l.manualAddress.mainContactPerson, l.manualAddress.mainContactPhone, l.manualAddress.mainContactEmail].filter(t => t != null).length > 0
            ? {
                contactEmail: l.manualAddress.mainContactEmail,
                contactName: l.manualAddress.mainContactPerson,
                contactPhone: l.manualAddress.mainContactPhone,
              }
            : null,
        orders: l.orders,
        timeslots: l.timeslots,
        unloadingOrderIds: l.unloadingOrderIds,
        notifySelectedPerson: l.manualAddress.notifyContactPerson,
      };
    }
  };

  const save = (data: NewExpeditionForm) => {
    createExpedition(
      {
        applicationId: data.customApplicationId,
        locations: data.locations.map(t => mapLocation(t)),
        distribution: mapDistribution(data),
        expeditionType: data.expeditionType,
        labelIds: [],
        cargoType: data.cargoType,
        carrierNote: data.carrierNote,
        loadingTypes: data.loadingTypes,
        truckTypeCode: data.truckTypeCode,
        transportationType: data.transportationType,
        requirements: data.specialRequirements,
        responsiblePersonId: data.person?.id,
        notifiedPersonIds: data.persons?.map(t => t.id),
        invoiceState: data.invoiceState,
        disponentPersonId: data.disponentPerson?.id,
        pairingSymbol: data.pairingSymbol,
        internalNote: data.internalNote,
        rebiling: data.rebiling,
        folderId: data.folder?.id,
        attachments: data.attachments?.map(t => ({
          attachmentId: t.attachmentId,
          documentType: t.documentType,
          userNote: t.userNote,
          userDate: isNotNullOrUndefined(t.userDate) ? mapToAPIDateTime(dayjs(t.userDate)) : null,
          accessType: AccessTypeEnum.ALL,
        })),
      } as SaveExpeditionRequestInDTO,
      d => {
        nav.navigate(nav.urlFunctions.createExpeditionDetailV2(d.expeditionId));
        showToastMessage('Uspech', `Expedice ${d.applicationId} byla vytvorena`, 'success');
      },
      null,
      { onValidationFailed: setBeValidationErrors },
    );
  };

  const addStop = (index: number) => {
    const newOrder = {
      orders: null,
      id: generateUUID(),
      unloadingOrderIds: null,
      timeslots: { dayWithInterval: [] },
      localityMode: LocalityEntryModeEnum.FROM_LOCATIONS,
    } as ExpeditionLocality;

    // add stop to the form immatubly
    form.setFieldValue('locations', [...form.values.locations.slice(0, index + 1), newOrder, ...form.values.locations.slice(index + 1)]);
  };

  const deleteLocation = (locIndex: number) => {
    form.useProduce(draft => {
      const unloadingOrderIds = draft.locations[locIndex].unloadingOrderIds || [];
      const allLoadingOrdersIds = draft.locations[locIndex].orders?.map(t => t.id) || [];
      draft.locations = draft.locations.filter((_, i) => i !== locIndex);

      // Ensure the last location has unloadingOrderIds initialized
      if (!draft.locations[draft.locations.length - 1].unloadingOrderIds) {
        draft.locations[draft.locations.length - 1].unloadingOrderIds = [];
      }

      draft.locations[draft.locations.length - 1].unloadingOrderIds = [
        ...draft.locations[draft.locations.length - 1].unloadingOrderIds.filter(t => !allLoadingOrdersIds.includes(t)),
        ...unloadingOrderIds,
      ];
    });
  };

  const getUnloadableOrders = (locIndex: number): GlobalOrdersRequest[] => {
    const prevLocations = form.values.locations.slice(0, locIndex);
    return prevLocations
      .map(loc => loc.orders)
      .flat()
      .filter(t => t != null)
      .filter(t => isNotBlank(t.orderName));
  };

  const unloadOrder = (locIndex: number, orderId: string) => {
    const previousLocation = form.values.locations.find(t => t.unloadingOrderIds?.includes(orderId));
    if (previousLocation) {
      const prevIndex = form.values.locations.indexOf(previousLocation);
      form.setFieldValue(
        `locations[${prevIndex}].unloadingOrderIds`,
        previousLocation.unloadingOrderIds.filter(t => t !== orderId),
      );
    }
    form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, Array.from(new Set([...(form.values.locations[locIndex].unloadingOrderIds || []), orderId])));
  };

  const getOrderName = (orderId: string): string => {
    const order = form.values.locations
      .map(loc => loc.orders)
      .flat()
      .filter(t => t != null)
      .find(t => t.id === orderId);
    return order?.orderName || '';
  };

  const deleteUnloadingLocation = (locIndex: number) => {
    const lastLocationIndex = form.values.locations.length - 1;
    form.setFieldValue(
      `locations[${lastLocationIndex}].unloadingOrderIds`,
      [...form.values.locations[lastLocationIndex].unloadingOrderIds, ...form.values.locations[locIndex].unloadingOrderIds],
      () => {
        form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, null);
      },
    );
  };

  const unloadNewlyAddedOrder = (orderId: string) => {
    const lastLocationIndex = form.values.locations.length - 1;
    const unloadingOrderIds = form.values.locations[lastLocationIndex]?.unloadingOrderIds || [];
    form.setFieldValue(`locations[${lastLocationIndex}].unloadingOrderIds`, [...unloadingOrderIds, orderId]);
  };

  const deleteOrderFromUnloadingLocation = (orderId: string) => {
    const location = form.values.locations.find(t => t.unloadingOrderIds?.includes(orderId));
    if (location) {
      const locIndex = form.values.locations.indexOf(location);
      form.setFieldValue(
        `locations[${locIndex}].unloadingOrderIds`,
        location.unloadingOrderIds.filter(t => t !== orderId),
      );
    }
  };

  const uniqId = useId().replace(/:/g, '');

  // Direct fix for onActivateUnloading
  const onActivateUnloading = (locIndex: number) => {
    // Get all order IDs from previous locations
    const prevLocations = form.values.locations.slice(0, locIndex);
    const allOrderIds = prevLocations
      .map(loc => loc.orders || [])
      .flat()
      .filter(t => t != null)
      .map(t => t.id);

    // Directly set unloadingOrderIds with all order IDs
    form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, [...allOrderIds]);
  };

  // Function to handle adding a new order
  const onOrderAdded = (locIndex: number, orderId: string) => {
    // Only set unloadingOrderIds for locations after the current one
    // For the current location (loading), we don't add the order to unloadingOrderIds
    form.values.locations.forEach((loc, idx) => {
      if (idx > locIndex && loc.unloadingOrderIds) {
        form.setFieldValue(`locations[${idx}].unloadingOrderIds`, [...loc.unloadingOrderIds, orderId]);
      }
    });
  };

  // Function to handle deleting an order
  const onDeleteOrder = (locIndex: number, orderId: string) => {
    form.useProduce(draft => {
      if (draft.locations[locIndex].unloadingOrderIds) {
        draft.locations[locIndex].unloadingOrderIds = draft.locations[locIndex].unloadingOrderIds.filter(id => id !== orderId);
      }
    });
    deleteOrderFromUnloadingLocation(orderId);
  };

  interface UnloadingOrdersCheckboxProps {
    locIndex: number;
    location: ExpeditionLocality[];
    orders: GlobalOrdersRequest[];
    unloadingOrderIds: string[] | null;
    onChange: (newUnloadingIds: string[]) => void;
    onAllCheckboxChange?: (isChecked: boolean) => void;
  }

  const UnloadingOrdersCheckbox: React.FC<UnloadingOrdersCheckboxProps> = ({ locIndex, location, orders, unloadingOrderIds, onChange, onAllCheckboxChange }) => {
    // Local state to track checkbox state
    const [checkboxes, setCheckboxes] = useState<{
      all: boolean;
      items: Record<string, boolean>;
    }>({
      all: false,
      items: {},
    });

    const { tr } = useTranslationLgs();

    // Get unloadable orders from previous locations
    const getUnloadableOrdersForLocation = (): GlobalOrdersRequest[] => {
      if (!location) return [];

      const prevLocations = location.slice(0, locIndex);
      const allOrders = prevLocations
        .map(loc => loc.orders || [])
        .flat()
        .filter(t => t != null && isNotBlank(t.orderName));

      return allOrders;
    };

    const unloadableOrders = getUnloadableOrdersForLocation();

    // Calculate initial state and update whenever props change
    useEffect(() => {
      const orderIds = unloadableOrders.map(o => o.id);
      const unloadIds = unloadingOrderIds || [];

      // If no changes in data, skip update
      const hasNewOrderIds = !orderIds.every(id => Object.prototype.hasOwnProperty.call(checkboxes.items, id));
      const hasChangedCheckedState = orderIds.some(id => checkboxes.items[id] !== unloadIds.includes(id));

      if (!hasNewOrderIds && !hasChangedCheckedState) {
        return;
      }

      // Check if all orders are selected
      const allSelected = orderIds.length > 0 && orderIds.every(id => unloadIds.includes(id));

      // Create individual checkbox states
      const itemStates: Record<string, boolean> = {};
      orderIds.forEach(id => {
        itemStates[id] = unloadIds.includes(id);
      });

      // Update the state
      setCheckboxes({
        all: allSelected,
        items: itemStates,
      });

      // Notify parent about the "all" checkbox state
      if (onAllCheckboxChange) {
        onAllCheckboxChange(allSelected);
      }

      // Auto-select orders on first load
      if (orderIds.length > 0 && unloadIds.length === 0) {
        setTimeout(() => {
          onChange([...orderIds]);
        }, 50);
      }
    }, [locIndex, unloadableOrders, unloadingOrderIds]);

    // Toggle the "all" checkbox
    const toggleAll = (e: { checked: boolean }) => {
      const newAllState = e.checked;
      const orderIds = unloadableOrders.map(o => o.id);

      // Update local state
      const newItemStates: Record<string, boolean> = {};
      orderIds.forEach(id => {
        newItemStates[id] = newAllState;
      });

      setCheckboxes({
        all: newAllState,
        items: newItemStates,
      });

      // Call parent onChange with new unloadingOrderIds
      const newUnloadingIds = newAllState ? [...orderIds] : [];
      onChange(newUnloadingIds);

      // Notify parent about the "all" checkbox state change
      if (onAllCheckboxChange) {
        onAllCheckboxChange(newAllState);
      }
    };

    // Toggle an individual checkbox
    const toggleItem = (orderId: string, e: { checked: boolean }) => {
      const newItemState = e.checked;
      const orderIds = unloadableOrders.map(o => o.id);

      // Update local state
      const newItemStates = { ...checkboxes.items, [orderId]: newItemState };

      // Check if all are now selected
      const newAllState = orderIds.every(id => newItemStates[id]);

      setCheckboxes({
        all: newAllState,
        items: newItemStates,
      });

      // Get current unloadingOrderIds
      const currentIds = unloadingOrderIds || [];

      // Calculate new unloadingOrderIds
      let newUnloadingIds: string[];
      if (newItemState) {
        // If checking an item, add it to the array
        newUnloadingIds = [...currentIds, orderId];
      } else {
        // If unchecking an item, remove it from the array
        newUnloadingIds = currentIds.filter(id => id !== orderId);
      }

      onChange(newUnloadingIds);

      // Notify parent about the "all" checkbox state change if it changed
      if (onAllCheckboxChange && newAllState !== checkboxes.all) {
        onAllCheckboxChange(newAllState);
      }
    };

    return (
      <div className="unloading-orders-checkbox">
        {/* "All" checkbox */}
        <div className="flex items-center mb-3">
          <Checkbox inputId={`unload-all-${locIndex}`} checked={checkboxes.all} onChange={toggleAll} className="mr-2" />
          <label htmlFor={`unload-all-${locIndex}`} className="font-medium">
            {tr('CreateExpedition.vsechnyObjednavky', 'Všechny objednávky')}
          </label>
        </div>

        {/* Individual order checkboxes */}
        {unloadableOrders.length > 0 ? (
          unloadableOrders.map(order => (
            <div key={order.id} className="flex items-center ml-4 mb-2">
              <Checkbox inputId={`unload-order-${order.id}`} checked={checkboxes.items[order.id] || false} onChange={e => toggleItem(order.id, e)} className="mr-2" />
              <label htmlFor={`unload-order-${order.id}`} className="text-sm">
                {order.orderName || `Order ${order.id.substring(0, 8)}`}
              </label>
            </div>
          ))
        ) : (
          <div className="text-sm text-gray-500 ml-4 mb-2">{tr('CreateExpedition.zadneObjednavky', 'No orders to unload')}</div>
        )}
      </div>
    );
  };

  // This ensures all locations have unloadingOrderIds initialized
  useEffect(() => {
    if (!hasMounted) return;

    const unprocessedLocations = form.values.locations
      .slice(1) // Skip first location
      .filter(loc => loc.unloadingOrderIds === null || loc.unloadingOrderIds === undefined);

    if (unprocessedLocations.length === 0) return;

    form.values.locations.forEach((loc, index) => {
      if (index === 0) return; // Skip first location
      if (loc.unloadingOrderIds !== null && loc.unloadingOrderIds !== undefined) return; // Skip already initialized

      // Get unloadable orders for this location
      const prevLocations = form.values.locations.slice(0, index);
      const unloadableOrderIds = prevLocations
        .map(loc => loc.orders || [])
        .flat()
        .filter(t => t != null)
        .map(t => t.id);

      // Only update if we have orders to initialize
      if (unloadableOrderIds.length > 0) {
        form.setFieldValue(`locations[${index}].unloadingOrderIds`, [...unloadableOrderIds]);
      }
    });
  }, [hasMounted]); // Only run after initial mount

  // Function to sync orders across locations
  const syncOrdersToFollowingLocations = (sourceLocIndex: number, orderId: string) => {
    // Update all subsequent locations' unloadingOrderIds to include this order
    form.values.locations.forEach((loc, index) => {
      // Only update locations after the source location (not the loading location itself)
      if (index <= sourceLocIndex || !loc.unloadingOrderIds) return;
      if (!loc.unloadingOrderIds.includes(orderId)) {
        form.setFieldValue(`locations[${index}].unloadingOrderIds`, [...loc.unloadingOrderIds, orderId]);
      }
    });
  };

  // Component to handle the unloading orders section
  interface UnloadingSectionProps {
    locIndex: number;
    loc: ExpeditionLocality;
    locations: ExpeditionLocality[];
    canDelete: boolean;
    onDelete: () => void;
  }

  const UnloadingSection: React.FC<UnloadingSectionProps> = ({ locIndex, loc, locations, canDelete, onDelete }) => {
    const { tr } = useTranslationLgs();
    const [allChecked, setAllChecked] = useState(false);

    return (
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-row justify-between items-center'}>
          <COHeadline variant={'h4'} title={tr('CreateExpedition.vylozitObjednavky', 'Unload orders')} icon={<SvgLoadDown />} />
          {canDelete && <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={onDelete} />}
        </div>

        <UnloadingOrdersCheckbox
          locIndex={locIndex}
          location={locations}
          orders={getUnloadableOrders(locIndex)}
          unloadingOrderIds={loc.unloadingOrderIds}
          onChange={newUnloadingIds => {
            form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, newUnloadingIds);
          }}
          onAllCheckboxChange={setAllChecked}
        />

        {/* Only show dropdown when not all orders are selected */}
        {!allChecked && (
          <Field label={tr('CreateExpedition.vylozitDalsiObjednavku', 'Unload next order')}>
            <Dropdown
              options={getUnloadableOrders(locIndex)}
              optionLabel={'orderName'}
              optionValue={'id'}
              value={null}
              onChange={e => {
                unloadOrder(locIndex, e.value);
              }}
            />
          </Field>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <BreadCrumbComponent items={[{ label: 'Expedice', url: nav.createNavigationLink(nav.urlFunctions.createExceptionList()) }]} />
        <BackendValidationComponent />
        <div className={'flex flex-row gap-4'}>
          <COHeadline variant={'h2'} title={tr('CreateExpedition.novaExpedice', 'New shipment')} />
          {/*<LabelsTag />*/}
        </div>
        <div className={'flex flex-col gap-2 my-4'}>
          <div className={'flex flex-row gap-4'}>
            <StateTag expeditionState={ExpeditionStatusEnum.NEW} />
            {loggedUser?.features.indexOf(FeaturesEnum.CUSTOM_EXP_APP_ID) != -1 && (
              <CustomerApplicationIdTag
                customApplicationId={form.values.customApplicationId}
                onChange={v =>
                  form.useProduce(d => {
                    d.customApplicationId = v;
                  })
                }
              />
            )}

            <TransportationTypeTag
              transportationType={form.values.transportationType}
              onChange={v => {
                form.useProduce(d => {
                  d.transportationType = v;
                });
              }}
            />

            <ExpeditionTypeTag
              value={form.values.expeditionType}
              onChange={v => {
                form.useProduce(d => {
                  d.expeditionType = v;
                });
              }}
            />

            <ManipulationTag
              value={form.values.loadingTypes}
              onChange={v =>
                form.useProduce(d => {
                  d.loadingTypes = v;
                })
              }
            />
          </div>
          <div className={'flex flex-row gap-4'}>
            <TruckTag
              truckTypeCode={form.values.truckTypeCode}
              onChange={v =>
                form.useProduce(d => {
                  d.truckTypeCode = v;
                })
              }
            />

            <CargoTypeTag
              cargoType={form.values.cargoType}
              onChange={v =>
                form.useProduce(d => {
                  d.cargoType = v;
                })
              }
            />
          </div>
          <div className={'flex flex-row gap-4'}>
            {loggedUser && (
              <RequirementsTag
                valueCodes={form.values.specialRequirements}
                onChange={v => {
                  form.useProduce(draft => {
                    draft.specialRequirements = v;
                  });
                }}
                prefillEnabled={true}
                companyId={loggedUser.companyId}
              />
            )}

            <CarrierNoteTag
              value={form.values.carrierNote}
              onChange={v =>
                form.useProduce(draft => {
                  draft.carrierNote = v;
                })
              }
            />
          </div>
        </div>
        {/*{dumpVars(form.values)}*/}
        <SectionPanel icon={<SvgRoute />} heading={tr('CreateExpedition.trasa', 'Route')}>
          <AnimatePresence>
            {form.values?.locations?.map((loc, locIndex, locArray) => {
              return (
                <motion.div
                  key={loc.id}
                  className={'flex flex-col gap-4'}
                  initial={hasMounted ? { opacity: 0, y: 10 } : false}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className={'flex flex-row items-center justify-between mr'}>
                    <NavigationBreadCrumb locationCount={form.values?.locations?.length} currentPosition={locIndex} idPrefix={'createExp'} />
                    {locIndex !== 0 && locIndex !== locArray.length - 1 && (
                      <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => deleteLocation(locIndex)} />
                    )}
                  </div>
                  <div className={'grid grid-cols-2 gap-4'}>
                    <Card>
                      <div className={'flex flex-col gap-4'}>
                        <COHeadline title={tr('CreateExpedition.lokalita', 'Location')} variant={'h4'} icon={<SvgMapPin />} />
                        <InputSelect
                          selectedItemCode={loc.localityMode}
                          model={[
                            {
                              label: tr('CreateExpedition.existujici', 'Existing'),
                              code: LocalityEntryModeEnum.FROM_LOCATIONS,
                            },
                            {
                              label: tr('CreateExpedition.zadatRucne', 'Enter manually'),
                              code: LocalityEntryModeEnum.MANUALLY,
                            },
                          ]}
                          onSelect={v => {
                            form.useProduce(draft => {
                              if (v === LocalityEntryModeEnum.MANUALLY) {
                                draft.locations[locIndex].location = null;
                                draft.locations[locIndex].manualAddress = {};
                              } else {
                                draft.locations[locIndex].manualAddress = null;
                              }
                              draft.locations[locIndex].localityMode = v as LocalityEntryModeEnum;
                            });
                          }}
                        />

                        {loc.localityMode === LocalityEntryModeEnum.FROM_LOCATIONS && (
                          <>
                            <Field
                              label={tr('CreateExpedition.lokace', 'Location')}
                              required
                              errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].companyLocationId`, tr)}
                            >
                              <LocationSelect
                                fullWidth
                                value={loc.location?.locationId}
                                onChange={v => {
                                  const firstContact = v.contacts?.find((_, index) => index == 0);

                                  form.useProduce(draft => {
                                    draft.locations[locIndex].location = v;
                                    draft.locations[locIndex].companyLocationId = v.locationId;
                                    if (isNotNullOrUndefined(firstContact)) {
                                      draft.locations[locIndex].selectedPerson = {
                                        contactEmail: firstContact.contactEmail,
                                        contactName: firstContact.contactName,
                                        contactPhone: firstContact.contactPhone,
                                      };
                                    }
                                  });
                                }}
                              />
                            </Field>
                            {isNotNullOrUndefined(loc.location) && (
                              <Field label={tr('CreateExpedition.adresa', 'Address')} required>
                                <ReadonlyInput
                                  value={formatAddress(
                                    loc.location.addressTemplate.country,
                                    loc.location.addressTemplate.postalCode,
                                    loc.location.addressTemplate.city,
                                    loc.location.addressTemplate.streetNr,
                                  )}
                                />
                              </Field>
                            )}
                            <Field
                              label={tr('CreateExpedition.hlavniKontakt', 'Main contact')}
                              required
                              errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].selectedPerson`, tr)}
                            >
                              <InputContact
                                contacts={loc.location?.contacts}
                                value={loc?.selectedPerson}
                                onChange={v => form.setFieldValue(`locations[${locIndex}].selectedPerson`, v)}
                                fullWidth
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.notifikovatOPreprave', 'Notify about shipment')}>
                              <MultiSelect
                                options={loc.location?.contacts}
                                optionLabel={'contactName'}
                                value={loc.additionalContacts}
                                onChange={e => form.setFieldValue(`locations[${locIndex}].additionalContacts`, e.value)}
                              />
                            </Field>
                          </>
                        )}
                        {loc.localityMode === LocalityEntryModeEnum.MANUALLY && (
                          <>
                            <Field
                              required
                              label={tr('CreateExpedition.nazevLokace', 'Location name')}
                              errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].manualAddress.name`, tr)}
                            >
                              <RingilInputText
                                value={loc.manualAddress?.name}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.name = v;
                                  })
                                }
                              />
                            </Field>
                            <div className={'grid grid-cols-2 gap-4'}>
                              <Field label={tr('CreateExpedition.psc', 'Postal code')}>
                                <RingilInputText
                                  value={loc.manualAddress.postalCode}
                                  onChange={v =>
                                    form.useProduce(draft => {
                                      draft.locations[locIndex].manualAddress.postalCode = v;
                                    })
                                  }
                                />
                              </Field>
                              <Field label={tr('CreateExpedition.zeme', 'Country')}>
                                <InputCountry
                                  value={loc.manualAddress.countryCode3}
                                  onChange={v => {
                                    form.useProduce(d => {
                                      d.locations[locIndex].manualAddress.countryCode3 = v;
                                    });
                                  }}
                                />
                              </Field>
                            </div>
                            <Field
                              required
                              label={tr('CreateExpedition.mesto', 'City')}
                              errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].manualAddress.city`, tr)}
                            >
                              <RingilInputText
                                value={loc.manualAddress?.city}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.city = v;
                                  })
                                }
                              />
                            </Field>
                            <Field
                              required
                              label={tr('CreateExpedition.uliceCp', 'Street, number')}
                              errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].manualAddress.streetNr`, tr)}
                            >
                              <RingilInputText
                                value={loc.manualAddress?.streetNr}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.streetNr = v;
                                  })
                                }
                              />
                            </Field>
                            <COHeadline variant={'h4'} title={tr('CreateExpedition.kontakty', 'Contacts')} />
                            <Field label={tr('CreateExpedition.hlavniKontaktJmeno', 'Main contact name')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactPerson}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactPerson = v;
                                  })
                                }
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.hlavniKontaktniTelefon', 'Main contact phone')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactPhone}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactPhone = v;
                                  })
                                }
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.hlavniKontaktniEmail', 'Main contact email')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactEmail}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactEmail = v;
                                  })
                                }
                              />
                            </Field>
                            {loc.manualAddress.additionalPersons?.length > 0 && (
                              <COHeadline variant={'h4'} title={tr('CreateExpedition.notifikovatEmailemOPreprave', 'Notify by email')} />
                            )}

                            {loc.manualAddress.mainContactEmail && loc.manualAddress.mainContactPerson && (
                              <InputCheckbox
                                value={loc.manualAddress.notifyContactPerson}
                                label={tr('CreateExpedition.hlavniKontakt', 'Main contact')}
                                note={loc.manualAddress.mainContactEmail}
                                onChange={(_, v) =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.notifyContactPerson = v;
                                  })
                                }
                              />
                            )}
                            {loc.manualAddress.additionalPersons?.map((person, personIndex) => {
                              return (
                                <React.Fragment key={personIndex}>
                                  <div className={'grid grid-cols-2 gap-4'}>
                                    <Field label={tr('CreateExpedition.jmenoOsoby', 'Name of person')} required>
                                      <RingilInputText
                                        value={person.name}
                                        onChange={v =>
                                          form.useProduce(draft => {
                                            draft.locations[locIndex].manualAddress.additionalPersons[personIndex].name = v;
                                          })
                                        }
                                      />
                                    </Field>
                                    <Field
                                      label={tr('CreateExpedition.notifikovatEmailemOPreprave', 'Notify by email')}
                                      required
                                      actionButtons={
                                        <RingilButton
                                          mode={'rect-small'}
                                          leftIcon={<SvgCoButtonDelete />}
                                          onClick={() => {
                                            form.useProduce(draft => {
                                              draft.locations[locIndex].manualAddress.additionalPersons = draft.locations[
                                                locIndex
                                              ].manualAddress.additionalPersons.filter((_, i) => i !== personIndex);
                                            });
                                          }}
                                        />
                                      }
                                    >
                                      <RingilInputText
                                        value={person.email}
                                        onChange={v =>
                                          form.useProduce(d => {
                                            d.locations[locIndex].manualAddress.additionalPersons[personIndex].email = v;
                                          })
                                        }
                                      />
                                    </Field>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                            <LinkWithIcon
                              label={tr('CreateExpedition.notifikovatDalsiEmail', 'Notify another email')}
                              icon={<SvgIconPlus />}
                              onClick={() => {
                                form.useProduce(draft => {
                                  if (draft.locations[locIndex].manualAddress.additionalPersons == null) {
                                    draft.locations[locIndex].manualAddress.additionalPersons = [];
                                  }
                                  draft.locations[locIndex].manualAddress.additionalPersons.push({
                                    name: '',
                                    email: '',
                                  });
                                });
                              }}
                            ></LinkWithIcon>
                          </>
                        )}

                        <Field label={tr('CreateExpedition.referenceProNakladku', 'Reference for loading')}>
                          <RingilInputText value={loc.loadingReference} onChange={v => form.setFieldValue(`locations[${locIndex}].loadingReference`, v)} />
                        </Field>
                        <Field
                          label={tr('CreateExpedition.ocekavanaManipulace', 'Expected manipulation')}
                          required
                          errorMessage={findValidationMessage(form.validationErrors, `locations[${locIndex}].timeslots.dayWithInterval`, tr)}
                        >
                          <ManageTimeslot
                            timeslot={loc.timeslots}
                            externalLabel
                            onChange={v => {
                              form.setFieldValue(`locations[${locIndex}].timeslots`, v);
                            }}
                            entityId={loc.location?.locationId}
                            entityType={OpeningEntityTypeEnum.LOCATION}
                            noOpeningHours={false}
                            validationErrors={form.validationErrors}
                            validationPrefix={`locations[${locIndex}].timeslots.`}
                          />
                        </Field>
                      </div>
                    </Card>

                    {/*// zde je pravy panel*/}
                    <div className={'flex flex-col gap-4'}>
                      {loc.orders?.length > 0 ? (
                        <div className={'flex flex-col gap-4'}>
                          <Card>
                            <OrdersComponent
                              config={expeditionConfig}
                              canBeEmpty={true}
                              generateOrderId={true}
                              onOrderAdded={orderId => {
                                // Don't add to unloadingOrderIds for loading location
                                // Only sync to following locations
                                syncOrdersToFollowingLocations(locIndex, orderId);
                              }}
                              onDeleteOrder={orderId => {
                                deleteOrderFromUnloadingLocation(orderId);
                              }}
                              onChange={(ordersList: Order[]) => {
                                // Get current orders to identify new ones
                                const currentOrders = form.values.locations[locIndex].orders || [];
                                const currentOrderIds = currentOrders.map(o => o.id);

                                // Find any new order IDs
                                const newOrderIds = ordersList.map(o => o.id).filter(id => !currentOrderIds.includes(id));

                                // Update the orders list
                                form.setFieldValue(`locations[${locIndex}].orders`, ordersList);

                                // Only propagate new orders to following locations
                                if (newOrderIds.length > 0) {
                                  setTimeout(() => {
                                    newOrderIds.forEach(orderId => {
                                      syncOrdersToFollowingLocations(locIndex, orderId);
                                    });
                                  }, 50);
                                }
                              }}
                              validationErrors={form.validationErrors
                                .filter(t => t.path.startsWith(`locations[${locIndex}].orders`))
                                .map(t => ({ ...t, path: t.path.replace(`locations[${locIndex}].orders`, 'orders') }))}
                              // @ts-ignore
                              orders={loc.orders}
                            />
                          </Card>
                        </div>
                      ) : (
                        <>
                          {locIndex < locArray.length - 1 && (
                            <div className={'flex flex-col gap-4'}>
                              <AddButton
                                title={tr('CreateExpedition.nakladka', 'Loading')}
                                fullWidth
                                onClick={() => {
                                  const newOrder = emptyOrder(expeditionConfig.cargo.required, true);

                                  // Get current orders
                                  const currentOrders = form.values.locations[locIndex].orders || [];

                                  // Update orders list directly with setFieldValue
                                  form.setFieldValue(`locations[${locIndex}].orders`, [...currentOrders, newOrder]);

                                  // Do NOT add to unloadingOrderIds for the current (loading) location
                                  // Only sync to following locations
                                  syncOrdersToFollowingLocations(locIndex, newOrder.id);
                                }}
                                type={'ROUNDED'}
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div className={'flex flex-row gap-4'}>
                        {isNullOrUndefined(loc.unloadingOrderIds) && locIndex !== 0 ? (
                          <AddButton
                            title={tr('CreateExpedition.vykladka', 'Unloading')}
                            fullWidth
                            onClick={() => onActivateUnloading(locIndex)}
                            type={'ROUNDED'}
                            hideButton={!(locIndex < locArray.length - 1)}
                          />
                        ) : (
                          <>
                            {locIndex !== 0 && (
                              <Card>
                                <UnloadingSection
                                  locIndex={locIndex}
                                  loc={loc}
                                  locations={form.values.locations}
                                  canDelete={locIndex < locArray.length - 1}
                                  onDelete={() => deleteUnloadingLocation(locIndex)}
                                />
                              </Card>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {locIndex < locArray.length - 1 && (
                    <AddButton title={tr('CreateExpedition.zastavka', 'Stop')} fullWidth onClick={() => addStop(locIndex)} type={'ROUNDED'} />
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
          <AttachmentsList
            attachments={form.values.attachments}
            onDeletedAttachment={attachmentId =>
              form.setFieldValue(
                form.names.attachments,
                form.values.attachments.filter(t => t.attachmentId !== attachmentId),
              )
            }
          ></AttachmentsList>
          <AttachmentUploadPanel
            onSave={v => {
              form.setFieldValue(form.names.attachments, [...(form.values.attachments || []), ...v.attachments]);
            }}
            loading={false}
          />

          <HorizontalLine variant={'tertiary'} />
          <CreateDistributionOrAssign distribution={form.values.distribution} onChange={v => form.setFieldValue(form.names.distribution, v)} />
          <COHeadline variant={'h3'} title={tr('CreateExpedition.interniInfo', 'Internal info')} />
          <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-row gap-4'}>
              <ResponsiblePersonTag
                person={form.values.person}
                onChange={v => {
                  form.useProduce(d => {
                    d.person = v;
                  });
                }}
              />

              <InternalInfoNotifyTag
                persons={form.values.persons}
                onChange={v => {
                  form.useProduce(d => {
                    d.persons = v;
                  });
                }}
              />

              <InternalInfoNotifyByRoleTag
                notifyRoleName={RoleEnum.ROLE_EXP_NOTIFY}
                isActive={true}
                onChange={v => {
                  form.useProduce(d => {
                    d.notifyByRole = v;
                  });
                }}
              />
            </div>
            <div className={'flex flex-row gap-4'}>
              <InternalInfoInvoiceState
                value={form.values.invoiceState}
                onChange={v => {
                  form.useProduce(d => {
                    d.invoiceState = v;
                  });
                }}
              />

              <InternalInfoDisponentTag
                disponentPerson={form.values.disponentPerson}
                onChange={v => {
                  form.useProduce(d => {
                    d.disponentPerson = v;
                  });
                }}
              />

              <InternalInfoPairingSymbol
                value={form.values.pairingSymbol}
                onChange={v => {
                  form.useProduce(d => {
                    d.pairingSymbol = v;
                  });
                }}
              />

              <InternalNoteTag
                value={form.values.internalNote}
                onChange={v => {
                  form.useProduce(d => {
                    d.internalNote = v;
                  });
                }}
              />

              <InternalInfoRebillingTag
                value={form.values.rebiling}
                onChange={v => {
                  form.useProduce(d => {
                    d.rebiling = v;
                  });
                }}
              />

              <InternalInfoFolderTag
                folder={form.values.folder}
                onChange={v => {
                  form.useProduce(d => {
                    d.folder = v;
                  });
                }}
                entityType={EntityTypeEnum.EXPEDITION}
              />
            </div>
          </div>
          <div className={'flex flex-row mx-4 my-3'}>
            <RingilButton mode="filled-big" label={tr('CreateExpedition.vytvorit', 'Create')} onClick={() => form.validateAndSend()} />
          </div>
        </SectionPanel>
      </div>
    </>
  );
};

export default CreateExpedition;
