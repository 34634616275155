import * as yup from 'yup';
import { RouteType } from '../new-expedition/types';
import { DistributionTypeEnum, ExpeditionTypeEnum, WeightModeEnum } from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';

/** step 2 **/
const locationSchema = yup.object({
  name: yup.string().defined().min(1),
  streetNr: yup.string().defined().min(1),
  city: yup.string().defined().min(1),
  country: yup.string().defined().min(1),
  zipCode: yup.string().defined().min(1),
  contactName: yup.string().nullable(),
  contactPhone: yup.string().nullable(),
  contactEmail: yup.string().email().nullable(),
});

export const stateStep2 = yup.object({
  routeType: yup.string().defined().oneOf([RouteType.DIRECT, RouteType.ROUND_TRIP]),
  locations: yup.array().of(locationSchema),
  templateName: yup.string().defined().required(),
});
/**  end - step 2 **/

const orderValidation = yup.object({
  amount: yup.number().defined().min(1),
  cargoItemType: yup.object().defined(),
  quantityUnit: yup.string().defined(),
  height: yup.number().defined().min(1),
  width: yup.number().defined().min(1),
  length: yup.number().defined().min(1),
  weight: yup.number().defined().min(1),
  weightMode: yup.string().defined().oneOf([WeightModeEnum.SKU_WEIGHT, WeightModeEnum.TOTAL_WEIGHT]),
});

const locationOrder = yup.object().shape({
  items: yup
    .array()
    .of(orderValidation)
    .when('loadingLocation', {
      is: true,
      then: yup.array().of(orderValidation).min(1),
    }),
});

const timeslotValidation = yup.object().shape({
  dayOfWeek: yup.string().defined().required(),
  intervals: yup.array().of(
    yup.object().shape({
      sinceTimeUtc: yup.string().defined().required(),
      tillTimeUtc: yup.string().defined().required(),
    }),
  ),
});

const stage3LocationWithOrders = yup.object().shape({
  timeslots: yup.array().of(timeslotValidation),
  loadingOrders: yup.array().of(locationOrder),
});

const stage3LocationWithoutOrders = yup.object().shape({
  timeslots: yup.array().of(timeslotValidation),
});

export const stage3Validation = yup.object().shape({
  manipulationTypes: yup.array().required().min(1),
  locations: yup
    .array()
    .nullable()
    .when('expeditionType', {
      is: ExpeditionTypeEnum.LESS_TRUCK_LOAD,
      then: yup.array().of(
        yup.object({
          loadingOrders: yup
            .array()
            .nullable()
            .of(
              yup.object({
                items: yup.array().of(orderValidation),
                unloadingLocationId: yup.mixed().test('notUnloaded', 'notUnloaded ', (value: any) => !isNullOrUndefined(value)),
              }),
            ),
        }),
      ),
    }),
});

export const stage4validation = yup.object().shape({
  distributionType: yup.string().when('enabled', {
    is: true,
    then: yup.string().oneOf([DistributionTypeEnum.FIXED_PRICE, DistributionTypeEnum.SPOT]),
  }),
  price: yup.number().when('enabled', {
    is: true,
    then: yup.number().when('distributionType', {
      is: DistributionTypeEnum.FIXED_PRICE,
      then: yup.number().defined().min(0),
      otherwise: yup.number().nullable().min(0),
    }),
    otherwise: yup.number(),
  }),
  selectedItems: yup.array().when('enabled', { is: true, then: yup.array().min(1) }),
});
