import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputSwitch } from 'primereact/inputswitch';
import { SectionHeading } from '@components/styles.tsx';

interface ComponentProps {
  buttons?: any;
  label: string;
  showToggle?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  onVisibilityChanged?: (visible: boolean) => void;
}

const Section: React.FC<PropsWithChildren<ComponentProps>> = ({
                                                                label,
                                                                buttons,
                                                                children,
                                                                showToggle = false, // renamed
                                                                isExpanded = false, // renamed
                                                                isDisabled = false, // new
                                                                onVisibilityChanged = v => {
                                                                },
                                                              }) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    setShowChildren(isExpanded);
    onVisibilityChanged(isExpanded);
  }, [isExpanded]);

  const childrenEnabled = showToggle == false || showChildren;
  return (
    <SectionInner>
      <Grid>
        <SectionHeading
          color={isDisabled ? 'var(--ringil3-content-tertiary-color)' : 'inherit'}>{label}</SectionHeading>
        {buttons && (
          <div>
            <Buttons>{buttons}</Buttons>
          </div>
        )}
        {showToggle && (
          <InputSwitch
            checked={showChildren}
            onChange={v => {
              onVisibilityChanged(v.value);
              setShowChildren(v.value);
            }}
            disabled={isDisabled}
          />
        )}
      </Grid>
      {childrenEnabled && (
        <div className={'flex flex-col gap-4'}>{children}</div>
      )}

    </SectionInner>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
`;

const SectionInner = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  background-color: var(--ringil3-base-tertiary-color);
  margin: 0.5rem 0;
  gap: 0.5rem;
`;

export default Section;
