import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { TruckTypeWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgTrucktype from '@components/ringil3/icons/Trucktype.tsx';
import { ApiContext } from '@api/api.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  truckTypeCode?: string;
  onChange: (truckTypeCode: string) => void;
  readOnly?: boolean;
}

const TruckTag: React.FC<ComponentProps> = ({ truckTypeCode, onChange, readOnly = false }) => {
  const { tr } = useTranslationLgs();
  const { lovTruckTypes } = useContext(ApiContext);
  const [options, setOptions] = useState<TruckTypeWithoutIdDTO[]>();
  const acRef = useRef<AbortController | null>();
  const [internalValue, setInternalValue] = useState(truckTypeCode);

  useEffect(() => {
    acRef.current = new AbortController();

    lovTruckTypes(d => {
      if (acRef.current.signal.aborted) return;
      setOptions(d);
    });

    return () => {
      acRef.current?.abort();
      acRef.current = null;
    };
  }, []);

  const formatValue = (code: string) => {
    const item = options?.find(i => i.code === code);
    return item ? item.name : null;
  };

  return (
    <>
      <ButtonTag
        label={tr('TruckTag.typAutomobilu', 'Typ automobilu')}
        value={formatValue(truckTypeCode)}
        icon={<SvgTrucktype />}
        onSave={onSuccess => {
          onSuccess();
          onChange(internalValue);
        }}
        readonly={readOnly}
        autoClose
      >
        <Dropdown options={options} value={internalValue} optionLabel={'name'} optionValue={'code'} onChange={e => setInternalValue(e.target.value)} />
      </ButtonTag>
    </>
  );
};

export default TruckTag;
