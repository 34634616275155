import React, { useContext, useEffect, useMemo, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import InputSpotDirect from '@components/ringil3/Input/InputSpotDirect/InputSpotDirect';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency';
import { CarrierGroupInDTO, DistributionInDTO, DistributionTypeEnum, FeaturesEnum } from '@api/logsteo-api.v2';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import { isNullOrUndefined } from '@utils/utils';
import { localDateTimeToApi } from '@utils/date';
import { findValidationMessage } from '@utils/validation';
import dayjs from 'dayjs';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { SelectCarriers } from '@components/ringil3/Input/InputCarriers/SelectCarriers';
import { ColWithGap } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  onContinue: () => void;
  customerId: string;
  applicationId: string;
  handlingUnitCount?: number;
  ladenFactorEligible: boolean;
}

export const useCreateRequestCarrier = () => {
  const [visible, setVisible] = useState(false);

  const RequestCarrier: React.FC<ComponentProps> = ({
                                                      onContinue,
                                                      customerId,
                                                      applicationId,
                                                      handlingUnitCount,
                                                      ladenFactorEligible,
                                                    }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { cuCreateDistributionForExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [numberOfTrucks, setNumberOfTrucks] = useState<string>('N/A');

    const ladenFactorOptions = useMemo(() => {
      if (!handlingUnitCount) return [];
      return Array.from({ length: handlingUnitCount }, (_, i) => i + 1)
        .filter(num => handlingUnitCount % num === 0)
        .map(value => ({ value, label: value.toString() }));
    }, [handlingUnitCount]);

    const schema = yup.object().shape({
      distributionType: yup.string().required(),
      selectedItems: yup.array().required().min(1),
      price: yup.mixed().test('required', tr('validation.required', 'Required'), (value, context) => {
        const formData = context.parent as Distribution;

        if (formData.distributionType === DistributionTypeEnum.FIXED_PRICE) {
          if (isNullOrUndefined(formData.price) || formData.price <= 0) {
            return false;
          }

          if (isNullOrUndefined(formData.currency) || formData.currency.length === 0) {
            return false;
          }
          return true;
        }
        return true;
      }),
      responseDeadline: yup.string().test('required', tr('validation.required', 'Required'), (value, context) => {
        const formData = context.parent as Distribution;
        const isValid = dayjs(value, 'YYYY-MM-DD\'T\'HH:mm').isValid();
        return isValid;
      }),
      isPublished: yup.boolean().required(),
      allowPartialDelivery: yup.boolean().required(),
      ladenFactor: yup.boolean().required(),
      ladenFactorValue: yup.mixed().when('ladenFactor', {
        is: true,
        then: yup.number().required(),
        otherwise: yup.mixed().nullable(),
      }),
    });

    const form = useForm<Distribution>(
      schema,
      {
        price: 0,
        distributionType: DistributionTypeEnum.SPOT,
        isPublished: true,
        responseDeadline: dayjs().add(8, 'hours').format('YYYY-MM-DDTHH:mm'),
        responseDeadlineTimezone: 'Europe/Prague',
        selectedItems: [],
        currency: loggedUser?.defaultCurrencyIsoCode,
        ladenFactor: false,
        allowPartialDelivery: false,
        ladenFactorValue: null,
      },
      d => onContinueHandler(d),
      false,
      false,
    );

    const calculatedTrucks = useMemo(() => {
      if (form.values.ladenFactorValue && handlingUnitCount) {
        return (handlingUnitCount / form.values.ladenFactorValue).toFixed(2);
      }
      return 'N/A';
    }, [form.values.ladenFactorValue, handlingUnitCount]);

    // Update state when calculated value changes
    useEffect(() => {
      setNumberOfTrucks(calculatedTrucks);
    }, [calculatedTrucks]);
    if (!visible) return null;

    // @ts-ignore
    /*    const { isFieldRequired } = useIsFieldRequired<Distribution>(schema);*/

    const onContinueHandler = (distribution: Distribution) => {
      cuCreateDistributionForExpedition(
        customerId,
        applicationId,
        mapToDistributionInDTO(distribution),
        () => {
          setVisible(false);
          onContinue();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    const ladenFactorEnabled = loggedUser?.features?.some(t => t === FeaturesEnum.LADEN_FACTOR);

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
          heading={tr(`RequestCarrier.requestCarrier`, `Poptat dopravce`)}
        >
          <BackendValidationComponent />
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr(`RequestCarrier.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
            {
              ladenFactorEnabled && ladenFactorEligible && (
                <>
                  <InputCheckbox
                    value={form.values.ladenFactor}
                    onChange={(key: string, checked: boolean) => {
                      form.setFieldValue('ladenFactor', checked);
                    }}
                    label={tr(`RequestCarrier.ladenFactor`, `Enable laden factor`)}
                  />

                  {form.values.ladenFactor && (
                    <>
                      <VerticalFieldAndValue
                        label={tr('RequestCarrier.ladenFactorValue', 'Enter laden factor')}
                        labelMode={'normal'}
                        required={true}
                        helpText={tr('RequestCarrier.ladenFactorValueHelp', 'Laden factor value help')}
                      >
                        <Dropdown
                          options={ladenFactorOptions}
                          optionValue="value"
                          optionLabel="label"
                          value={form.values.ladenFactorValue}
                          onChange={(e) => form.setFieldValue(form.names.ladenFactorValue, e.value)}
                          style={{ width: '100%', padding: '0.5rem', color: 'var(--ringil3-gr4)' }}
                          placeholder={tr('RequestCarrier.selectLadenFactor', 'Select laden factor')}
                        />
                      </VerticalFieldAndValue>
                      {form.values.ladenFactorValue && (
                        <COInlineMessage
                          variant={'info'}>{tr('RequestCarrier.ladenFactorNumberOfTrucks', 'Based on provided laden factor, number of trucks needed is: {{numberOfTrucks}}', { numberOfTrucks })}</COInlineMessage>
                      )}
                    </>
                  )}
                </>
              )}

            <VerticalFieldAndValue
              label={tr('RequestCarrier.distributionType', 'Distribution type')}
              labelMode={'normal'}
              required={true}
              helpText={tr('RequestCarrier.distributionTypeHelp', 'Distribution type help')}
            >
              <InputSpotDirect
                selectedItemCode={form.values.distributionType}
                onSelect={v => {
                  form.setFieldValue(form.names.distributionType, v);
                }}
              />
            </VerticalFieldAndValue>
            {
              ladenFactorEnabled && ladenFactorEligible && form.values.ladenFactor && (
                <InputCheckbox
                  value={form.values.allowPartialDelivery}
                  onChange={(key: string, checked: boolean) => {
                    form.setFieldValue('allowPartialDelivery', checked);
                  }}
                  label={tr(`RequestCarrier.allowPartialDelivery`, `Allow partial delivery`)}
                />
              )}
            <VerticalFieldAndValue
              label={tr(`RequestCarrier.price`, `Price`)}
              labelMode={'normal'}
              required={form.values.distributionType === DistributionTypeEnum.FIXED_PRICE}
              errorMessage={findValidationMessage(form.validationErrors, form.names.price, tr)}
            >
              <InputPriceWithCurrency
                currency={form.values.currency}
                price={form.values.price}
                changeCurrency={v => {
                  form.setFieldValue(form.names.currency, v);
                }}
                changePrice={v => {
                  form.setFieldValue(form.names.price, v);
                }}
              />
            </VerticalFieldAndValue>
            <VerticalFieldAndValue
              label={tr(`RequestCarrier.whoDoYouWantToAddress`, `Who do you want to address?`)}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, form.names.selectedItems, tr)}
            >
              <SelectCarriers
                value={form.values.selectedItems}
                onlyCarriers={false}
                onSelected={value => {
                  form.setFieldValue(form.names.selectedItems, value);
                }}
                isDisabled={false}
                placeholder={tr(`RequestCarrier.selectACarrierOrGroup`, `Select a carrier or group`)}
              />
            </VerticalFieldAndValue>

            <VerticalFieldAndValue
              label={tr(`RequestCarrier.byWhenDoYouNeedAQuotation`, `By when do you need a quotation`)}
              labelMode={'normal'}
              required={true}
              errorMessage={findValidationMessage(form.validationErrors, 'responseDeadline', tr)}
            >
              <InputDateAndTime
                value={form.values.responseDeadline}
                onChange={v => {
                  form.setFieldValue(form.names.responseDeadline, v);
                }}
                timeZone={loggedUser.companyTimeZone}
                onChangeTimeZone={v => {
                }}
              />
            </VerticalFieldAndValue>
          </ColWithGap>
        </RingilSidebar>
      </>
    )
      ;
  };

  return { RequestCarrier, setVisible };
};

interface Distribution {
  distributionType: DistributionTypeEnum;
  price?: number;
  selectedItems: CarrierGroupInDTO[];
  responseDeadline: string;
  responseDeadlineTimezone: string;
  currency: string;
  isPublished: boolean;
  ladenFactor: boolean;
  allowPartialDelivery: boolean;
  ladenFactorValue?: number;
  handlingUnitCount?: number;
}

const mapToDistributionInDTO = (distribution: Distribution): DistributionInDTO => {
  return {
    price: distribution.price,
    validTill: localDateTimeToApi(distribution.responseDeadline, distribution.responseDeadlineTimezone),
    selectAll: false,
    distributionType: distribution.distributionType,
    currency: distribution.currency,
    selectedItems: distribution.selectedItems,
    isPublish: distribution.isPublished,
    ladenFactor: distribution.ladenFactorValue,
    allowPartialDelivery: distribution.allowPartialDelivery,
  };
};
