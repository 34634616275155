import dayjs, { Dayjs } from 'dayjs';
import { DayTimeSlot, DistributionTemplateView, DistributionView, DropdownItem } from '@type/types';
import { QtyUnit } from '@type/enums';
import {
  CargoTemplateWithoutIdDTO,
  CarrierOrGroupEnum,
  ContactPerson,
  ContactTypeEnum,
  DistributionTypeEnum,
  ExpeditionTypeEnum,
  GlobalTimeslotsDTO,
  InvoiceRebillingTypeEnum,
  InvoiceStateEnum,
  LoadingTypesEnum,
  LovDTO,
  OpeningEntityTypeEnum,
  PartnerLocationContactPersonInDTO,
  UserLovOutDTO,
  WeightModeEnum,
} from '@api/logsteo-api.v2';

export enum PageID {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
}

export enum RouteType {
  DIRECT = 'DIRECT',
  ROUND_TRIP = 'ROUND_TRIP',
}

export interface NewExpeditionView {
  currentPage: PageID;
  fromSupplierManifestId: string;
  fromSupplierNotificationId: string;
  routeType?: RouteType;
  locations?: Location[];
  expeditionType?: ExpeditionTypeEnum;
  truckType?: string;
  manipulationTypes?: LoadingTypesEnum[];
  specialRequirements?: string[];
  cargoType?: string;
  carrierNote?: string;
  adrWeight?: number;
  unloadAllLocationId?: string;
  distribution: DistributionView;
  distributionTemplate?: DistributionTemplateView;
  distributionTemplateApplied?: boolean;
  responsiblePerson?: UserLovOutDTO;
  expeditionTemplateId?: string;
  subscribersList: LovDTO[];
  internalNote?: string;

  invoiceUserId?: string;
  invoiceState?: InvoiceStateEnum;
  pairingSymbol?: string;
  invoiceRebillingType?: InvoiceRebillingTypeEnum;
  invoicePartnerId?: string;
  invoiceDepartment?: string;
  labelCodes: string[];
  duplicatedFromExpeditionId?: string;
  copySNAttachments: boolean;
  linkToSN: boolean;
  copyAttachments: boolean;
  applicationId?: string;
}

export interface OrderItemViewData {
  cargoItemType?: CargoTemplateWithoutIdDTO;
  amount: number;
  quantityUnit: string;
  height: number;
  width: number;
  length: number;
  weight: number;
  weightMode: WeightModeEnum;
  // used to make readonly input
  /*viewDisabledProperties?: string[];*/
  stackable: boolean;
  itemNote: string;
  decimalPlaces: number;
}

export interface OrderViewData {
  internalId: string;
  loadingLocationId: number;
  unloadingLocationId: number;
  name?: string;
  items?: OrderItemViewData[];
  totalWeight: number;
}

export interface Location {
  // index
  id: number;
  name?: string;
  streetNr?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  carrierCode?: string;
  loadingLocation: boolean;
  unloadingLocation: boolean;
  unloadAll: boolean;
  loadingOrders: OrderViewData[];
  timeslots: GlobalTimeslotsDTO;
  feKey?: string;
  contactPersons: ContactPerson[];
  appliedContactPerson?: PartnerLocationContactPersonInDTO;
  enabledEmailNotificationForContactPerson: boolean;
  additionalNotificationPersons?: PartnerLocationContactPersonInDTO[];
  locationId: string;
  contactType: ContactTypeEnum;
  openingHoursCoordinated: OpeningHoursCoordinates;
}

export interface OpeningHoursCoordinates {
  entityId: string;
  entityType: OpeningEntityTypeEnum;
}
