import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';

export const computePermissions = (userPermissions: PermissionTypesEnum[]) => {
  const p = (permission: PermissionTypesEnum): boolean => {
    return userPermissions.includes(permission);
  };

  return {
    showHeader:
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ_WITHOUTPRICE) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ),
    editHeader: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW),
    showTransportation:
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ_WITHOUTPRICE) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ),
    editTransportation: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW),
    showTransportationPrices:
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW) || p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ),
    showInternalInfo:
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ_WITHOUTPRICE) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ),
    editInternalInfo: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW),
    showCustoms:
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ_WITHOUTPRICE) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW) ||
      p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ),
    editCustoms: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW),
    showAttachment: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_ATTACHMENTS_READ) || p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_ATTACHMENTS_RW),
    editAttachment: p(PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_ATTACHMENTS_RW),
    showMessages: p(PermissionTypesEnum.PERMISSION_EXPEDITION_MESSAGES_RW) || p(PermissionTypesEnum.PERMISSION_EXPEDITION_MESSAGES_READ),
    editMessages: p(PermissionTypesEnum.PERMISSION_EXPEDITION_MESSAGES_RW),
  };
};
