import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from '@api/api';
import { ListCompanyFolderItemResponseOutDTO, ListCompanyFolderResponseOutDTO } from '@api/logsteo-api.v2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { NoWrapText, RowWithSpaceBetween } from '@components/styles.tsx';
import { EditCompanyFolderSideBar } from '@components/pages/admin/folder/EditCompanyFolderSidebar.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  companyId: string;
}

interface VisibleDialog {
  visible: boolean;
  folderId?: string;
  folderName?: string;
}

const AdminCompanyFolders: React.FC<ComponentProps> = ({ companyId }) => {
  const { listCompanyFolder, deleteCompanyFolder } = useContext(ApiContext);
  const { showToastMessage } = useContext(ToastContext);
  const [pageData, setPageData] = useState<ListCompanyFolderResponseOutDTO>();
  const [visibleAddDialog, setVisibleAddDialog] = useState<VisibleDialog>({
    visible: false,
  });
  const abortControllerRef = useRef<AbortController | null>(null);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    const ac = new AbortController();
    abortControllerRef.current = ac;

    load(abortControllerRef.current);

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const load = (ac: AbortController) => {
    listCompanyFolder(companyId, d => {
      if (ac.signal.aborted) {
        return;
      }
      setPageData(d);
    });
  };

  // if (isNullOrUndefined(pageData)) return <></>;

  const renderActions = (row: ListCompanyFolderItemResponseOutDTO) => {
    return (
      <NoWrapText>
        <Button
          label={'Remove folder'}
          onClick={e => {
            deleteCompanyFolder(companyId, row.folderId, () => {
              showToastMessage('Success', 'Folder deleted!', 'success');
              load(abortControllerRef.current);
            });
          }}
        />
        <Button
          label={'Edit folder'}
          onClick={e => {
            setVisibleAddDialog({ visible: true, folderId: row.folderId, folderName: row.folderName });
          }}
        />
      </NoWrapText>
    );
  };

  return (
    <>
      <RowWithSpaceBetween>
        <h2>Company folders</h2>
        <Button label={'Add folder'} onClick={() => setVisibleAddDialog({ visible: true, folderId: null, folderName: null })} />
      </RowWithSpaceBetween>
      {/*// @ts-ignore*/}
      <DataTable value={pageData?.folders} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
        <Column header={'Folder id'} body={(row: ListCompanyFolderItemResponseOutDTO) => <div>{row.folderId}</div>} />
        <Column header={'Folder name'} body={(row: ListCompanyFolderItemResponseOutDTO) => <div>{row.folderName}</div>} />
        <Column header={'Entity name'} body={(row: ListCompanyFolderItemResponseOutDTO) => <div>{row.entityType}</div>} />
        <Column header={'Actions'} body={renderActions} style={{ width: '10rem' }} />
      </DataTable>

      <EditCompanyFolderSideBar
        companyId={companyId}
        folderName={visibleAddDialog.folderName}
        visible={visibleAddDialog.visible}
        folderId={visibleAddDialog.folderId}
        onHide={() => {
          load(abortControllerRef.current);
          setVisibleAddDialog({ folderId: null, visible: false, folderName: null });
        }}
      />
    </>
  );
};

export default AdminCompanyFolders;
