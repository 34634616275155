import React, { useMemo } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  totalsByCurrency: Record<string, number>;
}

const InvoiceTotalValueBadge: React.FC<ComponentProps> = ({ totalsByCurrency }) => {
  const { tr } = useTranslationLgs();

  const formattedTotals = useMemo(() => {
    if (!totalsByCurrency) return '';

    return Object.entries(totalsByCurrency)
      .sort(([currencyA], [currencyB]) => currencyA.localeCompare(currencyB))
      .map(([currency, amount]) => `${formatPrice(amount, currency)}`)
      .join(', ');
  }, [totalsByCurrency]);

  return (
    <>
      {isNotNullOrUndefined(totalsByCurrency) && (
        <Badge filled={false}
               value={formattedTotals}
               highlightedValue={false}
               label={tr(`InvoiceTotalValueBadge.label`, `Total value`)}></Badge>
      )}
    </>
  );
};

export default InvoiceTotalValueBadge;
