import React, { useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import ListInfo from '@components/obsolete/ListInfo/ListInfo';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem';
import NavTitle from '@components/obsolete/NavTitle/NavTitle';
import { ApiContext } from '@api/api.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useTranslation from '@hooks/i18n/useTranslation.tsx';
import EditPhoneSidebar from '@components/obsolete/EditPhoneSidebar/EditPhoneSidebar.tsx';
import { UserProfileOutDTO } from '@api/logsteo-api.v2.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import { Clickable } from '@components/styles.tsx';
import { useLocalStorage } from '@utils/utils.tsx';
import { InputSwitch } from 'primereact/inputswitch';
import COText from '@app/pages/COText/COText.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';

export const MyProfile = () => {
  const { tr } = useTranslation();
  const { getProfileForCurrentUser } = useContext(ApiContext);
  const [state, setState] = useState<UserProfileOutDTO>();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  useEffect(() => {
    load();
  }, []);
  const [visiblePhoneSidebar, setVisiblePhoneSidebar] = useState(false);
  const [removeWidthLimit, setRemoveWidthLimit] = useLocalStorage('Layout.removeWidthLimit', false);

  const load = () => {
    getProfileForCurrentUser((data) => {
      setState(data);
    });
  };
  return (
    <div>
      <NavTitle>
        <h2 className="h2-title p-m-0">{tr(`MyProfile.myProfile`, `Můj profil`)}</h2>
      </NavTitle>
      {state &&
      <>
          <ListInfo>
            <ListInfoItem title={tr(`MyProfile.firstName`, `Křestní jméno`)}>
              <p className="flex items-center">
                <small>{state.information.firstName}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`MyProfile.lastNAme`, `Příjmení`)}>
              <p className="flex items-center">
                <small>{state.information.surname}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`MyProfile.email`, `Email`)}>
              <p className="flex items-center">
                <small>{state.information.email}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`MyProfile.phone`, `Telefon`)}>
              <p className="flex items-center gap-2">
                <small>{state.information.phone}</small>
                <Clickable>
                  <div onClick={() => setVisiblePhoneSidebar(true)}>{tr(`MyProfile.edit`, `upravit`)}</div>
                </Clickable>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`MyProfile.roles`, `Role`)}>
              <p className="flex items-center">
                <small>{loggedUser?.securityRoles.join(', ')}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`MyProfile.company`, `Společnost`)}>
              <p className="flex items-center">
                <small>{loggedUser?.company}</small>
              </p>
            </ListInfoItem>
          </ListInfo>
          <div className={'flex flex-col mt-4'}>
            <Field label={tr("MyProfile.nastavitSirkuPracovnihoProstoru", "Nastavit šířku pracovního prostoru")}>
              <div className={'flex flex-row gap-2 '}>
                <InputSwitch checked={removeWidthLimit} onChange={(e) => setRemoveWidthLimit(e.target.value)} />
                <COText label={tr("MyProfile.roztahnoutNaSirku", "Roztáhnout na šířku")} />
              </div>
            </Field>
          </div>

          {/*          <div className="flex items-center">
           <Label title="Send notification emails" />
           <InputSwitch className="p-ml-4" checked={checked} onChange={(e) => showNotification(e)} />
          </div>*/}
        </>
      }

      {state &&
      <EditPhoneSidebar
        visible={visiblePhoneSidebar}
        onHide={() => setVisiblePhoneSidebar(false)}
        phoneNumber={state.information.phone}
        onComplete={() => {
          load();
          setVisiblePhoneSidebar(false);
        }} />

      }

      {/*      <Dialog
         header="Enable email notifications"
         visible={onModalNotification}
         className="modal-sm"
         onHide={() => onHide('onModalNotification')}
        >
         <div>
           <p className="p-mt-0">
             By enabling email notifications you will receive all notifications to your email address immediately as they
             occur.
           </p>
         </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
           <Button label="Enable" className="p-mr-2" onClick={() => onHide('onModalNotification')} />
           <Button label="Cancel" className="p-button-text" onClick={() => rejectAction('onModalNotification')} />
         </ButtonLayout>
        </Dialog>
        <Dialog
         header="Turn off email notifications"
         visible={offModalNotification}
         className="modal-sm"
         onHide={() => onHide('offModalNotification')}
        >
         <div>
           <p className="p-mt-0">
             By enabling email notifications you will receive all notifications to your email address immediately as they
             occur.
           </p>
         </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button label="Turn off" className="p-mr-2 p-button-danger" onClick={() => onHide('offModalNotification')} />
           <Button label="Turn off" className="p-mr-2 p-button-danger" onClick={() => onHide('offModalNotification')} />
           <Button label="Cancel" className="p-button-text" onClick={() => rejectAction('offModalNotification')} />
         </ButtonLayout>
        </Dialog>*/


      }
    </div>);

};