import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgMoreButton from '@components/ringil3/icons/buttons/MoreButton';
import SvgAttachToTable from '@components/ringil3/icons/menu/reservation/AttachToTable';
import SvgDuplicate from '@components/ringil3/icons/menu/reservation/Duplicate';
import SvgRepeatedReservations from '@components/ringil3/icons/menu/reservation/RepeatedReservations';
import SvgDelete from '@components/ringil3/icons/menu/reservation/Delete';
import { createPutOnTheTable } from '@components/ringil3/Features/Reservation/PutOnTheTable/PutOnTheTable';
import { ApiContext } from '@api/api';
import SvgCutReservation from '@components/ringil3/icons/reservation/CutReservation';
import useNavigation from '@hooks/useNavigation.tsx';
import { useCreateDeleteDialog } from '@components/ringil3/Dialog/DeleteDialog.tsx';
import SvgNotifyDriver from '@components/ringil3/icons/menu/reservation/NotifyDriver.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  reloadHandler: () => void;
  isPinnedOnLocRampTable: boolean;
  locationId: string;
  reservationId: string;
  reservationAppId: string;
  isOwner: boolean;
  hasTable: boolean;
  hasTimeslot: boolean;
  hasPhone: boolean;
  onCopyReservation: (reservationId: string) => void;
  onChangeDuration: (reservationId: string) => void;
  onDuplicate: (reservationId: string) => void;
  onNotifyDriver: (reservationId: string) => void;
}

const ReservationMoreButtons: React.FC<ComponentProps> = ({
  reloadHandler,
  reservationId,
  reservationAppId,
  locationId,
  isPinnedOnLocRampTable,
  isOwner,
  onCopyReservation,
  onChangeDuration,
  onDuplicate,
  hasTable,
  hasTimeslot,
  hasPhone,
  onNotifyDriver,
}) => {
  const { tr } = useTranslationLgs();
  const { deleteReservation, reservationNotifyDriver } = useContext(ApiContext);
  const navigation = useNavigation();
  const { hasPermission } = useContext(AuthenticatedUserContext);

  const [open, setOpen] = useState(false);
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const { DeleteDialog, setObjToDelete } = useCreateDeleteDialog();
  const { showToastMessage } = useContext(ToastContext);

  const deleteReservationHandler = (reservationId: string) => {
    deleteReservation(reservationId, () => {
      navigation.navigate(navigation.urlFunctions.createReservationList());
    });
  };

  const { PutOnTheTableComponent, putOnTheTable, removeFromTable } = createPutOnTheTable(
    () => {
      reloadHandler();
    },
    () => {
      reloadHandler();
    },
  );

  return (
    <>
      <Details
        ref={detailsRef}
        onClick={e => {
          if (open) {
            return;
          }
          setOpen(!open);
          e.preventDefault();
        }}
        open={open}
      >
        <Summary>
          <SvgMoreButton />
        </Summary>
        <Menu>
          <ul>
            {isOwner && hasTable && (
              <li
                onClick={e => {
                  if (!isPinnedOnLocRampTable) {
                    putOnTheTable(locationId, reservationId, '');
                  } else {
                    removeFromTable(locationId, reservationId);
                  }
                  reloadHandler();
                  setOpen(false);
                }}
              >
                <SvgAttachToTable />
                {!isPinnedOnLocRampTable && tr(`ReservationMoreButtons.attachToTable`, `Attach to the table`)}
                {isPinnedOnLocRampTable && tr(`ReservationMoreButtons.dettachToTable`, `Dettach from the table`)}
              </li>
            )}
            {hasPhone && (
              <li
                onClick={e => {
                  setOpen(false);
                  onNotifyDriver(reservationId);
                }}
              >
                <SvgNotifyDriver />
                {tr(`ReservationMoreButtons.notifyDriver`, `Notify driver`)}
              </li>
            )}

            <li
              onClick={e => {
                if (onDuplicate) onDuplicate(reservationId);
                setOpen(false);
              }}
            >
              <SvgDuplicate />
              {tr(`ReservationMoreButtons.duplicate`, `Duplicate`)}
            </li>

            {hasTimeslot && hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DUPLICATE]) && (
              <li
                onClick={e => {
                  onCopyReservation(reservationId);
                  setOpen(false);
                }}
              >
                <SvgRepeatedReservations />
                {tr(`ReservationMoreButtons.repeatingReservations`, `Repeating reservations`)}
              </li>
            )}

            {isOwner && hasTimeslot && (
              <li
                onClick={e => {
                  onChangeDuration(reservationId);
                  setOpen(false);
                }}
              >
                <SvgCutReservation />
                {tr(`ReservationMoreButtons.changeDuration`, `Change duration`)}
              </li>
            )}

            {hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DELETE]) && (
              <li
                onClick={e => {
                  setObjToDelete(reservationId);
                  setOpen(false);
                }}
              >
                <SvgDelete />
                {tr(`ReservationMoreButtons.deleteReservation`, `Delete reservation`)}
              </li>
            )
            }
          </ul>
        </Menu>
      </Details>
      <PutOnTheTableComponent reservationAppId={reservationAppId} />
      <DeleteDialog
        onDelete={(reservationId: string) => {
          deleteReservationHandler(reservationId);
        }}
      />
    </>
  );
};

const Summary = styled.summary`
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff; /* Barva pozadí uvnitř kruhu */
  border-radius: 50%;
  padding: 0;
  border: 2px solid var(--ringil3-brand-color);
  position: relative;
  box-shadow: 0px 12px 16px rgba(254, 80, 0, 0.1);
`;

const Details = styled.details`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.4rem;
  background: white;
  border: 1px solid #ccc;
  z-index: 1001;
  padding: 10px;
  min-width: 150px;
  border-radius: 0.5rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      cursor: pointer;
      white-space: nowrap;
      border-bottom: 1px solid var(--content-quaternary);

      &:hover {
        background: var(--ringil-base-quaternarycolor);
      }
    }

    li:last-child {
      border-bottom: none;
    }
  }
`;

export default ReservationMoreButtons;
