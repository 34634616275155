import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';
import { isNotBlank } from '@utils/utils.tsx';
import SvgIconNote from '@app/icons/heading/IconNote.tsx';
import SvgNoteInfo from '@components/ringil3/icons/NoteInfo.tsx';

interface ComponentProps {
  required?: boolean;
  readonly?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const InternalNoteTag: React.FC<ComponentProps> = ({ readonly, required, value, onChange }) => {
  const [internalValue, setInternalValue] = useState(value);
  const { tr } = useTranslationLgs();
  return (
    <>
      <ButtonTag
        label={tr('InternalNoteTag.internalnote', 'Internal note')}
        icon={<SvgNoteInfo />}
        required={required}
        readonly={readonly}
        value={value}
        onSave={(onSuccess) => {
          onChange(internalValue);
          onSuccess();
        }}
        autoClose>

        <RingilTextArea
          value={internalValue}
          onChange={(v) => {
            setInternalValue(isNotBlank(v) ? v : null);
          }}
          maxLength={255} />

      </ButtonTag>
    </>);

};

export default InternalNoteTag;