import React, { useContext, useLayoutEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card, Ringil3BadgeRow, Test } from '@components/ringil3/styles';
import Heading from '@components/ringil3/Heading/Heading';
import LabelsTag from '@components/framework/tags/LabelsTag.tsx';
import {
  EntityTypeEnum,
  FeaturesEnum,
  GetWorkflowHistoryOutDTO,
  PermissionTypesEnum,
  ReservationActionTypesEnum,
  ReservationDetailOutDTO,
  WorkflowHistoryItemOutDTO,
} from '@api/logsteo-api.v2';
import {
  formatLocationNotino,
  isNotNullOrUndefined,
  isNullOrUndefined,
  printTimeslotResponse,
  translateWorkflowState,
} from '@utils/utils';
import LinkedExpBadge from '@components/ringil3/Badges/LinkedExpBadge';
import CreatedBadge from '@components/ringil3/Badges/CreatedBadge';
import UpdatedBadge from '@components/ringil3/Badges/UpdatedBadge';
import ReservationWorkflowStateTag from '@components/ringil3/Features/Reservation/Tags/ReservationWorkflowStateTag';
import ReservationNameTag from '@components/ringil3/Features/Reservation/Tags/ReservationNameTag';
import CustomerNameTag from '@components/ringil3/Features/Reservation/Tags/CustomerNameTag';
import ReservationNoteTag from '@components/ringil3/Features/Reservation/Tags/ReservationNoteTag';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import SelectedCarrierTag from '@components/framework/tags/SelectedCarrierTag.tsx';
import CarPlateTag from '@components/framework/tags/CarPlateTag.tsx';
import DriverAndIdTag from '@components/framework/tags/DriverAndIdTag.tsx';
import PhoneTag from '@components/framework/tags/PhoneTag.tsx';
import ReservationCarrierNote from '@components/ringil3/Features/Reservation/Tags/ReservationCarrierNote';
import SvgReservation from '@components/ringil3/icons/heading/Reservation';
import styled from 'styled-components';
import EditableHeading3 from '@components/ringil3/Heading/EditableHeading3';
import { ColWithGap, ColWithSmallerGap, RowWithGap } from '@components/styles';
import RingilOrder from '@components/ringil3/RingilOrder/RingilOrder';
import SvgCalendar from '@components/ringil3/icons/Calendar';
import SvgMappoint from '@components/ringil3/icons/Mappoint';
import SvgReservationManipulation from '@components/ringil3/icons/ReservationManipulation';
import {
  useCreateEditReservationSlotSidebar,
} from '@components/ringil3/Features/Reservation/Sidedbars/EditReservationSlot';
import { useCreateEditReservationOrdersSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/EditOrders';
import ReservationMoreButtons from '@components/ringil3/Features/Reservation/MoreButton/ReservationMoreButtons';
import SvgReservationPin from '@components/ringil3/icons/reservation/ReservationPin';
import dayjs from 'dayjs';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { mapFromAPIToDateLong } from '@utils/date';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { useCreateChangeSlotDuration } from '@components/obsolete/timeslot/ChangeSlotSidebar.tsx';
import { useDuplicateTimeslot } from '@components/obsolete/timeslot/duplicate/duplicate.tsx';
import { useCreateRingilReservationTabs } from '@components/ringil3/Features/Reservation/Tabs/ReservationTabs.tsx';
import ETransport from '@components/features/eTransport/ETransport.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import {
  useCreateEditReservationOrderItemsDiscrepancySidebar,
} from '@components/ringil3/Features/Reservation/Sidedbars/EditOrderItemsDiscrepancy.tsx';
import DiscrepancyEditHeading3 from '@components/ringil3/Heading/DiscrepancyEditHeading3.tsx';

interface ComponentProps {
  reservationId: string;
}

const ReservationDetail: React.FC<ComponentProps> = ({ reservationId }) => {
  const { tr } = useTranslationLgs();
  const {
    getReservationDetail,
    reservationUpdateCarPlate,
    reservationUpdateDriverNameAndID,
    reservationUpdateDriverPhone,
    reservationUpdateNoteForCarrier,
    reservationUpdateNote,
    reservationUpdateReservationName,
    isEntityManagedByWorkflow,
    updateReservationCarrierEntity,
    getWorkflowHistory,
    reservationNotifyDriver,
  } = useContext(ApiContext);
  const [reservation, setReservation] = useState<ReservationDetailOutDTO>();
  const [isManagedByWorkflow, setIsManagedByWorkflow] = useState(false);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const { showToastMessage } = useContext(ToastContext);

  const { selectedPanel, ReservationTabs } = useCreateRingilReservationTabs();

  const { EditReservationSlotSidebar, setVisible, visible } = useCreateEditReservationSlotSidebar();
  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();
  const canEdit = loggedUser?.permissionTypes.some(t => t === PermissionTypesEnum.PERMISSION_TIMESLOT_DETAIL_RW);

  const { load: loadAttachments, AttachmentComponent } = useCreateAttachmentComponent({
    enableCopyDocument: false,
    initialAttachments: null,
    doNotShare: !canEdit,
    disabled: !canEdit,
  });

  const [workflowHistory, setWorkflowHistory] = useState<GetWorkflowHistoryOutDTO>();

  const {
    ReservationChangeSlotDuration,
    setVisible: setVisibleChangeDurationDialog,
    visible: visibleChangeDuration,
  } = useCreateChangeSlotDuration();
  const { setTimeslotIdForDuplication, DuplicateTimeslot } = useDuplicateTimeslot();

  const navigation = useNavigation();

  const isOwner = reservation?.customerId == loggedUser.companyId;

  const {
    EditReservationOrdersSidebar,
    setVisible: setVisibleOrdersSidebar,
    visible: visibleOrdersSidebar,
  } = useCreateEditReservationOrdersSidebar();

  const {
    EditReservationOrderItemsDiscrepancySidebar,
    setVisible: setVisibleOrderItemsDiscrepancySidebar,
    visible: visibleOrderItemsDiscrepancySidebar,
  } = useCreateEditReservationOrderItemsDiscrepancySidebar();

  const showEtransport = loggedUser?.features?.some(t => t == FeaturesEnum.ETRANSPORT_RO);
  const orderDiscrepancyEnabled = loggedUser?.features?.some(t => t === FeaturesEnum.ORDER_DISCREPANCY);

  useLayoutEffect(() => {
    const ac = new AbortController();
    if (isNotNullOrUndefined(reservationId)) {
      load();
      loadAttachments(EntityTypeEnum.TIMESLOT, reservationId);
    }
    return () => {
      ac.abort();
    };
  }, [reservationId]);

  const load = () => {
    getReservationDetail(reservationId, d => {
      setReservation(d);
      isEntityManagedByWorkflow(reservationId, EntityTypeEnum.TIMESLOT, d => {
        setIsManagedByWorkflow(d.isManagedByWF);
      });
    });
    getWorkflowHistory(reservationId, d => {
      setWorkflowHistory(d);
    });
  };

  const notifyDriver = (reservationId: string) => {
    reservationNotifyDriver(
      reservationId,
      {
        drivePhone: 'cislodotahnemezrezervaceTODO',
      },
      () => {
        showToastMessage(
          tr(`ReservationMoreButtons.driverNotified`, `Řidič byl vyzván k příjezdu`),
          tr('ReservationMoreButtons.driverNotifiedDetail', 'Na telefonní číslo uvedené v rezervaci byla odeslána SMS zpráva s odkazem na navigaci.'),
          'success',
        );
      },
    );
  };

  if (isNullOrUndefined(reservation)) return <></>;

  return (
    <>
      <Test>
        <BreadCrumbComponent
          items={[
            {
              label: tr(`ReservationDetail.bcReservationList`, `Seznam rezervací`),
              url: navigation.createNavigationLink(navigation.urlFunctions.createReservationList()),
            },
            { label: tr(`ReservationDetail.bcReservationDetail`, `Detail`) },
          ]}
        />
        <Heading
          title={reservation.applicationId}
          headingVariant={'h1'}
          labels={
            <>
              {reservation.isPinnedOnLocRampTable && <SvgReservationPin />}
              {isOwner && <LabelsTag objectId={reservation.id} objectType={EntityTypeEnum.TIMESLOT}
                                     resolveLabelsForType={EntityTypeEnum.TIMESLOT} disabled={!canEdit} />}
            </>
          }
          buttons={
            canEdit
              ? [
                <ReservationMoreButtons
                  reloadHandler={() => load()}
                  locationId={reservation.locationId}
                  reservationId={reservation.id}
                  reservationAppId={reservation.applicationId}
                  isPinnedOnLocRampTable={reservation.isPinnedOnLocRampTable}
                  hasPhone={reservation.driverInfo?.driverPhone != null}
                  hasTable={reservation.hasTable}
                  isOwner={isOwner}
                  onCopyReservation={reservationId => {
                    setTimeslotIdForDuplication(reservationId);
                  }}
                  onChangeDuration={reservationId => {
                    setVisibleChangeDurationDialog(true);
                  }}
                  onDuplicate={() => {
                    setCreateTimeslotRequest({
                      applicationId: null,
                      companyLocationId: null,
                      preferredDate: '',
                      companyLocationCustomerId: null,
                      expeditionLocationId: null,
                      expeditionId: reservation.linkedToExpCoords?.expeditionId,
                      reservationId: reservationId,
                    });
                  }}
                  hasTimeslot={reservation.timeslot?.since != null}
                  onNotifyDriver={id => notifyDriver(id)}
                />,
              ]
              : []
          }
        />
        <Ringil3BadgeRow>
          <LinkedExpBadge expeditionCoords={reservation.linkedToExpCoords} />
          <CreatedBadge createdAt={reservation.createdAt} createdBy={reservation.createdBy} />
          <UpdatedBadge updatedAt={reservation.updatedAt} updatedBy={reservation.updatedBy} />
        </Ringil3BadgeRow>

        <Ringil3BadgeRow>
          {isManagedByWorkflow && isOwner && (
            <ReservationWorkflowStateTag
              state={reservation.state}
              reservationId={reservation.id}
              onReload={() => {
                load();
              }}
              readOnly={!canEdit}
            />
          )}
          <ReservationNameTag
            reservationName={reservation.reservationName}
            onChange={v => {
              reservationUpdateReservationName(reservation.id, { reservationName: v }, () => {
                load();
              });
            }}
            readOnly={!canEdit}
          />
          <CustomerNameTag customerName={reservation.customerName} />
        </Ringil3BadgeRow>
        <Ringil3BadgeRow>
          <ReservationNoteTag
            reservationNote={reservation.reservationNote}
            onChange={v => {
              reservationUpdateNote(reservation.id, { reservationNote: v }, () => {
                load();
              });
            }}
            readOnly={!canEdit}
          />
        </Ringil3BadgeRow>

        <ReservationTabs onPaneChange={v => {
        }} showEtransport={showEtransport} />

        {selectedPanel === 'reservation' && (
          <>
            <COHeadline variant={'h2'} title={tr(`ReservationDetail.carrier`, `Carrier`)}
                        icon={<SvgLkw />}></COHeadline>

            <Ringil3BadgeRow>
              <SelectedCarrierTag
                carrierName={reservation.carrierName}
                carrierId={reservation.carrierId}
                companyLocationCustomerId={reservation.customerId}
                onChange={carrierId => {
                  updateReservationCarrierEntity(reservation.id, { carrierId }, () => {
                    load();
                  });
                }}
                readOnly={!canEdit}
              />
              <CarPlateTag
                carPlate={reservation.carPlate}
                onChange={v => {
                  reservationUpdateCarPlate(reservation.id, { carPlate: v }, () => {
                    load();
                  });
                }}
                readOnly={!canEdit}
              />
              <DriverAndIdTag
                driverName={reservation.driverInfo?.driverName}
                driverId={reservation.driverInfo?.driverId}
                onChange={(driverId, driverName) => {
                  reservationUpdateDriverNameAndID(reservation.id, { driverId, driverName }, () => {
                    load();
                  });
                }}
                readOnly={!canEdit}
              />
              <PhoneTag
                phone={reservation.driverInfo?.driverPhone}
                onChange={phone => {
                  reservationUpdateDriverPhone(reservation.id, { driverPhone: phone }, () => {
                    load();
                  });
                }}
                readOnly={!canEdit}
              />
            </Ringil3BadgeRow>
            <Ringil3BadgeRow>
              <ReservationCarrierNote
                note={reservation.carrierNote}
                onChange={v =>
                  reservationUpdateNoteForCarrier(reservation.id, { carrierNote: v }, () => {
                    load();
                  })
                }
                readOnly={!canEdit}
              />
            </Ringil3BadgeRow>
            <COHeadline variant={'h2'} title={tr(`ReservationDetail.reservation`, `Reservation`)}
                        icon={<SvgReservation />}></COHeadline>
            <TwoCols>
              <Card>
                <ColWithGap>
                  <EditableHeading3
                    editable={canEdit}
                    title={reservation.locationName}
                    icon={<SvgMappoint />}
                    onEditClick={() => {
                      setVisible(true);
                    }}
                  ></EditableHeading3>
                  <ColWithSmallerGap>
                    {formatLocationNotino(reservation.locationPostalCode, reservation.locationCity, reservation.locationCountry, reservation.locationStreetNr)}
                  </ColWithSmallerGap>
                  <EditableHeading3
                    editable={false}
                    title={tr(`ReservationDetail.plannedManipulation`, `Planned manipulation`)}
                    icon={<SvgCalendar />}
                  ></EditableHeading3>
                  <ColWithSmallerGap>
                    {printTimeslotResponse(reservation.slot)}
                    <div>{reservation.rampName}</div>
                  </ColWithSmallerGap>
                </ColWithGap>
              </Card>
              <Card>
                <DiscrepancyEditHeading3
                  editable={canEdit}
                  title={tr(`ReservationDetail.manipulation`, `Manipulation`)}
                  icon={<SvgReservationManipulation />}
                  onEditClick={() => {
                    setVisibleOrdersSidebar(true);
                  }}
                  onDiscrepancyUpdateClick={() => {
                    setVisibleOrderItemsDiscrepancySidebar(true);
                  }}
                ></DiscrepancyEditHeading3>
                <>
                  <ColWithGap>
                    <RowWithGap>
                      {reservation.reservationActionTypes.indexOf(ReservationActionTypesEnum.LOADING) > -1 && (
                        <COBadge type={'loading'} variant={'filled'}>
                          {tr(`ReservationDetail.loading`, `Loading`)}
                        </COBadge>
                      )}

                      {reservation.reservationActionTypes.indexOf(ReservationActionTypesEnum.UNLOADING) > -1 && (
                        <COBadge type={'unloading'} variant={'filled'}>
                          {tr(`ReservationDetail.unloading`, `Unloading`)}
                        </COBadge>
                      )}
                    </RowWithGap>
                    {reservation.orders.map((order, orderIndex) => {
                      return <RingilOrder key={orderIndex} type={'neutral'} order={order}
                                          showDiscrepancy={orderDiscrepancyEnabled} />;
                    })}
                  </ColWithGap>
                </>
              </Card>
              {visible && (
                <EditReservationSlotSidebar
                  applicationId={reservation.applicationId}
                  companyLocationCustomerId={reservation.customerId}
                  reservationId={reservation.id}
                  onRefresh={() => {
                    load();
                  }}
                  expeditionLocationId={reservation.locationId}
                />
              )}
              {visibleOrdersSidebar && (
                <EditReservationOrdersSidebar
                  applicationId={reservation.applicationId}
                  reservationId={reservation.id}
                  onRefresh={() => {
                    load();
                  }}
                  companyLocationCustomerId={reservation.customerId}
                />
              )}
              {visibleOrderItemsDiscrepancySidebar && (
                <EditReservationOrderItemsDiscrepancySidebar
                  applicationId={reservation.applicationId}
                  reservationId={reservation.id}
                  onRefresh={() => {
                    load();
                  }}
                />
              )}
            </TwoCols>
          </>
        )}
        {selectedPanel === 'eTransport' &&
          <ETransport reservationId={reservationId} applicationId={reservation.applicationId} readonly={!canEdit} />}
        {selectedPanel === 'internalinfo' && (
          <>
            {reservation.customerId == loggedUser.companyId && (
              <Section label={tr(`ReservationDetail.history`, `History`)} buttons={<></>}>
                {/*
                // @ts-ignore*/}
                <DataTable value={workflowHistory.history}
                           emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
                  <Column
                    header={tr(`ReservationDetail.activity`, `Activity`)}
                    body={() => <div>{tr(`ReservationDetail.changedWorkflowState`, `Changed workflow state`)}</div>}
                  />
                  <Column
                    header={tr(`ReservationDetail.dateTime`, `Date & time`)}
                    body={(row: WorkflowHistoryItemOutDTO) => <div>{mapFromAPIToDateLong(row.performedAt)}</div>}
                  />
                  <Column header={tr(`ReservationDetail.performedBy`, `Performed by`)}
                          body={(row: WorkflowHistoryItemOutDTO) => <div>{row.performedBy}</div>} />
                  <Column header={tr(`ReservationDetail.company`, `Company`)}
                          body={(row: WorkflowHistoryItemOutDTO) => <div>{row.performedByCompanyName}</div>} />
                  <Column
                    header={tr(`ReservationDetail.detail`, `Detail`)}
                    body={(row: WorkflowHistoryItemOutDTO) => (
                      <div>
                        {tr(`ReservationDetail.movedToTheStateState`, `Moved to the state {{state}}`, {
                          state: translateWorkflowState(row.toState, tr),
                        })}
                      </div>
                    )}
                  />
                </DataTable>
              </Section>
            )}
          </>
        )}
        {selectedPanel === 'attachments' && (
          <>
            <AttachmentComponent entityId={reservation.id} entityType={EntityTypeEnum.TIMESLOT} />
          </>
        )}

        {visibleChangeDuration && (
          <ReservationChangeSlotDuration
            till={reservation?.timeslot?.till}
            since={reservation?.timeslot?.since}
            calendarId={reservation?.calendarId}
            reservationId={reservationId}
            onComplete={() => {
              load();
            }}
          />
        )}
        <DuplicateTimeslot onComplete={() => {
        }} />
        {createTimeslotRequest && (
          <CreateTimeslotSidebar
            onContinue={() => {
            }}
            initialData={{
              actionTypes: reservation.reservationActionTypes,
              driverInfo: reservation.driverInfo,
              companyLocationCustomerId: reservation.customerId,
              customerCompanyCode: null,
              carPlate: reservation.carPlate,
              orders: reservation.orders.map(t => ({
                orderAmount: t.orderAmount,
                items: t.items.map(t => ({
                  amount: t.amount,
                  itemNote: t.itemNote,
                  cargoTemplateCode: t.cargoTemplate?.code,
                  stackable: t.stackable,
                  decimalPlaces: t.cargoTemplate?.decimalPlaces,
                })),
                orderValue: t.orderValue,
                orderName: t.orderName,
                orderNote: t.orderNote,
                orderDescription: t.orderDescription,
              })),
              reservationName: reservation.reservationName,
              reservationNote: reservation.reservationNote,
              carrierId: reservation.carrierId,
              companyLocationId: reservation.locationId,
              carrierNote: reservation.carrierNote,
              timeslot: {
                localDate: dayjs().format('YYYY-MM-DD'),
                arrivalHourString: '',
                durationInMin: '0',
                rampId: null,
              },
              createExpedition: false,
            }}
          />
        )}
      </Test>
    </>
  );
};

const TwoCols = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export default ReservationDetail;
