import * as yup from 'yup';
import { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import { createOrdersComponentValidationSchema, fullRequiredConfig, OrdersConfig } from '@components/framework/orders/validation.ts';

export const validation = (tr: any, config: OrdersConfig) => {
  const orders = createOrdersComponentValidationSchema(config, true, tr);

  const SupplierNotificationDapFormSchema = orders.shape({
    companyId: yup.string().required(),
    supplierId: yup.string().required(),
    carrierName: yup.string().required(),
    notifyData: yup
      .object({
        responsiblePerson: yup
          .object({
            id: yup.string().required(),
            name: yup.string().required(),
          })
          .nullable(),
        userToNotify: yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
              name: yup.string().required(),
            }),
          )
          .nullable(),
        notifyUsersDependsOnRole: yup.boolean().required(),
      })
      .nullable(),
    transportationType: yup.string().required(),
    shipmentNumber: yup.string().required(),
    supplierLocationId: yup.string().required(),
    customerLocationId: yup.string().required(),
    internalNote: yup.string().nullable(),
    loadingGlobalTimeslots: timeslotValidationSchema().required(),
    unloadingGlobalTimeslots: timeslotValidationSchema().required(),
    customerLocation: yup
      .object({
        id: yup.string().required(),
      })
      .nullable(),
    supplierLocation: yup
      .object({
        id: yup.string().required(),
      })
      .nullable(),
    goodsInvoice: yup
      .object()
      .shape({
        goodsInvoiceNumber: yup.string().required(),
        goodsInvoiceDate: yup.date().required(),
      })
      .nullable() // Umožní objektu být null
      .notRequired() // Není vyžadován, ale pokud je přítomen, tak validuje jeho vnitřní pole
      .default(null),
  });

  return SupplierNotificationDapFormSchema;
};
