import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { ButtonRow, CenteredRowWithGap, Clickable, Col, Label, RingilHeading, RowWithSpaceBetween } from '@components/styles.tsx';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { dumpVars, formatPercentage, formatPrice, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import {
  CarrierQuoteDemandOutDTO,
  EntityTypeEnum,
  GroupDemandDetailCompleteViewOutDTO,
  LovCompanyAdditionalTermsOutDTO,
  StateEnum,
  SupplierNotificationTypeEnum,
  TheOriginsPriceAlertResponseBody,
  TypeEnum,
} from '@api/logsteo-api.v2';
import { mapFromAPIDateTime, mapFromAPIToDateLong, mapFromAPIToDateShort, renderFromToOnlyDate } from '@utils/date';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import useNavigation from '@hooks/useNavigation';
import produce from 'immer';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateMDDistributionDialog } from '@components/obsolete/multi-demand/CreateMDDistrobutionDialog/CreateMDDistrobutionDialog.tsx';
import { useCreateChangeValidTillDialog } from '@components/obsolete/multi-demand/ChangeValidTillDialog/ChangeValidTillDialog.tsx';
import { useCreateAddMoreCarrierMDDialog } from '@components/obsolete/multi-demand/AddMoreCarrierDialog/AddMoreCarrierMDDialog.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import { Link } from 'react-router-dom';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import SvgPriceAlert1 from '@components/obsolete/multi-demand/icons/PriceAlert1.tsx';
import SvgPriceAlert3 from '@components/obsolete/multi-demand/icons/PriceAlert3.tsx';
import SvgPriceAlert2 from '@components/obsolete/multi-demand/icons/PriceAlert2.tsx';
import SvgPriceAlert4 from '@components/obsolete/multi-demand/icons/PriceAlert4.tsx';
import SvgPriceAlert5 from '@components/obsolete/multi-demand/icons/PriceAlert5.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { ColumnTitle } from '@components/obsolete/ResponsiveColumnTitle/ColumnTitle.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';

interface ComponentProps {
  multiDemandGroupId: string;
}

interface SelectedQuote {
  snAppId: string;
  snId: string;
  quoteSnId: string;
  referencePrice: number;
  referencePriceCurrency: string;
  price: number;
  priceCurrency: string;
  percentOfOrder: number;
  carrierName: string;
  carrierId: string;
  additionalTerms: LovCompanyAdditionalTermsOutDTO[];
}

interface Term {
  enabled: boolean;
  termId: string;
  quoteId: string;
}

const CustomerMultiDemandDetail: React.FC<ComponentProps> = ({ multiDemandGroupId }) => {
  const { tr } = useTranslationLgs();
  const { getGroupDemandDetailByIdCompleteView, cuCreateExpeditionFromGroupDemandsSN, getPriceAlert } = useContext(ApiContext);
  const [data, setData] = useState<GroupDemandDetailCompleteViewOutDTO>(undefined);
  const [selectedQuotes, setSelectedQuotes] = useState<SelectedQuote[]>([]);
  const [detailedView, setDetailedView] = useState(true);
  const [checkedTerms, setCheckedTerms] = useState<Term[]>([]);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [priceAlert, setPriceAlert] = useState<TheOriginsPriceAlertResponseBody>();

  useEffect(() => {
    load();
  }, [multiDemandGroupId]);

  const load = () => {
    getGroupDemandDetailByIdCompleteView(multiDemandGroupId, d => {
      setData(d);
      getPriceAlert(d.alwaysShowState.groupDemandId, v => {
        setPriceAlert(v);
      });
    });
  };

  const renderCompanies = () => {
    return (
      <>
        {data?.alwaysShowState.carriersHeader?.map((c, cIndex) => {
          return <th key={cIndex}>{c}</th>;
        })}
      </>
    );
  };

  const { CreateMDDistributionDialog, setVisible } = useCreateMDDistributionDialog();
  const { CreateChangeValidTillDialog, setVisible: setVisibleCreateValidTillDialog } = useCreateChangeValidTillDialog();
  const { AddMoreCarrierMDDialog, setVisible: setVisibleAddMoreCarrierDialog } = useCreateAddMoreCarrierMDDialog();

  const navigation = useNavigation();

  const createDistribution = () => {
    setVisible(true);
  };

  if (isNullOrUndefined(data)) return <></>;

  const renderCarrierQuotes = (snId: string, carriersQuotes: CarrierQuoteDemandOutDTO[]) => {
    return carriersQuotes.map((t, key) => {
      if (t.isNotInterested)
        return (
          <td key={key}>
            <NotInterested>{tr(`CustomerMultiDemandDetail.notInterested`, `Not interested`)}</NotInterested>
          </td>
        );

      if (isNullOrUndefined(t.referencePrice)) return <td key={key}></td>;

      const quote = selectedQuotes.find(t => t.snAppId === snId);

      return (
        <td>
          <div className={'flex flex-col gap-1'}>
            {data.alwaysShowState.state === StateEnum.NEW ? (
              <CheckboxWithLabel
                checkboxLabel={formatPrice(t.referencePrice.price, t.referencePrice.currency)}
                value={quote ? quote.quoteSnId === t.quoteSnId : false}
                onChange={v => {
                  toggleQuote(
                    snId,
                    v,
                    t.quoteSnId,
                    t.referencePrice.price,
                    t.percentOfOrder,
                    t.carrierName,
                    t.referencePrice.currency,
                    t.price.currency,
                    t.price.price,
                    snId,
                    t.carrierId,
                    t.additionalTerms,
                  );
                }}
                disabled={false}
                labelStyle={t.isMin ? { color: 'green' } : {}}
              />
            ) : (
              <Label style={t.isMin ? { color: 'green' } : {}}>{formatPrice(t.referencePrice.price, t.referencePrice.currency)}</Label>
            )}

            <RowWithSpaceBetween>
              {t.price.currency != t.referencePrice.currency && <div>({formatPrice(t.price.price, t.price.currency)})</div>}
              <div>{formatPercentage(t.percentOfOrder)} %</div>
            </RowWithSpaceBetween>
            <PriceAlert offerId={t.offerId} snId={snId} />
          </div>
        </td>
      );
    });
  };

  const renderSummedPrice = () => {
    {
      if (isNullOrUndefined(data.alwaysShowState.carriersHeaderWithSumPrice)) return <></>;
      return data.alwaysShowState.carriersHeaderWithSumPrice
        .sort((a, b) => a.carrierName.localeCompare(a.carrierName))
        .map((c, cIndex) => {
          if (isNullOrUndefined(c.carrierSumPrice)) return <td></td>;
          return <td>{formatPrice(c.carrierSumPrice.price, c.carrierSumPrice.currency)}</td>;
        });
    }
  };

  const hasQuote = (snId: string) => {
    return selectedQuotes.some(t => t.snAppId === snId);
  };

  const toggleQuote = (
    snAppId: string,
    checked: boolean,
    quoteSnId: string,
    referencePrice: number,
    percentOfOrder: number,
    carrierName: string,
    referencePriceCurrency: string,
    priceCurrency: string,
    price: number,
    snId: string,
    carrierId: string,
    additionalTerms: LovCompanyAdditionalTermsOutDTO[],
  ) => {
    if (!checked) {
      setSelectedQuotes(prev => prev.filter(t => t.snAppId !== snAppId));
    } else {
      setSelectedQuotes(prev => {
        return [
          ...prev.filter(t => t.snAppId !== snAppId),
          {
            snId,
            snAppId,
            quoteSnId,
            referencePrice,
            percentOfOrder,
            carrierName,
            referencePriceCurrency,
            priceCurrency,
            price,
            carrierId,
            additionalTerms,
          },
        ];
      });
    }
  };

  const weightAverage = () => {
    if (data.alwaysShowState.state === StateEnum.FINISHED) {
      return data.finishedState.sumPercentOfOrder;
    }

    const sum = selectedQuotes.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.referencePrice;
    }, 0);
    const a = selectedQuotes.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.referencePrice * currentValue.percentOfOrder;
    }, 0);
    return a / sum;
  };

  const priceSum = () => {
    if (data.alwaysShowState.state === StateEnum.FINISHED) {
      return data.finishedState.sumPrice.price;
    }
    return selectedQuotes.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.referencePrice;
    }, 0);
  };

  // FIXME @mvlach - odprasit
  const findBestPrices = () => {
    const aa = data.alwaysShowState.supplierNotificationRowsWithDemands
      .map(t => {
        return {
          snId: t.snId,
          snAppId: t.snApplicationId,
          carriersQuote: t.carriersQuotes.find(t => t.isMin),
        };
      })
      .map(t2 => {
        return {
          snId: t2.snId,
          price: t2.carriersQuote.price.price,
          priceCurrency: t2.carriersQuote.price.currency,
          referencePrice: t2.carriersQuote.referencePrice.price,
          referencePriceCurrency: t2.carriersQuote.referencePrice.currency,
          quoteSnId: t2.carriersQuote.quoteSnId,
          carrierName: t2.carriersQuote.carrierName,
          snAppId: t2.snAppId,
          carrierId: t2.carriersQuote.carrierId,
          percentOfOrder: t2.carriersQuote.percentOfOrder,
          additionalTerms: t2.carriersQuote.additionalTerms,
        } as SelectedQuote;
      })
      .map(t => {
        toggleQuote(
          t.snId,
          true,
          t.quoteSnId,
          t.referencePrice,
          t.percentOfOrder,
          t.carrierName,
          t.referencePriceCurrency,
          t.priceCurrency,
          t.price,
          t.snId,
          t.carrierId,
          t.additionalTerms,
        );
      });
    //setSelectedQuotes(aa);
  };

  const PriceVisualisation: React.FC<Props> = ({ price, p }) => {
    const c = p > 2 ? 'red' : p > 1 ? 'orange' : 'gray';

    const fp = (p: number) => {
      return <span style={{ color: c }}>{formatPercentage(p)}</span>;
    };

    return (
      <>
        <PricePanel>
          <CenteredRowWithGap>
            <div>{tr(`CustomerMultiDemandDetail.priceForSelection`, `Price for selection`)}</div>
            <div>
              {formatPrice(price, 'CZK')} ( {fp(p)} % )
            </div>
          </CenteredRowWithGap>
        </PricePanel>
        {data.alwaysShowState.state === StateEnum.NEW && (
          <>
            <Button
              label={tr(`CustomerMultiDemandDetail.summaryAndCreateExpedition`, `Summary and create expedition`)}
              disabled={!(selectedQuotes.length > 0)}
              onClick={e => setDetailedView(false)}
            />
            <Button label={tr(`CustomerMultiDemandDetail.findBestPrices`, `Find best prices`)} onClick={e => findBestPrices()} />
          </>
        )}
      </>
    );
  };

  const model = [
    {
      label: tr('CustomerMultiDemandDetail.updateValidTill', 'Update valid till'),
      command: () => {
        setVisibleCreateValidTillDialog(true);
      },
      visible: !isNullOrUndefined(data.alwaysShowState.validTill),
    },
  ];

  const PriceAlert: React.FC<{ offerId: string; snId: string }> = ({ offerId, snId }) => {
    const alert = priceAlert?.offerAlerts.find(t => t.offerId === offerId && t.snId === snId);

    const renderIcon = (severity: number) => {
      switch (severity) {
        case 0:
          return <SvgPriceAlert1 />;
        case 1:
          return <SvgPriceAlert2 />;
        case 2:
          return <SvgPriceAlert3 />;
        case 3:
          return <SvgPriceAlert4 />;
        case 4:
          return <SvgPriceAlert5 />;
        default:
          return <></>;
      }
    };

    return (
      <>
        {alert && alert.severitySn > 0 && (
          <div
            className={
              'flex flex-row flex-grow-0 gap-2 items-center rounded-[30px] border border-solid px-2 py-0.5 border-content-quaternary select-none group relative cursor-pointer self-start'
            }
          >
            <div className={'flex flex-row gap-1 items-center '}>
              {renderIcon(alert.severitySn)}
              <b>{alert.severitySn + 1}</b>
            </div>
            <div className={'whitespace-nowrap'}>Price alert</div>
            <div className={'my-2 hidden group-hover:block absolute w-[250px] z-10 bg-white top-full p-3 border border-solid border-content-quaternary'}>
              {alert.reasonSn}
            </div>
          </div>
        )}
      </>
    );
  };

  const DetailView = () => {
    const [visibleCustomerChat, setVisibleCustomerChat] = useState(false);
    return (
      <>
        <>
          <BreadCrumbComponent items={[{ label: tr(`CustomerMultiDemandDetail.multiDemandDetail`, `Multi demand detail`) }]} />
        </>
        <div className={'flex flex-row justify-between my-4'}>
          <COHeadline
            title={tr(`CustomerMultiDemandDetail.multiDemandDetail2`, `Multi demand detail {{multiDemandName}}`, {
              multiDemandName: data.alwaysShowState.groupDemandName,
            })}
            variant={'h1'}
          />
          <CenteredRowWithGap>
            <Button
              icon="pi pi-envelope"
              className="p-button-outlined"
              label={tr(`TabShipmentAssigned.writeMessage`, `Napsat zprávu`)}
              onClick={() => setVisibleCustomerChat(true)}
            />
            {data.alwaysShowState.state === StateEnum.NEW && (
              <>
                <Button
                  label={tr(`CustomerMultiDemandDetail.addMoreCarriers`, `Add more carriers`)}
                  className={'p-button-outlined'}
                  onClick={e => setVisibleAddMoreCarrierDialog(true)}
                />
                {model.filter(t => t.visible).length > 0 && (
                  <SplitButton model={model.filter(t => t.visible !== false)} className="btn-more-info p-button-rounded p-button-outlined" />
                )}
              </>
            )}
          </CenteredRowWithGap>
        </div>
        {data.alwaysShowState.state === StateEnum.FINISHED && (
          <>
            <Table>
              <thead>
                <tr>
                  <th>{tr(`CustomerMultiDemandDetail.supplierNotification`, `Supplier notification`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.loadingPlace`, `Loading place`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.timeslots`, `Timeslots`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.selectedCarrier`, `Selected carrier`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.offeredPrice`, `Offered price`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.percentage`, `Percentage`)}</th>
                  <th>{tr(`CustomerMultiDemandDetail.expeditionId`, `Expedition ID`)}</th>
                </tr>
              </thead>
              <tbody>
                {data.finishedState.supplierNotificationRowsWithDemands.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      <td>
                        <div>
                          <Link
                            to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(row.snId, SupplierNotificationTypeEnum.FCA))}
                          >
                            <Clickable>{row.snApplicationId}</Clickable>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>{row.loadingPlaceName}</div>
                          <div>{row.snCompanyName}</div>
                        </div>
                      </td>
                      <td>
                        <div>{renderFromToOnlyDate(row.loadingFromTime, row.loadingToTime)}</div>
                      </td>
                      <td>
                        <div>{row.carrierName}</div>
                      </td>
                      <td className={'number'}>
                        <div>{formatPrice(row.price.price, row.price.currency)}</div>
                      </td>
                      <td>
                        <div>{formatPercentage(row.percentOfOrder)} %</div>
                      </td>
                      <td>
                        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(row.expeditionApplicationId))}>
                          <Clickable>{row.expeditionApplicationId}</Clickable>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={3} style={{ textAlign: 'left' }}>
                    {tr(`CustomerMultiDemandDetail.celkem`, `Celkem`)}
                  </th>
                  <th>
                    {tr(`CustomerMultiDemandDetail.carrierCount`, `{{count}} Carriers`, { count: data.finishedState.carrierAmount }, data.finishedState.carrierAmount)}
                  </th>
                  <th className={'number'}>{formatPrice(data.finishedState.sumPrice.price, data.finishedState.sumPrice.currency)}</th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </Table>
          </>
        )}

        <RowWithSpaceBetween margin={'1rem 0'}>
          <CenteredRowWithGap>
            <PriceVisualisation price={priceSum()} p={weightAverage()} />
            {!data.alwaysShowState.isDistributionExists && (
              <Button label={tr(`CustomerMultiDemandDetail.createDistributions`, `Create distributions`)} onClick={e => createDistribution()} />
            )}
          </CenteredRowWithGap>
          <Col>
            <div>{tr(`CustomerMultiDemandDetail.platneDo`, `Platne do`)}:</div>
            <div>{mapFromAPIToDateLong(data.alwaysShowState.validTill)}</div>
          </Col>
        </RowWithSpaceBetween>
        <Table>
          <thead>
            <tr>
              <th>{tr(`CustomerMultiDemandDetail.supplierNotification`, `Supplier notification`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.dateAndLoading`, `Date and loading location`)}</th>
              {renderCompanies()}
            </tr>
          </thead>
          <tbody>
            {data.alwaysShowState.supplierNotificationRowsWithDemands.map((row, trKey) => {
              const selectedStyle = hasQuote(row.snId) ? { backgroundColor: 'var(--ringil-base-tertiary-color)' } : {};
              return (
                <tr key={trKey} style={selectedStyle}>
                  <td>
                    <div>
                      <Link to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(row.snId, SupplierNotificationTypeEnum.FCA))}>
                        <Clickable>{row.snApplicationId}</Clickable>
                      </Link>
                      <div>{row.snCompanyName}</div>
                    </div>
                  </td>
                  <td>
                    <div>{mapFromAPIToDateShort(row.loadingFromTime)}</div>
                    <div>{row.loadingPlaceName}</div>
                  </td>
                  {renderCarrierQuotes(row.snId, row.carriersQuotes)}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={2} style={{ textAlign: 'left' }}>
                {tr(`CustomerMultiDemandDetail.celkovaCena`, `Celkova cena`)}
              </th>
              {renderSummedPrice()}
            </tr>
          </tfoot>
        </Table>

        <CreateMDDistributionDialog
          groupDemandId={multiDemandGroupId}
          onCompleted={() => {
            load();
          }}
        />
        <AddMoreCarrierMDDialog
          groupDemandId={multiDemandGroupId}
          onCompleted={() => {
            load();
          }}
        />
        <CreateChangeValidTillDialog
          groupDemandId={multiDemandGroupId}
          distributionValidTill={mapFromAPIDateTime(data.alwaysShowState.validTill)}
          onCompleted={() => {
            load();
          }}
        />
        {loggedUser && (
          <CustomerChatComponent
            companyId={loggedUser.companyId}
            entityId={data.alwaysShowState.groupDemandId}
            entityType={EntityTypeEnum.GROUP_DEMAND}
            visible={visibleCustomerChat}
            selectedCarrierId={null}
            onHide={() => setVisibleCustomerChat(false)}
          />
        )}
      </>
    );
  };

  const SummaryView = () => {
    const navigation = useNavigation();

    const findQuote = (snApplicationId: string) => {
      return selectedQuotes.find(t => t.snAppId === snApplicationId);
    };

    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [loading, setLoading] = useState(false);

    const createExpeditions = () => {
      setLoading(true);
      const body = {
        quotes: selectedQuotes.map(t => {
          return {
            snId: t.snId,
            quoteSnId: t.quoteSnId,
            carrierId: t.carrierId,
            appliedAdditionalTermsIds: checkedTerms.filter(t2 => t2.quoteId === t.quoteSnId && t2.enabled === true).map(t => t.termId),
          };
        }),
      };
      cuCreateExpeditionFromGroupDemandsSN(
        multiDemandGroupId,
        body,
        () => {
          /*router.push('/supplier-notifications');*/
          navigation.navigate(navigation.urlFunctions.createSupplierNotificationList());
        },
        null,
        {
          onValidationFailed: v => {
            setLoading(false);
            setBeValidationErrors(v);
          },
        },
      );
    };

    const changeTerm = (snId: string, quoteId: string, id: string, v: boolean) => {
      const newValue = produce(checkedTerms, draft => {
        const s = draft.find(t => t.termId === id && t.quoteId === quoteId);
        if (isNotNullOrUndefined(s)) {
          s.enabled = v;
        } else {
          draft.push({ quoteId, termId: id, enabled: v });
        }
      });
      setCheckedTerms(newValue);
    };

    const [visibleCustomerChat, setVisibleCustomerChat] = useState(false);

    return (
      <>
        <RowWithSpaceBetween>
          <RingilHeading>
            {tr(`CustomerMultiDemandDetail.summaryTitle`, `Carrier selection`, {
              multiDemandName: data.alwaysShowState.groupDemandName,
            })}
          </RingilHeading>
        </RowWithSpaceBetween>
        <BackendValidationComponent />
        <Table>
          <thead>
            <tr>
              <th>{tr(`CustomerMultiDemandDetail.summarySupplierNotification`, `Supplier notification`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.summaryLocation`, `Location`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.summaryTimeslots`, `Timeslots`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.summarySelectedCarrier`, `Selected carrier`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.summaryOfferedPrice`, `Offered price`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.additionalTerms`, `Additional terms`)}</th>
            </tr>
          </thead>
          <tbody>
            {data.alwaysShowState.supplierNotificationRowsWithDemands.map((sr, srIndex) => {
              const quote = findQuote(sr.snId);
              return (
                <tr key={srIndex}>
                  <td>
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(sr.snId, SupplierNotificationTypeEnum.FCA))}>
                      <Clickable>{sr.snApplicationId}</Clickable>
                    </Link>
                  </td>
                  <td>
                    <div>{sr.loadingPlaceName}</div>
                    <div>{sr.snCompanyName}</div>
                  </td>
                  <td>
                    {mapFromAPIToDateShort(sr.loadingFromTime)} - {mapFromAPIToDateShort(sr.loadingToTime)}
                  </td>
                  <td>{quote ? quote.carrierName : <WithoutQuote>{tr(`CustomerMultiDemandDetail.withoutQuote`, `Without quote`)}</WithoutQuote>}</td>
                  <td>
                    {quote ? (
                      formatPrice(quote.referencePrice, quote.referencePriceCurrency)
                    ) : (
                      <WithoutQuote>{tr(`CustomerMultiDemandDetail.withoutQuote`, `Without quote`)}</WithoutQuote>
                    )}
                  </td>
                  <td>
                    {quote?.additionalTerms?.map((terms, termKey) => {
                      return (
                        <CheckboxWithLabel
                          key={termKey}
                          checkboxLabel={terms.name}
                          value={terms.isMandatory ? true : checkedTerms.find(t => t.termId === terms.id && t.quoteId === quote.quoteSnId)?.enabled || false}
                          onChange={v => {
                            changeTerm(sr.snId, quote.quoteSnId, terms.id, v);
                          }}
                          disabled={terms.isMandatory}
                        />
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>{tr(`CustomerMultiDemandDetail.summed`, `Summed`)}</th>
              <th>{tr(`CustomerMultiDemandDetail.carrierCount`, `{{count}} Carrier`, { count: selectedQuotes.length }, selectedQuotes.length)}</th>
              <th>
                {formatPrice(
                  selectedQuotes.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue.referencePrice;
                  }, 0),
                  loggedUser?.defaultCurrencyIsoCode,
                )}
              </th>
              <th></th>
            </tr>
          </tfoot>
        </Table>
        <ButtonRow>
          <Button
            label={tr(`CustomerMultiDemandDetail.createCountExpeditions`, `Create {{count}} expeditions`, { count: selectedQuotes.length }, selectedQuotes.length)}
            onClick={e => createExpeditions()}
            loading={loading}
          />
          <Button label={tr(`CustomerMultiDemandDetail.back`, `Back`)} className={'p-button-outlined'} onClick={e => setDetailedView(true)} />
        </ButtonRow>
        <CustomerChatComponent
          companyId={loggedUser.companyId}
          entityId={data.alwaysShowState.groupDemandId}
          entityType={EntityTypeEnum.GROUP_DEMAND}
          visible={visibleCustomerChat}
          selectedCarrierId={null}
          onHide={() => setVisibleCustomerChat(false)}
        />
      </>
    );
  };

  return (
    <>
      {detailedView && <DetailView />}
      {!detailedView && <SummaryView />}
    </>
  );
};

const WithoutQuote = styled.div`
  display: flex;
  font-style: italic;
  font-size: small;
  color: var(--ringil-tertiary-color);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead tr th,
  tbody tr td {
    text-align: left;
    padding: 0.5rem;
  }

  tbody tr td.number,
  th.number {
    text-align: right;
  }

  tfoot tr,
  thead tr {
    background-color: var(--ringil-base-penitentiary-color);
  }

  tfoot tr th {
    padding: 1rem 0.5rem;
  }
`;

interface Props {
  p: number;
  price: number;
}

const NotInterested = styled.div`
  display: flex;
  font-style: italic;
  color: var(--ringil-tertiary-color);
`;

const PricePanel = styled.div`
  display: flex;
  font-weight: 700;
  background-color: var(--ringil-base-penitentiary-color);
  padding: 0.5rem;
`;

export default CustomerMultiDemandDetail;
