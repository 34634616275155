import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ExpeditionDetail from '@components/ringil3/Features/Expedition/ExpeditionDetail';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {}

const ExpeditionTestPage: React.FC<ComponentProps> = () => {
  const { expeditionId } = useParams();
  const { tr } = useTranslationLgs();

  return (
    <>
      <Layout
        title={tr('ExpeditionTestPage.detailExpedice', 'Detail expedice')}
        oneOfPermission={[
          PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ,
          PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_RW,
          PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_TRANSPORTATIONTABS_READ_WITHOUTPRICE,
          PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_ATTACHMENTS_READ,
          PermissionTypesEnum.PERMISSION_EXPEDITION_DETAIL_ATTACHMENTS_RW,
          PermissionTypesEnum.PERMISSION_EXPEDITION_MESSAGES_READ,
          PermissionTypesEnum.PERMISSION_EXPEDITION_MESSAGES_RW,
        ]}
      >
        <ExpeditionDetail expeditionId={expeditionId} />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(ExpeditionTestPage);
