import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { Col } from '@components/styles.tsx';
import RingilButton from '@components/ringil3/Buttons/RingilButton.tsx';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import SvgButtonTagEdit from '@components/framework/tag/ButtonTagEdit.tsx';
import { InputText } from 'primereact/inputtext';
import SvgButtonTagAdd from '@components/framework/tag/ButtonTagAdd.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import SvgButtonTagError from '@components/framework/tag/ButtonTagError.tsx';

interface ComponentProps {
  label?: string | React.ReactNode;
  value?: string | ReactNode;
  icon?: JSX.Element;
  actionIcon?: JSX.Element;
  actionPane?: React.ReactNode;
  onClose?: () => void;
  onSave?: (onSuccess: () => void) => void;
  errorMessage?: string;
  hideButtons?: boolean;
  autoClose?: boolean;
  required?: boolean;
  readonly?: boolean;
  children?: ReactElement;
  popupWidth?: string;
}

export const InputTextPane = <T, >({ value, onChange }: { value?: T; onChange?: (value: T) => void }) => {
  return (
    <>
      {/*// @ts-ignore*/}
      <InputText value={`${value}`} onChange={e => onChange(e.target.value)} />
    </>
  );
};

const ButtonTag = <T, >({
                          label,
                          icon,
                          actionIcon,
                          actionPane,
                          onClose = () => {
                          },
                          value,
                          onSave = () => {
                          },
                          hideButtons = false,
                          autoClose = false,
                          required = false,
                          readonly = false,
                          errorMessage,
                          popupWidth,
                          children,
                        }: ComponentProps) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<T>();
  const summaryRef = useRef<HTMLDivElement>(null);
  const [isAbove, setIsAbove] = useState(false);

  useEffect(() => {
    const handlePosition = () => {
      if (summaryRef.current) {
        const rect = summaryRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        setIsAbove(rect.bottom > windowHeight / 2);
      }
    };

    handlePosition(); // Run once on mount

    window.addEventListener('resize', handlePosition); // Update on window resize
    return () => window.removeEventListener('resize', handlePosition);
  }, []);

  const handleClickOutside = (event: any) => {
    const target = event.target as HTMLElement;

    if (target.closest('li.p-dropdown-item')) {
      return;
    }

    if (target.closest('div.p-checkbox-box')) {
      return;
    }

    if (target.closest('.p-multiselect-panel')) {
      return;
    }
    if (target.closest('.p-datepicker')) {
      return;
    }

    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      console.log(event);
      setOpen(false);
      onClose();
    }
  };

  useEffect(() => {
    if (open && autoClose) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const variant = isNullOrUndefined(errorMessage) 
    ? (isNullOrUndefined(value) 
      ? (required ? 'required' : 'empty') 
      : 'defined') 
    : 'error';

  const hasError = isNotNullOrUndefined(errorMessage);
  const hasValue = isNullOrUndefined(errorMessage) && isNotNullOrUndefined(value);

  return (
    <div>
      <Details
        ref={detailsRef}
        open={open}
        onClick={e => {
          /*        if (open) {
          e.preventDefault();
          return;
        }
        setOpen(true);*/
          e.preventDefault();
        }}
      >
        <summary
          ref={summaryRef}
          onClick={e => {
            if (!readonly) setOpen(true);
          }}
          className={twMerge(
            clsx(
              'flex list-none rounded-lg px-[12px] py-[14px] gap-2 items-center content-center border border-solid border-content-secondary',
              {
                'cursor-pointer': !readonly,
              },
              {
                'bg-content-quaternary': variant === 'empty',
              },
              {
                'bg-complementary-warningMedium': variant === 'required' && !readonly,
              },
              {
                'bg-complementary-errorMedium border-complementary-error  py-[2px]': variant === 'error',
              },
              {
                'bg-base-secondary border-content-brandMedium  py-[2px]': variant === 'defined',
              },
            ),
          )}
        >
          {icon && isNullOrUndefined(errorMessage) && <div>{icon}</div>}
          {isNotNullOrUndefined(errorMessage) && (
            <div>
              <SvgButtonTagError />
            </div>
          )}
          {label && typeof label == 'string' && (
            <Col>
              {isNotNullOrUndefined(errorMessage) && <div className={'text-complementary-error'}>{errorMessage}</div>}
              <div className={''}>{label}</div>
              {isNotNullOrUndefined(value) && (
                <div className={'font-semibold'}>
                  {errorMessage ? (
                    <span className="text-complementary-error">{value}</span>
                  ) : (
                    <span>{value}</span>
                  )}
                </div>
              )}
            </Col>
          )}
          {label && typeof label != 'string' && <span>{label}</span>}
          {!readonly && isNotNullOrUndefined(value) && (
            <div>
              <SvgButtonTagEdit
                onClick={e => {
                  setOpen(true);
                }}
              >
                {actionIcon}
              </SvgButtonTagEdit>
            </div>
          )}
          {!readonly && isNullOrUndefined(value) && (
            <SvgButtonTagAdd
              onClick={e => {
                setOpen(true);
              }}
            >
              {actionIcon}
            </SvgButtonTagAdd>
          )}
        </summary>
        {open && (
          <FloatContent popupWidth={popupWidth} isAbove={isAbove}>
            {actionPane}
            {children}
            {!hideButtons && (
              <TagPaneButtonRow>
                <RingilButton
                  label={tr(`RingilSidebar.continue`, `Continue`)}
                  onClick={() => {
                    onSave(() => setOpen(false));
                  }}
                  mode={'filled'}
                  size={'big'}
                  loading={false}
                />
                <RingilButton
                  label={tr(`RingilSidebar.back`, `Back`)}
                  onClick={() => {
                    if (onClose !== null) onClose();
                    setOpen(false);
                  }}
                  mode={'text-only'}
                  size={'big'}
                  loading={false}
                />
              </TagPaneButtonRow>
            )}
          </FloatContent>
        )}
      </Details>
    </div>
  );
};

const TagPaneButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Details = styled.details`
  display: inline-block;
  position: relative;

  & [open] summary {
    border: 1px solid var(--content-brand);
    background-color: var(--base-basesecondary);
  }
`;

const FloatContent = styled.div<{ popupWidth?: string; isAbove?: boolean }>`
  display: flex;
  padding: 1.5rem;
  background-color: var(--base-basesecondary);
  position: absolute;
  z-index: 1000;
  margin: 0.5rem 0;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 1rem;
  top: ${({ isAbove }) => (isAbove ? 'auto' : '100%')};
  bottom: ${({ isAbove }) => (isAbove ? '100%' : 'auto')};
  ${({ popupWidth }) => popupWidth && `width: ${popupWidth};`}
`;

export default ButtonTag;
