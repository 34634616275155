import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import {
  AccessTypeEnum,
  DetailSupplierNotificationOutDTO,
  EntityTypeEnum,
  FeaturesEnum,
  ShipmentStateCodeEnum,
  SNConfiguration,
  WeightModeEnum,
} from '@api/logsteo-api.v2';
import {
  formatDistance,
  formatLocationNotino,
  formatPieces,
  formatPrice,
  formatWeight,
  isNotNullOrUndefined,
  isNullOrUndefined,
} from '@utils/utils';
import {
  CenteredRowWithGap,
  Clickable,
  Label,
  RingilH3,
  RingilHeading,
  Row,
  RowWithSpaceBetween,
} from '@components/styles';
import { Button } from 'primereact/button';
import { mapFromApiToDate, mapFromAPIToDateShort } from '@utils/date';
import { Checkbox } from 'primereact/checkbox';
import {
  CUSTOMIZATION_MODE_DISABLED,
  CUSTOMIZATION_MODE_OPTIONAL,
  CUSTOMIZATION_MODE_READONLY,
  CUSTOMIZATION_MODE_REQUIRED,
} from '@utils/exports';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';
import InfoPanelWithIcon from '@components/obsolete/InfoPanelWithIcon/InfoPanelWithIcon.tsx';
import OrderInfoComponent from '@components/obsolete/OrderInfoComponent/OrderInfoComponent.tsx';
import CreatedBadge from '@components/obsolete/Badge/CreatedBadge.tsx';
import UpdatedBadge from '@components/obsolete/Badge/UpdatedBadge.tsx';
import Badge, { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';
import {
  inputTransporationTypeConvertToIcon,
} from '@components/obsolete/Form/InputTransportationType/InputTransportationType.tsx';
import {
  MoreButtonsSupplierNotification,
} from '@components/obsolete/ButtonMoreNavigation/MoreButtonsSupplierNotification.tsx';
import {
  isSomeOpenTimeslotsExists,
  printDeliverySlots,
} from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import {
  useCreateEditSupplierNotificationCargoInfoSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationCargoInfoSidebar.tsx';
import {
  useCreateEditSupplierNotificationInternalInfoSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationInternalInfoSidebar.tsx';
import {
  useCreateEditSupplierNotificationLoadingUnloadingSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationLoadingUnloadingSidebar.tsx';
import {
  useCreateEditSupplierNotificationInternalNoteSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationInternalNoteSidebar.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';
import {
  useCreateEditSupplierNotificationHeaderSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationHeaderSidebar.tsx';
import {
  useCreateEditSupplierNotificationOrdersSidebar,
} from '@app/pages/customer/supplier-notification/sidebars/EditSupplierNotificationOrdersSidebar.tsx';
import { isEntityOwner, toUIMapNotifyData } from '@app/pages/customer/supplier-notification/utils.ts';
import InputInternalNote from '@components/obsolete/Form/InputInternalNote/InputInternalNote.tsx';
import RoundedInfoNote from '@components/obsolete/RoundedInfoNote/RoundedInfoNote.tsx';
import InfoNote from '@components/obsolete/InfoNote/InfoNote.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';
import { Card } from 'primereact/card';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

interface ComponentProps {
  supplierNotificationId: string;
}

const SupplierNotificationDetail: React.FC<ComponentProps> = ({ supplierNotificationId }) => {
  const { tr } = useTranslationLgs();
  const {
    detailSupplierNotificationById,
    deleteDocumentForEntity,
    genericUploadV2,
    changeDocumentVisibility,
    getSNFeatureConfiguration,
    createExpeditionFromSN,
  } =
    useContext(ApiContext);
  const [data, setData] = useState<DetailSupplierNotificationOutDTO>();
  const n = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [visibleCounterPartyChat, setVisibleCounterPartyChat] = useState(false);
  const [visibleCustomerChat, setVisibleCustomerChat] = useState(false);

  const disabled = loggedUser?.securityRoles.indexOf('ROLE_SN_RW') == -1;

  useEffect(() => {
    load();
  }, [supplierNotificationId]);

  const load = () => {
    detailSupplierNotificationById(supplierNotificationId, d => {
      setData(d);
      reloadAttachments(EntityTypeEnum.SUPPLIER_NOTIFICATION, supplierNotificationId);
    });
  };

  const addAttachment = (attachments: AttachmentDocument[]) => {
    genericUploadV2(
      {
        entityType: EntityTypeEnum.SUPPLIER_NOTIFICATION,
        entityIds: [data.id],
        attachments: attachments.map(t => {
          return {
            attachmentId: t.attachmentId,
            accessType: t.accessType,
            documentType: t.documentType?.documentType,
            userNote: t.userNote,
            userDate: t.userDate,
          };
        }),
        companyOwnerId: null,
      },
      () => {
        load();
      },
    );
  };

  const createExpeditionFromSupplierNotification = (supplierNotificationId: string) => {
    createExpeditionFromSN(supplierNotificationId, expeditionCoordinates => {
      navigation.navigate(navigation.urlFunctions.createExpeditionDetailV2(expeditionCoordinates.expeditionId));
    });
  };

  const delAttachment = (attachmentId: string) => {
    deleteDocumentForEntity(EntityTypeEnum.SUPPLIER_NOTIFICATION, data.id, attachmentId, () => {
      load();
    });
  };

  const hasTimeslots = () => data.supplierDeliverySlots.dayWithInterval.length > 0;
  const isReady = () => data.shipmentStateCode === 'READY';

  const {
    EditSupplierNotificationHeaderSidebar,
    setVisible: setVisibleSupplierNotificationHeader,
  } = useCreateEditSupplierNotificationHeaderSidebar(() => {
    load();
  });

  const {
    EditSupplierNotificationOrdersSidebar,
    setVisible: setVisibleSupplierNotificationOrders,
  } = useCreateEditSupplierNotificationOrdersSidebar(() => {
    load();
  });

  const {
    EditSupplierNotificationCargoInfoSidebar,
    setVisible: setVisibleSupplierNotificationCargoInfo,
  } = useCreateEditSupplierNotificationCargoInfoSidebar(() => {
    load();
  });

  const {
    EditSupplierNotificationInternalInfoSidebar,
    setVisible: setVisibleSupplierNotificationInternalInfo,
  } = useCreateEditSupplierNotificationInternalInfoSidebar(
    () => {
      load();
    },
  );

  const {
    EditSupplierNotificationLoadingUnloadingSidebar,
    setVisible: setVisibleSupplierNotificationLoadingUnloading,
  } =
    useCreateEditSupplierNotificationLoadingUnloadingSidebar(() => {
      load();
    });

  const {
    EditSupplierNotificationInternalNoteSidebar,
    setVisible: setVisibleSupplierNotificationInternalNote,
  } = useCreateEditSupplierNotificationInternalNoteSidebar(
    () => {
      load();
    },
  );
  const {
    AttachmentComponent,
    load: reloadAttachments,
  } = useCreateAttachmentComponent({
    enableCopyDocument: isEntityOwner(loggedUser, data?.customerCompany?.id),
    doNotShare: disabled,
    disabled: disabled,
  });

  const changeAttachmentVisibility = (attachmentId: string, internal: boolean) => {
    changeDocumentVisibility(attachmentId, { accessType: internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL }, () => {
      load();
    });
  };

  const [snConfiguration, setSnConfiguration] = useState<SNConfiguration>();
  const navigation = useNavigation();

  useEffect(() => {
    if (data?.customerCompany != null) {
      getSNFeatureConfiguration(data.customerCompany.id, 'edit', d => {
        setSnConfiguration(d);
      });
    }
  }, [data?.customerCompany]);

  if (isNullOrUndefined(data) || isNullOrUndefined(loggedUser)) return <></>;

  const hasNewReservationFeature = loggedUser?.features?.indexOf(FeaturesEnum.NEW_RESERVATION) !== -1;

  return (
    /*// @ts-ignore*/
    <Card>
      <RowWithSpaceBetween>
        <RingilHeading>
          {tr(`SupplierNotificationDetail.supplierNotificationTitle`, `Supplier notification {{applicationId}}`, {
            applicationId: data.applicationId,
          })}
        </RingilHeading>
        <CenteredRowWithGap>
          {hasNewReservationFeature &&
            isNullOrUndefined(data.expeditionCreated) &&
            data.supplierDeliverySlots?.dayWithInterval?.length > 0 &&
            isEntityOwner(loggedUser, data.entityOwnerCompanyId) &&
            isSomeOpenTimeslotsExists(data.customerDeliverySlots) &&
            isSomeOpenTimeslotsExists(data.supplierDeliverySlots) &&
            isNullOrUndefined(data.groupDemandCreated) &&
            data.shipmentStateCode !== ShipmentStateCodeEnum.WAITING &&
            !disabled && (
              <Button
                label={tr('SupplierNotificationDetail.createExpedition2', 'Create expedition 2')}
                onClick={e => {
                  // new way - without need to use create process in FE
                  createExpeditionFromSupplierNotification(data.id);
                }}
              />
            )}
          {isNullOrUndefined(data.expeditionCreated) &&
            data.supplierDeliverySlots?.dayWithInterval?.length > 0 &&
            isEntityOwner(loggedUser, data.entityOwnerCompanyId) &&
            isSomeOpenTimeslotsExists(data.customerDeliverySlots) &&
            isSomeOpenTimeslotsExists(data.supplierDeliverySlots) &&
            isNullOrUndefined(data.groupDemandCreated) &&
            data.shipmentStateCode !== ShipmentStateCodeEnum.WAITING &&
            !disabled && (
              <Button
                label={tr('SupplierNotificationDetail.createExpedition', 'Create expedition')}
                onClick={e => {
                  /*router.push(`/customer/process-supplier-notification?fromSupplierNotification=${data.id}`);*/
                  navigation.navigate(navigation.urlFunctions.createSupplierNotificationFromSupplierNotification(data.id));
                }}
              />
            )}

          {isEntityOwner(loggedUser, data.entityOwnerCompanyId) ? (
            <Button
              icon="pi pi-envelope"
              className="p-button-outlined"
              label={tr('RouteDescriptionComponent.writeMessage', 'Write message')}
              onClick={() => setVisibleCustomerChat(true)}
            />
          ) : (
            <Button
              icon="pi pi-envelope"
              className="p-button-outlined"
              label={tr('RouteDescriptionComponent.writeMessage', 'Write message')}
              onClick={() => setVisibleCounterPartyChat(true)}
            />
          )}

          {!disabled && (
            <MoreButtonsSupplierNotification
              supplierNotificationId={data.id}
              supplierNotificationApplicationId={data.applicationId}
              supplierLocationName={data.supplierLocation.locationName}
              onChange={() => load()}
              archived={data.isArchived}
              isEntityOwner={data.entityOwnerCompanyId === loggedUser.companyId}
              isDAP={false}
              onCanceledSupplierNotification={() => {
                load();
              }}
            />
          )}
        </CenteredRowWithGap>
      </RowWithSpaceBetween>
      <RowWithSpaceBetween>
        <BadgeRow>
          {!hasTimeslots() &&
            <Badge text={tr(`SupplierNotificationDetail.withoutTimeslots`, `Without timeslots`)} icon={<></>}
                   color={'var(--ringil-draft-color)'} />}
          {!isReady() &&
            <Badge text={tr(`SupplierNotificationDetail.waitingForPayment`, `Waiting for payment`)} icon={<></>}
                   color={'var(--ringil-processing-color'} />}

          <Badge text={data.transportationTypeCode.name}
                 icon={inputTransporationTypeConvertToIcon(data.transportationTypeCode.transportationType)} />
          <Badge text={formatDistance(data.distance)} />
          <Badge text={formatPrice(data.orderValue.amount, data.orderValue.currencyCode)} />
          <Badge text={formatWeight(data.totalWeight)} />
          <Badge
            text={formatPieces(
              data.orders.reduce((previousValue, currentValue) => {
                return previousValue + currentValue.orderAmount;
              }, 0),
              tr,
            )}
          />
          <Badge
            text={tr(
              `SystemLabel.piecesCount`,
              `Pieces count {{count}}`,
              {
                count: data.orders.reduce((previousValue, currentValue) => {
                  return previousValue + currentValue.items.reduce((p, c) => p + c.quantity, 0);
                }, 0),
              },
              data.orders.reduce((previousValue, currentValue) => {
                return previousValue + currentValue.items.reduce((p, c) => p + c.quantity, 0);
              }, 0),
            )}
          />
          <Labels objectType={EntityTypeEnum.SUPPLIER_NOTIFICATION} objectId={supplierNotificationId}
                  disabled={disabled} />
        </BadgeRow>
        <BadgeRow>
          <CreatedBadge createdAt={data.createdAt} createdBy={data.createdBy.fullName}
                        createdEmail={data.createdBy.email} />
          {data.updatedAt && <UpdatedBadge updatedAt={data.updatedAt} updatedBy={data.updatedBy.fullName}
                                           updatedEmail={data.updatedBy.email} />}
        </BadgeRow>
      </RowWithSpaceBetween>
      <Section
        label={tr(`SupplierNotificationDetail.parties`, `Cooperating parties`)}
        buttons={
          <>
            {!disabled && (
              <EditButton
                label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
                onClick={e => {
                  setVisibleSupplierNotificationHeader(true);
                }}
              />
            )}
          </>
        }
      >
        {data.expeditionCreated && (
          <RR>
            <RC large={4} medium={6} small={6}>
              <span>{tr(`SupplierNotificationDetail.linkedExpedition`, `Expedition:`)}</span>
              &#160;
              {isEntityOwner(loggedUser, data.entityOwnerCompanyId) ? (
                <Clickable
                  onClick={e => {
                    /*router.push(`/customer/expedition/${data.expeditionCreated.expeditionApplicationId}`);*/
                    navigation.navigate(navigation.urlFunctions.createCustomerExpeditionDetail(data.expeditionCreated.expeditionApplicationId));
                  }}
                >
                  <span>{data.expeditionCreated.expeditionApplicationId}</span>
                </Clickable>
              ) : (
                <span>{data.expeditionCreated.expeditionApplicationId}</span>
              )}
            </RC>
          </RR>
        )}
        <RR>
          <RC large={4} medium={6} small={6}>
            <RingilH3>{tr(`SupplierNotificationDetail.supplier`, `Supplier`)}</RingilH3>
            <Label>{data.carrierCompany.name}</Label>
            <div>{formatLocationNotino(data.supplierLocation.zipCode, data.supplierLocation.city, data.supplierLocation.country, data.supplierLocation.streetNr)}</div>
            <br />
            <InputInternalNote ownerCompanyId={data.customerCompany.id} partnerCompanyId={data.carrierCompany.id}
                               readOnly={disabled} />
          </RC>
          <RC large={4} medium={6} small={6}>
            <RingilH3>{tr(`SupplierNotificationDetail.customer`, `Customer`)}</RingilH3>
            <Label>{data.customerCompany.name}</Label>
            <div>{formatLocationNotino(data.customerLocation.zipCode, data.customerLocation.city, data.customerLocation.country, data.customerLocation.streetNr)}</div>
          </RC>
        </RR>
        <RR>
          <RC large={4} medium={6} small={6}>
            <HorizontalFieldValue label={tr(`SupplierNotificationDetail.shipmentState`, `Shipment state`)}
                                  value={data.shipmentStateName} />
          </RC>
        </RR>
        <RR>
          <RC large={4} medium={6} small={6}>
            <HorizontalFieldValue label={tr(`SupplierNotificationDetail.transportationType`, `Transportation type`)}
                                  value={data.transportationTypeCode.name} />
          </RC>
        </RR>
      </Section>
      <Section
        label={tr(`SupplierNotificationDetail.shipmentTimeslots`, `Shipment timeslots`)}
        buttons={
          hasTimeslots() && snConfiguration?.timeslots !== CUSTOMIZATION_MODE_DISABLED && snConfiguration?.timeslots !== CUSTOMIZATION_MODE_READONLY && !disabled ? (
            <EditButton
              label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
              onClick={e => {
                setVisibleSupplierNotificationLoadingUnloading(true);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {snConfiguration?.timeslots !== CUSTOMIZATION_MODE_DISABLED && (
          <>
            {hasTimeslots() ? (
              <RR>
                <RC large={4} medium={6} small={6}>
                  <Label>{tr(`SupplierNotificationDetail.departure`, `Departure`)}</Label>
                  {printDeliverySlots(data.supplierDeliverySlots, tr)}
                </RC>
                <RC large={4} medium={6} small={6}>
                  <Label>{tr('SupplierNotificationDetail.arrival', 'Arrival')}</Label>
                  {printDeliverySlots(data.customerDeliverySlots, tr)}
                </RC>
              </RR>
            ) : (
              <>
                {(snConfiguration?.timeslots == CUSTOMIZATION_MODE_OPTIONAL || snConfiguration?.timeslots === CUSTOMIZATION_MODE_REQUIRED) && (
                  <RoundedInfoNote>
                    <CenteredRowWithGap>
                      <span>{tr(`SupplierNotificationDetail.transportationDoesNotHaveAnyTimeslots`, `Transportation does not have any timeslots`)}</span>
                      <Button
                        label={tr(`SupplierNotificationDetail.enterTimeslots`, `Enter timeslots`)}
                        onClick={e => setVisibleSupplierNotificationLoadingUnloading(true)}
                        disabled={disabled}
                      />
                    </CenteredRowWithGap>
                  </RoundedInfoNote>
                )}
              </>
            )}
          </>
        )}
      </Section>
      {isEntityOwner(loggedUser, data.customerCompany.id) && (
        <Section
          label={tr(`SupplierNotificationDetail.internalNote`, `Internal note`)}
          buttons={
            isEntityOwner(loggedUser, data.entityOwnerCompanyId) ? (
              <>
                {!disabled && (
                  <EditButton
                    label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
                    onClick={e => {
                      setVisibleSupplierNotificationInternalNote(true);
                    }}
                  />
                )}
              </>
            ) : (
              <></>
            )
          }
        >
          <RR>
            {data.internalNote && (
              <RC large={12} medium={12} small={12}>
                <InfoNote text={data.internalNote} />
              </RC>
            )}
          </RR>
        </Section>
      )}
      <Section
        label={tr(`SupplierNotificationDetail.cargoInfo`, `Cargo information`)}
        buttons={
          <>
            {!disabled && (
              <EditButton
                label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
                onClick={e => {
                  setVisibleSupplierNotificationCargoInfo(true);
                }}
              />
            )}
          </>
        }
      >
        <Row>
          <HorizontalFieldValue
            label={tr(`SupplierNotificationDetail.additionalCarrierRequirements`, `Additional carrier requirements`)}
            value={
              <RR>
                {data.specialRequirements.map((requirement, requirementIndex) => (
                  <RC large={4} medium={6} small={12} key={requirementIndex}>
                    <Checkbox checked={true} className="p-mr-2" disabled />
                    <label>{requirement.name}</label>
                  </RC>
                ))}
              </RR>
            }
          />
        </Row>
        <Row>
          <HorizontalFieldValue
            label={tr(`SupplierNotificationDetail.note`, `Note`)}
            value={
              <RR>
                <RC large={4} medium={6} small={6}>
                  {data.note}
                </RC>
              </RR>
            }
          />
        </Row>
        <RR>
          {data.cargoType && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue
                label={tr(`SupplierNotificationDetail.cargoType`, `Cargo type`)}
                value={[
                  data.cargoType.cargoType.cargoTypeName,
                  data.cargoType.cargoType.cargoTypeCode === CargoTypeCodeEnum.ADR_LQ ? formatWeight(data.cargoType.adrWeight) : null,
                ]
                  .filter(t => t != null)
                  .join(', ')}
              />
            </RC>
          )}

          {data.containerInfo && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue
                label={tr(`SupplierNotificationDetail.container`, `Container`)}
                value={`${data.containerInfo.containerInfoName}, ${data.containerInfo.containerId}`}
              />
            </RC>
          )}

          {data.customsTypeCode && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`SupplierNotificationDetail.customsType`, `Customs type`)}
                                    value={data.customsTypeName} />
            </RC>
          )}

          {data.shipmentId && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`SupplierNotificationDetail.shipmentId`, `Shipment ID`)}
                                    value={data.shipmentId} />
            </RC>
          )}

          {data.referralCode && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`SupplierNotificationDetail.referralCode`, `Referral code`)}
                                    value={data.referralCode} />
            </RC>
          )}
        </RR>
      </Section>
      <Section
        label={tr(`SupplierNotificationDetail.orders`, `Orders`)}
        buttons={
          <>
            {!disabled && (
              <EditButton
                label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
                onClick={e => {
                  setVisibleSupplierNotificationOrders(true);
                }}
              />
            )}
          </>
        }
      >
        {data.orders.map((order, orderIndex) => {
          return (
            <React.Fragment key={orderIndex}>
              <Label>{order.orderName}</Label>
              <RR>
                <RC large={4} medium={6} small={6}>
                  {order.items.map((item, itemIndex) => {
                    return (
                      <OrderInfoComponent
                        key={itemIndex}
                        name={item.cargoItemType.name}
                        amount={item.quantity}
                        unit={item.quantityUnitCode}
                        weight={null}
                        length={item.length}
                        height={item.height}
                        width={item.width}
                        stackable={item.stackable}
                      />
                    );
                  })}
                </RC>
                <RC large={4} medium={6} small={6}>
                  <InfoPanelWithIcon icon={'/images/icons/info.svg'}>
                    {order.orderAmount && (
                      <div>
                        {order.orderDescription}, {order.orderAmount} ks
                      </div>
                    )}

                    {order.weight && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tr('SupplierNotificationDetail.totalWeight', 'Total weight: <strong>{{totalWeight}}</strong>', {
                            totalWeight: formatWeight(order.weight.value),
                          }),
                        }}
                      />
                    )}

                    {isNotNullOrUndefined(order.orderValue) && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tr('SupplierNotificationDetail.orderValue', 'Order value: <strong>{{orderValue}}</strong>', {
                            orderValue: formatPrice(order.orderValue.amount, order.orderValue.currencyCode),
                          }),
                        }}
                      />
                    )}
                  </InfoPanelWithIcon>
                </RC>
              </RR>
            </React.Fragment>
          );
        })}
      </Section>
      {isEntityOwner(loggedUser, data.customerCompany.id) && (
        <Section
          label={tr(`SupplierNotificationDetail.internalInfo`, `Internal info`)}
          buttons={
            isEntityOwner(loggedUser, data.entityOwnerCompanyId) ? (
              <>
                {!disabled && (
                  <EditButton
                    label={tr(`SupplierNotificationDetail.edit`, `Edit`)}
                    onClick={e => {
                      setVisibleSupplierNotificationInternalInfo(true);
                    }}
                  />
                )}
              </>
            ) : (
              <></>
            )
          }
        >
          <RR>
            {data.notifyData.responsiblePerson && (
              <RC large={4} medium={6} small={6}>
                <HorizontalFieldValue label={tr(`SupplierNotificationDetail.responsiblePerson`, `Responsible person`)}
                                      value={data.notifyData.responsiblePerson.name} />
              </RC>
            )}

            {data.notifyData.usersToNotify && data.notifyData.usersToNotify.length > 0 && (
              <RC large={4} medium={6} small={6}>
                <HorizontalFieldValue
                  label={tr(`SupplierNotificationDetail.subscribers`, `Subscribers`)}
                  value={data.notifyData.usersToNotify.map(t => t.name).join(', ')}
                />
              </RC>
            )}

            {data.internalInvoiceNumber && (
              <RC large={4} medium={6} small={6}>
                <HorizontalFieldValue
                  label={tr(`SupplierNotificationDetail.internalInvoiceNumber`, `Internal invoice number`)}
                  value={data.internalInvoiceNumber} />
              </RC>
            )}

            {data.invoiceDueDate && (
              <RC large={4} medium={6} small={6}>
                <HorizontalFieldValue
                  label={tr(`SupplierNotificationDetail.internalInvoiceDueDate`, `Internal invoice due date`)}
                  value={mapFromAPIToDateShort(data.invoiceDueDate)}
                />
              </RC>
            )}
            {data.deliveryConditionsIncotermCode && (
              <RC large={4} medium={6} small={6}>
                <HorizontalFieldValue label={tr(`SupplierNotificationDetail.incoterm`, `Incoterm`)}
                                      value={data.deliveryConditionsIncotermCode} />
              </RC>
            )}
          </RR>
        </Section>
      )}
      {data.previousShipment && isEntityOwner(loggedUser, supplierNotificationId) && (
        <Section label={tr(`SupplierNotificationDetail.previousShipment`, `Previous shipment`)}>
          <a href={n.createNavigationLink(n.urlFunctions.createShipmentDetail(data.previousShipment.id))}
             target={'_new'}>
            <Clickable>{data.previousShipment.shipmentApplicationId}</Clickable>
          </a>
        </Section>
      )}
      <AttachmentComponent
        onAddAttachment={attachments => {
          addAttachment(attachments);
        }}
        onDeletedAttachment={attachmentId => {
          delAttachment(attachmentId);
        }}
        entityId={data.id}
        entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
        onChangeVisibility={(attachmentId, internal) => {
          changeAttachmentVisibility(attachmentId, internal);
        }}
      />
      <EditSupplierNotificationHeaderSidebar
        supplierNotificationId={data.id}
        initialData={{
          supplierId: data.carrierCompany.id,
          supplierLocationId: data.supplierLocation.locationId,
          transportationType: data.transportationTypeCode.transportationType,
          shipmentStateCode: data.shipmentStateCode,
          companyId: data.customerCompany.id,
          customerLocationId: data.customerLocation.locationId,
          customerDeliverySlots: data.customerDeliverySlots,
          supplierDeliverySlots: data.supplierDeliverySlots,
        }}
        configuration={snConfiguration}
      />
      <EditSupplierNotificationOrdersSidebar
        companyId={data.customerCompany.id}
        supplierNotificationId={data.id}
        supplierId={data.carrierCompany.id}
        initialData={{
          orders: data.orders.map(t => {
            return {
              name: t.orderName,
              items: t.items.map(t2 => {
                return {
                  length: t2.length,
                  quantity: t2.quantity,
                  width: t2.width,
                  stackable: isNullOrUndefined(t2.stackable) ? false : t2.stackable,
                  height: t2.height,
                  weightMode: WeightModeEnum.SKU_WEIGHT,
                  weight: t2.weight,
                  cargoMessage: '',
                  quantityUnit: t2.quantityUnitCode.toString(),
                  cargoItemType: {
                    name: t2.cargoItemType.name,
                    code: t2.cargoItemType.code,
                    quantity: 1,
                    decimalPlaces: 0,
                  },
                };
              }),
              cargoDescription: t.orderDescription,
              weight: t.weight,
              currencyOrderValue: t.orderValue,
              cargoAmount: t.orderAmount,
            };
          }),
        }}
        configuration={snConfiguration}
      />
      <EditSupplierNotificationCargoInfoSidebar
        supplierNotificationId={data.id}
        companyId={data.customerCompany.id}
        transportationTypeCode={data.transportationTypeCode.transportationType}
        initialData={{
          shipmentId: data.shipmentId,
          cargoType: {
            cargoTypeCode: data.cargoType.cargoType.cargoTypeCode,
            adrWeight: data.cargoType.adrWeight,
          },
          customsTypeCode: data.customsTypeCode,
          referralCode: data.referralCode,
          containerInfo: data.containerInfo,
          specialRequirementIds: data.specialRequirements.map(t => t.code),
          note: data.note,
        }}
        configuration={snConfiguration}
      />
      <EditSupplierNotificationInternalInfoSidebar
        supplierNotificationId={data.id}
        companyId={data.customerCompany.id}
        initialData={{
          notifyData: toUIMapNotifyData(data.notifyData),
          deliveryConditionsIncotermCode: data.deliveryConditionsIncotermCode,
          internalInvoiceNumber: data.internalInvoiceNumber,
          invoiceDueDate: mapFromApiToDate(data.invoiceDueDate),
        }}
      />
      <EditSupplierNotificationLoadingUnloadingSidebar
        supplierNotificationId={data.id}
        companyId={data.customerCompany.id}
        initialData={{
          supplierDeliverySlots: data.supplierDeliverySlots,
          customerDeliverySlots: data.customerDeliverySlots,
          supplierLocationId: data.supplierLocation.locationId,
          customerLocationId: data.customerLocation.locationId,
        }}
        supplierId={data.carrierCompany?.id}
        customerLocationId={data.customerLocation?.locationId}
        supplierLocationId={data.supplierLocation?.locationId}
      />
      <EditSupplierNotificationInternalNoteSidebar
        supplierNotificationId={data.id}
        initialData={{ internalNote: data.internalNote }}
        companyId={data.customerCompany.id}
      />
      {!isEntityOwner(loggedUser, data.customerCompany.id) ? (
        <>
          <CarrierChatComponent
            companyId={data.customerCompany.id}
            entityId={data.id}
            entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
            visible={visibleCounterPartyChat}
            onHide={() => setVisibleCounterPartyChat(false)}
            customerName={data.customerCompany.name}
            readOnly={disabled}
          />
        </>
      ) : (
        <CustomerChatComponent
          companyId={loggedUser.companyId}
          entityId={data.id}
          entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
          visible={visibleCustomerChat}
          selectedCarrierId={data.carrierCompany.id}
          onHide={() => setVisibleCustomerChat(false)}
          readOnly={disabled}
        />
      )}
    </Card>
  );
};

export default SupplierNotificationDetail;
