import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgDriver from '@components/ringil3/icons/Driver.tsx';
import { InputText } from 'primereact/inputtext';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';

interface ComponentProps {
  assignedDriverName: string;
  onChange: (assignedDriverName: string) => void;
  readOnly: boolean;
}

const AssignedDriverTag: React.FC<ComponentProps> = ({ assignedDriverName, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();
  const [assignedDriverNameInternal, setAssignedDriverNameInternal] = useState(assignedDriverName);

  const assignedDriverPane = () => {
    return (
      <>
        <InputText value={assignedDriverNameInternal} onChange={e => setAssignedDriverNameInternal(e.target.value)} />
      </>
    );
  };

  const save = () => {
    onChange(assignedDriverNameInternal);
  };

  return (
    <ButtonTag
      label={tr(`AssignedDriverTag.title`, `Driver`)}
      value={assignedDriverName}
      icon={<SvgDriver />}
      actionPane={assignedDriverPane()}
      actionIcon={<SvgTagEdit />}
      onSave={onSuccess => {
        save();
        console.log('aaaa')
        onSuccess();
      }}
      autoClose
      readonly={readOnly}
    />
  );
};

export default AssignedDriverTag;
