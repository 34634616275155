import React, { useMemo } from 'react';
import { LadenFactorExpeditionOutDTO } from '@api/logsteo-api.v2';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Link } from 'react-router-dom';
import useNavigation from '@hooks/useNavigation.tsx';
import { Col } from '@components/styles.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { formatManipulationUnit, formatPrice } from '@utils/utils.tsx';

interface ComponentProps {
  expeditions?: LadenFactorExpeditionOutDTO[];
}

interface GroupedExpedition {
  carrierId: string;
  carrierName: string;
  expeditions: LadenFactorExpeditionOutDTO[];
}

const ArrangedQuoteTable: React.FC<ComponentProps> = ({ expeditions = [] }) => {
  const { tr } = useTranslationLgs();
  const navigation = useNavigation();

  const groupedExpeditions = useMemo(() => {
    const grouped: Record<string, GroupedExpedition> = {};

    expeditions.forEach(expedition => {
      if (!grouped[expedition.carrierId]) {
        grouped[expedition.carrierId] = {
          carrierId: expedition.carrierId,
          carrierName: expedition.carrierName,
          expeditions: [],
        };
      }

      grouped[expedition.carrierId].expeditions.push(expedition);
    });

    Object.values(grouped).forEach(group => {
      group.expeditions.sort((a, b) =>
        a.expeditionApplicationId.localeCompare(b.expeditionApplicationId),
      );
    });

    return Object.values(grouped);
  }, [expeditions]);

  const renderName = (row: GroupedExpedition) => {
    return <div>{row.carrierName}</div>;
  };

  const rendererLinkedExpeditions = (row: GroupedExpedition) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', flexWrap: 'wrap' }}>
        {row.expeditions.map(expedition => (
          <Link
            key={expedition.expeditionApplicationId}
            to={navigation.createNavigationLink(navigation.urlFunctions.createExpeditionDetailV2(expedition.expeditionId))}
          >
            {expedition.expeditionApplicationId}
          </Link>
        ))}
      </div>
    );
  };

  const renderPrice = (row: GroupedExpedition) => {
    const ladenFactorUnit = tr(`ArrangedQuoteTable.ladenFactorUnits`, `MU`);
    const totalPrice = row.expeditions.reduce((sum, expedition) => sum + (expedition.price || 0), 0);
    const totalMUs = row.expeditions.reduce((sum, expedition) => sum + (expedition.manipulationUnits || 0), 0);
    const currency = row.expeditions[0]?.currency || '';
    const distance = row.expeditions[0]?.distance || '';

    return (
      <div>
        <Col>
          <COBadge variant={'outlined'} type={'neutral'} style={{ marginBottom: '4px' }}>
            {[formatManipulationUnit(totalMUs, ladenFactorUnit), `${(totalPrice / totalMUs).toFixed(2)} ${currency}/${ladenFactorUnit}`]
              .filter(t => t != null)
              .join(' | ')}
          </COBadge>

          <COBadge variant={'filled'} type={'positive'}>
            {[formatPrice(totalPrice, currency), distance ? `${(totalPrice / (distance * row.expeditions.length)).toFixed(2)} ${currency}/km` : null]
              .filter(t => t != null)
              .join(' | ')}
          </COBadge>
        </Col>

      </div>
    );
  };


  return (
    <>
      <RingilDataTable
        data={groupedExpeditions}
        columns={[
          { name: tr('ArrangedQuoteTable.carrier', 'Carrier'), width: '20%' },
          { name: tr('ArrangedQuoteTable.price', 'Price'), width: '30%' },
          { name: tr('ArrangedQuoteTable.linkedExpeditions', 'Linked expeditions'), width: '50%' },
        ]}
        renderers={[renderName, renderPrice, rendererLinkedExpeditions]}
      />
    </>
  );
};

export default ArrangedQuoteTable;
