import React, { useContext, useMemo, useState } from 'react';
import {
  CarrierQuoteStateEnum,
  CustomerQuoteStateEnum,
  EmailStatusEnum,
  EntityTypeEnum,
  QuoteGroupEnum,
  QuotesPreviewOutDTO,
} from '@api/logsteo-api.v2';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import { Col, RowWithGap } from '@components/styles.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { dateFormatDateTime, mapFromAPIDateTime, mapFromAPIToDateLong } from '@utils/date';
import { Dayjs } from 'dayjs';
import styled, { css } from 'styled-components';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { formatManipulationUnit, formatPrice, isNotNullOrUndefined } from '@utils/utils';
import { Ringil3Note } from '@components/ringil3/styles';
import RectangleButton from '@components/ringil3/Buttons/RectangleButton';
import SvgMessage from '@components/ringil3/icons/Message';
import SvgWarning from '@components/ringil3/icons/Warning';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useCreateAcceptQuote } from '@components/ringil3/Features/Expedition/QuoteTable/AcceptQuote/AcceptQuote.tsx';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import { useCreateRejectQuote } from '@components/ringil3/Features/Expedition/QuoteTable/RejectQuote/RejectQuote.tsx';

interface ComponentProps {
  quotes?: QuotesPreviewOutDTO[];
  onChanged: () => void;
  companyId: string;
  expeditionId: string;
  showPrices: boolean;
  showMessages: boolean;
  editMessages: boolean;
  editTransportation: boolean;
  ladenfactor?: number;
  freeManipulationUnits?: number;
}

const SpotQuoteTable: React.FC<ComponentProps> = ({
                                                    quotes,
                                                    onChanged,
                                                    companyId,
                                                    expeditionId,
                                                    showPrices,
                                                    editMessages,
                                                    showMessages,
                                                    editTransportation,
                                                    ladenfactor,
                                                    freeManipulationUnits,
                                                  }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [selectedCarrierId, setSelectedCarrierId] = useState(undefined);

  const sortedQuotes = useMemo(() => {
    if (!quotes || quotes.length === 0) return [];

    // Create enhanced quotes with calculated prices
    const enhancedQuotes = quotes.map(quote => new EnhancedQuote(quote, ladenfactor, freeManipulationUnits));

    // First sort by total price
    enhancedQuotes.sort(EnhancedQuote.sortByTotalPrice);

    // Then sort by price per manipulation unit (keeping total price order for equal values)
    enhancedQuotes.sort((a, b) => {
      if (a.pricePerKm === null && b.pricePerKm === null) {
        return EnhancedQuote.sortByTotalPrice(a, b);
      }
      return EnhancedQuote.sortByPricePerKm(a, b);
    });

    // Finally sort by price per km (keeping previous orders for equal values)
    enhancedQuotes.sort((a, b) => {
      if (a.pricePerManipulationUnit === null && b.pricePerManipulationUnit === null) {
        if (a.pricePerKm === null && b.pricePerKm === null) {

          return EnhancedQuote.sortByTotalPrice(a, b);
        }
        return EnhancedQuote.sortByPricePerKm(a, b);
      }
      return EnhancedQuote.sortByPricePerManipulationUnit(a, b);
    });

    // Return the original quotes in the sorted order
    return enhancedQuotes;
  }, [quotes, freeManipulationUnits, ladenfactor]);

  const renderName = (row: EnhancedQuote) => {
    return <div>{row.quote.name}</div>;
  };

  const { AcceptQuote, setSidebarData } = useCreateAcceptQuote();
  const { RejectQuote, setSidebarData: setRejectSidebarData } = useCreateRejectQuote();

  const renderActions = (row: EnhancedQuote) => {
    return (
      <>
        {row.quote.quoteGroup === QuoteGroupEnum.RINGIL && editTransportation && (
          <>
            {row.quote.carrierQuoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !row.quote.isExpired && (
              <ColSameSize>
                <RectangleButton
                  label={tr('customerExpeditionDetail.tabCarrierPending.pickButton', 'Vybrat')}
                  onClick={() =>
                    setSidebarData({
                      selectedQuoteId: row.quote.id,
                      selectedCarrierName: row.quote.name,
                      applicationId: row.quote.expeditionApplicationId,
                      companyId: loggedUser.companyId,
                      ladenFactor: ladenfactor,
                      offeredManipulationUnits: row.quote.offeredManipulationUnits,
                      freeManipulationUnits: row.freeManipulationUnits,
                    })
                  }
                />
                <RectangleButton
                  label={tr('customerExpeditionDetail.tabCarrierPending.rejectButton', 'Odmítnout')}
                  onClick={() => {
                    setRejectSidebarData({
                      selectedQuoteId: row.quote.id,
                      selectedCarrierName: row.quote.name,
                      applicationId: row.quote.expeditionApplicationId,
                      companyId: loggedUser.companyId,
                    });
                  }}
                />
              </ColSameSize>
            )}
            {row.quote.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER && !row.quote.isExpired && (
              <GreyLabel>{tr('SpotQuoteTable.acceptedUnits', 'Selected: {{chosenManipulationUnits}} MU', { chosenManipulationUnits: row.quote.chosenManipulationUnits })}</GreyLabel>
            )}
          </>
        )}
      </>
    );
  };

  const renderMessages = (row: EnhancedQuote) => {
    if (showMessages === false) return <></>;
    const hasDifferentSlot = row.quote.locations.filter(location => location.isDifferent).length > 0;
    return (
      <MessagesAndWarning>
        {row.quote.messagesCount == 0 && editMessages === true && (
          <LinkWithIcon onClick={() => setSelectedCarrierId(row.quote.carrierId)} icon={<SvgMessage />}
                        label={tr(`QuoteTable.writeMessage`, `Write message`)}></LinkWithIcon>
        )}
        {row.quote.messagesCount > 0 && showMessages && (
          <LinkWithIcon
            onClick={() => setSelectedCarrierId(row.quote.carrierId)}
            label={tr(`QuoteTable.messagesReply`, `Message: {{messagesCount}}`, {
              messagesCount: row.quote.messagesCount,
            })}
          ></LinkWithIcon>
        )}
        {hasDifferentSlot && <LinkWithIcon label={tr(`SpotQuoteTable.diferrentSlots`, `Diferrent slots`)}
                                           icon={<SvgWarning />}></LinkWithIcon>}
      </MessagesAndWarning>
    );
  };

  const mapToColor = (emailStatus?: EmailStatusEnum) => {
    switch (emailStatus) {
      case EmailStatusEnum.DELIVERED:
      case EmailStatusEnum.READ_EMAIL:
      case EmailStatusEnum.READ_IN_APP:
      case EmailStatusEnum.SEND:
        return 'var(--ringil3-complementary-draft)';
      case EmailStatusEnum.FAILED:
        return 'var(--ringil3-complementary-error-color)';
      default:
        return '';
    }
  };

  const mapCarrierQuoteStateToColor = (emailStatus?: CarrierQuoteStateEnum) => {
    switch (emailStatus) {
      case CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER:
      case CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM:
        return 'var(--ringil3-complementary-success)';
      case CarrierQuoteStateEnum.OFFERED_PRICE:
        return 'var(--ringil3-complementary-draft)';
      case CarrierQuoteStateEnum.REJECTED_BY_CARRIER:
        return 'var(--ringil3-complementary-error-color)';
      case CarrierQuoteStateEnum.NEW:
      case CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER:
      default:
        return '';
    }
  };

  const mapCustomerQuoteStateToColor = (emailStatus?: CustomerQuoteStateEnum) => {
    switch (emailStatus) {
      case CustomerQuoteStateEnum.ASSIGNED_MANUALLY_BY_CUSTOMER:
      case CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER:
        return 'var(--ringil3-complementary-success)';
      case CustomerQuoteStateEnum.REJECTED_BY_CARRIER:
        return 'var(--ringil3-complementary-error-color)';
      default:
        return '';
    }
  };

  const renderState = (row: EnhancedQuote) => {
    return (
      <div>
        {row.quote.emailStatus && row.quote.carrierActionAt && (
          <>
            {row.quote.distributionType === 'SPOT' && (
              <DescriptionDistributionStatus color={mapCarrierQuoteStateToColor(row.quote.carrierQuoteState)}>
                {tr(`SpotQuoteTable.carrierQuoteState.${row.quote.carrierQuoteState}`, `${row.quote.carrierQuoteState}`)}
              </DescriptionDistributionStatus>
            )}{' '}
            {row.quote.distributionType === 'FIXED_PRICE' && (
              <DescriptionDistributionStatus color={mapCustomerQuoteStateToColor(row.quote.customerQuoteState)}>
                {tr(`SpotQuoteTable.customerQuoteState.${row.quote.customerQuoteState}`, `${row.quote.customerQuoteState}`)}
              </DescriptionDistributionStatus>
            )}
            <div className="p-d-flex p-flex-wrap">
              <RowWithGap>
                <Date>{mapFromAPIToDateLong(row.quote.emailStatusCreatedAt)}</Date>
                <div className="tooltip">
                  <img src={'/images/icons/ringil3/envelope.svg'} alt="info"
                       className="p-button-text tooltip-button p-p-0" />
                  <div className="tooltip-container">
                    <div className="descrip-wrapper">
                      {row.quote.emailStatusDetail?.map((item, index) => DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email))}
                    </div>
                  </div>
                </div>
              </RowWithGap>
            </div>
          </>
        )}
        {row.quote.emailStatus && !row.quote.carrierActionAt && (
          <>
            <DescriptionDistributionStatus color={mapToColor(row.quote.emailStatus)}>
              {tr(`SpotQuoteDataTable.emailStatus.${row.quote.emailStatus}`, 'SpotQuoteDataTable.emailStatus.${emailStatus}')}
            </DescriptionDistributionStatus>
            <div className="p-d-flex p-flex-wrap">
              <RowWithGap>
                <Date>{mapFromAPIToDateLong(row.quote.emailStatusCreatedAt)}</Date>
                <div className="tooltip">
                  <img src={'/images/icons/ringil3/envelope.svg'} alt="info"
                       className="p-button-text tooltip-button p-p-0" />
                  <div className="tooltip-container">
                    <div className="descrip-wrapper">
                      {row.quote.emailStatusDetail?.map((item, index) => DescriptionTooltip(index, item.userName, item.emailStatus, mapFromAPIDateTime(item.time), item.email))}
                    </div>
                  </div>
                </div>
              </RowWithGap>
            </div>
          </>
        )}
      </div>
    );
  };

  const DescriptionTooltip = (index: number, name: string, status: string, dateTime: Dayjs, email: string) => {
    return (
      <DescriptionInner key={index}>
        <div className="descrip-detail__name">
          <strong>{name}</strong>
        </div>
        <div className="descrip-detail__name">
          <strong>{email}</strong>
        </div>
        <div className="p-d-flex p-jc-between">
          <small
            className="descrip-detail__status">{tr(`SpotQuoteDataTable.emailStatus.${status}`, 'SpotQuoteDataTable.emailStatus.${status}')}</small>
          <small className="descrip-detail__data">{dateFormatDateTime(dateTime)}</small>
        </div>
      </DescriptionInner>
    );
  };

  const renderPrice = (row: EnhancedQuote) => {
    const ladenFactorUnit = tr(`QuoteTable.ladenFactorUnits`, `MU`);
    if (showPrices === false) return <></>;
    if (row.quote.distributionType === 'SPOT')
      return (
        <div>
          {row.quote.offeredPrice && row.quote.carrierQuoteState !== CarrierQuoteStateEnum.REJECTED_BY_CARRIER && (
            <Col>
              {row.quote.offeredManipulationUnits && (
                <COBadge variant={'outlined'} type={'neutral'} style={{ marginBottom: '4px' }}>
                  {[formatManipulationUnit(row.quote.offeredManipulationUnits, ladenFactorUnit), row.quote.offeredManipulationUnits ? `${row.pricePerManipulationUnit.toFixed(2)} ${row.quote.currency}/${ladenFactorUnit}` : null]
                    .filter(t => t != null)
                    .join(' | ')}
                </COBadge>
              )}
              <COBadge variant={'filled'}
                       type={row.quote.carrierQuoteState === CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER ? 'neutral' : 'positive'}
                       style={{ marginBottom: '4px' }}>
                {[formatPrice(row.totalPrice, row.quote.currency), row.quote.distance ? `${row.pricePerKm.toFixed(2)} ${row.quote.currency}/km` : null]
                  .filter(t => t != null)
                  .join(' | ')}
              </COBadge>
              <Ringil3Note>
                {tr(`QuoteTable.offerValidTillDateWithTime`, `Offer valid till {{dateWithTime}}`, {
                  dateWithTime: mapFromAPIToDateLong(row.quote.validTill),
                })}
              </Ringil3Note>
            </Col>
          )}
        </div>
      );

    return (
      <div>
        {row.totalPrice &&
          (row.quote.customerQuoteState === CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER || row.quote.customerQuoteState === CustomerQuoteStateEnum.ASSIGNED_MANUALLY_BY_CUSTOMER) && (
            <Col>
              <COBadge variant={'filled'} type={'positive'}>
                {[formatPrice(row.totalPrice, row.quote.currency), row.quote.distance ? `${row.pricePerKm.toFixed(2)} ${row.quote.currency}/km` : null]
                  .filter(t => t != null)
                  .join(' | ')}
              </COBadge>
            </Col>
          )}
      </div>
    );
  };

  return (
    <>
      <RingilDataTable
        data={sortedQuotes}
        columns={[
          { name: tr('SpotQuoteTable.carrier', 'Carrier') },
          { name: tr('SpotQuoteTable.state', 'State') },
          { name: tr('SpotQuoteTable.price', 'Offered Price') },
          { name: tr('SpotQuoteTable.communication', 'Communication') },
          { name: tr('SpotQuoteTable.action', 'Action') },
        ]}
        renderers={[renderName, renderState, renderPrice, renderMessages, renderActions]}
      ></RingilDataTable>
      <AcceptQuote onCompleted={() => onChanged()} />
      <RejectQuote onCompleted={() => onChanged()} />
      <CustomerChatComponent
        companyId={companyId}
        entityId={expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={isNotNullOrUndefined(selectedCarrierId)}
        selectedCarrierId={selectedCarrierId}
        onHide={() => setSelectedCarrierId(undefined)}
        readOnly={!editMessages}
      />
    </>
  );
};

const DescriptionInner = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DescriptionDistributionStatusProps {
  color: string;
}

const DescriptionDistributionStatus = styled.div<DescriptionDistributionStatusProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  ${props =>
      props.color &&
      css`
        color: ${props.color};
      `}
`;

const Date = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: var(--ringil3-gr4);
  font-weight: 400;
`;

const ColSameSize = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  flex: 1;
`;

const MessagesAndWarning = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
`;

const GreyLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--ringil3-gr4);
  margin-top: -4px;
  margin-bottom: 4px;
`;

export default SpotQuoteTable;


class EnhancedQuote {
  quote: QuotesPreviewOutDTO;
  pricePerManipulationUnit: number | null;
  pricePerKm: number | null;
  totalPrice: number | null;
  freeManipulationUnits: number | null;

  constructor(quote: QuotesPreviewOutDTO, ladenFactor: number, freeManipulationUnits: number) {
    this.quote = quote;
    this.totalPrice = quote.offeredPrice || null;
    this.freeManipulationUnits = freeManipulationUnits;
    this.pricePerManipulationUnit = quote.offeredPrice && quote.offeredManipulationUnits
      ? quote.offeredPrice / quote.offeredManipulationUnits
      : null;
    // if (isNotNullOrUndefined(ladenFactor) && ladenFactor > 0 && isNotNullOrUndefined(quote.offeredManipulationUnits) && quote.offeredManipulationUnits > 0) {
    this.pricePerKm = quote.offeredPrice && quote.distance && quote.distance > 0
      ? ladenFactor && ladenFactor > 0 && quote.offeredManipulationUnits && quote.offeredManipulationUnits > 0
        ? (quote.offeredPrice * ladenFactor) / (quote.distance * quote.offeredManipulationUnits)
        : quote.offeredPrice / quote.distance
      : null;
  }

  static sortByTotalPrice(a: EnhancedQuote, b: EnhancedQuote): number {
    if (a.totalPrice === null) return 1;
    if (b.totalPrice === null) return -1;
    return a.totalPrice - b.totalPrice;
  }

  static sortByPricePerManipulationUnit(a: EnhancedQuote, b: EnhancedQuote): number {
    if (a.pricePerManipulationUnit === null) return 1;
    if (b.pricePerManipulationUnit === null) return -1;
    return a.pricePerManipulationUnit - b.pricePerManipulationUnit;
  }

  static sortByPricePerKm(a: EnhancedQuote, b: EnhancedQuote): number {
    if (a.pricePerKm === null) return 1;
    if (b.pricePerKm === null) return -1;
    return a.pricePerKm - b.pricePerKm;
  }
}