import React, { useState, useMemo } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { useImmer } from 'use-immer';
import { InputMask } from 'primereact/inputmask';
import { setTime, shortDateFormat } from '@utils/date';
import { getTime } from '@utils/utils';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import { DefaultFormPanel } from '@components/obsolete/DefaultFormPanel/DefaultFormPanel.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import RejectDialogComponent from '@app/pages/carrier/demand-detail/RejectDialog/RejectDialogComponent.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { Dropdown } from 'primereact/dropdown';

interface ComponentProps {
  demandValidTill: Dayjs;
  onAccept: (data: CreateSpotState) => void;
  onReject: () => void;
  enableAction: boolean;
  code: string;
  manipulationUnits?: number;
  ladenFactor?: number;
  allowPartialDelivery?: boolean;
}

export interface CreateSpotState {
  price: number;
  selectedData: Dayjs;
  currency: string;
  partialDeliveryUnits?: number;
}

const CreateSpotComponent: React.FC<ComponentProps> = ({
                                                         demandValidTill,
                                                         onAccept,
                                                         onReject,
                                                         enableAction,
                                                         code,
                                                         manipulationUnits,
                                                         ladenFactor,
                                                         allowPartialDelivery = false,
                                                       }) => {
  const { t, i18n } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [checked, setChecked] = useState(false);
  const [rejectDialogVisible, setRejectDialogVisible] = useState(false);
  const ladenFactorOptions = useMemo(() => {
    if (!manipulationUnits || !ladenFactor) return [];
    const options = [];
    for (let i = ladenFactor; i <= manipulationUnits; i += ladenFactor) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  }, [manipulationUnits, ladenFactor]);

  const [state, setState] = useImmer<CreateSpotState>({
    price: 0,
    selectedData: demandValidTill.add(72, 'hours'),
    currency: null,
    partialDeliveryUnits: manipulationUnits,
  });

  const validationScheme = yup.object().shape({
    price: yup.number().defined().required().min(0),
    selectedData: yup.mixed().test('required', 'required', (value: Dayjs) => value.isValid()),
    currency: yup.string().defined().required(),
    ...(manipulationUnits && ladenFactor && {
      partialDeliveryUnits: yup.number().defined().required()
    })
  });


  const { validationErrors, validateAndSend } = useValidation(validationScheme, () => {
    onAccept(state);
  });

  return (
    <>
      <div className="p-col-12 p-lg-6">
        <DefaultFormPanel>

          {ladenFactor && (
            <div className="p-field">
              <COInlineMessage variant={'info'}>
                {tr('CreateSpotComponent.ladenFactorInfo', 'Requested is {{manipulationUnits}} MU. Customer set laden factor to {{ladenFactor}} MU', {
                  manipulationUnits: manipulationUnits,
                  ladenFactor: ladenFactor,
                })}
              </COInlineMessage>
                <div className="p-field">
                  <Label title={tr('CreateSpotComponent.deliveredUnitsHelp', 'How many units will be delivered')} />
                  <div className="p-inputgroup">
                    <Dropdown
                      options={ladenFactorOptions}
                      optionValue="value"
                      optionLabel="label"
                      value={state.partialDeliveryUnits}
                      onChange={(e) => {
                        setState(draft => {
                          draft.partialDeliveryUnits = e.value;
                        });
                      }}
                      style={{ width: '100%', color: 'var(--ringil3-gr4)' }}
                      disabled={!allowPartialDelivery}
                      // placeholder={tr('CreateSpotComponent.selectDeliveryUnits', 'Select how much MU will be delivered')}
                    />
                  </div>
                </div>
            </div>
          )}
          <div className="p-field">
            <Label title={t(`CreateSpotComponent.setYourPriceQuote`, `Zadejte svojí nabídku`)} />
            <div className="p-inputgroup">
              <InputPriceWithCurrency
                price={state.price}
                currency={state.currency}
                changePrice={v => {
                  setState(draft => {
                    draft.price = v;
                  });
                }}
                changeCurrency={v => {
                  setState(draft => {
                    draft.currency = v;
                  });
                }}
                disabled={!enableAction}
                code={code}
              />
            </div>
            <ValidationDiv errors={validationErrors} path={'price'} />
          </div>

          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p- md-6">
              <Label title={t(`CreateSpotComponent.setAValidityDateOfYourPriceQuote`, `Zadejte platnost nabídky`)} />
              <Calendar
                locale={i18n.language}
                className="p-mb-1"
                value={state.selectedData.toDate()}
                showIcon
                dateFormat={shortDateFormat}
                onChange={e =>
                  setState(draft => {
                    draft.selectedData = dayjs(e.value as Date);
                  })
                }
                disabled={!enableAction}
              />
              <ValidationDiv errors={validationErrors} path={'selectedData'} />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <Label title={t(`CreateSpotComponent.setAValidityDateOfYourPriceQuote`, `Zadejte platnost nabídky`)} />
              <InputMask
                mask={'99:99'}
                value={getTime(state.selectedData)}
                onComplete={e =>
                  setState(draft => {
                    draft.selectedData = setTime(draft.selectedData, e.value);
                  })
                }
                disabled={!enableAction}
              />
              <ValidationDiv errors={validationErrors} path={'selectedData'} />
            </div>

            <div className="p-field p-col-12">
              <ButtonLayout>
                <Button
                  label={t(`CreateSpotComponent.1Day`, `1 den`)}
                  className="p-mr-2 p-button-text p-p-0"
                  style={{ width: 'auto' }}
                  onClick={() => {
                    const dateTime = demandValidTill.add(1, 'day');
                    setState(draft => {
                      draft.selectedData = dateTime;
                    });
                  }}
                  disabled={!enableAction}
                />
                <Button
                  label={t(`CreateSpotComponent.2Days`, `2 dny`)}
                  className="p-mr-2 p-button-text p-p-0"
                  style={{ width: 'auto' }}
                  onClick={() => {
                    const dateTime = demandValidTill.add(2, 'day');
                    setState(draft => {
                      draft.selectedData = dateTime;
                    });
                  }}
                  disabled={!enableAction}
                />
                <Button
                  label={t(`CreateSpotComponent.8Hours`, `8 hodin po konci poptávky`)}
                  className="p-button-text p-p-0"
                  style={{ width: 'auto' }}
                  onClick={() => {
                    const dateTime = demandValidTill.add(8, 'hour');
                    setState(draft => {
                      draft.selectedData = dateTime;
                    });
                  }}
                  disabled={!enableAction}
                />
              </ButtonLayout>
            </div>
          </div>

          <div className="p-fluid p-field-checkbox p-mb-3">
            <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)}
                      disabled={!enableAction} />
            <label htmlFor="binary">
              <small>
                {t(
                  `CreateSpotComponent.weConfirmThatWeAreAbleToFulfillRequiredTimeSlotsForThisShipment`,
                  `Potvrzujeme, že jsme schopni realizovat poptávku v zadaných časových oknech`,
                )}
              </small>
            </label>
          </div>
          <ButtonLayout wrapper="WITH_PADDING_TOP_4">
            <Button
              label={t(`CreateSpotComponent.sendPriceQuote`, `Odeslat cenovou nabídku`)}
              className="p-mr-3"
              disabled={!checked || !enableAction}
              onClick={() => validateAndSend(state)}
            />
            <Button
              label={t(`CreateSpotComponent.reject`, `Odmítnout`)}
              className="p-button-outlined"
              icon="pi pi-times"
              onClick={() => setRejectDialogVisible(true)}
              disabled={!enableAction}
            />
          </ButtonLayout>
        </DefaultFormPanel>
        <RejectDialogComponent
          visible={rejectDialogVisible}
          onHide={() => setRejectDialogVisible(false)}
          onReject={() => {
            onReject();
            setRejectDialogVisible(false);
          }}
          disabled={!enableAction}
        />
      </div>
    </>
  );
};

export default CreateSpotComponent;
