import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { ColWithGap, RowWithSpaceBetween } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import useForm from '@hooks/useForm/useForm';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { ColOdd, HorizontalLine, InputAndButtonGrid, OrderWrapper, ThreeColGridWithoutGap } from '@components/ringil3/styles';
import { findValidationMessage } from '@utils/validation';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix';
import { isNotNullOrUndefined } from '@utils/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import InputSKU from '@components/ringil3/Input/InputSKU/InputSKU';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink';
import { Order, orderSchema } from '@components/framework/orders/common';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  onRefresh: () => void;
}

interface Form {
  orders: Order[];
}

export const useCreateEditReservationOrderItemsDiscrepancySidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditReservationOrderItemsDiscrepancySidebar: React.FC<ComponentProps> = ({ reservationId, applicationId, onRefresh }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const { getReservationDetail, updateOrderDiscrepancies } = useContext(ApiContext);

    useEffect(() => {
      const ac = new AbortController();

      getReservationDetail(
        reservationId,
        d => {
          if (ac.signal.aborted) return;
          form.overwriteValues({
            orders:
              d.orders.length > 0
                ? d.orders.map(
                    t =>
                      ({
                        id: t.id,
                        orderName: t.orderName,
                        orderAmount: t.orderAmount,
                        orderDescription: t.orderDescription,
                        orderDiscrepancyNote: t.discrepancyNote,
                        items: t.items.map(i => ({
                          id: i.id,
                          amount: i.amount,
                          cargoTemplateCode: i.cargoTemplate?.code,
                          stackable: i.stackable,
                          discrepancyAmount: i.discrepancyAmount ? i.discrepancyAmount : i.amount,
                        })),
                      } as Order),
                  )
                : [
                    {
                      items: [],
                      orderName: null,
                      orderAmount: null,
                      orderDescription: null,
                    },
                  ],
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
      return () => {
        ac.abort();
      };
    }, [reservationId]);

    const form = useForm<Form>(yup.object().shape({ orders: orderSchema }), null, d => save(d), false, false, null, 'scroll');

    const mapDiscrepancies = (orders: Order[]) => {
      return {
        orderDiscrepancies: orders.map(order => ({
          id: order.id,
          discrepancyNote: order.orderDiscrepancyNote,
          discrepancies: order.items.map(item => ({
            id: item.id,
            discrepancyAmount: item.discrepancyAmount, // Adjust this field as needed
          })),
        })),
      };
    };

    const save = (data: Form) => {
      updateOrderDiscrepancies(
        mapDiscrepancies(data.orders),
        () => {
          setVisible(false);
          onRefresh();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
            <RowWithSpaceBetween>
              <div>{tr('EditOrderItemsDiscrepancySidebar.title', 'Update order real state')}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          {isNotNullOrUndefined(form.values) && (
            <>
              <BackendValidationComponent />
              <ColWithGap>
                <VerticalFieldAndValueWithIcon
                  label={tr(`EditOrderItemsDiscrepancySidebar.forReservation`, `For reservation`)}
                  valueText={applicationId}
                  iconPath={'/images/icons/ringil3/forExpedition.svg'}
                />
                <HorizontalLine variant={'tertiary'} />
                <ColOdd>
                  {form.values.orders.map((order, orderIndex) => {
                    return (
                      <OrderWrapper key={orderIndex}>
                        <VerticalFieldAndValue
                          label={tr(`EditOrderItemsDiscrepancySidebar.order`, `Order`)}
                          labelMode={'normal'}
                          errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].orderName`, tr)}
                        >
                          <InputAndButtonGrid>
                            <InputTextarea
                              value={order.orderName}
                              style={{ width: '100%' }}
                              onChange={e => form.setFieldValue(`orders[${orderIndex}].orderName`, e.target.value)}
                              disabled={true}
                              autoResize={true}
                            />
                          </InputAndButtonGrid>
                        </VerticalFieldAndValue>
                        {order.items.map((item, itemIndex) => {
                          return (
                            <React.Fragment key={itemIndex}>
                              <ThreeColGridWithoutGap>
                                <VerticalFieldAndValue
                                  label={tr(`EditOrderItemsDiscrepancySidebar.manipulationUnit`, `Manipulation unit SKU`)}
                                  labelMode={'normal'}
                                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, tr)}
                                >
                                  <InputSKU
                                    fullHeight={true}
                                    value={item.cargoTemplateCode}
                                    onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, v)}
                                    disabled={true}
                                  />
                                </VerticalFieldAndValue>
                                <VerticalFieldAndValue
                                  label={tr(`EditOrderItemsDiscrepancySidebar.amount`, `Amount`)}
                                  labelMode={'normal'}
                                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].amount`, tr)}
                                >
                                  <InputTextWithSuffix
                                    fullHeight={true}
                                    suffix={'ks'}
                                    inputTextPosition={'right'}
                                    value={item.amount}
                                    onChange={() => {}}
                                    disabled={true}
                                  />
                                </VerticalFieldAndValue>
                                <VerticalFieldAndValue
                                  label={tr(`EditOrderItemsDiscrepancySidebar.amountDiscrepancy`, `Discrepancy`)}
                                  labelMode={'normal'}
                                  errorMessage={findValidationMessage(form.validationErrors, `orders[${orderIndex}].items[${itemIndex}].discrepancyAmount`, tr)}
                                >
                                  <InputTextWithSuffix
                                    fullHeight={true}
                                    suffix={'ks'}
                                    inputTextPosition={'right'}
                                    value={item.discrepancyAmount}
                                    onChange={v => form.setFieldValue(`orders[${orderIndex}].items[${itemIndex}].discrepancyAmount`, v)}
                                  />
                                </VerticalFieldAndValue>
                              </ThreeColGridWithoutGap>
                            </React.Fragment>
                          );
                        })}
                        <InputNoteWithLink
                          note={order.orderDiscrepancyNote}
                          onChanges={v => form.setFieldValue(`orders[${orderIndex}].orderDiscrepancyNote`, v)}
                          noteLabel={tr(`EditOrderItemsDiscrepancySidebar.orderDiscrepancyNote`, `Order discrepancy note`)}
                        />
                      </OrderWrapper>
                    );
                  })}
                </ColOdd>
              </ColWithGap>
            </>
          )}
        </RingilSidebar>
      </>
    );
  };

  return { EditReservationOrderItemsDiscrepancySidebar, setVisible, visible };
};
