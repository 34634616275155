import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgDriverAndId from '@components/ringil3/icons/DriverAndId.tsx';
import { InputText } from 'primereact/inputtext';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import { isNotBlank } from '@utils/utils.tsx';

interface ComponentProps {
  customApplicationId: string;
  onChange: (customApplicationId: string) => void;
}

const CustomerApplicationIdTag: React.FC<ComponentProps> = ({ customApplicationId, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState(customApplicationId);

  const save = () => {
    onChange(isNotBlank(internalValue) ? internalValue : null);
  };

  const pane = () => {
    return (
      <>
        <InputText value={internalValue} onChange={e => setInternalValue(e.target.value)} keyfilter={RegExp('[a-zA-Z0-9-]+')} />
      </>
    );
  };

  return (
    <>
      <ButtonTag
        label={tr(`CustomerApplicationIdTag.title`, `Vlastní ID expedice`)}
        value={customApplicationId}
        actionIcon={<SvgTagEdit />}
        actionPane={pane()}
        onSave={onSuccess => {
          onChange(isNotBlank(internalValue) ? internalValue : null);
          onSuccess();
        }}
        autoClose
      />
    </>
  );
};

export default CustomerApplicationIdTag;
