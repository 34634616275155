import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface ComponentProps {
  variant: 'filled' | 'outlined';
  type: BadgeType;
  backgroundColor?: string;
  color?: string;
  iconPath?: string;
  onIconClick?: (e: any) => void;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
}

interface BadgeColors {
  outlined: {
    backgroundColor: string;
    color: string;
  };
  filled: {
    backgroundColor: string;
    color: string;
  };
}

type Badges = Record<Exclude<BadgeType, 'custom'>, BadgeColors>;

type BadgeType = 'draft' | 'neutral' | 'processing' | 'positive' | 'warning' | 'loading' | 'unloading' | 'custom';

const badges: Badges = {
  unloading: {
    outlined: {
      backgroundColor: 'var(--complementary-draftmedium)',
      color: 'var(--complementary-draftmedium)',
    },
    filled: {
      backgroundColor: 'var(--complementary-draftmedium)',
      color: 'var(--ringil3-content-primary)',
    },
  },
  loading: {
    outlined: {
      backgroundColor: 'var(--complementary-processingmedium)',
      color: 'var(--complementary-processingmedium)',
    },
    filled: {
      backgroundColor: 'var(--complementary-processingmedium)',
      color: 'var(--ringil3-content-primary)',
    },
  },
  warning: {
    outlined: {
      backgroundColor: 'var(--ringil3-complementary-warning)',
      color: 'var(--ringil3-content-secondary)',
    },
    filled: {
      backgroundColor: 'var(--ringil3-complementary-warning-medium)',
      color: 'var(--ringil3-content-primary)',
    },
  },
  positive: {
    outlined: {
      backgroundColor: '#00C65E',
      color: '#00C65E',
    },
    filled: {
      backgroundColor: 'var(--ringil3-complementary-success-medium)',
      color: 'var(--ringil3-content-primary)',
    },
  },
  processing: {
    outlined: {
      backgroundColor: '#00B5E2',
      color: '#00B5E2',
    },
    filled: {
      backgroundColor: '#00B5E2',
      color: 'var(--ringil3-content-primary)',
    },
  },
  draft: {
    outlined: {
      backgroundColor: '#4A25AA',
      color: '#4A25AA',
    },
    filled: {
      backgroundColor: '#4A25AA',
      color: '#FFFFFF',
    },
  },
  neutral: {
    outlined: {
      backgroundColor: 'var(--ringil3-content-secondary)',
      color: 'var(--ringil3-content-secondary)',
    },
    filled: {
      backgroundColor: 'var(--ringil3-content-secondary)',
      color: '#FFFFFF',
    },
  },
};

const COBadge: React.FC<PropsWithChildren<ComponentProps>> = ({
                                                                children,
                                                                variant,
                                                                type,
                                                                backgroundColor,
                                                                color,
                                                                iconPath,
                                                                onIconClick = e => {
                                                                },
                                                                onClick = e => {
                                                                },
                                                                style: style,
                                                              }) => {
  if (type !== 'custom')
    return (
      <Inner backgroundColor={badges[type][variant].backgroundColor} color={badges[type][variant].color}
             outline={variant === 'outlined'} onClick={e => onClick(e)} style={style}>
        {children}
        {iconPath && <img src={iconPath} onClick={onIconClick} />}
      </Inner>
    );
  return (
    <Inner backgroundColor={backgroundColor} color={color} outline={variant === 'outlined'} onClick={e => onClick(e)}
           style={style}>
      {children}
      {iconPath && <img src={iconPath} onClick={onIconClick} />}
    </Inner>
  );
};

interface InnerProps {
  backgroundColor: string;
  color?: string;
  outline?: boolean;
}

const Inner = styled.div<InnerProps>`
  /* Auto layout */
  padding: 4px 8px;
  border-radius: 4px;
  color: #616061;
  align-self: flex-start;
  display: inline-flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${props =>
      props &&
      props.outline === true &&
      css`
        border: 1px solid ${props.backgroundColor};
      `};

  ${props =>
      props &&
      props.backgroundColor &&
      props.outline === false &&
      css`
        background-color: ${props.backgroundColor};
      `}

  ${props =>
      props &&
      props.color &&
      css`
        color: ${props.color};
      `}
`;

export default COBadge;
