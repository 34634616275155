import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Label } from '@components/styles.tsx';
import SvgDelete from '@components/ringil3/icons/buttons/Delete.tsx';
import SvgClose from '@components/ringil3/icons/panel/Close.tsx';

export interface DropdownItem {
  id: string;
  number: string;
}

interface EditableDropdownProps {
  options: DropdownItem[];
  selectedOptions?: DropdownItem[];
  placeholder: string;
  label: string;
  onSelect?: (values: DropdownItem[]) => void;
  onDelete?: (value: DropdownItem) => void;
  multiSelect?: boolean;
  mandatory?: boolean;
  note?: React.ReactNode;
}

const EditableDropdown: React.FC<EditableDropdownProps> = ({
                                                             options = [],
                                                             selectedOptions = [],
                                                             onSelect,
                                                             onDelete,
                                                             placeholder,
                                                             label,
                                                             multiSelect = false,
                                                             mandatory = false,
                                                             note,
                                                           }) => {

  const { tr } = useTranslationLgs();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<DropdownItem[]>([]);
  const [filteredValues, setFilteredValues] = useState<DropdownItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const scrollIntoView = (index: number) => {
    const dropdownList = document.querySelector('[data-dropdown-list]');
    const item = document.querySelector(`[data-dropdown-item="${index}"]`);

    if (dropdownList && item) {
      const dropdownRect = dropdownList.getBoundingClientRect();
      const itemRect = item.getBoundingClientRect();

      if (itemRect.top < dropdownRect.top) {
        dropdownList.scrollTop -= dropdownRect.top - itemRect.top;
      } else if (itemRect.bottom > dropdownRect.bottom) {
        dropdownList.scrollTop += itemRect.bottom - dropdownRect.bottom;
      }
    }
  };

  useEffect(() => {
    if (selectedOptions && selectedOptions.length > 0)
      setSelectedValues(selectedOptions);
  }, [selectedOptions]);


  useEffect(() => {
    const searchTerms = searchTerm.split(',');
    const currentSearchTerm = searchTerms[searchTerms.length - 1].trim().toLowerCase();

    const filtered = options.filter(item => {
      if (selectedValues.some(selected => selected.id === item.id)) return false;
      if (!currentSearchTerm) return true;
      return item.number.toLowerCase().startsWith(currentSearchTerm);
    });

    setFilteredValues(filtered);
  }, [searchTerm, options, selectedValues]);

  useEffect(() => {
    if (focusedIndex >= 0) {
      scrollIntoView(focusedIndex);
    }
  }, [focusedIndex]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectValue = (item: DropdownItem) => {
    let newSelectedValues: DropdownItem[];
    setSearchTerm('')

    if (multiSelect) {
      newSelectedValues = selectedValues.some(selected => selected.id === item.id)
        ? selectedValues.filter(selected => selected.id !== item.id)
        : [...selectedValues, item];
    } else {
      newSelectedValues = [item];
      setIsOpen(false);
    }

    setSelectedValues(newSelectedValues);
    if (onSelect) {
      onSelect(newSelectedValues);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleDeleteValue = (e: React.MouseEvent, item: DropdownItem) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete(item);
    }
  };

  const getHighlightedText = (text: string, searchTerm: string) => {
    if (!searchTerm) return <>{text}</>;

    const index = text.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (index === -1) return <>{text}</>;

    return (
      <>
        {text.slice(0, index)}
        <HighlightedText>{text.slice(index, index + searchTerm.length)}</HighlightedText>
        {text.slice(index + searchTerm.length)}
      </>
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchTerm === '' && selectedValues.length > 0) {
      e.preventDefault();
      const lastItem = selectedValues[selectedValues.length - 1];
      handleChipRemove(lastItem);
      return;
    }

    if (!isOpen) {
      if (e.key === 'ArrowDown' || e.key === 'Enter') {
        setIsOpen(true);
        setFocusedIndex(0);
        e.preventDefault();
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex(prev =>
          prev < filteredValues.length - 1 ? prev + 1 : prev,
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < filteredValues.length) {
          handleSelectValue(filteredValues[focusedIndex]);
        } else if (searchTerm.trim()) {
          const newItem: DropdownItem = {
            id: searchTerm.trim(),
            number: searchTerm.trim(),
          };
          handleSelectValue(newItem);
        }
        setSearchTerm('');
        setFocusedIndex(-1);
        break;
      case 'Escape':
        setIsOpen(false);
        setFocusedIndex(-1);
        break;
    }
  };

  const handleChipRemove = (item: DropdownItem) => {
    const newSelected = selectedValues.filter(p => p.id !== item.id);
    setSelectedValues(newSelected);
    if (onSelect) {
      onSelect(newSelected);
    }
    setSearchTerm('');
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Check if the click was inside the dropdown
    if (dropdownRef.current) {
      return;
    }

    if (e.target.value.trim()) {
      const newItem: DropdownItem = {
        id: searchTerm.trim(),
        number: searchTerm.trim(),
      };
      setSearchTerm('');
      handleSelectValue(newItem);
    }
  };

  return (
    <>
      <Container ref={dropdownRef}>
        <Label>
          {label}
          {mandatory && <>*</>}
        </Label>
        <InputContainer isOpen={isOpen}>
          <ChipsContainer>
            {selectedValues.map(item => (
              <Chip key={item.id}>
                <ChipText>{item.number}</ChipText>
                <ChipRemoveButton onClick={() => handleChipRemove(item)}>
                  <SvgClose />
                </ChipRemoveButton>
              </Chip>
            ))}
            <Input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              placeholder={selectedValues.length === 0 ? placeholder : ''}
              onClick={() => setIsOpen(true)}
            />
          </ChipsContainer>
        </InputContainer>

        {isOpen && (
          <DropdownList ref={dropdownRef} data-dropdown-list>
            {filteredValues.length > 0 ? (
              filteredValues.map((item, index) => (
                <DropdownItem
                  key={item.id}
                  data-dropdown-item={index}
                  onClick={() => handleSelectValue(item)}
                  isSelected={selectedValues.some(selected => selected.id === item.id)}
                  isFocused={index === focusedIndex}
                >
                  <ValueText>
                    {getHighlightedText(item.number, searchTerm.split(',')[searchTerm.split(',').length - 1].trim())}
                  </ValueText>
                  {onDelete && (
                    <DeleteButton
                      onClick={(e) => handleDeleteValue(e, item)}
                      title={tr('EditableDropdown.delete', 'Delete')}
                    >
                      <SvgDelete />
                    </DeleteButton>
                  )}
                </DropdownItem>
              ))
            ) : (
              <NoResults>{tr('EditableDropdown.noResults', 'No items found')}</NoResults>
            )}
          </DropdownList>
        )}
      </Container>
      {note}
    </>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.isOpen ? 'var(--content-brand)' : 'var(--content-secondary)'};
  border-radius: 4px;
  background-color: white;
  cursor: text;

  &:hover {
    border-color: var(--content-brand);
  }
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.25rem;
  align-items: center;
  width: 100%;
  min-height: 38px; // Add fixed minimum height
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--base-basetertiary);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin: 0.125rem;
  gap: 0.5rem;
`;

const ChipText = styled.span`
  font-size: 14px;
  color: var(--content-primary);
`;

const ChipRemoveButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    svg path {
      fill: var(--complementary-error);
    }
  }
`;

// Update Input styles
const Input = styled.input`
  flex: 1;
  min-width: 50px;
  padding: 0.5rem;
  font-family: "DM Sans", serif;
  font-size: 15px;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: var(--content-secondary);
  }
`;

const DropdownList = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid var(--base-basesecondary);
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: -1px;
`;

const DropdownItem = styled.div<{ isSelected: boolean; isFocused: boolean }>`
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: ${props =>
      props.isSelected
          ? 'var(--base-basetertiary)'
          : props.isFocused
              ? 'var(--base-basetertiary)'
              : 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: var(--base-basetertiary, #F7F7F7);
  }
`;

const ValueText = styled.span`
  flex: 1;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;

  &:hover {
    svg path {
      fill: var(--complementary-error);
    }
  }
`;

const NoResults = styled.div`
  padding: 0.75rem;
  color: var(--content-secondary);
  text-align: center;
  font-style: italic;
`;

const HighlightedText = styled.span`
  color: var(--content-black);
  font-weight: 600;
`;


export default EditableDropdown;