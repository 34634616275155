import React, { useContext, useEffect, useState } from 'react';
import { CarrierQuoteStateEnum, CustomerQuoteStateEnum, DistributionTypeEnum, EntityTypeEnum, QuoteDetailOutDTO } from '@api/logsteo-api.v2';
import { formatHandlingUnits, formatPrice, formatWeight, isNullOrUndefined } from '@utils/utils';
import { dateFormatDateTime, mapFromAPIDateTime } from '@utils/date';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ReconfirmQuote from '../common/ReconfirmQuote';
import { ApiContext } from '@api/api';
import dayjs from 'dayjs';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import DemandDataProvider, { DemandDetailContext } from '@app/pages/carrier/demand-detail/DemandDetailActions.tsx';
import { DemandDetailView } from '@app/pages/carrier/demand-detail/types.ts';
import DemandStateSpan from '@components/obsolete/StateSpan/DemandStateSpan.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import FixedPriceAcceptReject from '@app/pages/carrier/demand-detail/FixedPriceAcceptReject/FixedPriceAcceptReject.tsx';
import ChangeQuoteComponent from '@app/pages/carrier/demand-detail/ChangeQuote/ChangeQuoteComponent.tsx';
import MyQuoteSummaryComponent from '@app/pages/carrier/demand-detail/MyQuoteSummary/MyQuoteSummaryComponent.tsx';
import CreateSpotComponent from '@app/pages/carrier/demand-detail/CreateSpot/CreateSpotComponent.tsx';
import RouteDescriptionComponent from '@app/pages/carrier/demand-detail/RouteDescription/RouteDescriptionComponent.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';
import { useParams } from 'react-router-dom';

interface ComponentProps {}

const DemandDetailComponentInternal: React.FC<ComponentProps> = () => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { state, acceptFixedPrice, rejectFixedPrice, rejectSpot, makeQuote, createQuote, reconfirm, BackendValidationComponent, loading } =
    useContext(DemandDetailContext);
  const [visibleChat, setVisibleChat] = useState(false);
  const { getInheritCompanySettings, caGetCountMessagesV2 } = useContext(ApiContext);
  const [displayNewMessageAlert, setDisplayNewMessageAlert] = useState(false);

  const navigation = useNavigation();

  const quoteState = state.distributionType === DistributionTypeEnum.SPOT ? state.carrierQuoteState : state.customerQuoteState;
  const createSpot =
    state.distributionType === DistributionTypeEnum.SPOT &&
    (quoteState === CarrierQuoteStateEnum.NEW || (quoteState === CarrierQuoteStateEnum.REJECTED_BY_CARRIER && !state.isAssignedToAnotherCarrier));

  const disabled = state.isExpeditionCanceled || state.isManualExpedition;
  const [locationDisplayPattern, setLocationDisplayPattern] = useState(null);

  useEffect(() => {
    getInheritCompanySettings({ identifier: 'LOCATION_DISPLAY_PATTERN' }, state.companyId.toString(), 'CUSTOMER', data => {
      setLocationDisplayPattern(data.payload);
    });
  }, []);

  const resolveExpeditionStatus = (state: DemandDetailView) => {
    return (
      <>
        <DemandStateSpan demandState={state.customerQuoteState} isManual={state.isManualExpedition} isCanceled={state.isExpeditionCanceled} />
        {!disabled && (
          <>
            {!state.isAssignedToAnotherCarrier &&
              (!isNullOrUndefined(state.reconfirmedAt) || state.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM) && (
                <div className="p-mb-3">
                  <ReconfirmQuote
                    onReconfirm={() => reconfirm(state.companyId, state.applicationId)}
                    reconfirmedAt={state.reconfirmedAt}
                    isReconfirmedByCarrier={state.isReconfirmedByCarrier}
                    disabled={state.isManualExpedition || state.isExpeditionCanceled}
                  />
                </div>
              )}

            <div>
              {t('DemandDetailComponent.validTill', 'Platná do ')}
              <strong>{dateFormatDateTime(mapFromAPIDateTime(state.distributionValidTill))}</strong>
            </div>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    caGetCountMessagesV2(state.companyId, state.expeditionId, EntityTypeEnum.EXPEDITION, data => {
      if (data.countUnread > 0) setDisplayNewMessageAlert(true);
      else setDisplayNewMessageAlert(false);
    });
  }, []);

  const carrierDemandDetailBreadCrumbs = () => {
    return [
      {
        label: t('DemandDetailComponent.demands', 'Demands'),
        url: navigation.createNavigationLink(navigation.urlFunctions.createCarrierDemandsList()),
      },
      {
        label: t('DemandDetailComponent.demandDetail', 'Demand detail'),
        disabled: true,
      },
    ];
  };

  const tooShort = dayjs(state.myQuoteInfo.myQuoteValidTill).diff(dayjs(), 'hours') < 1;

  return (
    <>
      <div className="p-mb-5">
        <BreadCrumbComponent items={carrierDemandDetailBreadCrumbs()} />
      </div>
      {tooShort && (
        <AlertInfo backgroundWrapper="red">
          <p>{tr(`DemandDetailComponent.offerValiditySetTooShort`, `Offer validity set too short`)}</p>
        </AlertInfo>
      )}

      {displayNewMessageAlert && !disabled && (
        <AlertInfo iconName="mail_outline" buttonName={t(`DemandDetailComponent.showMessage`, `Zobrazit zprávu`)} onClick={() => setVisibleChat(true)}>
          <p>{t(`DemandDetailComponent.youHaveANewMessageFromCustomer`, `Máte novou zprávu`)}</p>
        </AlertInfo>
      )}
      {(quoteState === CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER ||
        quoteState === CustomerQuoteStateEnum.ASSIGNED_MANUALLY_BY_CUSTOMER ||
        quoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER ||
        quoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM) &&
        !disabled && (
          <>
            <AlertInfo iconName="check" backgroundWrapper="cyan">
              <p>{t('DemandDetailComponent.youWon', 'Customer assigned you as a carrier to execute this shipment.')}</p>
            </AlertInfo>
            <AlertInfo backgroundWrapper="transparent">
              <div className="p-mr-3">
                <p>{t('DemandDetailComponent.findDetailInShipments', 'You can find detail in all shipments list or here')}</p>
              </div>
              <Button
                label={t('DemandDetailComponent.goToShipmentDetail', 'Go to shipment details')}
                className="p-mt-1 p-mb-1"
                onClick={e => {
                  /*router.push(`/carrier/shipment/${state.companyId}-${state.applicationId}`);*/
                  navigation.navigate(navigation.urlFunctions.createCarrierShipmentDetail(state.companyId, state.applicationId));
                }}
              />
            </AlertInfo>
          </>
        )}
      {quoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !state.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>
            {t(
              `DemandDetailComponent.yourPriceQuoteHasBeenSentCustomerWillNotEitherAcceptOrRejectIt`,
              `Your price quote has been sent. Customer will not either accept or reject it.`,
            )}
          </p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.OFFERED_PRICE && state.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.youOfferIsNotValid`, `Vaše nabídka vypršela. Můžete ji prodloužit, pokud je poptávka stále platná.`)}</p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER && !state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{t(`DemandDetailComponent.customerRejectedYourPrice`, `Customer rejected your price.`)}</p>
            <br />
            <p>{state.myQuoteInfo?.rejectedMessageByCustomer}</p>
          </>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CARRIER && !state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{tr(`DemandDetailComponent.demandWasRejectedByYourCompany`, `Demand was rejected by your company`)}</p>
          </>
        </AlertInfo>
      )}
      {state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.assignedToAnotherCarrier`, `Nabídka byla přiřazena jinému dopravci`)}</p>
        </AlertInfo>
      )}
      <BackendValidationComponent />
      <div className="p-grid">
        <div className="p-col-12 p-lg-5">
          <NavTitle wrapper="WITHOUT-COL_PADDING-BOOTOM-4">
            <h2 className="h2-title p-m-0">
              {t('demandDetail.pageTitle', {
                applicationId: state.applicationId,
              })}{' '}
              <span></span>
            </h2>
            <p>{resolveExpeditionStatus(state)}</p>
          </NavTitle>
          <ListInfo>
            <ListInfoItem title={t(`DemandDetailComponent.distance`, `Distance`)}>
              <p>
                <small>{state.distance ? state.distance : '-'} km</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={t(`DemandDetailComponent.expeditionType`, `Expedition type`)}>
              <p>
                <small>{t(`global.expeditionType${state.expeditionType}`)}</small>
              </p>
            </ListInfoItem>
            {state.truckType && (
              <ListInfoItem title={t(`DemandDetailComponent.truckType`, `Truck type`)}>
                <p>
                  <small>{state.truckType}</small>
                </p>
              </ListInfoItem>
            )}
            <ListInfoItem title={t(`DemandDetailComponent.maximumWeight`, `Maximum weight`)}>
              <p>
                <small>{formatWeight(state.maxWeight)}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={tr(`DemandDetailComponent.totalHandlingUnits`, `Total handling units`)}>
              <p>
                <small>{formatHandlingUnits(state.handlingUnitCount, tr)}</small>
              </p>
            </ListInfoItem>
            <ListInfoItem title={t(`DemandDetailComponent.cargoType`, `Cargo type`)}>
              <p>
                <small>{state.cargoType}</small>
              </p>
            </ListInfoItem>
            {!isNullOrUndefined(state.carrierNote) && (
              <ListInfoItem title={t(`DemandDetailComponent.noteToCarriers`, `Note to carriers`)}>
                <p>
                  <small>“{state.carrierNote}“</small>
                </p>
              </ListInfoItem>
            )}
            {state.distributionType === DistributionTypeEnum.SPOT && (
              <ListInfoItem title={t(`DemandDetailComponent.expectedPrice`, `Expected price`)}>
                <p>
                  <small>{formatPrice(state.price, state.currency)}</small>
                </p>
              </ListInfoItem>
            )}
          </ListInfo>
        </div>

        {!state.isDistributionExpired &&
          !state.isAssignedToAnotherCarrier &&
          state.distributionType === DistributionTypeEnum.FIXED_PRICE &&
          state.customerQuoteState === CustomerQuoteStateEnum.NEW && (
            <FixedPriceAcceptReject
              price={state.price}
              currency={state.currency}
              onAccept={() => {
                acceptFixedPrice(state.companyId, state.applicationId, state.quoteId);
              }}
              onReject={() => rejectFixedPrice(state.companyId, state.applicationId, state.quoteId)}
              enableActions={!disabled}
              loading={loading}
            />
          )}

        {!state.isDistributionExpired &&
          state.distributionType === DistributionTypeEnum.SPOT &&
          (quoteState === CarrierQuoteStateEnum.OFFERED_PRICE ||
            quoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM ||
            quoteState === CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER ||
            quoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER) &&
          !isNullOrUndefined(state.myQuoteInfo.myQuotePrice) && (
            <ChangeQuoteComponent
              onReject={() => rejectSpot(state.companyId, state.applicationId, state.quoteId)}
              enableActions={
                !state.isManualExpedition &&
                !state.isExpeditionCanceled &&
                !state.isDistributionExpired &&
                !state.isAssignedToAnotherCarrier &&
                quoteState !== CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER &&
                quoteState !== CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM
              }
              state={state}
              code={null}
              createQuote={createQuote}
              manipulationUnits={state.handlingUnitCount}
              ladenFactor={state.ladenFactor}
            />
          )}
        {state.distributionType === DistributionTypeEnum.FIXED_PRICE && state.customerQuoteState !== CustomerQuoteStateEnum.NEW && (
          <MyQuoteSummaryComponent
            // @ts-ignore
            distributionType={DistributionTypeEnum[state.distributionType]}
            enableReject={false}
            onReject={() => {}}
            enableChangePrice={false}
            enableChangeTimeslots={false}
            price={state.price}
            currency={state.currency}
            quoteValidTill={mapFromAPIDateTime(state.distributionValidTill)}
            timeslots={state.timeslots}
          />
        )}
        {createSpot && !state.isDistributionExpired && (
          <CreateSpotComponent
            demandValidTill={mapFromAPIDateTime(state.distributionValidTill)}
            onReject={() => rejectSpot(state.companyId, state.applicationId, state.quoteId)}
            onAccept={data => makeQuote(state.companyId, state.applicationId, state.quoteId, data, state.timeslots)}
            enableAction={!disabled}
            code={null}
            manipulationUnits={state.handlingUnitCount}
            ladenFactor={state.ladenFactor}
            allowPartialDelivery={state.allowPartialDelivery}
          />
        )}
      </div>
      <RouteDescriptionComponent enableAction={!state.isExpeditionCanceled && !state.isManualExpedition} locationDisplayPattern={locationDisplayPattern} />
      <CarrierChatComponent
        companyId={state.companyId}
        entityId={state.expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={visibleChat}
        onHide={() => setVisibleChat(false)}
        customerName={state.customer.name}
      />
    </>
  );
};

const DemandDetailComponent = () => {
  const [quoteDetail, setQuoteDetail] = useState<QuoteDetailOutDTO>();

  const { caGetDemandDetail } = useContext(ApiContext);

  const splitIDs = (input: string) => {
    const firstDashIndex = input.indexOf('-');

    if (firstDashIndex === -1) {
      throw new Error('Invalid format: No dash found in input.');
    }

    let customerId, applicationId;

    if (firstDashIndex === 8) {
      // UUID (36 znaků celkem)
      if (/^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/.test(input.slice(0, 36))) {
        customerId = input.slice(0, 36); // Prvních 36 znaků je UUID
        applicationId = input.slice(37); // Zbytek po pomlčce
      } else {
        throw new Error('Invalid format: Expected a valid UUID.');
      }
    } else {
      // MongoID (24 znaků)
      if (/^[a-f\d]{24}$/.test(input.slice(0, 24))) {
        customerId = input.slice(0, 24); // Prvních 24 znaků je MongoID
        applicationId = input.slice(25); // Zbytek po pomlčce
      } else {
        throw new Error('Invalid format: Expected a valid MongoID.');
      }
    }

    return { customerId, applicationId };
  };

  const { id } = useParams();

  useEffect(() => {
    /// http://localhost:3000/carrier/demand/cffea7b3-a6cf-4d25-99fd-4eaf0a95c03d-FV-000001
    /*const regexp = /([\w]+)-(.*)/;
    const result = (router.query['id'] as string).match(regexp);*/

    const { customerId, applicationId } = splitIDs(id);

    caGetDemandDetail(customerId, applicationId, data => {
      setQuoteDetail(data);
    });
  }, []);

  return (
    <>
      {quoteDetail && (
        <DemandDataProvider initialData={quoteDetail}>
          <DemandDetailComponentInternal />
        </DemandDataProvider>
      )}
    </>
  );
};

export default DemandDetailComponent;
