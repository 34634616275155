import useTranslationLgs from '@hooks/i18n/useTranslation';

interface ComponentProps {
  title?: any;
  description?: any;
  stackable: boolean;
  itemNote: string;
}

const LoadInfoList: React.FC<ComponentProps> = ({ title, description, stackable, itemNote }) => {
  const { tr } = useTranslationLgs();
  return (
    <div className="load-info p-mb-3">
      <div className="load-info__icon p-d-flex">
        <span className="icon p-mr-2">
          <img src="/images/box.svg" alt="" />
        </span>
        <span className="load-info__title">{title}</span>
      </div>
      <div className="p-pt-1 load-info__description text-gray5">
        <p>
          <small>{description}</small>
        </p>
        <p className="stackable-value">
          <small>
            {tr(`LoadInfoList.stohovatelnost`, `Stohovatelnost`)}: <span>{stackable ? tr('LoadInfoList.ano', 'Ano') : tr('LoadInfoList.ne', 'Ne')}</span>
          </small>
        </p>
        {itemNote && (
          <p className="message-value">
            <small>
              {tr(`LoadInfoList.cargoMessage`, `Poznámka`)}: <span>{itemNote}</span>
            </small>
          </p>
        )}
      </div>
    </div>
  );
};

export default LoadInfoList;
