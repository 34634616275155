import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation';
import { InputText } from 'primereact/inputtext';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';

interface ComponentProps {
  reservationName: string;
  onChange: (reservationName: string) => void;
  readOnly: boolean;
}

const ReservationNameTag: React.FC<ComponentProps> = ({ reservationName, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();
  const [internalReservationName, setInternalReservationName] = useState(reservationName);
  const [open, setOpen] = useState(false);

  const actionPane = () => {
    return (
      <>
        <InputText value={internalReservationName} onChange={e => setInternalReservationName(e.target.value)} />
      </>
    );
  };

  return (
    <>
      <ButtonTag
        label={tr(`ReservationNameTag.title`, `Reservation name`)}
        value={reservationName}
        icon={<SvgYellowExclamation fill={'var(--content-secondary)'} />}
        actionPane={actionPane()}
        actionIcon={<SvgTagEdit />}
        onSave={() => {
          setOpen(false);
          onChange(internalReservationName);
        }}
        readonly={readOnly}
      />
    </>
  );
};

export default ReservationNameTag;
