import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { ButtonRow, RingilH3, RingilHeading } from '@components/styles';
import useForm, { proxiedPropertiesOf } from '@/hooks/useForm/useForm';
import * as yup from 'yup';
import { AccessTypeEnum, CustomsTypeCodeEnum, EntityTypeEnum, SNConfiguration } from '@api/logsteo-api.v2';
import { mapToAPIDateTime } from '@utils/date';
import dayjs from 'dayjs';
import { ApiContext } from '@api/api';
import SupplierNotificationHeaderForm, { SupplierNotificationHeaderData } from './forms/SupplierNotificationHeaderForm';
import SupplierNotificationOrdersForm, { mapOders, SupplierNotificationOrdersData } from './forms/SupplierNotificationOrdersForm';
import SupplierNotificationCargoInfoForm, { SupplierNotificationCargoInfoData } from './forms/SupplierNotificationCargoInfoForm';
import SupplierNotificationInternalInfoForm, { SupplierNotificationInternalInfoData } from './forms/SupplierNotificationInternalInfoForm';
import { dumpVars, europeanUnion, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import produce from 'immer';
import { InputTextarea } from 'primereact/inputtextarea';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { CUSTOMIZATION_MODE_REQUIRED } from '@utils/exports';
import useNavigation from '@hooks/useNavigation';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { isEntityOwner } from '@app/pages/customer/supplier-notification/utils.ts';
import BreadCrumbComponent, { BreadCrumbItemModel } from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { SaveButton } from '@components/obsolete/Buttons/SaveButton/SaveButton.tsx';
import { AttachmentUploadedOutDTO } from '@api/logsteo-api.v2';

interface ComponentProps {
  initialData: CreateSupplierNotificationFormData;
}

export interface CreateSupplierNotificationFormData
  extends SupplierNotificationHeaderData,
    SupplierNotificationCargoInfoData,
    SupplierNotificationOrdersData,
    SupplierNotificationInternalInfoData {
  attachments: AttachmentDocument[];
  fromShipmentId?: string;

  internalNote?: string;
}

const CreateSupplierNotification: React.FC<ComponentProps> = ({ initialData }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser, hasModule } = useContext(AuthenticatedUserContext);
  const { showToastMessage, redirectWithToastMessage } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation();

  const { createSupplierNotification, getSNFeatureConfiguration } = useContext(ApiContext);

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const [snConfiguration, setSnConfiguration] = useState<SNConfiguration>();

  const save = (values: CreateSupplierNotificationFormData) => {
    setLoading(true);
    createSupplierNotification(
      {
        notifyData: values.notifyData,
        deliveryConditionsIncotermCode: values.deliveryConditionsIncotermCode,
        customsTypeCode: values.customsTypeCode,
        orders: values.orders.map(mapOders),
        containerInfo: values.containerInfo,
        companyId: values.companyId,
        shipmentId: values.shipmentId,
        cargoType: values.cargoType,
        attachments: values.attachments.map(t => {
          return {
            attachmentId: t.attachmentId,
            documentType: t.documentType.documentType,
            accessType: t.accessType,
          };
        }),
        customerLocationId: values.customerLocationId,
        internalInvoiceNumber: values.internalInvoiceNumber,
        invoiceDueDate: mapToAPIDateTime(values.invoiceDueDate ? dayjs(values.invoiceDueDate) : null),
        shipmentStateCode: values.shipmentStateCode,
        supplierId: values.supplierId,
        supplierLocationId: values.supplierLocationId,
        referralCode: values.referralCode,
        specialRequirementIds: values.specialRequirementIds,
        transportationType: values.transportationType,
        loadingGlobalTimeslots: values.supplierDeliverySlots,
        unloadingGlobalTimeslots: values.customerDeliverySlots,
        fromShipmentId: values.fromShipmentId,
        internalNote: values.internalNote,
        note: values.note,
      },
      () => {
        if (isEntityOwner(loggedUser, values.companyId)) {
          /*router.push('/supplier-notifications');*/
          navigation.navigate(navigation.urlFunctions.createSupplierNotificationList());
        } else {
          redirectWithToastMessage(
            '/supplier-notifications',
            tr(`CreateSupplierNotification.snCreated`, `SN created`),
            tr('CreateSupplierNotification.snCreatedDetailWithCompanyName', 'SN created and company {{companyName}} will contact you soon.', {
              companyName: loggedUser.modules.find(t => t.owner?.companyId === values.companyId).owner.companyName,
            }),
            'success',
          );
        }
      },
      null,
      {
        onValidationFailed: d => {
          setLoading(false);
          setBeValidationErrors(d);
        },
        onError: d => {
          setLoading(false);
        },
      },
    );
  };

  const itemValidation = yup.object().shape({
    cargoItemType: yup.object().required(),
    quantity: yup.number().required(),
    length: yup.number().required(),
    width: yup.number().required(),
    height: yup.number().required(),
    weight: yup.number().required(),
  });

  const orderValidation = yup.object().shape({
    name: yup.string().required(),
    cargoAmount: yup.mixed().test('required', (value, b) => {
      if (snConfiguration?.amountOfOrder === CUSTOMIZATION_MODE_REQUIRED) {
        return value != null;
      }
      return true;
    }),
    items: yup.array().of(itemValidation),
    currencyOrderValue: yup.object().test('required', (value, b) => {
      if (snConfiguration?.orderValue === CUSTOMIZATION_MODE_REQUIRED) {
        const res = parseFloat(value.amount);
        return res != null && res >= 0;
      }
      return true;
    }),
  });

  const validationSchema = yup.object().shape({
    companyId: yup.string().required(),
    orders: yup.array().of(orderValidation),
    containerInfo: yup.object().when({
      is: null,
      then: yup.object().nullable(),
      otherwise: yup.object().shape({
        containerId: yup.string().required(),
        containerInfoCode: yup.string().required(),
      }),
    }),
    customsTypeCode: yup.mixed().test('required', (value, b) => {
      if (snConfiguration?.customsMode === CUSTOMIZATION_MODE_REQUIRED) {
        return value != null;
      }
      return true;
    }),
    shipmentId: yup.string().when({
      is: null,
      then: yup.string().nullable(),
      otherwise: yup.string().required(),
    }),
    supplierId: yup.string().required(),
    customerLocation: yup.mixed().required(),
    supplierLocation: yup.mixed().required(),
    supplierDeliverySlots: timeslotValidationSchema(false),
    customerDeliverySlots: timeslotValidationSchema(false),
  });

  const childrenForms = useRef([]);
  const form = useForm(
    validationSchema,
    initialData,
    values => save(values),
    false,
    false,
    () => runChildrenValidation(),
  );

  useEffect(() => {
    if (isNotNullOrUndefined(form.values.companyId)) {
      getSNFeatureConfiguration(form.values.companyId, 'create', d => {
        setSnConfiguration(d);
      });
    }
  }, [form.values.companyId]);

  const runChildrenValidation = () => {
    childrenForms.current.forEach(t => t?.validate());
  };

  const breadcrumbs = (): BreadCrumbItemModel[] => {
    return [
      {
        label: form.values.shipmentId
          ? tr(`CreateSupplierNotification.followingSupplierNotification`, `Following supplier notification`)
          : tr(`CreateSupplierNotification.newSupplierNotification`, `New supplier notification`),
        url: /*"/supplier-notification/create-supplier-notification"*/ navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationCreate()),
        disabled: true,
      },
    ];
  };

  const names = proxiedPropertiesOf<CreateSupplierNotificationFormData>();
  const changeVisibility = (attachmentId: string, internal: boolean) => {
    form.setFieldValue(
      names.attachments,
      produce(form.values.attachments, draft => {
        const attachment = draft.find(t => t.attachmentId === attachmentId);
        attachment.accessType = internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL;
      }),
    );
  };

  useEffect(() => {
    if (isNotNullOrUndefined(form.values.supplierLocation)) {
      const isEU = europeanUnion.includes(form.values.supplierLocation.countryCode3);
      if (!isEU && form.values.customsTypeCode == null && snConfiguration.customsMode === CUSTOMIZATION_MODE_REQUIRED) {
        form.setFieldValue(names.customsTypeCode, CustomsTypeCodeEnum.UNDER_CUSTOMS);
      }
    }
  }, [form.values.supplierLocation]);

  const disabled = loggedUser?.securityRoles.indexOf('ROLE_SN_RW') == -1;

  // Convert form attachments (AttachmentDocument) to AttachmentUploadedOutDTO format
  const [formattedAttachments, setFormattedAttachments] = useState<AttachmentUploadedOutDTO[]>([]);
  
  useEffect(() => {
    if (form.values.attachments && form.values.attachments.length > 0) {
      // Convert AttachmentDocument[] to AttachmentUploadedOutDTO[]
      const formatted = form.values.attachments.map(attachment => {
        return {
          attachmentId: attachment.attachmentId,
          fileName: attachment.fileName || '',
          size: 0, // Default value 
          contentType: '',  // Default value
          userId: loggedUser?.id || '',
          userName: `${loggedUser?.firstName || ''} ${loggedUser?.lastName || ''}`,
          companyId: loggedUser?.companyId || '',
          uploadedAt: new Date().toISOString(),
          companyName: loggedUser?.company || '',
          accessType: attachment.accessType,
          accessTypeName: attachment.accessType === AccessTypeEnum.INTERNAL ? 'Internal' : 'All',
          documentType: attachment.documentType?.documentType,
          documentTypeName: attachment.documentType?.name || '',
          canBeDeleted: true,
          userNote: attachment.userNote || '',
          userDate: attachment.userDate,
        } as AttachmentUploadedOutDTO;
      });
      setFormattedAttachments(formatted);
    } else {
      setFormattedAttachments([]);
    }
  }, [form.values.attachments]);

  const { AttachmentComponent, load: reloadAttachments } = useCreateAttachmentComponent({
    enableCopyDocument: isEntityOwner(loggedUser, form.values.companyId),
    initialAttachments: formattedAttachments,
    doNotShare: disabled,
    disabled: disabled,
  });

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <BreadCrumbComponent items={breadcrumbs()} />
      <BackendValidationComponent />
      {/*
        // @ts-ignore*/}
      <Card>
        {isNullOrUndefined(form.values.shipmentId) && (
          <RingilHeading>{tr(`CreateSupplierNotification.newSupplierNotification`, `New supplier notification`)}</RingilHeading>
        )}
        {form.values.shipmentId && <RingilHeading>{tr(`CreateSupplierNotification.followingSupplierNotification`, `Following supplier notification`)}</RingilHeading>}
        <RingilH3>{tr(`CreateSupplierNotification.transportationType`, `Transportation type`)}</RingilH3>
        <SupplierNotificationHeaderForm
          // @ts-ignore
          form={form}
          configuration={snConfiguration}
          showTimeslots={true}
          disabled={disabled}
        />
        <hr />
        <SupplierNotificationCargoInfoForm
          // @ts-ignore
          form={form}
          companyId={form.values.companyId}
          inSidebar={false}
          transportationTypeCode={form.values.transportationType}
          configuration={snConfiguration}
          disabled={disabled}
        />
        {loggedUser && loggedUser.companyId === form.values.companyId && (
          <>
            <hr />
            <SupplierNotificationInternalInfoForm
              // @ts-ignore
              form={form}
              companyId={form.values.companyId}
              inSidebar={false}
              disabled={disabled}
            />
            <HorizontalFieldValue
              label={tr('CreateSupplierNotification.internalNote', 'Internal note')}
              value={
                <InputTextarea
                  value={form.values.internalNote}
                  onChange={e => form.setFieldValue(names.internalNote, e.target.value)}
                  rows={6}
                  style={{ width: '100%' }}
                  disabled={disabled}
                />
              }
            />
          </>
        )}
        <hr />
        <SupplierNotificationOrdersForm
          // @ts-ignore
          form={form}
          companyId={form.values.companyId}
          inSidebar={false}
          supplierId={form.values.supplierId}
          configuration={snConfiguration}
          disabled={disabled}
        />
        <AttachmentComponent
          onAddAttachment={attachments => {
            form.setFieldValue('attachments', [...form.values.attachments, ...attachments]);
          }}
          onDeletedAttachment={attachmentId => {
            form.setFieldValue(
              'attachments',
              form.values.attachments.filter(t => t.attachmentId !== attachmentId),
            );
          }}
          entityType={EntityTypeEnum.SUPPLIER_NOTIFICATION}
          entityId={null}
          onChangeVisibility={changeVisibility}
          disabled={disabled}
        />
        <ButtonRow>
          <SaveButton onClick={e => form.validateAndSend()} loading={loading} disabled={disabled} />
        </ButtonRow>
      </Card>
    </>
  );
};

export default CreateSupplierNotification;
