import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { CarPlateDTO } from '@api/logsteo-api.v2.tsx';

type CarPlateHookReturn = [CarPlateDTO[], (plateId: string) => Promise<void>];

const useCarPlate = (): CarPlateHookReturn => {
  const [carPlates, setCarPlates] = useState<CarPlateDTO[]>([]);
  const { listCarPlates, deleteCarPlate } = useContext(ApiContext);

  useEffect(() => {
    const fetchCarPlates = async () => {
      try {
        listCarPlates((data: CarPlateDTO[]) => {
          setCarPlates(data);
        });
      } catch (error) {
        setCarPlates([]);
      }
    };

    fetchCarPlates();
  }, []);


  const handleDeletePlate = async (plateId: string): Promise<void> => {
    try {
      deleteCarPlate(plateId, () => {
        setCarPlates(prev => prev.filter(p => p.id !== plateId));
      });
    } catch (error) {
      console.error('Failed to delete car plate:', error);
    }
  };

  return [carPlates, handleDeletePlate];
};

export default useCarPlate;