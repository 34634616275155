import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import * as yup from 'yup';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import { dumpVars } from '@utils/utils.tsx';
import { createOrdersComponentValidationSchema, fullRequiredConfig, OrdersConfig } from '@components/framework/orders/validation.ts';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { Note } from '@components/styles.tsx';

interface ComponentProps {}

interface Form {
  orders: Order[];
}

const conf: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'never', required: true },
  cargoDimensions: {
    visibility: 'optional',
    required: false,
    items: {
      width: { required: true },
      height: { required: true },
      length: { required: true },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'optional',
    required: false,
    items: { orderAmount: { required: false }, orderDescription: { required: false }, orderValue: { required: false } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: false,
  },
};

const OrderComponentTestPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { showToastMessage } = useContext(ToastContext);

  const configForm = useForm<OrdersConfig>(yup.object().shape({}), fullRequiredConfig, d => {}, false, false);
  const form = useForm<Form>(
    createOrdersComponentValidationSchema(configForm.values, true, tr),
    { orders: [emptyOrder(configForm.values.cargo.required)] },
    d => {
      showToastMessage('Data was sent successfully', 'Data sent', 'success');
    },
    false,
    false,
    null,
    'scroll',
  );

  return (
    <>
      <div className={'flex flex-row p-8 gap-10 justify-between'}>
        <div className={'bg-content-quaternary w-[530px] p-8 gap-8 flex flex-col'}>
          <OrdersComponent
            config={configForm.values}
            onChange={v =>
              form.useProduce(d => {
                d.orders = v;
              })
            }
            orders={form.values.orders}
            validationErrors={form.validationErrors}
          />
          <RingilButton mode="filled-big" label="Submit" onClick={() => form.validateAndSend()} />
        </div>
        <div className={'w-[500px] overflow-clip'}>{dumpVars(form.validationErrors)}</div>

        <div className={'w-[500px]'}>
          {dumpVars(form.values)}
          {dumpVars(configForm.values)}
        </div>

        <div className={'w-[500px] [&>*:nth-child(odd)]:bg-content-quaternary'}>
          {/* <div className={'p-4'}>
            <Field label={'Order'}>
              <InputSelect
                model={[
                  { label: 'Always', code: 'always' },
                  { label: 'Never', code: 'never' },
                  { label: 'Optional', code: 'optional' },
                ]}
                selectedItemCode={configForm.values.orderName.visibility}
                onSelect={selectedItemCode => {
                  configForm.setFieldValue('orderName.visibility', selectedItemCode);
                }}
              />
            </Field>
          </div>*/}

          <div className={'p-4'}>
            <COHeadline variant={'h3'} title={'OrderName'} />
            <Note>Je vzdy povinna, proto se nijak nenastavuje.</Note>
          </div>

          <div className={'p-4'}>
            <div className={'flex flex-col gap-2 mb-2'}>
              <COHeadline variant={'h3'} title={'Cargo'} />
              <Note>Zde se nastavuje, zda policko Manipulacni jednotka a pocet je povinna (min 1). Je tu jen rezim Always a Never.</Note>
            </div>
            <Field label={'Cargo'}>
              <InputSelect
                model={[
                  { label: 'Always', code: 'always' },
                  { label: 'Never', code: 'never' },
                  { label: 'Optional', code: 'optional' },
                ]}
                selectedItemCode={configForm.values.cargo.visibility}
                onSelect={selectedItemCode => {
                  configForm.setFieldValue('cargo.visibility', selectedItemCode);
                }}
              />
            </Field>
            <Field label={'Required item'}>
              <InputYesNoPlain value={configForm.values.cargo.required} onChange={value => configForm.setFieldValue('cargo.required', value)} />
            </Field>
          </div>

          {configForm.values.cargo.visibility !== 'never' && (
            <div className={'p-4'}>
              <Field label={'CargoDimension'}>
                <InputSelect
                  model={[
                    { label: 'Always', code: 'always' },
                    { label: 'Never', code: 'never' },
                    { label: 'Optional', code: 'optional' },
                  ]}
                  selectedItemCode={configForm.values.cargoDimensions.visibility}
                  onSelect={selectedItemCode => {
                    configForm.setFieldValue('cargoDimensions.visibility', selectedItemCode);
                  }}
                />
              </Field>
              <Field label={'Required width'}>
                <InputYesNoPlain
                  value={configForm.values.cargoDimensions.items.width.required}
                  onChange={value => configForm.setFieldValue('cargoDimensions.items.width.required', value)}
                />
              </Field>
              <Field label={'Required height'}>
                <InputYesNoPlain
                  value={configForm.values.cargoDimensions.items.height.required}
                  onChange={value => configForm.setFieldValue('cargoDimensions.items.height.required', value)}
                />
              </Field>
              <Field label={'Required length'}>
                <InputYesNoPlain
                  value={configForm.values.cargoDimensions.items.length.required}
                  onChange={value => configForm.setFieldValue('cargoDimensions.items.length.required', value)}
                />
              </Field>
              <Field label={'Required weight'}>
                <InputYesNoPlain
                  value={configForm.values.cargoDimensions.items.weight.required}
                  onChange={value => configForm.setFieldValue('cargoDimensions.items.weight.required', value)}
                />
              </Field>
            </div>
          )}

          <div className={'p-4'}>
            <div className={'flex flex-col gap-2 mb-2'}>
              <COHeadline variant={'h3'} title={'Cargo detail'} />
              <Note>Nasvauje se, zda je povinne zadat pocet v objednavce a popis. Dale se nastavuje, zda se musi zadat hodnota objednavky</Note>
            </div>
            <Field label={'Cargo detail'}>
              <InputSelect
                model={[
                  { label: 'Always', code: 'always' },
                  { label: 'Never', code: 'never' },
                  { label: 'Optional', code: 'optional' },
                ]}
                selectedItemCode={configForm.values.cargoDetails.visibility}
                onSelect={selectedItemCode => {
                  configForm.setFieldValue('cargoDetails.visibility', selectedItemCode);
                }}
              />
            </Field>
            <Field label={'orderAmount required'}>
              <InputYesNoPlain
                value={configForm.values.cargoDetails.items.orderAmount.required}
                onChange={value => configForm.setFieldValue('cargoDetails.items.orderAmount.required', value)}
              />
            </Field>
            <Field label={'orderDescription required'}>
              <InputYesNoPlain
                value={configForm.values.cargoDetails.items.orderDescription.required}
                onChange={value => configForm.setFieldValue('cargoDetails.items.orderDescription.required', value)}
              />
            </Field>
            <Field label={'orderValue required'}>
              <InputYesNoPlain
                value={configForm.values.cargoDetails.items.orderValue.required}
                onChange={value => configForm.setFieldValue('cargoDetails.items.orderValue.required', value)}
              />
            </Field>
          </div>
          <div className={'p-4'}>
            <div className={'flex flex-col gap-2 mb-2'}>
              <COHeadline variant={'h3'} title={'Show computed weight'} />
              <Note>Zobrazuje readonly pole, ktere scita hmotnosti objednavky</Note>
            </div>
            <Field label={'render total weight'}>
              <InputYesNoPlain value={configForm.values.totalWeight.render} onChange={value => configForm.setFieldValue('totalWeight.render', value)} />
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(OrderComponentTestPage);
