import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { CarrierGroupInDTO, PriceRequest } from '@api/logsteo-api.v2.tsx';
import dayjs from 'dayjs';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import { SelectCarriers } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { SelectCarrier } from '@components/ringil3/Input/InputCarriers/SelectCarrier.tsx';

interface ComponentProps {
  distribution?: CreateDistributionOrAssignData;
  onChange: (distribution: CreateDistributionOrAssignData) => void;
}

export type DistributionType = 'SPOT' | 'FIXED' | 'ASSIGN';

const CreateDistributionOrAssign: React.FC<ComponentProps> = ({ distribution, onChange }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const initDistribution = (): CreateDistributionOrAssignData => {
    return {
      distributionType: 'FIXED',
      selectedItems: [],
      responseDeadlineLocalTime: dayjs().add(14, 'day').format('YYYY-MM-DDTHH:mm:ss'),
      responseDeadlineTimezone: dayjs.tz.guess(),
      price: { currency: loggedUser?.defaultCurrencyIsoCode, price: 0 },
      notifyAssignedCarrier: false,
      assigningCarrierId: null,
    };
  };

  const model = [
    { code: 'SPOT', label: tr('CreateDistributionOrAssign.spot', 'Spot') },
    { code: 'FIXED', label: tr('CreateDistributionOrAssign.fixed', 'Fixed') },
    { code: 'ASSIGN', label: tr('CreateDistributionOrAssign.assign', 'Assign') },
  ];

  return (
    <>
      {isNullOrUndefined(distribution) && (
        <AddButton
          title={tr('CreateDistributionOrAssign.poptavkaNeboPrirazeniDopravce', 'Poptávka nebo přiřazení dopravce')}
          onClick={() => onChange(initDistribution())}
          type={'ROUNDED'}
          fullWidth
        />
      )}
      {isNotNullOrUndefined(distribution) && (
        <Card>
          <div className={'flex flex-row items-center justify-between'}>
            <COHeadline variant={'h3'} title={tr('CreateDistributionOrAssign.dopravce', 'Dopravce')} />
            <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => onChange(null)} />
          </div>
          <div className={'flex flex-col w-[500px] gap-2'}>
            <Field label={tr('CreateDistributionOrAssign.typPoptavky', 'Typ poptávky')}>
              <InputSelect
                model={model}
                onSelect={v => {
                  onChange({ ...distribution, distributionType: v as DistributionType });
                }}
                selectedItemCode={distribution?.distributionType}
              />
            </Field>
            <Field label={tr('CreateDistributionOrAssign.cena', 'Cena')} required>
              <InputPriceWithCurrency
                price={distribution.price?.price}
                currency={distribution.price?.currency}
                changePrice={v => onChange({ ...distribution, price: { ...distribution.price, price: v } })}
                changeCurrency={v => {}}
              />
            </Field>
            {distribution.distributionType !== 'ASSIGN' && (
              <Field label={tr('CreateDistributionOrAssign.dopravce', 'Dopravce')}>
                <SelectCarriers
                  value={distribution.selectedItems}
                  onlyCarriers={false}
                  onSelected={value =>
                    onChange({
                      ...distribution,
                      selectedItems: value,
                    })
                  }
                  placeholder={''}
                  isDisabled={false}
                />
              </Field>
            )}

            {distribution.distributionType == 'ASSIGN' && (
              <Field label={tr('CreateDistributionOrAssign.dopravce', 'Dopravce')}>
                <SelectCarrier
                  value={distribution.assigningCarrierId}
                  onSelected={value =>
                    onChange({
                      ...distribution,
                      assigningCarrierId: value,
                    })
                  }
                  placeholder={''}
                  isDisabled={false}
                />
              </Field>
            )}

            {distribution.distributionType !== 'ASSIGN' && (
              <Field label={tr('CreateDistributionOrAssign.doKdyPotrebujeteNabidku', 'Do kdy potřebujete nabídku')}>
                <InputDateAndTime
                  value={distribution.responseDeadlineLocalTime}
                  onChange={v => {
                    //form.setFieldValue(form.names.responseDeadline, v);
                    onChange({ ...distribution, responseDeadlineLocalTime: v });
                  }}
                  timeZone={loggedUser.companyTimeZone}
                  onChangeTimeZone={v => {}}
                  fullWidth
                />
              </Field>
            )}
            {distribution.distributionType === 'ASSIGN' && (
              <div className={'flex flex-row  items-start mt-2'}>
                <InputCheckbox
                  label={tr('CreateDistributionOrAssign.informovatOPrirazeniDopravce', 'Informovat o přiřazení dopravce')}
                  note={'Dopravci pošleme e-mail s podrobnostmi'}
                  value={distribution.notifyAssignedCarrier}
                  onChange={(v, value) => onChange({ ...distribution, notifyAssignedCarrier: value })}
                />
              </div>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export interface CreateDistributionOrAssignData {
  distributionType: DistributionType;
  price?: PriceRequest;
  selectedItems: CarrierGroupInDTO[];
  assigningCarrierId: string;
  responseDeadlineLocalTime?: string;
  responseDeadlineTimezone?: string;
  notifyAssignedCarrier?: boolean;
}

/*const mapToDistributionInDTO = (distribution: Distribution): DistributionInDTO => {
  return {
    price: distribution.price,
    validTill: localDateTimeToApi(distribution.responseDeadline, distribution.responseDeadlineTimezone),
    selectAll: false,
    distributionType: distribution.distributionType,
    currency: distribution.currency,
    selectedItems: distribution.selectedItems,
    isPublish: distribution.isPublished,
  };
};*/

export default CreateDistributionOrAssign;
