import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyLocationRampWithOpeningHoursOutDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: string[];
  onChange: (value: string[]) => void;
  style?: any;
  locationIds: string[];
  showTemporaryClosed?: boolean;
}

const InputCompanyRampsForLocations: React.FC<ComponentProps> = ({ value, onChange, style, locationIds, showTemporaryClosed = false }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { getRampsForMultipleCompanyLocations } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationRampWithOpeningHoursOutDTO[]>([]);

  useEffect(() => {
    getRampsForMultipleCompanyLocations(
      {
        locationIds,
        showTemporaryClosed: true,
      },
      d => setData(d),
    );
    /*onChange([]);*/
  }, [locationIds]);

  const removeInvalidValues = (value: string[]) => {
    const validValues = data.map(t => t.rampId);
    const newValues = value.filter(t => validValues.indexOf(t) !== -1);
    return newValues;
  };

  return (
    <MultiSelect
      style={style}
      options={data}
      placeholder={tr(`InputCompanyRamps.allRamps`, `All ramps`)}
      optionLabel={'name'}
      optionValue={'rampId'}
      onChange={e => onChange(e.value)}
      value={removeInvalidValues(value)}
      filter={true}
    />
  );
};

export default InputCompanyRampsForLocations;
