import * as yup from 'yup';

export const createOrdersComponentValidationSchema = (config: OrdersConfig, wrap: boolean, tr: any) => {
  const visibilitySchema = (visibility: 'always' | 'optional' | 'never', required: boolean, schema: any) => {
    if (visibility === 'never') {
      return yup.mixed().nullable(true);
    } else if (visibility === 'optional') {
      return required === true ? schema : schema.nullable(true).notRequired();
    }
    return required === true ? schema : schema.nullable(true).notRequired();
  };

  const visibilitySchemaWithNotDef = (visibility: 'always' | 'optional' | 'never', required: boolean, schema: any, notRequiredSchema: any) => {
    if (visibility === 'never') {
      return yup.mixed().nullable(true); // Pole nebude validováno, protože není zobrazeno
    }
    return required === true ? schema : notRequiredSchema; // Explicitní kontrola na true
  };

  const orderSchemaNoWrapped = yup.object().shape({
    orderName: visibilitySchema(
      config.orderName.visibility,
      config.orderName.required,
      yup.string()
        .required('Order name is required')
        .min(1, 'Order name must be at least 1 character')
        .test('not-empty', 'Order name cannot be empty', value => value && value.trim().length > 0),
    ),
    items: yup
      .array()
      .min(config.cargo.visibility === 'always' ? 1 : 0, tr('CreateOrdersComponentValidationSchema.oneItemMinimum', 'Zadejte minimálně jednu položku'))
      .of(
        yup.object().shape({
          amount: visibilitySchema(config.cargo.visibility, config.cargo.required, yup.string()),
          cargoTemplateCode: visibilitySchema(config.cargo.visibility, config.cargo.required, yup.string()),
          width: visibilitySchema(
            config.cargoDimensions.visibility, 
            config.cargo.visibility !== 'never' && config.cargoDimensions.items.width.required,
            yup.number()
              .required('Width is required')
              .moreThan(0, 'Width must be greater than 0')
              .typeError('Width must be a number')
          ),
          height: visibilitySchema(
            config.cargoDimensions.visibility,
            config.cargo.visibility !== 'never' && config.cargoDimensions.items.height.required,
            yup.number()
              .required('Height is required')
              .moreThan(0, 'Height must be greater than 0')
              .typeError('Height must be a number')
          ),
          length: visibilitySchema(
            config.cargoDimensions.visibility,
            config.cargo.visibility !== 'never' && config.cargoDimensions.items.length.required,
            yup.number()
              .required('Length is required')
              .moreThan(0, 'Length must be greater than 0')
              .typeError('Length must be a number')
          ),
          weight: visibilitySchema(
            config.cargoDimensions.visibility,
            config.cargo.visibility !== 'never' && config.cargoDimensions.items.weight.required,
            yup.number()
              .required('Weight is required')
              .moreThan(0, 'Weight must be greater than 0')
              .typeError('Weight must be a number')
          ),
        }),
      ),
    orderAmount: visibilitySchema(config.cargoDetails.visibility, config.cargoDetails.items.orderAmount.required, yup.number().nullable()),
    orderDescription: visibilitySchema(config.cargoDetails.visibility, config.cargoDetails.items.orderDescription.required, yup.string()),
    orderValue: visibilitySchemaWithNotDef(
      config.cargoDetails.visibility,
      config.cargoDetails.items.orderValue.required,
      yup.object().shape({
        currency: yup.string().required(),
        price: yup.string().required(),
      }),
      yup.mixed().nullable().optional()
    ),
  });

  if (wrap) {
    return yup.object().shape({
      orders: yup.array().of(orderSchemaNoWrapped),
    });
  } else {
    return orderSchemaNoWrapped;
  }
};

export const expeditionConfig: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'always', required: true },
  cargoDimensions: {
    visibility: 'always',
    required: true,
    items: {
      width: { required: true },
      height: { required: true },
      length: { required: true },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'optional',
    required: false,
    items: { orderAmount: { required: false }, orderDescription: { required: false }, orderValue: { required: false } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: false,
  },
};

export const fullRequiredConfig: OrdersConfig = {
  orderName: { visibility: 'always', required: true },
  cargo: { visibility: 'always', required: true },
  cargoDimensions: {
    visibility: 'always',
    required: true,
    items: {
      width: { required: true },
      height: { required: true },
      length: { required: true },
      weight: { required: true },
    },
  },
  cargoDetails: {
    visibility: 'optional',
    required: false,
    items: { orderAmount: { required: false }, orderDescription: { required: false }, orderValue: { required: false } },
  },
  totalWeight: {
    render: true,
  },
  unloadingLocation: {
    render: true,
  },
};

export interface OrdersConfig {
  orderName: { visibility: Visibility; required: boolean };
  // jedna se o manipulacni jednotku a pocet
  cargo: { visibility: Visibility; required: boolean };
  // jedna se o sirku, vysku, delku a vahu
  cargoDimensions: {
    visibility: Visibility;
    required: boolean;
    items: {
      width: { required: boolean };
      height: { required: boolean };
      length: { required: boolean };
      weight: { required: boolean };
    };
  };
  cargoDetails: {
    visibility: Visibility;
    required: boolean;
    items: {
      orderAmount: { required: boolean };
      orderDescription: { required: boolean };
      orderValue: { required: boolean };
    };
  };
  totalWeight: {
    render: boolean;
  };
  unloadingLocation: {
    render: boolean;
  };
}

export type Visibility = 'always' | 'never' | 'optional';
