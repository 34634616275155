import dayjs, { Dayjs } from 'dayjs';
// @ts-ignore
import {
  CustomerQuoteStateEnum,
  CustomsDetailOutDTO,
  GlobalTimeslotsDTO,
  PriceRequest,
  State,
  TimeSlotResponse,
} from '@api/logsteo-api.v2.tsx';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date.tsx';
import React, { useState } from 'react';
import { WithGap } from '@components/styles.tsx';
import { ValidationError } from 'yup';

export const dumpVars = (object: any) => {
  return (
    <>
      <pre>{JSON.stringify(object, null, 2)}</pre>
    </>
  );
};

export const isLastItem = (itemIndex: number, collection: any[]) => itemIndex == collection.length - 1;

export const formatHandlingUnits = (pieces: number, tr: any) => {
  if (isNullOrUndefined(pieces)) return `- ${tr('QtyUnit.KS')}`;
  return `${pieces.toLocaleString('cs', { minimumFractionDigits: 0 })} ${tr('QtyUnit.KS')}`;
};

export const isNullOrUndefined = (value: any) => value === undefined || value === null;
export const isNotNullOrUndefined = (value: any) => !isNullOrUndefined(value);
export const isNotBlank = (value: any) => value !== undefined && value !== null && `${value}`.length > 0;
export const isBlank = (value: any) => !isNotBlank(value);
export const isValidString = (str: string | null | undefined): boolean => !!str && str.trim().length > 0;

export const formatTons = (amount: number) => `${amount?.toLocaleString('cs', { minimumFractionDigits: 2 }) || ' - '} kg`;

export const formatWeight = (weight: number) => {
  if (isNullOrUndefined(weight)) return `- kg`;
  return `${weight.toLocaleString('cs', { minimumFractionDigits: 2 })} kg`;
};

export const formatPieces = (pieces: number, tr: any) => {
  if (isNullOrUndefined(pieces)) return `- ${tr('QtyUnit.KS')}`;
  return `${pieces.toLocaleString('cs', { minimumFractionDigits: 2 })} ${tr('QtyUnit.KS')}`;
};

export const formatDistance = (distance: number) => {
  return `${distance.toLocaleString()} km`;
};

export const formatPrice = (price: number, currency: string) => {
  return `${price?.toLocaleString('cs', { minimumFractionDigits: 2 }) || '-'} ${currency}`;
};

export const formatManipulationUnit = (count: number, unit: string) => {
  return `${count.toLocaleString()} ${unit}`;
};

export const formatPriceRequest = (priceRequest: PriceRequest) => {
  if (isNullOrUndefined(priceRequest) || isNullOrUndefined(priceRequest.price) || isNullOrUndefined(priceRequest.currency)) return '-';

  return `${priceRequest.price.toLocaleString('cs', { minimumFractionDigits: 2 }) || '-'} ${priceRequest.currency}`;
};

export const convertToStrignArray = (value: any) => {
  if (isNullOrUndefined(value)) return [];
  if (Array.isArray(value)) return value.map(t => t);
  return [value];
};

/**
 * This function renders firstDate on the first location and the last date on the last location. The problem is that
 * if customer doesn't specify time (tillInUTC) and is set isNotSpecifiedTime we have to use sinceInUTC
 * @param location
 */
export const formatInterval = (isWholeDay: boolean, since: string, till: string, t: any): string => {
  return isWholeDay ? `${t('TimeListItem.isWholeDay')}` : `${getTime(mapFromAPIDateTime(since))} - ${getTime(mapFromAPIDateTime(till))}`;
};

export const formatDateInterval = (since: string, till: string): string => {
  return `${mapFromAPIDateTime(since).format('DD.MM.YY')} - ${mapFromAPIDateTime(till).format('DD.MM.YY')}`;
};

export const isObject = (object: any) => {
  return object != null && typeof object === 'object';
};

interface LocationFormat {
  streetNr: string;
  city: string;
  postalCode: string;
  country: string;
}

export const formatLocation = (location: LocationFormat) => `${location.streetNr}, ${location.postalCode}, ${location.city}, ${location.country}`;

// @ts-ignore
export const validateHours = (value, context) => {
  return validateHourString(value);
};

export const getTime = (date: Dayjs): string => {
  if (isNullOrUndefined(date)) return null;
  return date.format('HH:mm');
};

export const first = <T,>(collection: T[] | T): T | null => {
  if (Array.isArray(collection)) {
    // Pokud je to pole, ověř, zda má alespoň jeden prvek
    if (collection.length > 0) return collection[0];
    return null;
  } else {
    // Pokud to není pole, vrať hodnotu přímo
    return collection;
  }
};

export const deepEqual = (object1: any, object2: any) => {
  if (object1 === object2) return true;
  if (isNullOrUndefined(object1)) return false;
  if (isNullOrUndefined(object2)) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

export const parseNumberOrUndefined = (value: string): number => {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) return undefined;
  return parsed;
};

export const mapFromDateToDayjs = (day: Date) => (isNullOrUndefined(day) ? null : dayjs(day));

export const formatCarrierLocation = (zipCode: string, city: string, country: string): string => {
  return `${zipCode}, ${city} (${country})`;
};

export const formatLocationNotino = (zipCode: string, city: string, country: string, streetNr: string) => {
  return (
    <>
      <div>{streetNr}</div>
      <div>{`${zipCode} ${city}, ${country}`}</div>
    </>
  );
};

export const validateHourString = (value: string) => {
  if (isNullOrUndefined(value)) return false;
  const splitted = value.split(':');
  if (splitted.length == 1) {
    /*const number = parseInt(splitted[0]);
                        if (isNaN(number)) return false;
                        if (number >= 0 && number < 24) return true;*/
    return false;
  } else {
    if (splitted[1].length !== 2) return false;

    const number = parseInt(splitted[0]);
    if (isNaN(number)) return false;
    if (number > 24 || number < 0) return false;

    const number2 = parseInt(splitted[1]);
    if (isNaN(number2)) return false;
    if (number2 > 59 || number2 < 0) return false;

    if (number == 24 && number2 > 0) return false;

    return true;
  }
  return false;
};

export const printTimeslotResponse = (timeslot: TimeSlotResponse) => {
  if (isNullOrUndefined(timeslot)) return null;
  return `${dateFormatOnlyDateShort(dayjs(timeslot.localDate))} | ${timeslot.sinceHourString} - ${timeslot.tillHourString} (${timeslot.timeZone})`;
};

export const translateWorkflowState = (stateName: string, tr: any) => {
  return tr(`CreateEditWorkflowStateComponent.stateName${stateName}`, `${stateName}`);
};

export const visualiseWorkflowStateState = (state: State, tr: any, withText: boolean = true) => {
  if (isNullOrUndefined(state)) return <></>;
  return (
    <WithGap>
      {state.icon && <span className={`pi ${state.icon.name}`} style={{ color: state.icon.color }}></span>}
      {withText && <>{translateWorkflowState(state.name, tr)}</>}
    </WithGap>
  );
};

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export const translateQuantityCode = (code: string, tr: any): string => tr(`quantityCode${code}`, `quantityCode${code}`);

export const weightAndDimensions = (height: number, length: number, width: number, weight: number, t: any) => {
  // rozměry: ${orderItem.length} x ${orderItem.height} x ${orderItem.width} cm (d x š x v)
  return `${weight.toLocaleString('cs', { minimumFractionDigits: 2 })} kg, ${t('common.dimensions', {
    height,
    length,
    width,
  })}`;
};

export const convertToArray = (value: any) => {
  if (isNullOrUndefined(value)) return [];
  if (Array.isArray(value)) return value;
  return [value];
};

export type Identifier = string;

export const fixBooleanValue = (value: string | boolean): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true';
  }
};
export const fixBooleanValueWithNull = (value: string | boolean): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (value === null) return null;
  if (typeof value === 'string') {
    return value === 'true';
  }
};

export const europeanUnion: string[] = [
  'BEL',
  'BGR',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'HRV',
  'IRL',
  'ITA',
  'CYP',
  'LTU',
  'LVA',
  'LUX',
  'HUN',
  'MLT',
  'DEU',
  'NLD',
  'POL',
  'PRT',
  'AUT',
  'ROU',
  'GRC',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
];

export const formatPercentage = (percentage: number) => {
  return `${percentage?.toLocaleString('cs', { minimumFractionDigits: 2 }) || '-'}`;
};

export const resolveError = (path: string, errors: ValidationError[], t: any) => {
  const error = errors.find(e => e.path === path);
  return error ? t(`validation.${error.type}`) : undefined;
};

export const colorizeState = (status: string, state: string) => {
  switch (state) {
    case CustomerQuoteStateEnum.NEW: {
      return <span>{status}</span>;
    }
    case CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER: {
      return <span className={'text-green'}>{status}</span>;
    }
    case CustomerQuoteStateEnum.REJECTED_BY_CARRIER: {
      return <span className={'text-red'}>{status}</span>;
    }
  }
};

export const enumerate = (en: any): string[] => {
  return Object.keys(en).map(key => en[key]);
};

export function minMax<T>(elements: T[]): { min: T; max: T } {
  return elements.reduce(
    ({ min, max }, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }),
    { min: elements[0], max: elements[0] },
  );
}

export const formatFromToAPITime = (since: string, till: string): string => {
  return `${getTime(mapFromAPIDateTime(since))} - ${getTime(mapFromAPIDateTime(till))}`;
};

export const convertToDate = (value: any) => {
  if (typeof value === 'string') return dayjs(value).toDate();
  return value;
};

export const printYesNo = (value: boolean, tr: any) => {
  if (isNullOrUndefined(value)) return null;

  if (value === true) {
    return tr(`global.yes`, `Yes`);
  } else {
    return tr(`global.no`, `No`);
  }
};

export const formatLocalDate = (date: string) => {
  if (isNullOrUndefined(date)) return null;
  return dayjs(date).format('DD.MM.YYYY');
};

export const parserDate = (date: string) => {
  if (isNullOrUndefined(date)) return null;
  return dayjs(date).toDate();
};

export const parseURLQUeryDate = (dates: string | string[]): Date | Date[] => {
  if (dates == null) return null;
  if (Array.isArray(dates)) {
    return dates.map(d => dayjs(d).toDate());
  }
  return dayjs(dates).toDate();
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isProduction = () => {
  return window.location.origin.includes('app.ringil.com') || window.location.origin.includes('playground.ringil.com');
};

export type GetCustomsFunction = (id: string, callback: (customs: CustomsDetailOutDTO) => void) => void;

export const openMailClientCustoms = (
  getCustomsFunction: GetCustomsFunction,
  id: string,
  locationName: string,
  applicationId: string
): void => {
  getCustomsFunction(id, (customs) => {
    const subject = customs?.loadingNumber
      ? `Z%C3%A1silka%20${applicationId}%20vyclena%20%28celn%C3%AD%20p%C5%99%C3%ADpad%20${customs.loadingNumber}%29`
      : `Z%C3%A1silka%20${applicationId}%20vyclena`;

    const customsRow = customs?.loadingNumber
      ? `%C4%8C%C3%ADslo%20p%C5%99%C3%ADjmu%3A%20${customs.loadingNumber}`
      : '';

    const message = `mailto:?subject=${subject}&body=V%C3%A1%C5%BEen%C3%AD%20kolegov%C3%A9%2C%0A%0Az%C3%A1silka%20byla%20vyclena%20a%20m%C5%AF%C5%BEete%20zah%C3%A1jit%20jej%C3%AD%20zpracov%C3%A1n%C3%AD.%20%0A${customsRow}%0ADodavatel%3A%20${encodeURIComponent(
      locationName
    )}%0A%0AS%20pozdravem%0A%0AInbound%20team`;

    window.open(message);
  });
};
