import React, { useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import { CarrierCodeSelector } from '@components/obsolete/new-expedition/CarrirerCodeSelector/CarrierCodeSelector';
import { CreateTemplateHandlerContext } from './CreateTemplateHandler';
import { PageID } from '../new-expedition/types';
import LocationDetails from '../common/LocationDetails';
import useValidation from '@hooks/validation-hook/useValidation';
import { stage3Validation } from './validation';
import { ExpeditionTypeEnum, OpeningHourDefinitionsDTO } from '@api/logsteo-api.v2';
import LocationDetailItem from '@components/obsolete/CreateTemplate/components/LocationDetailsItem';
import LoadingComponent from '@components/obsolete/CreateTemplate/components/LoadingComponent';
import { UnloadOrderComponent } from '@components/obsolete/CreateTemplate/components/UnloadOrderComponent';
import { useTranslation } from 'react-i18next';
import OpeningHourDefinitionForm from '@components/obsolete/OpeningHoursDefinition/OpeningHourDefinitionForm.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { dumpVars } from '@utils/utils.tsx';

interface ComponentProps {
  unloadLocationId?: number;
  expeditionType: ExpeditionTypeEnum;
}

const NewExpeditionStep3Header: React.FC<ComponentProps> = ({ expeditionType, unloadLocationId }) => {
  const { t } = useTranslation('common');
  const { state, clickOnBreadCrumb, changeCarrierCode, setValidationErrorsToState, changeOpeningHours } = useContext(CreateTemplateHandlerContext);

  // @ts-ignore
  const resolveTitle = (index, length) => {
    if (index === 0) return t('CreateTemplateStep3Manipulation.begin', 'Begin');
    if (index === length - 1) return t('CreateTemplateStep3Manipulation.end', 'End');
    return t('CreateTemplateStep3Manipulation.counterStop', 'Counter stop');
  };

  const locations = state.locations;

  const { clear, validationErrors, validateAndSend } = useValidation(stage3Validation, () => clickOnBreadCrumb(PageID.STEP4));

  useEffect(() => {
    setValidationErrorsToState(validationErrors);
  }, [validationErrors]);

  return (
    <>
      {locations.map((item, locationIndex, collection) => (
        <LocationDetails key={locationIndex}>
          <LocationDetailItem
            count={locationIndex + 1}
            company={item.name}
            city={item.city}
            locationDescription={resolveTitle(locationIndex, collection.length)}
            buttonTitle={t(`CreateTemplateStep3Manipulation.edit`, `Edit`)}
          />
          <div className="p-mb-3">
            <CarrierCodeSelector carrierCode={item.carrierCode} setCarrierCode={e => changeCarrierCode(locationIndex, e)} />
          </div>
          <OpeningHourDefinitionForm openingHours={item.openingHourDefinitions} onChange={v => changeOpeningHours(locationIndex, v)} />
          {expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD && locationIndex < collection.length - 1 && (
            <LoadingComponent collapsed={item.loadingLocation || false} orders={item.loadingOrders} locationIndex={locationIndex} locationName={item.name} />
          )}
          {expeditionType === ExpeditionTypeEnum.LESS_TRUCK_LOAD && locationIndex !== 0 && (
            <UnloadOrderComponent
              key={locationIndex}
              isLastLocation={locationIndex == collection.length - 1}
              collapsed={item.unloadingLocation || false}
              ltlOrders={locations
                .filter((item, index) => item.id < locationIndex)
                .map((item, index) => item.loadingOrders)
                .flat(1)
                .filter((item, index) => item.unloadingLocationId === locationIndex || item.unloadingLocationId === null)}
              locationId={locationIndex}
              unloadAll={item.unloadAll}
            />
          )}
        </LocationDetails>
      ))}
      <ButtonLayout>
        <Button
          label={t(`CreateTemplateStep3Manipulation.continue`, `Continue`)}
          className="p-button-success p-mr-2"
          onClick={e => {
            validateAndSend(state);
          }}
        />
        <Button
          label={t(`CreateTemplateStep3Manipulation.back`, `Back`)}
          className="p-button-text"
          onClick={e => {
            clickOnBreadCrumb(PageID.STEP2);
          }}
        />
      </ButtonLayout>
    </>
  );
};

export default NewExpeditionStep3Header;
