import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { AccessTypeEnum, EntityTypeEnum, LovDocumentTypeOutDTO } from '@api/logsteo-api.v2';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { mapFromDateToDayjs } from '@utils/utils';
import { shortDateFormat } from '@utils/date';
import { InputTextarea } from 'primereact/inputtextarea';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { ButtonRow, Hr } from '@components/styles.tsx';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  entityType: EntityTypeEnum;
  entityIds: string[];
  visible: boolean;
  onHide: () => void;
  onComplete: (documentCount: number) => void;
}

interface StoredDocument {
  fileName: string;
  attachmentId: string;
  documentType?: LovDocumentTypeOutDTO;
  accessType?: AccessTypeEnum;
  userNote?: string;
  userDate?: string;
}

interface Form {
  documents: StoredDocument[];
}

const MassActionFileUploadDialog: React.FC<ComponentProps> = ({ entityType, entityIds, onComplete, visible, onHide }) => {
  const { tr } = useTranslationLgs();
  const { genericUploadV2, uploadAttachment, lovAttachmentDocumentType } = useContext(ApiContext);
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const [documentTypes, setDocumentTypes] = useState<LovDocumentTypeOutDTO[]>();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    if (visible) {
      form.overwriteValues([]);
      form.clear();
      lovAttachmentDocumentType(data => {
        setDocumentTypes(data);
      });
    }
  }, [visible]);

  const validationSchema = yup.array().of(
    yup.object().shape({
      documentType: yup.mixed().required(),
    }),
  );
  const form = useForm<StoredDocument[]>(
    validationSchema,
    [],
    () => {
      attachDocuments();
    },
    true,
    false,
  );

  const fileUpload = useRef(null);

  // @ts-ignore
  const uploadHandler = ({ files }) => {
    const [file] = files;
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const dataURL = e.target.result;
      const fileName = file.name;
      uploadAttachment({ dataURL: dataURL as string, fileName }, d => {
        form.overwriteValues([...form.values, { attachmentId: d.attachmentId, fileName: d.fileName }]);
      });
      fileUpload.current.clear();
    };
    fileReader.readAsDataURL(file);
  };

  const formNames = proxiedPropertiesOf<StoredDocument>();
  const formFieldName = (index: number, name: keyof StoredDocument) => `[${index}].${name}`;

  const attachDocuments = () => {
    genericUploadV2(
      {
        entityIds,
        entityType,
        attachments: form.values.map(t => {
          return {
            attachmentId: t.attachmentId,
            documentType: t.documentType.documentType,
            accessType: t.accessType,
            userDate: t.userDate,
            userNote: t.userNote,
          };
        }),
        companyOwnerId: loggedUser.companyId,
      },
      () => {
        onComplete(form.values.length);
      },
      null,
      { onValidationFailed: setBeValidationErrors },
    );
  };

  const { i18n } = useTranslation();

  const names = proxiedPropertiesOf<Form>();

  return (
    <>
      {/*// @ts-ignore*/}
      <Dialog
        onHide={onHide}
        visible={visible}
        blockScroll={true}
        closeOnEscape={false}
        header={tr(`MassActionFileUploadDialog.nahratDokumenty`, `Nahrat dokumenty`)}
        style={{ width: '450px' }}
      >
        <BackendValidationComponent />
        <UploadedDocumentWrapper>
          {form.values.map((d, documentsIndex) => {
            return (
              <React.Fragment key={documentsIndex}>
                <HorizontalFieldValue
                  label={tr(`MassActionFileUploadDialog.nahravanyDokument`, `Nahravany dokument`)}
                  value={
                    <Button
                      label={d.fileName}
                      className={'p-button-outlined'}
                      onClick={e => form.overwriteValues(form.values.filter((_, tIndex) => tIndex !== documentsIndex))}
                      icon="pi pi-trash"
                    />
                  }
                />
                <HorizontalFieldValue
                  label={tr(`MassActionFileUploadDialog.typDokumentu`, `Typ dokumentu`)}
                  required={true}
                  value={
                    <Dropdown
                      options={documentTypes || []}
                      onChange={e => form.setFieldValue(`[${documentsIndex}].documentType`, e.value)}
                      value={form.values[documentsIndex].documentType}
                      optionLabel="name"
                    />
                  }
                  validationError={form.find(`[${documentsIndex}].documentType`)}
                />
                <HorizontalFieldValue
                  label={tr('UploadAttachmentDialog.internal', 'Internal')}
                  validationError={form.find(formFieldName(documentsIndex, 'accessType'))}
                  value={
                    <InputYesNoPlain
                      value={d.accessType === AccessTypeEnum.INTERNAL ? true : false}
                      onChange={v => form.setFieldValue(formFieldName(documentsIndex, 'accessType'), v ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL)}
                    />
                  }
                />
                <HorizontalFieldValue
                  label={tr('UploadAttachmentDialog.date', 'Date')}
                  value={
                    <Calendar
                      showIcon={true}
                      value={d.userDate ? dayjs(d.userDate).toDate() : null}
                      onChange={e => form.setFieldValue(formFieldName(documentsIndex, 'userDate'), mapFromDateToDayjs(e.value as Date).toISOString())}
                      dateFormat={shortDateFormat}
                      selectionMode={'single'}
                      style={{ width: '100%' }}
                      locale={i18n.language}
                      hideOnDateTimeSelect={true}
                    />
                  }
                />
                <HorizontalFieldValue
                  label={tr('UploadAttachmentDialog.note', 'Note')}
                  value={
                    <InputTextarea
                      style={{ width: '100%', height: '100%' }}
                      value={d.userNote}
                      onChange={e => form.setFieldValue(formFieldName(documentsIndex, 'userNote'), e.target.value)}
                    />
                  }
                />
                <Hr />
              </React.Fragment>
            );
          })}
        </UploadedDocumentWrapper>
        <NewAttachmentWrapper>
          <HorizontalFieldValue
            label={tr(`MassActionFileUploadDialog.nahravanyDokument`, `Nahravany dokument`)}
            value={
              <FileUpload
                maxFileSize={10 * 1024 * 1024}
                ref={fileUpload}
                uploadHandler={e => uploadHandler(e)}
                mode="basic"
                auto={true}
                name="document"
                chooseLabel={tr('MassActionFileUploadDialog.addAttachment', 'Add attachment')}
                customUpload={true}
              />
            }
          />
        </NewAttachmentWrapper>
        <ButtonRow>
          <Button
            label={tr(`MassActionFileUploadDialog.confirm`, `Confirm`)}
            className="p-mr-2"
            onClick={() => {
              form.validateAndSend();
            }}
          />
          <Button label={tr(`MassActionFileUploadDialog.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
        </ButtonRow>
      </Dialog>
    </>
  );
};

const UploadedDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewAttachmentWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
`;

export default MassActionFileUploadDialog;
