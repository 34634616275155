import React, { useContext, useMemo } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import SelectedCarrierTag from '@components/framework/tags/SelectedCarrierTag.tsx';
import CarPlateTag from '@components/framework/tags/CarPlateTag.tsx';
import AssignedDriverTag from '@components/framework/tags/AssignedDriverTag.tsx';
import RouteTable from '@components/ringil3/Features/Expedition/RouteTable/RouteTable';
import { CarrierQuoteStateEnum, ExpeditionDetailV4OutDTO } from '@api/logsteo-api.v2';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import ArrangedPriceTag from '@components/framework/tags/ArrangedPriceTag.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV4OutDTO;
  onReload: () => void;
  canEdit: boolean;
}

const TabExpeditionAssignedPlus: React.FC<ComponentProps> = ({ expedition, onReload, canEdit }) => {
  const { tr } = useTranslationLgs();

  const { expEditCarInfo } = useContext(ApiContext);

  const arrangedPrice = useMemo(() => {
    // Check if expedition has quotes
    if (expedition.quotes && expedition.quotes.length > 0) {
      // Filter quotes with specific states
      const acceptedQuotes = expedition.quotes.filter(
        quote => quote.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER || quote.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM,
      );

      if (acceptedQuotes.length > 0) {
        // Get the price from the first accepted quote
        const quote = acceptedQuotes[0];
        return {
          price: quote.offeredPrice,
          currency: quote.currency,
        };
      }
    }
    // Fall back to distribution price and currency
    return {
      price: expedition.distribution?.price,
      currency: expedition.distribution?.currency,
    };
  }, [expedition]);

  const saveCarPlate = (carPlate: string) => {
    expEditCarInfo(expedition.id, { carPlate }, () => {
      onReload();
    });
  };
  const saveDriverName = (driverName: string) => {
    expEditCarInfo(expedition.id, { driverName: driverName, carPlate: expedition.carPlate }, () => {
      onReload();
    });
  };

  return (
    <>
      <ColWithGap>
        <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.carrierTitle`, `Carriers`)} icon={<SvgLkw />}></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            {arrangedPrice && <ArrangedPriceTag price={arrangedPrice.price} currency={arrangedPrice.currency} readOnly={true} />}
            <SelectedCarrierTag carrierName={expedition.assignedCarrierName} carrierId={null} companyLocationCustomerId={expedition.customerId} readOnly={true} />
            <CarPlateTag carPlate={expedition.carPlate} onChange={carPlate => saveCarPlate(carPlate)} readOnly={!canEdit} />
            <AssignedDriverTag assignedDriverName={expedition.assignedDriver} readOnly={!canEdit} onChange={driverName => saveDriverName(driverName)} />
          </Row>
        </RowForTagsAndBadges>
        <RouteTable
          routes={expedition.routes}
          onChanged={() => onReload()}
          applicationId={expedition.applicationId}
          companyId={expedition.customerId}
          canEdit={canEdit}
        ></RouteTable>
      </ColWithGap>
    </>
  );
};

export default TabExpeditionAssignedPlus;
