import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { formatLocation, validateHours } from '@utils/utils';
import { Checkbox } from 'primereact/checkbox';
import { ApiContext } from '@api/api';
import { CompanyLocationsListPreviewOutDTO, ExceptionTimeIntervalInDTO, LocationSelectModeEnum, OpenHourExceptionTypeEnum } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { shortDateFormat } from '@utils/date';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import RadioButtons from '@components/obsolete/RadioButtons.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onAdded: () => void;
}

enum LocationSelectionMode {
  ALL_LOCATIONS = 'ALL_LOCATIONS',
  SELECTED_LOCATIONS = 'SELECTED_LOCATIONS',
}

enum RampSelectionMode {
  ALL_RAMP = 'ALL_RAMP',
  SELECTED_RAMPS = 'SELECTED_RAMPS',
}

enum OpenHoursExceptionType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

interface FormData {
  dates: Date[];
  exceptionName: string;
  locationSelectMode: LocationSelectModeEnum;
  openHourExceptionType: OpenHourExceptionTypeEnum;
  openingHours: ExceptionTimeIntervalInDTO[];
  timeZone: string;
  locationsAndRamps: LocationAndRamps[];
  isOnlyForExternalPartners: boolean;
}

interface LocationAndRamps {
  locationName: string;
  locationId: string;
  streetNr: string;
  city: string;
  postalCode: string;
  country: string;
  enabled: boolean;
  ramps: string[];
}

const initialValues: FormData = {
  dates: [new Date()],
  exceptionName: '',
  locationSelectMode: LocationSelectModeEnum.ALL_LOCATIONS,
  openHourExceptionType: OpenHourExceptionTypeEnum.CLOSED,
  openingHours: [],
  timeZone: 'Europe/Prague',
  locationsAndRamps: [],
  isOnlyForExternalPartners: false,
};

const NewExceptionSidebar: React.FC<ComponentProps> = ({ visible, onHide, onAdded }) => {
  const { tr } = useTranslationLgs();
  const { getCompanyLocations, createCompanyLocationException } = useContext(ApiContext);
  const [locations, setLocations] = useState<CompanyLocationsListPreviewOutDTO[]>([]);

  const fromToScheme = yup.object().shape({
    since: yup.string().test({
      test: validateHours,
      name: 'time-ok',
      message: tr('validation.invalid', 'Value is invalid'),
      exclusive: false,
    }),
    till: yup.string().test({
      test: validateHours,
      name: 'time-ok',
      message: tr('validation.invalid', 'Value is invalid'),
      exclusive: false,
    }),
  });

  const validationSchema = yup.object().shape({
    exceptionName: yup.string().required(tr('validation.required', 'Value is required')),
    dates: yup.array().min(1, tr('validation.required', 'Value is required')),
    openingHours: yup
      .array()
      .of(fromToScheme)
      .when('openHourExceptionType', {
        is: OpenHourExceptionTypeEnum.OPEN,
        then: yup.array().of(fromToScheme).min(1, tr('NewExceptionSidebar.atLeastOneIntervalShouldBeCreated', 'At least one interval should be created')),
        otherwise: yup.array(),
      }),
  });

  const loadLocations = () => {
    getCompanyLocations(null, null, d => {
      const locAndRamps = d.map((item, index) => {
        return {
          city: item.city,
          country: item.country,
          enabled: false,
          locationName: item.name,
          locationId: item.locationId,
          ramps: [],
          postalCode: item.postalCode,
          streetNr: item.streetNr,
        } as LocationAndRamps;
      });
      setLocations(d);
      formik.setFieldValue('locationsAndRamps', locAndRamps);
    });
  };

  const createException = (values: FormData) => {
    createCompanyLocationException(
      {
        exceptionName: values.exceptionName,
        dates: values.dates.map(t => t.toISOString()),
        locationsAndRamps: values.locationsAndRamps,
        openHourExceptionType: values.openHourExceptionType,
        locationSelectMode: values.locationSelectMode,
        openingHours: values.openingHours,
        timeZone: values.timeZone,
        isOnlyForExternalPartners: values.isOnlyForExternalPartners,
      },
      () => {
        onAdded();
      },
    );
  };

  const formik = useFormik<FormData>({
    initialValues,
    onSubmit: values => createException(values),
    validationSchema,
  });

  const addDate = () => {
    formik.setFieldValue('dates', [...formik.values.dates, new Date()]);
  };

  const deleteDate = (index: number) => {
    formik.setFieldValue('dates', [...formik.values.dates.filter((_, i) => i !== index)]);
  };

  const addOpeningTime = () => {
    formik.setFieldValue('openingHours', [...formik.values.openingHours, { since: '', till: '' }]);
  };
  const removeOpeningTime = (i: number) => {
    formik.setFieldValue('openingHours', [...formik.values.openingHours.filter((_, index) => index !== i)]);
  };

  useEffect(() => {
    formik.resetForm();
  }, [visible]);

  useEffect(() => {
    if (formik.values.openHourExceptionType === OpenHourExceptionTypeEnum.CLOSED) {
      formik.setFieldValue('openingHours', []);
    }
  }, [formik.values.openHourExceptionType]);

  useEffect(() => {
    if (formik.values.locationSelectMode === LocationSelectModeEnum.SELECTED_LOCATIONS) {
      loadLocations();
    } else {
      formik.setFieldValue('locationsAndRamps', []);
    }
  }, [formik.values.locationSelectMode]);

  const { i18n } = useTranslation();

  return (
    <SidebarWrapper>
      {/*// @ts-ignore*/}

      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={onHide} blockScroll={true} style={{ minWidth: '40rem' }}>
        <ColumnRow>
          <form onSubmit={formik.handleSubmit}>
            <ColumnRow>
              <InnerRow>
                <SidebarHeading>{tr(`NewExceptionSidebar.openingTimeException`, `Opening time exception`)}</SidebarHeading>
                <HorizontalFieldValue
                  label={tr(`NewExceptionSidebar.exceptionDate`, `Exception date`)}
                  value={
                    <ColumnRow>
                      <CalendarWrapper>
                        {formik.values.dates.map((date, index) => {
                          return (
                            <div key={index}>
                              <Calendar
                                locale={i18n.language}
                                dateFormat={shortDateFormat}
                                showIcon={true}
                                style={{ width: '15rem' }}
                                value={formik.values.dates[index]}
                                name={`dates[${index}]`}
                                onChange={formik.handleChange}
                              />
                              <Button
                                className={'p-button-outlined'}
                                icon={'pi pi-trash'}
                                style={{ marginLeft: '1rem' }}
                                onClick={e => {
                                  e.preventDefault();
                                  deleteDate(index);
                                }}
                              />
                            </div>
                          );
                        })}
                        <Error>{formik.errors?.dates?.toString()}</Error>
                      </CalendarWrapper>
                    </ColumnRow>
                  }
                />
                <div>
                  <Button
                    label={tr(`NewExceptionSidebar.addDate`, `Add date`)}
                    icon={'pi pi-plus'}
                    className={'p-button-outlined'}
                    onClick={e => {
                      e.preventDefault();
                      addDate();
                    }}
                  />
                </div>
              </InnerRow>
              <InnerRow>
                <HorizontalFieldValue
                  label={tr(`NewExceptionSidebar.name`, `Name`)}
                  value={
                    <>
                      <InputText style={{ width: '15rem' }} name={'exceptionName'} value={formik.values.exceptionName} onChange={formik.handleChange} />
                      <Error>{formik.errors.exceptionName}</Error>
                    </>
                  }
                />
              </InnerRow>

              <InnerRow>
                <HorizontalFieldValue
                  label={tr(`NewExceptionSidebar.locations`, `Locations`)}
                  value={
                    <Dropdown
                      style={{ width: '15rem' }}
                      options={[
                        {
                          code: LocationSelectionMode.ALL_LOCATIONS,
                          label: tr('NewExceptionSidebar.allLocationsAllRamps', 'All locations, All ramps'),
                        },
                        {
                          code: LocationSelectionMode.SELECTED_LOCATIONS,
                          label: tr('NewExceptionSidebar.selectedLocations', 'Selected locations'),
                        },
                      ]}
                      optionLabel={'label'}
                      optionValue={'code'}
                      name={'locationSelectMode'}
                      onChange={formik.handleChange}
                      value={formik.values.locationSelectMode}
                    />
                  }
                />
              </InnerRow>
              {formik.values.locationsAndRamps.length > 0 && (
                <InnerRow>
                  <LocationRampTable>
                    <thead>
                      <tr>
                        <th>{tr(`NewExceptionSidebar.location`, `Location`)}</th>
                        <th>{tr(`NewExceptionSidebar.ramp`, `Ramp`)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.locationsAndRamps.map((location, locIndex) => {
                        return (
                          <tr key={locIndex}>
                            <td>
                              <LocationCheckboxRow>
                                <Checkbox
                                  name={`locationsAndRamps[${locIndex}].enabled`}
                                  onChange={formik.handleChange}
                                  checked={formik.values.locationsAndRamps[locIndex].enabled}
                                />
                                <LocationWithAddress>
                                  <div style={{ whiteSpace: 'nowrap' }}>{location.locationName}</div>
                                  <small style={{ whiteSpace: 'nowrap' }}>{formatLocation(location)}</small>
                                </LocationWithAddress>
                              </LocationCheckboxRow>
                            </td>
                            <td>
                              <MultiSelect
                                options={locations[locIndex].ramps}
                                optionLabel={'name'}
                                optionValue={'rampId'}
                                value={formik.values.locationsAndRamps[locIndex].ramps}
                                onChange={formik.handleChange}
                                name={`locationsAndRamps[${locIndex}].ramps`}
                                placeholder={tr('NewExceptionSidebar.allRamps', 'All ramps')}
                                disabled={!formik.values.locationsAndRamps[locIndex].enabled}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </LocationRampTable>
                </InnerRow>
              )}
            </ColumnRow>
            <ColumnRow style={{ marginTop: '1rem' }}>
              <HorizontalFieldValue
                label={tr(`NewExceptionSidebar.whenWillBeOpen`, `When will be open`)}
                value={
                  <RadioButtons
                    options={[
                      {
                        label: tr(`NewExceptionSidebar.closedWholeDay`, `Closed whole day`),
                        code: OpenHoursExceptionType.CLOSED,
                      },
                      {
                        label: tr('NewExceptionSidebar.opened', 'Opened'),
                        code: OpenHoursExceptionType.OPEN,
                      },
                    ]}
                    optionLabel={'label'}
                    optionValue={'code'}
                    onChange={d => {
                      formik.setFieldValue('openHourExceptionType', d);
                    }}
                    value={formik.values.openHourExceptionType}
                  />
                }
              />
              {formik.values.openHourExceptionType === OpenHourExceptionTypeEnum.OPEN && (
                <div>
                  <SlotsTable>
                    <thead>
                      <tr>
                        <th>{tr(`NewExceptionSidebar.openedSince`, `Opened since`)}</th>
                        <th>{tr(`NewExceptionSidebar.till`, `Till`)}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.openingHours.map((openingHour, index) => {
                        return (
                          <tr key={index} style={{ verticalAlign: 'top' }}>
                            <td>
                              <InputText name={`openingHours[${index}].since`} value={formik.values.openingHours[index].since} onChange={formik.handleChange} />
                              <Error>
                                {formik.errors.openingHours &&
                                  typeof formik.errors.openingHours !== 'string' &&
                                  formik.errors.openingHours[index] &&
                                  // @ts-ignore
                                  formik.errors.openingHours[index].since}
                              </Error>
                            </td>
                            <td>
                              <InputText name={`openingHours[${index}].till`} value={formik.values.openingHours[index].till} onChange={formik.handleChange} />
                              <Error>
                                {formik.errors.openingHours &&
                                  typeof formik.errors.openingHours !== 'string' &&
                                  formik.errors.openingHours[index] &&
                                  // @ts-ignore
                                  formik.errors.openingHours[index].till}
                              </Error>
                            </td>
                            <td>
                              <Button
                                icon={'pi pi-trash'}
                                className={'p-button-outlined'}
                                onClick={e => {
                                  e.preventDefault();
                                  removeOpeningTime(index);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </SlotsTable>
                  <Button
                    label={tr(`NewExceptionSidebar.addInterval`, `Add interval`)}
                    icon={'pi pi-plus'}
                    className={'p-button-outlined'}
                    onClick={e => {
                      e.preventDefault();
                      addOpeningTime();
                    }}
                  />
                  <Error>{formik.errors.openingHours && typeof formik.errors.openingHours == 'string' && formik.errors.openingHours}</Error>
                </div>
              )}

              <InputCheckbox
                label={tr(`NewExceptionSidebar.applyOnlyForCounterParty`, `Aplikovat jen na partnery`)}
                value={formik.values.isOnlyForExternalPartners}
                onChange={(_, v) => formik.setFieldValue('isOnlyForExternalPartners', v)}
              />

              <ButtonLayout>
                <Button label={tr(`NewExceptionSidebar.save`, `Save`)} type={'submit'} />
                <Button
                  label={tr(`NewExceptionSidebar.cancel`, `Cancel`)}
                  className={'p-button-text'}
                  onClick={e => {
                    e.preventDefault();
                    onHide();
                  }}
                />
              </ButtonLayout>
            </ColumnRow>
          </form>
        </ColumnRow>
      </Sidebar>
    </SidebarWrapper>
  );
};
const Error = styled.div`
  display: flex;
  color: red;
`;
const LocationWithAddress = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 4rem;
`;

const LocationCheckboxRow = styled.div`
  display: flex;
  gap: 1rem;
`;

const LocationRampTable = styled.table`
  margin-bottom: 1rem;
  border-spacing: 0 1rem;

  thead > tr > th {
    text-align: left;
  }
`;
const CalendarWrapper = styled.div`
  grid-row-gap: 10px;
  display: grid;
  grid-template-columns: auto;
`;

const ButtonLayout = styled.div`
  display: flex;
  margin-top: 2rem;
  column-gap: 3rem;
`;

const SlotsTable = styled.table`
  border-spacing: 0px;
  margin-bottom: 1rem;

  th {
    text-align: left;
  }
`;

const Label = styled.label``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  width: 20rem;
`;
const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`;
const InnerRow = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;

const SidebarHeading = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
`;

const SidebarWrapper = styled.div``;

export default NewExceptionSidebar;
