import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { EntityTypeEnum, LovDTO, MassActionExpeditionResponseOutDTO } from '@api/logsteo-api.v2';
import {
  internalInfoPanelValidationSchema,
} from '@app/pages/customer/expedition-detail/InternalInfoTab/InternalInfoPanelForm.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import LabelsControlled from '@components/obsolete/Labels/LabelsControlled.tsx';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo.tsx';
import { Trans } from 'react-i18next';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import InputFolder from '@components/framework/input/InputFolder/InputFolder.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';

interface ExpeditionInfo {
  applicationId: string;
  expeditionId: string;
}

interface ComponentProps {
  onComplete: () => void;
  selectedExpeditions: ExpeditionInfo[];
}

interface Form {
  overwriteLabels: boolean;
  labels: string[];
  overwriteArchiveExpeditions: boolean;
  archiveLinkedSN?: boolean;
  overwriteFolder: boolean;
  folder?: LovDTO;
}

export const useMAExp = () => {
  const [visible, setVisible] = useState(false);

  const MassActionExpedition: React.FC<ComponentProps> = ({ selectedExpeditions, onComplete }) => {
    const { tr } = useTranslationLgs();
    const { expUpdateMassAction, getMassActionExpeditionData } = useContext(ApiContext);
    const [expeditionInfos, setExpeditionInfos] = useState<MassActionExpeditionResponseOutDTO>();

    const form = useForm<Form>(
      internalInfoPanelValidationSchema,
      {
        labels: [],
        overwriteLabels: false,
        overwriteArchiveExpeditions: false,
        archiveLinkedSN: false,
        overwriteFolder: false,
        folder: null,
      },
      d => {
        save(d);
      },
      true,
      false,
    );

    const save = (data: Form) => {
      expUpdateMassAction(
        {
          expIds: selectedExpeditions.map(t => t.expeditionId),
          internalData: {
            overwriteLabels: data.overwriteLabels,
            labels: data.labels,
            overwriteArchiveExpeditions: data.overwriteArchiveExpeditions,
            archiveLinkedSN: data.archiveLinkedSN,
            overwriteFolder: data.overwriteFolder,
            folderId: data.folder?.id,
          },
        },
        () => {
          setVisible(false);
          onComplete();
        },
      );
    };

    const names = proxiedPropertiesOf<Form>();

    useEffect(() => {
      getMassActionExpeditionData({ expIds: selectedExpeditions.map(t => t.expeditionId) }, d => {
        setExpeditionInfos(d);
      });
    }, []);

    return (
      <>
        <RingilSidebar
          visible={visible}
          heading={tr(`MAExp.massAction`, `Mass action`)}
          onBack={() => setVisible(false)}
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          <SidebarContent>
            <Section
              label={tr(`MultiEditSupplierNotificationSidebar.archiveExpeditions`, `Archivovat přepravu`)}
              showToggle={true}
              onVisibilityChanged={v => {
                form.setFieldValue(names.archiveLinkedSN, false);
                form.setFieldValue(names.overwriteArchiveExpeditions, v);
              }}
            >
              <COInlineMessage icon={<SvgGrayInfo />} variant={'info'}>
                <Trans i18nKey={'MultiEditSupplierNotificationSidebar.archiveExpeditionsInfo'}></Trans>
              </COInlineMessage>
              {expeditionInfos?.allSNCoords?.length > 0 && (
                <InputCheckbox
                  label={tr('MAExp.archivovatIPropojeneAvizace', 'Archivovat i propojené avizace')}
                  value={form.values.archiveLinkedSN}
                  note={expeditionInfos?.allSNCoords.map(t => t.appId).join(', ')}
                  onChange={(_, v) => {
                    form.useProduce(d => {
                      d.archiveLinkedSN = v;
                    });
                  }}
                />
              )}
            </Section>
            <Section
              label={tr(`MultiEditSupplierNotificationSidebar.overrideLabels`, `Override labels`)}
              showToggle={true}
              onVisibilityChanged={v => {
                form.setFieldValue(names.labels, []);
                form.setFieldValue(names.overwriteLabels, v);
              }}
            >
              <LabelsControlled
                entityType={EntityTypeEnum.EXPEDITION}
                value={form.values.labels}
                onChange={v => {
                  form.setFieldValue(names.labels, v);
                }}
              />
            </Section>

            <Section
              label={tr(`MultiEditSupplierNotificationSidebar.overwriteFolder`, `Přepsat složku`)}
              showToggle={true}
              onVisibilityChanged={v => {
                form.setFieldValue(names.folder, null);
                form.setFieldValue(names.overwriteFolder, v);
              }}
            >
              <Field label={tr('MAExp.vyberteSlozku', 'Vyberte složku')}>
                <InputFolder
                  folder={form.values.folder}
                  onChange={v =>
                    form.useProduce(d => {
                      d.folder = v;
                    })
                  }
                  entityType={EntityTypeEnum.EXPEDITION}
                />
              </Field>
            </Section>
          </SidebarContent>
        </RingilSidebar>
      </>
    );
  };

  return { MassActionExpedition, setVisible };
};

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;
