import React, { PropsWithChildren } from 'react';
import SvgBreadcrumbDivider from '@icons/BreadcrumbDivider.tsx';
import COText from '@app/pages/COText/COText.tsx';
import clsx from 'clsx';
import { Link } from '@router.ts';
import { dumpVars } from '@utils/utils.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  currentPosition: number;
  locationCount: number;
  idPrefix: string;
}

interface Props {
  position: number;
  currentPosition: number;
}

const Anchor: React.FC<PropsWithChildren<Props>> = ({ position, currentPosition, children }) => {
  return (
    <>
      <React.Fragment key={position}>
        <div className={clsx({ 'text-content-brand font-medium cursor-pointer': position !== currentPosition + 1 })} onClick={e => {}}>
          {children}
        </div>
      </React.Fragment>
    </>
  );
};

const NavigationBreadCrumb: React.FC<ComponentProps> = ({ currentPosition, locationCount, idPrefix }) => {
  const { tr } = useTranslationLgs();
  const resolveText = (i: number) => {
    if (i === 1) {
      return <COText label={tr('NavigationBreadCrumb.zacatekTrasy', 'Začátek trasy')} />;
    }
    if (i === locationCount) {
      return <COText label={tr('NavigationBreadCrumb.konecTrasy', 'Konec trasy')} />;
    } else {
      return <COText label={tr('NavigationBreadCrumb.mezizastavka', 'Mezizastávka')} />;
    }
  };

  const navigateTo = (position: number) => {
    document.getElementById(`${idPrefix}_${position}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div id={`${idPrefix}_${currentPosition + 1}`} className={'flex flex-row items-center gap-4 flex-wrap'}>
        {Array.from({ length: locationCount }, (_, i) => i + 1).map(position => {
          return (
            <React.Fragment key={position}>
              <div onClick={e => navigateTo(position)}>
                <Anchor position={position} currentPosition={currentPosition}>
                  {resolveText(position)}
                </Anchor>
              </div>
              {position < locationCount && <SvgBreadcrumbDivider />}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default NavigationBreadCrumb;
