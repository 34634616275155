import React, { useContext } from 'react';
import { formatPrice, formatTons, formatWeight, isNullOrUndefined, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import { DemandDetailView } from '../demand-detail/types';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToTime } from '@utils/date';
import { CarrierQuoteStateEnum, CustomerQuoteStateEnum, DistributionTypeEnum, QuoteDetailOutDTO } from '@api/logsteo-api.v2';
import AcceptOrRejectComponent from './QuickActionsFixedPriceAcceptReject';
import { Button } from 'primereact/button';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import useBEValidation from '../../../../hooks/useBEValidation/useBEValidation';
import { useTranslation } from 'react-i18next';
import DemandStateSpan from '@components/obsolete/StateSpan/DemandStateSpan.tsx';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import DisplayLocation from '@components/obsolete/DisplayLocation/DisplayLocation.tsx';
import TimeList from '@components/obsolete/TimeList/TimeList.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {
  action: string;
  applicationId: string;
  quoteDetail: QuoteDetailOutDTO;
  code: string;
  locationDisplayPattern: string;
}

export const QuickActionFixedPrice: React.FC<ComponentProps> = ({ code, action, applicationId, quoteDetail, locationDisplayPattern }) => {
  const { t } = useTranslation('common');
  const { caRejectFixedPrice, caAcceptFixedPrice } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();

  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

  if (isNullOrUndefined(quoteDetail)) return <></>;

  const disabled = quoteDetail.isExpeditionCanceled || quoteDetail.isManualExpedition;
  const quoteState = quoteDetail.distributionType === DistributionTypeEnum.SPOT ? quoteDetail.carrierQuoteState : quoteDetail.customerQuoteState;
  const createSpot =
    quoteDetail.distributionType === DistributionTypeEnum.SPOT && (quoteState === CarrierQuoteStateEnum.NEW || quoteState === CarrierQuoteStateEnum.REJECTED_BY_CARRIER);

  const resolveExpeditionStatus = (state: DemandDetailView) => {
    return (
      <>
        <DemandStateSpan demandState={quoteDetail.customerQuoteState} isManual={quoteDetail.isManualExpedition} isCanceled={quoteDetail.isExpeditionCanceled} />

        {!disabled && (
          <div>
            {t('DemandDetailComponent.validTill', 'Platná do ')}
            <strong>{dateFormatDateTime(mapFromAPIDateTime(quoteDetail.distributionValidTill))}</strong>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <BackendValidationComponent />
      {(quoteState == CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER || quoteState == CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER) && !disabled && (
        <>
          <AlertInfo iconName="check" backgroundWrapper="cyan">
            <p>{t('DemandDetailComponent.youWon', 'Customer assigned you as a carrier to execute this shipment.')}</p>
          </AlertInfo>
          <AlertInfo backgroundWrapper="transparent">
            <div className="p-mr-3">
              <p>{t('DemandDetailComponent.findDetailInShipments', 'You can find detail in all shipments list or here')}</p>
            </div>
            <Button
              label={t('DemandDetailComponent.goToShipmentDetail', 'Go to shipment details')}
              className="p-mt-1 p-mb-1"
              onClick={e => {
                nav.navigate(nav.urlFunctions.createCarrierShipmentDetail(quoteDetail.companyId, quoteDetail.applicationId));
              }}
            />
          </AlertInfo>
        </>
      )}
      {quoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !quoteDetail.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>
            {t(
              `DemandDetailComponent.yourPriceQuoteHasBeenSentCustomerWillNotEitherAcceptOrRejectIt`,
              `Your price quote has been sent. Customer will not either accept or reject it.`,
            )}
          </p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.OFFERED_PRICE && quoteDetail.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.youOfferIsNotValid`, `Vaše nabídka vypršela. Můžete ji prodloužit, pokud je poptávka stále platná.`)}</p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER && !quoteDetail.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{t(`DemandDetailComponent.customerRejectedYourPrice`, `Customer rejected your price.`)}</p>
            <br />
            <p>{quoteDetail.myQuoteInfo?.rejectedMessageByCustomer}</p>
          </>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CARRIER && !quoteDetail.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{tr(`DemandDetailComponent.demandWasRejectedByYourCompany`, `Demand was rejected by your company`)}</p>
          </>
        </AlertInfo>
      )}
      {quoteDetail.isDistributionExpired && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{tr(`DemandDetailComponent.expired`, `Tato přeprava již není dostupná k akceptaci`)}</p>
          </>
        </AlertInfo>
      )}
      {quoteDetail.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.assignedToAnotherCarrier`, `Nabídka byla přiřazena jinému dopravci`)}</p>
        </AlertInfo>
      )}
      <div className="container-wrapper-lg">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            <NavTitle wrapper="WITHOUT-COL_PADDING-BOOTOM-4">
              <h2 className="h2-title p-m-0">
                {t('demandDetail.pageTitle', {
                  applicationId: quoteDetail.applicationId,
                })}{' '}
                <span></span>
              </h2>
              <p>{resolveExpeditionStatus(quoteDetail)}</p>
            </NavTitle>
            <ListInfo>
              <ListInfoItem title={t(`DemandDetailComponent.distance`, `Distance`)}>
                <p>
                  <small>{quoteDetail.distance ? quoteDetail.distance : '-'} km</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={t(`DemandDetailComponent.expeditionType`, `Expedition type`)}>
                <p>
                  <small>{t(`global.expeditionType${quoteDetail.expeditionType}`)}</small>
                </p>
              </ListInfoItem>
              {quoteDetail.truckType && (
                <ListInfoItem title={t(`DemandDetailComponent.truckType`, `Truck type`)}>
                  <p>
                    <small>{quoteDetail.truckType}</small>
                  </p>
                </ListInfoItem>
              )}
              <ListInfoItem title={t(`DemandDetailComponent.maximumWeight`, `Maximum weight`)}>
                <p>
                  <small>{quoteDetail.maxWeight.toLocaleString()} kg</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={t(`DemandDetailComponent.cargoType`, `Cargo type`)}>
                <p>
                  <small>{quoteDetail.cargoType}</small>
                </p>
              </ListInfoItem>
              {!isNullOrUndefined(quoteDetail.carrierNote) && (
                <ListInfoItem title={t(`DemandDetailComponent.noteToCarriers`, `Note to carriers`)}>
                  <p>
                    <small>“{quoteDetail.carrierNote}“</small>
                  </p>
                </ListInfoItem>
              )}
              {quoteDetail.distributionType === DistributionTypeEnum.SPOT && (
                <ListInfoItem title={t(`DemandDetailComponent.expectedPrice`, `Expected price`)}>
                  <p>
                    <small>{formatPrice(quoteDetail.price, quoteDetail.currency)}</small>
                  </p>
                </ListInfoItem>
              )}
            </ListInfo>
          </div>
          {!quoteDetail.isDistributionExpired &&
            !quoteDetail.isAssignedToAnotherCarrier &&
            quoteDetail.distributionType === DistributionTypeEnum.FIXED_PRICE &&
            quoteDetail.customerQuoteState === CustomerQuoteStateEnum.NEW && (
              <AcceptOrRejectComponent
                price={quoteDetail.price}
                currency={quoteDetail.currency}
                onAccept={() => {
                  caAcceptFixedPrice(
                    quoteDetail.companyId,
                    quoteDetail.applicationId,
                    quoteDetail.quoteId,
                    () => {
                      window.location.reload();
                    },
                    code,
                    { onValidationFailed: e => setBeValidationErrors(e) },
                  );
                }}
                onReject={() => {
                  caRejectFixedPrice(
                    quoteDetail.companyId,
                    quoteDetail.applicationId,
                    quoteDetail.quoteId,
                    () => {
                      window.location.reload();
                    },
                    code,
                    { onValidationFailed: e => setBeValidationErrors(e) },
                  );
                }}
                enableActions={!disabled}
                action={action}
              />
            )}
        </div>
        <div className="p-grid p-mt-4">
          <div className="p-col-12 p-lg-5">
            <>
              <Label title={t('RouteDescriptionComponent.routePlan', 'Plán cesty')} />
              {quoteDetail.timeslots.map((location, locationIndex) => {
                return (
                  <div className="timeslots-wrapper" key={locationIndex}>
                    <PanelWithIcon numberIcon={`${locationIndex + 1}`} wrapper="SM">
                      <p>
                        <DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />
                      </p>
                    </PanelWithIcon>
                    <div className="time-shell">
                      <TimeList>
                        {location.timeslots.map((timeslot, timeslotIndex) => {
                          return (
                            <TimeListItem
                              key={timeslotIndex}
                              date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                              isWholeDay={timeslot.isNotSpecifiedTime}
                              time={`${mapFromAPIToTime(timeslot.sinceInUTC)} - ${mapFromAPIToTime(timeslot.tillInUTC)}`}
                              selected={timeslot.isSelected}
                            />
                          );
                        })}
                      </TimeList>
                    </div>
                  </div>
                );
              })}
            </>
            <div className="shipment-requirements-wrapper">
              <Label title={t('RouteDescriptionComponent.manipulationType', 'Manipulace')} />
              <p>
                <i className="p-mr-2 pi pi-check"></i>
                <span>{quoteDetail.loadingTypes && quoteDetail.loadingTypes.map(type => t(`RouteDescriptionComponent.manipulatioType.${type}`)).join(', ')}</span>
              </p>
              <p>
                {quoteDetail.requirements && (
                  <>
                    <strong>{t('RouteDescriptionComponent.requirements', 'Požadavky')}:</strong>
                    {quoteDetail.requirements.join(', ')}
                  </>
                )}
              </p>
            </div>

            {quoteDetail.items && quoteDetail.items.length > 0 && (
              <div className="load-info-wrapper load-info-wrapper--sm  p-mt-4 p-mb-4 p-d-flex p-flex-wrap">
                {quoteDetail.items.map((cargo, cargoIndex) => {
                  return (
                    <LoadInfoList
                      key={cargoIndex}
                      title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                      description={formatTons(cargo.weight)}
                      stackable={cargo.stackable}
                      itemNote={cargo.itemNote}
                    />
                  );
                })}
              </div>
            )}
            <div className="p-mt-5 list-routes">
              {quoteDetail.locations.map((location, locationIndex) => {
                return (
                  <React.Fragment key={locationIndex}>
                    <TimeslotsProgressTitle
                      number={`${locationIndex + 1}`}
                      address={<DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />}
                    />

                    {location.loadedOrders && location.loadedOrders.length > 0 && (
                      <div className="p-d-flex">
                        <PanelWithIcon srcIcon="/images/icon_pickup.png">
                          <Label title={t('RouteDescriptionComponent.pickUp', 'Nakládka')} />

                          {location.loadedOrders && location.loadedOrders.length > 0 && (
                            <>
                              {location.loadedOrders.map((loadedOrder, loadedOrderKey) => {
                                return (
                                  <Col>
                                    {loadedOrder.orderName && (
                                      <Row>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                          <p>
                                            <small>{loadedOrder.orderName}</small>
                                          </p>
                                        </ListInfoItem>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                          <p>
                                            <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                          </p>
                                        </ListInfoItem>
                                      </Row>
                                    )}
                                    {loadedOrder.items.map((cargo, cargoIndex) => {
                                      return (
                                        <LoadInfoList
                                          key={cargoIndex}
                                          title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                          description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                          stackable={cargo.stackable}
                                          itemNote={cargo.itemNote}
                                        />
                                      );
                                    })}
                                  </Col>
                                );
                              })}
                            </>
                          )}
                        </PanelWithIcon>
                      </div>
                    )}
                    {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                      <div className="p-d-flex">
                        <PanelWithIcon srcIcon="/images/icon_dropoff.png">
                          <Label title={t('RouteDescriptionComponent.dropoff', 'Vykládka')} />

                          {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                            <>
                              {location.unloadedOrders.map((loadedOrder, loadedOrderKey) => {
                                return (
                                  <Col>
                                    {loadedOrder.orderName && (
                                      <Row>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                          <p>
                                            <small>{loadedOrder.orderName}</small>
                                          </p>
                                        </ListInfoItem>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                          <p>
                                            <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                          </p>
                                        </ListInfoItem>
                                      </Row>
                                    )}
                                    {loadedOrder.items.map((cargo, cargoIndex) => {
                                      return (
                                        <LoadInfoList
                                          key={cargoIndex}
                                          title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                          description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                          stackable={cargo.stackable}
                                          itemNote={cargo.itemNote}
                                        />
                                      );
                                    })}
                                  </Col>
                                );
                              })}
                            </>
                          )}
                        </PanelWithIcon>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="p-mt-5">
              <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                {t('RouteDescriptionComponent.customer', 'Poptávající')}
              </h3>
              <PanelWithIcon srcIcon="/images/contacts.svg">
                <Label title={quoteDetail.customer.name} />
                <p>
                  {quoteDetail.customer.streetNr}, {quoteDetail.customer.city}, {quoteDetail.customer.country}
                </p>
                <p>
                  {t('RouteDescriptionComponent.CrnAndTin', 'IČ: 28441345 | DIČ: CZ28441345', {
                    crn: quoteDetail.customer.crn || '-',
                    tin: quoteDetail.customer.tin || '-',
                  })}
                </p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/user.svg">
                <p>{quoteDetail.responsiblePerson.fullName}</p>
                <p className="text-gray5">
                  <small>{t('RouteDescriptionComponent.responsiblePerson', 'odpovědná osoba')}</small>
                </p>
              </PanelWithIcon>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
