import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { formatCarrierLocation, formatFromToAPITime, formatPrice } from '@utils/utils';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { LocationTimeslotQuoteDemandOutDTO, MyQuoteInfoOutDTO, QuoteDetailOutDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { DefaultFormPanel } from '@components/obsolete/DefaultFormPanel/DefaultFormPanel.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import TimeList from '@components/obsolete/TimeList/TimeList.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import EditQuoteSidemodal from '@app/pages/carrier/demand-detail/ChangeQuote/EditQuoteSidemodal.tsx';
import RejectDialogComponent from '@app/pages/carrier/demand-detail/RejectDialog/RejectDialogComponent.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import RingilInputNumber from '@components/framework/input/RingilInputNumber.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';

interface ComponentProps {
  onReject: () => void;
  enableActions: boolean;
  createQuote: (customerId: string, applicationId: string, quote: MyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => void;
  state: QuoteDetailOutDTO;
  code: string;
  manipulationUnits?: number;
  ladenFactor?: number;
}

const ChangeQuoteComponent: React.FC<ComponentProps> = ({
                                                          onReject,
                                                          enableActions,
                                                          state,
                                                          createQuote,
                                                          code,
                                                          manipulationUnits,
                                                          ladenFactor,
                                                        }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [rejectDialogVisible, setRejectDialogVisible] = useState(false);

  const createNewQuote = (quote: MyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => {
    createQuote(state.companyId, state.applicationId, quote, timeslots);
    setEditModalVisible(false);
  };

  return (
    <>
      <div className="p-col-12 p-lg-6">
        <DefaultFormPanel>
          {ladenFactor && (
            <>
              <COInlineMessage
                variant={'info'}>{tr('CreateSpotComponent.ladenFactorInfo', 'Requested is {{manipulationUnits}} MU. Customer set laden factor to {{ladenFactor}} MU', {
                manipulationUnits: manipulationUnits,
                ladenFactor: ladenFactor,
              })}
              </COInlineMessage>
              <VerticalFieldAndValue
                label={tr('CreateSpotComponent.deliveredUnits', 'How many units will be delivered')}
                labelMode={'normal'}
                required={true}
                helpText={tr('CreateSpotComponent.deliveredUnitsHelp', 'How many units will be delivered')}
              >
                <small>
                  <strong>{state.myQuoteInfo.myQuoteManipulationUnits}</strong>
                </small>
              </VerticalFieldAndValue>
            </>
          )}
          <NavTitle wrapper="PADDING-0">
            <div>
              {t(`ChangeQuoteComponent.yourPriceQuote`, `Your price quote`)}
              <div className="p-m-0">
                <small>
                  <strong>{formatPrice(state.myQuoteInfo.myQuotePrice, state.myQuoteInfo.myQuoteCurrency)}</strong>
                </small>
              </div>
            </div>
            {enableActions && (
              <Button
                label={t(`ChangeQuoteComponent.change`, `Change`)}
                className="p-button-outlined p-btn-edit p-btn-edit-tab"
                iconPos="left"
                icon="pi pi-pencil"
                onClick={() => setEditModalVisible(true)}
              />
            )}
          </NavTitle>
          <div className="p-mt-3 p-mb-3">
            <div>{t(`ChangeQuoteComponent.validTill`, `Vaše nabídka platí do`)}</div>
            <div>
              <small>{dateFormatDateTime(mapFromAPIDateTime(state.myQuoteInfo.myQuoteValidTill))}</small>
            </div>
          </div>

          <NavTitle>
            <div>{t(`ChangeQuoteComponent.acceptedSlots`, `Accepted slots`)}</div>
          </NavTitle>

          {/* 1 */}
          {state.timeslots.map((timeslotLocation, index) => {
            return (
              <div className="timeslots-wrapper p-mt-4" key={index}>
                <PanelWithIcon numberIcon={`${index + 1}`} wrapper="PADDING_BOTTOM_0">
                  <Label
                    title={formatCarrierLocation(timeslotLocation.zipCode, timeslotLocation.city, timeslotLocation.country)} />
                </PanelWithIcon>
                <div className="time-shell">
                  <TimeList>
                    {timeslotLocation.timeslots.map((timeslot, index) => {
                      return (
                        <TimeListItem
                          key={index}
                          date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                          isWholeDay={timeslot.isNotSpecifiedTime}
                          time={formatFromToAPITime(timeslot.sinceInUTC, timeslot.tillInUTC)}
                          selected={timeslot.isSelected}
                        />
                      );
                    })}
                  </TimeList>
                </div>
              </div>
            );
          })}

          {enableActions && (
            <>
              <hr />
              <NavTitle>
                <div>{t(`ChangeQuoteComponent.cancelPrice`, `Cancel price`)}</div>
                <Button
                  label={t(`ChangeQuoteComponent.reject`, `Reject`)}
                  className="p-button-outlined p-btn-edit p-btn-edit-tab"
                  iconPos="left"
                  icon="pi pi-times"
                  onClick={() => setRejectDialogVisible(true)}
                />
              </NavTitle>
            </>
          )}
        </DefaultFormPanel>
        {state.myQuoteInfo.myQuoteValidTill && (
          <EditQuoteSidemodal
            visible={editModalVisible}
            onHide={() => setEditModalVisible(false)}
            myQuoteInfo={state.myQuoteInfo}
            timeslots={state.timeslots}
            code={code}
            // FIXME mvlach - change objects correctly
            // @ts-ignore
            onNewQuote={(quote, timeslots) => createNewQuote(quote, timeslots)}
            manipulationUnits={manipulationUnits}
            ladenFactor={ladenFactor}
          />
        )}

        <RejectDialogComponent visible={rejectDialogVisible} onHide={() => setRejectDialogVisible(false)}
                               onReject={() => onReject()} disabled={false} />
      </div>
    </>
  );
};

export default ChangeQuoteComponent;
