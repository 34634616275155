import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import SvgLkw from '@components/ringil3/icons/heading/Lkw.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import SvgETransportSend from '@components/features/eTransport/icons/ETransportSend.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgPencil from '@components/framework/icons/Pencil.tsx';
import SvgCalendar from '@components/framework/icons/Calendar.tsx';
import CardSection from '@components/framework/card/CardSection.tsx';
import { HorizontalLine } from '@components/styles.tsx';
import SvgTruck from '@components/framework/icons/Truck.tsx';
import SvgUnion from '@components/framework/icons/Union.tsx';
import { ETransportDTO } from '@api/logsteo-api.v2.tsx';
import { createI18NKey } from '@components/features/eTransport/input/ETransportInputTransportMode.tsx';
import { eTransportBorderCrossingItemLabel } from '@components/features/eTransport/input/EtransportBorderCrossingDropdown.tsx';
import { eTransportItemRegionLabel } from '@components/features/eTransport/input/ETransportInputRegion.tsx';
import ETransportReferenceNoTag from '@components/features/eTransport/tags/ETransportReferenceNoTag.tsx';
import ETransportTransportCompany from '@components/features/eTransport/tags/ETransportTransportCompany.tsx';
import ETransportLicencePlateTag from '@components/features/eTransport/tags/ETransportLicencePlateTag.tsx';
import ETransportDocumentTypeTag from '@components/features/eTransport/tags/ETransportDocumentType.tsx';
import { formatLocalDate, isNotBlank, isNotNullOrUndefined } from '@utils/utils.tsx';
import UITBadge from '@components/features/eTransport/badges/UITBadge.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation.tsx';

interface ComponentProps {
  detail: ETransportDTO;
  onEdit: () => void;
  readOnly: boolean;
}

const ETransportDetail: React.FC<ComponentProps> = ({ detail, onEdit, readOnly }) => {
  const { tr } = useTranslationLgs();

  return (
    <div className={'flex flex-col gap-4'}>
      {isNotBlank(detail.errorMessage) && (
        <COInlineMessage variant={'error'} icon={<SvgWarningExclamation />}>
          {detail.errorMessage}
        </COInlineMessage>
      )}

      <div className={'flex flex-row justify-between'}>
        <COHeadline variant={'h2'} title={tr(`ETransportCreate.trasportInfo`, `Informace k přepravě`)} icon={<SvgLkw />} />
        {isNotNullOrUndefined(onEdit) && !readOnly && <RingilButton leftIcon={<SvgPencil />} mode={'outlined-big'} onClick={() => onEdit()} />}
      </div>

      <div className={'flex flex-row gap-2'}>
        <UITBadge value={detail?.uitCode} />
      </div>

      <div className={'flex flex-row gap-2'}>
        <ETransportReferenceNoTag value={detail.referenceNumber} onChange={v => {}} readonly />
        <ETransportTransportCompany value={detail.transportCompany} onChange={v => {}} readonly />
        <ETransportLicencePlateTag value={detail.licencePlate} onChange={v => {}} readonly />
        <ETransportDocumentTypeTag value={detail.document} onChange={v => {}} readonly />
      </div>

      <SectionPanel icon={<SvgETransportSend />} heading={tr(`ETransportDetail.transportHeading`, `O transportu`)}>
        <div className={'flex flex-row gap-4'}>
          <Card>
            <div className={'flex flex-col gap-4'}>
              <CardSection icon={<SvgMapPin />} title={tr(`ETransportDetail.supplier`, `Dodavatel`)}>
                <div>{detail.supplierName}</div>
              </CardSection>
              <CardSection icon={<SvgCalendar />} title={tr(`ETransportDetail.transportDate`, `Datum transportu`)}>
                <div>{formatLocalDate(detail.transportDate)}</div>
              </CardSection>
              <HorizontalLine variant={'tertiary'} />
              <CardSection icon={<SvgMapPin />} title={tr(`ETransportDetail.transportMode`, `Transport mode`)}>
                <div>{tr(createI18NKey(detail.transportMode), createI18NKey(detail.transportMode))}</div>
              </CardSection>
              <CardSection icon={<SvgMapPin />} title={tr('ETransportDetail.adressOrigin', 'Adress origin')}>
                <div>{eTransportBorderCrossingItemLabel(detail.originAddress.borderId)}</div>
                <div>{detail.originAddress.town}</div>
                <div>{detail.originAddress.street}</div>
                <div>{eTransportItemRegionLabel(detail.originAddress.regionId)}</div>
              </CardSection>
              <CardSection icon={<SvgMapPin />} title={tr('ETransportDetail.adressDestination', 'Adress destination')}>
                <div>{eTransportBorderCrossingItemLabel(detail.destinationAddress.borderId)}</div>
                <div>{detail.destinationAddress.town}</div>
                <div>{detail.destinationAddress.street}</div>
                <div>{eTransportItemRegionLabel(detail.destinationAddress.regionId)}</div>
              </CardSection>
            </div>
          </Card>
          <Card>
            <div className={'flex flex-col gap-4'}>
              <COHeadline variant={'h3'} title={tr('ETransportDetail.manipulace', 'Manipulace')} icon={<SvgTruck />} />
              <div className={'flex flex-col gap-4'}>
                {/*                <div className={'flex flex-row gap-2'}>
                   <Badge title={'1 EUR'} value={'4.96 RON'} />
                   <Badge title={'100 PLZ'} value={'1.96 RON'} />
                   <Badge title={'100 EUR'} value={'4.96 RON'} />
                  </div>*/}
                <div className={'flex flex-col gap-4'}>
                  {detail.products.map((product, pIndex) => {
                    return (
                      <CardSection
                        key={pIndex}
                        icon={<SvgUnion />}
                        title={tr(`ETransportDetail.productWithQuantity`, `{{productName}} ({{quantity}} ks)`, {
                          productName: product.name,
                          quantity: product.quantity,
                        })}
                        mode={'compact'}
                      >
                        <div>EAN: {product.ean}</div>
                        <div>{tr(`ETransportDetail.weight`, `Hmotnost: {{weight}} kg`, { weight: product.weightNetto })}</div>
                        <div>
                          {tr(`ETransportDetail.values`, `Hodnota bez DPH: {{price}} {{currency}} ({{recomputedPrice}} {{recomputedCurrency}})`, {
                            price: product.priceExVat.price,
                            currency: product.priceExVat.currency,
                            recomputedPrice: product.priceExVat.companyComputedPrice,
                            recomputedCurrency: product.priceExVat.companyComputedCurrency,
                          })}
                        </div>
                      </CardSection>
                    );
                  })}

                  {/*<MoreLink title={'Zobrazit vše'} />*/}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </SectionPanel>
    </div>
  );
};

export default ETransportDetail;
