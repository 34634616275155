import React from 'react';
import styled from 'styled-components';

interface ComponentProps {
  columns?: Column[];
  renderers: RenderFunction[];
  data: any[];
}

interface Column {
  name: string;
  width?: string;
}

type RenderFunction = (row: any, index: number) => JSX.Element;

const RingilDataTable: React.FC<ComponentProps> = ({ columns, renderers, data }) => {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            {columns.map((col, colIndex) => {
              return (
                <Th key={colIndex}
                    style={col.width ? { width: col.width } : undefined}>
                  <ColName>{col.name}</ColName>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, itemIndex) => {
            return (
              <Tr key={itemIndex}>
                {renderers?.map((renderer, rendererIndex) => {
                  return <Td key={rendererIndex}>{renderer(item, itemIndex)}</Td>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const ColName = styled.div`
  font-weight: bold;
  margin-bottom: 0;
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 10px; /* Nastaví zaoblené rohy tabulky */
  overflow: hidden; /* Zajistí, že obsah tabulky nebude přesahovat zaoblené rohy */
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem 1rem;

  :first-child {
    border-top-left-radius: 10px; /* Zaoblený levý horní roh */
  }

  :last-child {
    border-top-right-radius: 10px; /* Zaoblený pravý horní roh */
  }
`;

const Td = styled.td``;

const Tr = styled.tr`
  background-color: white;

  ${Td} {
    padding: 2rem 1rem;
  }

  :last-child td:first-child {
    border-bottom-left-radius: 10px; /* Zaoblený levý spodní roh */
  }

  :last-child td:last-child {
    border-bottom-right-radius: 10px; /* Zaoblený pravý spodní roh */
  }
`;

const Tbody = styled.tbody``;

const Thead = styled.thead`
  ${Tr} {
    background-color: var(--ringil3-content-quaternary);
  }
`;

export default RingilDataTable;
