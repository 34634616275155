import React, { useContext, useEffect, useState } from 'react';
import { LovWithoutIdDTO } from '@/api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  selectedCurrenry: string;
  onSelected: (currency: string) => void;
  disabled?: boolean;
  required?: boolean;
  style?: any;
  code?: string;
}

const CurrencyDropdown: React.FC<ComponentProps> = ({ selectedCurrenry, onSelected, style, disabled = false, required = true, code }) => {
  const { lovCurrency } = useContext(ApiContext);
  const [currencies, setCurrencies] = useState<LovWithoutIdDTO[]>();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    lovCurrency(data => {
      setCurrencies(data);
    }, code);
  }, []);

  useEffect(() => {
    if (!disabled && isNotNullOrUndefined(loggedUser) && isNullOrUndefined(selectedCurrenry) && required) {
      onSelected(loggedUser.defaultCurrencyIsoCode);
    } else if (isNotNullOrUndefined(selectedCurrenry)) {
      onSelected(selectedCurrenry);
    } else {
      onSelected('CZK');
    }
  }, [currencies, loggedUser]);

  return (
    <Tweak>
      <Dropdown
        options={currencies}
        optionValue={'code'}
        optionLabel={'code'}
        onChange={e => onSelected(e.value)}
        value={selectedCurrenry}
        disabled={disabled}
        style={{ width: '110px' }}
        showClear={!required}
      />
    </Tweak>
  );
};

const Tweak = styled.div`
  .p-inputtext {
    display: flex;
    height: 40px;
    padding: 0 1rem;
    align-items: center;
  }
`;

export default CurrencyDropdown;
