import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import { ApiContext } from '@api/api';
import { LovDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import useDispatchers from '@hooks/dispatchers/dispatchers.tsx';
import useDrivers from '@hooks/drivers/drivers.tsx';
import { Label } from '@components/styles.tsx';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import useCarPlate from '@hooks/useCarPlate/useCarPlate.tsx';
import EditableDropdown, { DropdownItem } from '@components/ringil3/EditableDropdown/EditableDropdown.tsx';
import styled from 'styled-components';

interface ComponentProps {
  onComplete: () => void;
  data: ComponentData;
  expeditionId: string;
  showDriver: boolean;
  showDispatcher: boolean;
}

export interface ComponentData {
  carPlate: string;
  dispatcherId: string;
  driverId: string;
}

export const useCreateChangeVehicleInfo = () => {
  const [visible, setVisible] = useState(false);

  const ChangeVehicleInfo: React.FC<ComponentProps> = ({
                                                         expeditionId,
                                                         data,
                                                         onComplete,
                                                         showDispatcher,
                                                         showDriver,
                                                       }) => {
    const { t } = useTranslation('common');
    const dispatchers = useDispatchers(expeditionId);
    const drivers = useDrivers(expeditionId);
    const { expEditCarInfo } = useContext(ApiContext);
    const [plates, handleDeletePlate] = useCarPlate();
    const [selectedOptions, setSelectedOptions] = useState<DropdownItem[]>([]);
    const names = proxiedPropertiesOf<ComponentData>();

    const validationSchema = yup.object().shape({
      carPlate: yup.string().required(),
      dispatcherId: showDispatcher ? yup.string().required() : yup.string().nullable(),
      driverId: showDriver ? yup.string().required() : yup.string().nullable(),
    });

    const save = (d: ComponentData) => {
      expEditCarInfo(expeditionId, d, () => {
        setVisible(false);
        onComplete();
      });
    };

    const form = useForm<ComponentData>(
      validationSchema,
      data,
      d => {
        save(d);
      },
      false,
      false,
    );

    useEffect(() => {
      if (form.values.carPlate) {
        const initialPlates = getInitialSelectedPlates(form.values.carPlate, plates);
        setSelectedOptions(initialPlates);
      }
    }, [form.values.carPlate, plates]);

    const getInitialSelectedPlates = (carPlate: string, plates: DropdownItem[]): DropdownItem[] => {
      if (!carPlate) return [];

      const plateNumbers = carPlate.split(' || ');
      return plateNumbers.map(plateNumber => {
        const existingPlate = plates.find(plate => plate.number === plateNumber);
        if (existingPlate) {
          return existingPlate;
        }
        // Create new DropdownItem if not found
        return {
          id: plateNumber,
          number: plateNumber,
        };
      });
    };

    const handleSelectPlate = (plates: DropdownItem[]) => {
      setSelectedOptions(plates);
      form.setFieldValue(names.carPlate, plates.map(p => p.number).join(' || '));
    };

    // Handle plate deletion
    const handleDropDownDelete = (plate: DropdownItem) => {
      try {
        handleDeletePlate(plate.id).catch(error => {
          console.error('Failed to delete plate:', error);
        });
      } catch (error) {
        console.error('Error in handleDropDownDelete:', error);
      }
    };

    return (
      <>
        {/*//
      @ts-ignore*/}
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
        >
          <h3 className="p-mb-5" style={{ fontWeight: 'normal' }}>
            {t(`ChangeVehicleInfo.assignedCar`, `Assigned car`)}
          </h3>
          <div className="group-wrapper group-assign-vehicle">
            <div className="p-field">
              <div className="p-field">
                <EditableDropdown
                  options={plates}
                  selectedOptions={selectedOptions}
                  onSelect={(plates: DropdownItem[]) => handleSelectPlate(plates)}
                  onDelete={(plate: DropdownItem) => handleDropDownDelete(plate)}
                  placeholder="Search car plates..."
                  label="SPZ"
                  multiSelect={true}
                  mandatory={true}
                  note={
                    <Note>
                      <b>{t(`InputPlate.note1`, `Máte auto s přívěsem?`)}</b>
                      <p>{t(`InputPlate.note2`, `Např. tahač SPZ 0001 | 1. přívěs SPZ 0002 | 2. přívěs SPZ 00003`)} </p>
                      <p>{t(`InputPlate.note3`, `Oddělte SPZ čárkami: SPZ 0001, SPZ 0002. SPZ 0003`)}</p>
                    </Note>
                  }
                />
                <ValidationDiv errors={form.validationErrors} path={'carPlate'} />
              </div>
            </div>
            {showDriver && (
              <div className="p-field">
                <Label title={t('ChangeVehicleInfo.driver', 'Driver')} />
                <Dropdown
                  value={form.values.driverId}
                  options={drivers}
                  dataKey={'id'}
                  onChange={e => form.setFieldValue(names.driverId, e.value)}
                  optionLabel="name"
                  optionValue="id"
                  filter={true}
                  itemTemplate={(option: LovDTO) => (
                    <>
                      {option.active ? (
                        <span>{option.name}</span>
                      ) : (
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: 'gray',
                          }}
                        >
                          {option.name}
                        </span>
                      )}
                    </>
                  )}
                  placeholder={t('ChangeVehicleInfo.chooseDriver', 'Choose driver')}
                />
                <ValidationDiv errors={form.validationErrors} path={names.driverId} />
              </div>
            )}

            {showDispatcher && (
              <div className="p-field">
                <Label title={t('ChangeVehicleInfo.dispatcher', 'Dispatcher')} />
                <Dropdown
                  value={form.values.dispatcherId}
                  options={dispatchers}
                  dataKey={'id'}
                  onChange={e => form.setFieldValue(names.dispatcherId, e.value)}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t('ChangeVehicleInfo.chooseDispatcher', 'Choose dispatcher')}
                />
                <ValidationDiv errors={form.validationErrors} path={names.dispatcherId} />
              </div>
            )}
          </div>
        </RingilSidebar>
      </>
    );
  };

  return { ChangeVehicleInfo, setVisible };
};

const Note = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.78rem;

  b {
    font-weight: 700;
    color: black;
    margin-top: 0.3rem;
  }

  p {
    margin: 0;
  }
`;
