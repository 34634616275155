import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgMoreButton from '@components/ringil3/icons/buttons/MoreButton';
import SvgXcircle from '@icons/Xcircle.tsx';
import { useCreateCancelSupplierNotificationSidebar } from '@app/pages/customer/supplier-notification/sidebars/CancelSupplierNotificationSidebar';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  supplierNotificationId: string;
  isSupplierNotificationCanceled: boolean;
  onCanceledSupplierNotification: () => void;
}

const SupplierNotificationMoreButton: React.FC<ComponentProps> = ({
  supplierNotificationId,
  isSupplierNotificationCanceled,
  onCanceledSupplierNotification,
}) => {
  const { tr } = useTranslationLgs();

  const { CancelSupplierNotificationSidebar, setCancelationRequest, cancelationRequest } = useCreateCancelSupplierNotificationSidebar();

  const [open, setOpen] = useState(false);
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const model = [
    {
      label: tr('SupplierNotificationMoreButton.cancel', 'Cancel supplier notification'),
      icon: <SvgXcircle />,
      command: () => {
        setCancelationRequest({
          supplierNotificationId,
        });
      },
      visible: !isSupplierNotificationCanceled,
    },
  ];

  return (
    <>
      <Details
        ref={detailsRef}
        onClick={e => {
          if (open) {
            return;
          }
          setOpen(!open);
          e.preventDefault();
        }}
        open={open}
      >
        <Summary>
          <SvgMoreButton />
        </Summary>
        <Menu>
          <ul>
            {model
              .filter(t => t.visible)
              .map((item, itemIndex) => {
                return (
                  <li
                    key={itemIndex}
                    className={'cursor-pointer '}
                    onClick={() => {
                      setOpen(false);
                      item.command();
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </li>
                );
              })}
          </ul>
        </Menu>
      </Details>
      {isNotNullOrUndefined(cancelationRequest) && (
        <CancelSupplierNotificationSidebar
          onContinue={() => {
            onCanceledSupplierNotification();
          }}
        />
      )}
    </>
  );
};

const Summary = styled.summary`
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff; /* Barva pozadí uvnitř kruhu */
  border-radius: 50%;
  padding: 0;
  border: 2px solid var(--ringil3-brand-color);
  position: relative;
  box-shadow: 0 12px 16px rgba(254, 80, 0, 0.1);
`;

const Details = styled.details`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.4rem;
  background: white;
  border: 1px solid #ccc;
  z-index: 1001;
  padding: 10px;
  min-width: 150px;
  border-radius: 0.5rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      cursor: pointer;
      white-space: nowrap;
      border-bottom: 1px solid var(--content-quaternary);

      &:hover {
        background: var(--ringil-base-quaternarycolor);
      }
    }

    li:last-child {
      border-bottom: none;
    }
  }
`;

export default SupplierNotificationMoreButton;
