import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import SvgMoreButton from '@components/ringil3/icons/buttons/MoreButton';
import { isNotNullOrUndefined, openMailClientCustoms } from '@utils/utils.tsx';
import { AdditionalCostsSidebar } from '@components/obsolete/AdditionalCostsSidebar/AdditionalCostsSidebar.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import DuplicateExpeditionDialog from '@components/obsolete/DuplicateExpedition/DuplicateExpeditionDialog.tsx';
import { ApiContext } from '@api/api.tsx';
import SvgDuplicate from '@components/ringil3/icons/menu/reservation/Duplicate.tsx';
import SvgXcircle from '@icons/Xcircle.tsx';
import SvgAlert from '@icons/Alert.tsx';
import SvgMessage from '@icons/Message.tsx';
import SvgArchive from '@icons/Archive.tsx';
import SvgCheckSquare from '@icons/CheckSquare.tsx';
import SvgNumber from '@icons/Number.tsx';
import SvgCoins from '@icons/Coins.tsx';
import {
  useCreateDuplicateExpeditionSidebar,
} from '@components/ringil3/Features/Expedition/DuplicateExpeditionSidebar/DuplicateExpeditionSidebar.tsx';
import {
  useCreateCancelExpeditionSidebar,
} from '@components/ringil3/Features/Expedition/Sidebars/CancelExpeditionSidebar.tsx';
import SvgCarPlate from '@components/ringil3/icons/CarPlate.tsx';
import {
  useCreateAssignCarrierSidebar,
} from '@components/ringil3/Features/Expedition/Sidebars/AssignCarrierSidebar.tsx';
import SvgTabAttachments from '@components/ringil3/icons/TabAttachments.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { FeaturesEnum } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  customerId: string;
  applicationId: string;
  isExpeditionCanceled: boolean;
  onCanceledExpedition: () => void;
  onSwitchedToManualExpedition: (newApplicationId: string) => void;
  onAddedExtraCosts: () => void;
  currency: string;
  showExtraCosts: boolean;
  inStateNew: boolean;
  onManuallyAssignedCarrier: () => void;
  assignedDispatcherEmail?: string;
  isArchived: boolean;
  onArchivedChanged: () => void;
  canBeFinished: boolean;
  isDistributionCreated: boolean;
  expeditionId: string;
  linkedToSN: boolean;
  firstLocationName: string;
  refreshAttachment?: () => void;
}

const ExpeditionMoreButtons: React.FC<ComponentProps> = ({
                                                           customerId,
                                                           applicationId,
                                                           isExpeditionCanceled,
                                                           onCanceledExpedition,
                                                           onAddedExtraCosts,
                                                           currency,
                                                           showExtraCosts,
                                                           onManuallyAssignedCarrier,
                                                           assignedDispatcherEmail,
                                                           isArchived,
                                                           onArchivedChanged,
                                                           canBeFinished,
                                                           isDistributionCreated,
                                                           expeditionId,
                                                           linkedToSN,
                                                           firstLocationName,
                                                           refreshAttachment = () => {
                                                           },
                                                         }) => {
  const { tr } = useTranslationLgs();
  const {
    changeArchiveStateForExpedition,
    finishExpedition,
    reindexOneExpedition,
    getCustomsForExpedition,
    createOrderConfirmationPdf,
  } = useContext(ApiContext);

  const { loggedUser } = useContext(AuthenticatedUserContext);

  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [visibleConfirmArchiveWithSNDialog, setVisibleConfirmArchiveWithSNDialog] = useState(false);
  const [visibleDuplicateDialog, setVisibleDuplicateDialog] = useState(false);

  const [visibleAdditionalCosts, setVisibleAdditionalCosts] = useState(false);

  const [visibleFinishDialog, setVisibleFinishDialog] = useState(false);

  const { CancelExpeditionSidebar, setCancelationRequest, cancelationRequest } = useCreateCancelExpeditionSidebar();
  const { AssignCarrierSidebar, setVisible: setVisibleAssignCarrierSidebar } = useCreateAssignCarrierSidebar();

  const {
    DuplicateExpeditionSidebar,
    visible: visibleDuplicateSidebar,
    setVisible: setVisibleDuplicateSidebar,
  } = useCreateDuplicateExpeditionSidebar();

  const [open, setOpen] = useState(false);
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const text = `Dobr%C3%BD%20den%2C%0D%0A%0D%0Adovolujeme%20si%20v%C3%A1m%20ozn%C3%A1mit%2C%20%C5%BEe%20z%C3%A1silka%20%C4%8D%C3%ADslo%20${applicationId}%20byla%20doru%C4%8Dena%20dne%20(!!!DOPLNIT)%20ve%20stavu%3A%20po%C5%A1kozen%C3%A1%20%2F%20nekompletn%C3%AD.%0D%0A%0D%0AZ%C3%A1silku%20aktu%C3%A1ln%C4%9B%20zpracov%C3%A1v%C3%A1%20n%C3%A1%C5%A1%20sklad.%20Jakmile%20bude%20dokon%C4%8Dena%20kontrola%20produkt%C5%AF%2C%20sd%C4%9Bl%C3%ADme%20v%C3%A1m%20v%C3%BDsledek%20reklamace.%0D%0A%0D%0AS%20pozdravem`;

  const openMailClientCustomsForExp = () => {
    openMailClientCustoms(
      getCustomsForExpedition,
      expeditionId,
      firstLocationName,
      applicationId,
    );
  };

  const hasOrderConfirmationPdfFeature = loggedUser?.features?.some(t => t === FeaturesEnum.PDF_ORDER_CONFIRMATION);

  const model = [
    {
      label: tr('AllExpedition.duplicateConfigurable', 'Duplikovat...'),
      icon: <SvgDuplicate />,
      command: () => setVisibleDuplicateDialog(true),
      visible: true,
    },
    {
      label: tr('AllExpedition.mutliDuplicateConfigurable', 'Duplikovat více...'),
      icon: <SvgDuplicate />,
      command: () => setVisibleDuplicateSidebar(true),
      visible: true,
    },
    {
      label: tr('AllExpedition.cancel', 'Cancel'),
      icon: <SvgXcircle />,
      command: () => {
        setCancelationRequest({
          applicationId,
          customerId,
        });
      },
      visible: !isExpeditionCanceled,
    },
    {
      label: tr(`ButtonMoreNavigation.extraCosts`, `Vícenáklady`),
      icon: <SvgCoins />,
      command: () => setVisibleAdditionalCosts(true),
      visible: showExtraCosts,
    },
    {
      label: tr(`ButtonMoreNavigation.assignManually`, `Priradit dopravce`),
      icon: <SvgCarPlate />,
      command: () => setVisibleAssignCarrierSidebar(true),
      visible: !isDistributionCreated,
    },
    {
      label: tr(`ButtonMoreNavigation.claim`, `Claim`),
      icon: <SvgAlert fill={'var(--ringil3-content-secondary)'} />,
      command: () => window.open(`mailto:${assignedDispatcherEmail ? assignedDispatcherEmail : ''}?subject=REKLAMACE%20PŘEPRAVY%20${applicationId}&body=${text}`),
      visible: true,
    },
    {
      label: tr(`ButtonMoreNavigation.notifyWarehouse`, `Notify warehouse`),
      icon: <SvgMessage />,
      command: () => {
        openMailClientCustomsForExp();
      },
      visible: true,
    },
    {
      label: tr(`ButtonMoreNavigation.archive`, `Archive`),
      icon: <SvgArchive />,
      command: () => setVisibleConfirmDialog(true),
      visible: !isArchived,
    },
    {
      label: tr(`ButtonMoreNavigation.archiveWithSN`, `Archive with SN`),
      icon: <SvgArchive />,
      command: () => setVisibleConfirmArchiveWithSNDialog(true),
      visible: !isArchived && linkedToSN,
    },
    {
      label: tr(`ButtonMoreNavigation.unarchive`, `Unarchive`),
      icon: <SvgArchive />,
      command: () => setVisibleConfirmDialog(true),
      visible: isArchived,
    },
    {
      label: tr(`ButtonMoreNavigation.finish`, `Finish`),
      icon: <SvgCheckSquare />,
      command: () => setVisibleFinishDialog(true),
      visible: canBeFinished,
    },
    {
      label: tr(`ButtonMoreNavigation.index`, `Index`),
      icon: <SvgNumber />,
      command: () =>
        reindexOneExpedition(expeditionId, () => {
          // todo - prejmenovat na reload
          onCanceledExpedition();
        }),
      visible: true,
    },
    {
      label: tr(`ButtonMoreNavigation.generateConfirmationPDF`, `Generate confirmation PDF`),
      icon: <SvgTabAttachments />,
      command: () =>
        createOrderConfirmationPdf(expeditionId, () => {
          refreshAttachment();
        }),
      visible: hasOrderConfirmationPdfFeature,
    },

  ];

  const archive = () => {
    changeArchiveStateForExpedition(customerId, applicationId, {
      archive: !isArchived,
      archiveSN: false,
    }, () => {
      setVisibleConfirmDialog(false);
      onArchivedChanged();
    });
  };

  const archiveWithSN = () => {
    changeArchiveStateForExpedition(customerId, applicationId, {
      archive: !isArchived,
      archiveSN: true,
    }, () => {
      setVisibleConfirmArchiveWithSNDialog(false);
      onArchivedChanged();
    });
  };

  const finish = () => {
    finishExpedition(customerId, applicationId, () => {
      setVisibleFinishDialog(false);
      onCanceledExpedition();
    });
  };

  return (
    <>
      <Details
        ref={detailsRef}
        onClick={e => {
          if (open) {
            return;
          }
          setOpen(!open);
          e.preventDefault();
        }}
        open={open}
      >
        <Summary>
          <SvgMoreButton />
        </Summary>
        <Menu>
          <ul>
            {model
              .filter(t => t.visible)
              .map((item, itemIndex) => {
                return (
                  <li
                    key={itemIndex}
                    className={'cursor-pointer '}
                    onClick={() => {
                      setOpen(false);
                      item.command();
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </li>
                );
              })}
          </ul>
        </Menu>
      </Details>
      {isNotNullOrUndefined(cancelationRequest) && (
        <CancelExpeditionSidebar
          onContinue={() => {
            onCanceledExpedition();
          }}
        />
      )}

      {visibleAdditionalCosts && (
        <AdditionalCostsSidebar
          visible={visibleAdditionalCosts}
          onHide={() => setVisibleAdditionalCosts(false)}
          onComplete={() => {
            setVisibleAdditionalCosts(false);
            onAddedExtraCosts();
          }}
          customerId={customerId}
          currency={currency}
          applicationId={applicationId}
        />
      )}

      <AssignCarrierSidebar
        applicationId={applicationId}
        customerId={customerId}
        onContinue={() => {
          onManuallyAssignedCarrier();
        }}
      />
      <ConfirmDialog
        visible={visibleConfirmDialog}
        dialogTitle={
          isArchived
            ? tr(`ButtonMoreNavigation.unarchiveTitle`, `Do you want to unarchive this record?`)
            : tr(`ButtonMoreNavigation.archiveTitle`, `Do you want to archive this record?`)
        }
        dialogMessage={
          isArchived ? tr(`ButtonMoreNavigation.body1`, `Do you want to unarchive this record?`) : tr(`ButtonMoreNavigation.body2`, `Do you want to archive this record?`)
        }
        onCancel={() => setVisibleConfirmDialog(false)}
        onConfirm={() => archive()}
        onAcceptButtonText={isArchived ? tr(`ButtonMoreNavigation.yesUnarchive`, `Unarchive`) : tr(`ButtonMoreNavigation.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />

      <ConfirmDialog
        visible={visibleConfirmArchiveWithSNDialog}
        dialogTitle={
          isArchived
            ? tr(`ButtonMoreNavigation.unarchiveWithSNTitle`, `Do you want to unarchive this record?`)
            : tr(`ButtonMoreNavigation.archiveWithSNTitle`, `Do you want to archive this record?`)
        }
        dialogMessage={
          isArchived
            ? tr(`ButtonMoreNavigation.body1WithSN`, `Do you want to unarchive this record? `)
            : tr(`ButtonMoreNavigation.body2WithSN`, `Do you want to archive this record? Linked SN will be archived too.`)
        }
        onCancel={() => setVisibleConfirmArchiveWithSNDialog(false)}
        onConfirm={() => archiveWithSN()}
        onAcceptButtonText={isArchived ? tr(`ButtonMoreNavigation.yesUnarchive`, `Unarchive`) : tr(`ButtonMoreNavigation.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />

      <ConfirmDialog
        visible={visibleFinishDialog}
        dialogTitle={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        dialogMessage={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        onCancel={() => setVisibleFinishDialog(false)}
        onConfirm={() => finish()}
        onAcceptButtonText={tr(`ButtonMoreNavigation.finish`, `Finish`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />

      <ConfirmDialog
        visible={visibleFinishDialog}
        dialogTitle={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        dialogMessage={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        onCancel={() => setVisibleFinishDialog(false)}
        onConfirm={() => finish()}
        onAcceptButtonText={tr(`ButtonMoreNavigation.finish`, `Finish`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />
      <DuplicateExpeditionDialog
        applicationId={applicationId}
        visible={visibleDuplicateDialog}
        onCancel={() => setVisibleDuplicateDialog(false)}
        isLinkedToSN={linkedToSN}
      />
      {visibleDuplicateSidebar && <DuplicateExpeditionSidebar expeditionId={expeditionId} />}
    </>
  );
};

const Summary = styled.summary`
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff; /* Barva pozadí uvnitř kruhu */
  border-radius: 50%;
  padding: 0;
  border: 2px solid var(--ringil3-brand-color);
  position: relative;
  box-shadow: 0 12px 16px rgba(254, 80, 0, 0.1);
`;

const Details = styled.details`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.4rem;
  background: white;
  border: 1px solid #ccc;
  z-index: 1001;
  padding: 10px;
  min-width: 150px;
  border-radius: 0.5rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      cursor: pointer;
      white-space: nowrap;
      border-bottom: 1px solid var(--content-quaternary);

      &:hover {
        background: var(--ringil-base-quaternarycolor);
      }
    }

    li:last-child {
      border-bottom: none;
    }
  }
`;

export default ExpeditionMoreButtons;
