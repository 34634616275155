import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatPieces, formatPrice, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  expeditionCount: number;
  manipulationUnits: number;
}

const OrderedExpeditionsBadge: React.FC<ComponentProps> = ({ expeditionCount, manipulationUnits }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(expeditionCount) && (
        <Badge filled={false} value={tr(`OrderedExpeditionsBadge.value`, `{{expeditionCount}} expeditions | {{manipulationUnits}} MU`, {expeditionCount: expeditionCount, manipulationUnits: manipulationUnits})} highlightedValue={false} label={tr(`OrderedExpeditionsBadge.totalOrder`, `Ordered`)}></Badge>
      )}
    </>
  );
};

export default OrderedExpeditionsBadge;
