import LoadInfoList from '../LoadInfoList/LoadInfoList';
import LoadInfoItem from './LoadInfoItem';
import { Location } from '@components/obsolete/customer/new-expedition/types';
import { dumpVars, formatWeight, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  loadingLocations: Location[];
}

const LoadInfo: React.FC<ComponentProps> = ({ loadingLocations }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();

  return (
    <div className="load-info">
      <strong>{t(`LoadInfo.load`, `Co převážíte`)}</strong>
      <div className="load-info-wrapper">
        {loadingLocations &&
          loadingLocations?.map((location: Location, locationIndex) => {
            return location.loadingOrders.map(order => (
              <div className="p-grid" key={locationIndex}>
                <div className="p-col-12 p-md-3">
                  <LoadInfoItem bookingRef={order.name} totalWeight={formatWeight(order.totalWeight)} className="p-mb-3" />
                </div>
                <div className="p-col-12 p-md-4">
                  {order.items.map((orderItem, orderItemIndex) => {
                    return (
                      <>
                        <LoadInfoList
                          key={orderItemIndex}
                          title={`${orderItem.cargoItemType.name} (${orderItem.amount} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
                          description={weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
                          stackable={orderItem.stackable}
                          itemNote={orderItem.itemNote}
                        />
                      </>
                    );
                  })}
                </div>
              </div>
            ));
          })}
        {/*
        <div className="p-grid">
          <div className="p-col-12 p-md-3">
            <LoadInfoItem bookingRef="1223344" codeRef="Kód pro dopravce: 3R 445 H" />
          </div>
          <div className="p-col-12 p-md-4">
            <LoadInfoList title="Europaleta (8 ks)" description="3 980 kg" />
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default LoadInfo;
