import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { first, formatTons, formatWeight, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToTime } from '@utils/date';
import { CarrierQuoteStateEnum, DistributionTypeEnum, EntityTypeEnum, LocationTimeslotQuoteDemandOutDTO, TimeSlotQuoteDemandOutDTO } from '@api/logsteo-api.v2';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { DemandDetailContext } from '@app/pages/carrier/demand-detail/DemandDetailActions.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import TimeList from '@components/obsolete/TimeList/TimeList.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import DisplayLocation from '@components/obsolete/DisplayLocation/DisplayLocation.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import CarrierChatComponent from '@app/pages/carrier/Chat/CarrierChatComponent.tsx';
import EditSlotsComponent, { LocationTimeSlots, Slot } from '@app/pages/carrier/demand-detail/EditSlots/EditSlotsComponent.tsx';

interface ComponentProps {
  enableAction: boolean;
  locationDisplayPattern: string;
}

const RouteDescriptionComponent: React.FC<ComponentProps> = ({ enableAction, locationDisplayPattern }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();

  const { state, changeTimeSlots } = useContext(DemandDetailContext);
  const [visibleSlotEditDialog, setVisibleSlotEditDialog] = useState(false);
  const quoteState = state.distributionType === DistributionTypeEnum.SPOT ? state.carrierQuoteState : state.customerQuoteState;
  const editSlotsFirstTime = state.distributionType === DistributionTypeEnum.SPOT && quoteState === CarrierQuoteStateEnum.NEW;
  const [visibleChatDialog, setVisibleChatDialog] = useState(false);

  return (
    <>
      <div className="route-description p-grid">
        <div className="p-col-12 p-lg-5">
          <hr className="p-mt-0 p-mb-5" />
          <>
            <Label title={t('RouteDescriptionComponent.routePlan', 'Plán cesty')} />
            {state.timeslots.map((location, locationIndex: number) => {
              return (
                <div className="timeslots-wrapper" key={locationIndex}>
                  <PanelWithIcon numberIcon={`${locationIndex + 1}`} wrapper="SM">
                    <p>
                      <DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />
                    </p>
                  </PanelWithIcon>
                  <div className="time-shell">
                    <TimeList>
                      {location.timeslots.map((timeslot, timeslotIndex: number) => {
                        return (
                          <TimeListItem
                            key={timeslotIndex}
                            date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                            isWholeDay={timeslot.isNotSpecifiedTime}
                            time={`${mapFromAPIToTime(timeslot.sinceInUTC)} - ${mapFromAPIToTime(timeslot.tillInUTC)}`}
                            selected={timeslot.isSelected}
                          />
                        );
                      })}
                      {editSlotsFirstTime && !state.isDistributionExpired && !state.isAssignedToAnotherCarrier && (
                        <Button
                          className="p-button-outlined"
                          label={t(`RouteDescriptionComponent.iNeedADifferentTimeslot`, `Chci jiné čas. okno`)}
                          onClick={() => setVisibleSlotEditDialog(true)}
                          disabled={!enableAction}
                        />
                      )}
                    </TimeList>
                  </div>
                </div>
              );
            })}
          </>
          <div className="shipment-requirements-wrapper">
            <Label title={t('RouteDescriptionComponent.manipulationType', 'Manipulace')} />
            <p>
              <i className="p-mr-2 pi pi-check"></i>
              <span>{state.loadingTypes && state.loadingTypes.map((type: string) => t(`RouteDescriptionComponent.manipulatioType.${type}`)).join(', ')}</span>
            </p>
            <p>
              {state.requirements && (
                <>
                  <strong>{t('RouteDescriptionComponent.requirements', 'Požadavky')}:</strong>
                  {state.requirements.join(', ')}
                </>
              )}
            </p>
          </div>
          {state.items && state.items.length > 0 && (
            <div className="load-info-wrapper load-info-wrapper--sm  p-mt-4 p-mb-4 p-d-flex p-flex-wrap">
              {state.items.map((cargo, cargoIndex) => {
                return (
                  <LoadInfoList
                    key={cargoIndex}
                    title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                    description={formatTons(cargo.weight)}
                    stackable={cargo.stackable}
                    itemNote={cargo.itemNote}
                  />
                );
              })}
            </div>
          )}
          <div className="p-mt-5">
            {/*              <h3 className="p-mt-0 p-mb-5" style={{ fontWeight: 'normal' }}>
                {t('RouteDescriptionComponent.detailedRouteInformation', 'Detailní informace o trase')}
              </h3>*/}
            {state.locations.map((location, locationIndex: number) => {
              return (
                <React.Fragment key={locationIndex}>
                  <TimeslotsProgressTitle
                    number={`${locationIndex + 1}`}
                    address={<DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />}
                  />
                  {location.loadedOrders && location.loadedOrders.length > 0 && (
                    <div className="p-d-flex">
                      <PanelWithIcon srcIcon="/images/icon_pickup.png">
                        <Label title={t('RouteDescriptionComponent.pickUp', 'Nakládka')} />

                        {location.loadedOrders && location.loadedOrders.length > 0 && (
                          <>
                            {location.loadedOrders.map((loadedOrder, loadedOrderKey: number) => {
                              return (
                                <Col key={loadedOrderKey}>
                                  {loadedOrder.orderName && (
                                    <Row>
                                      <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                        <p>
                                          <small>{loadedOrder.orderName}</small>
                                        </p>
                                      </ListInfoItem>
                                      <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                        <p>
                                          <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                        </p>
                                      </ListInfoItem>
                                    </Row>
                                  )}
                                  {loadedOrder.items.map((cargo, cargoIndex: number) => {
                                    return (
                                      <LoadInfoList
                                        key={cargoIndex}
                                        title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                        description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                        stackable={cargo.stackable}
                                        itemNote={cargo.itemNote}
                                      />
                                    );
                                  })}
                                </Col>
                              );
                            })}
                          </>
                        )}
                      </PanelWithIcon>
                    </div>
                  )}
                  {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                    <div className="p-d-flex">
                      <PanelWithIcon srcIcon="/images/icon_dropoff.png">
                        <Label title={t('RouteDescriptionComponent.dropoff', 'Vykládka')} />

                        {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                          <>
                            {location.unloadedOrders.map((loadedOrder, loadedOrderKey: number) => {
                              return (
                                <Col key={loadedOrderKey}>
                                  {loadedOrder.orderName && (
                                    <Row>
                                      <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                        <p>
                                          <small>{loadedOrder.orderName}</small>
                                        </p>
                                      </ListInfoItem>
                                      <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                        <p>
                                          <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                        </p>
                                      </ListInfoItem>
                                    </Row>
                                  )}
                                  {loadedOrder.items.map((cargo, cargoIndex: number) => {
                                    return (
                                      <LoadInfoList
                                        key={cargoIndex}
                                        title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                        description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                        stackable={cargo.stackable}
                                        itemNote={cargo.itemNote}
                                      />
                                    );
                                  })}
                                </Col>
                              );
                            })}
                          </>
                        )}
                      </PanelWithIcon>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div className="p-mt-5">
            <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
              {t('RouteDescriptionComponent.customer', 'Poptávající')}
            </h3>
            <PanelWithIcon srcIcon="/images/contacts.svg">
              <Label title={state.customer.name} />
              <p>
                {state.customer.streetNr}, {state.customer.city}, {state.customer.country}
              </p>
              <p>
                {t('RouteDescriptionComponent.CrnAndTin', 'IČ: 28441345 | DIČ: CZ28441345', {
                  crn: state.customer.crn || '-',
                  tin: state.customer.tin || '-',
                })}
              </p>
            </PanelWithIcon>

            <PanelWithIcon srcIcon="/images/user.svg">
              <p>{state.responsiblePerson.fullName}</p>
              <p className="text-gray5">
                <small>{t('RouteDescriptionComponent.responsiblePerson', 'odpovědná osoba')}</small>
              </p>
            </PanelWithIcon>

            <PanelWithIcon wrapper="VERTICAL_ALIGNMENT" srcIcon="/images/envelope.svg">
              <Button
                icon="pi pi-envelope"
                className="p-button-outlined"
                label={t('RouteDescriptionComponent.writeMessage', 'Napsat zprávu')}
                onClick={() => setVisibleChatDialog(true)}
              />
            </PanelWithIcon>
          </div>
        </div>
      </div>
      <EditSlotsComponent
        visible={visibleSlotEditDialog}
        onHide={() => {
          setVisibleSlotEditDialog(false);
        }}
        locationsWithSlots={mapToTimeLocationTimeSlots(state.timeslots)}
        onConfirm={(data: LocationTimeSlots[]) => {
          changeTimeSlots(data);
          setVisibleSlotEditDialog(false);
        }}
      />
      <CarrierChatComponent
        companyId={state.companyId}
        entityId={state.expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={visibleChatDialog}
        onHide={() => setVisibleChatDialog(false)}
        customerName={state.customer.name}
      />
    </>
  );
};

const mapToTimeLocationTimeSlots = (location: LocationTimeslotQuoteDemandOutDTO[]): LocationTimeSlots[] => {
  return location.map(loc => {
    return {
      name: loc.locationName,
      city: loc.city,
      country: loc.country,
      zipCode: loc.zipCode,
      id: loc.locationId,
      useCustomSlots: loc.timeslots.some(ts => ts.isCarrier),
      customerSlots: loc.timeslots.filter(ts => ts.isCarrier === false).map(item => mapToSlot(item)),
      carrierSlot: first(loc.timeslots.filter(ts => ts.isCarrier === true).map(item => mapToSlot(item))),
    };
  });
};

export const mapToSlot = (slot: TimeSlotQuoteDemandOutDTO): Slot => {
  return {
    selectedDate: mapFromAPIDateTime(slot.sinceInUTC),
    sinceTime: mapFromAPIToTime(slot.sinceInUTC),
    tillTime: mapFromAPIToTime(slot.tillInUTC),
    isWholeDay: slot.isNotSpecifiedTime,
  };
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export default RouteDescriptionComponent;
