import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { ColWithGap, RowWithSpaceBetween } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import useForm from '@hooks/useForm/useForm';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { ActionTypesEnum, ComputeReservationDurationConfiguration, ReservationActionTypesEnum } from '@api/logsteo-api.v2';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { ColOdd, HorizontalLine } from '@components/ringil3/styles';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils';
import InputManipulationType from '@components/ringil3/Input/InputManipulationType/InputManipulationType';
import OrderTabs, { TabProps } from '@components/ringil3/RingilTabs/OrderTabs';
import { Order, orderSchema } from '@components/framework/orders/common';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { generateTimeslotOrderConfig } from '@components/ringil3/Features/Reservation/orderConfig.ts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  onRefresh: () => void;
  companyLocationCustomerId: string;
}

interface Form {
  actionTypes: ReservationActionTypesEnum[];
  orders: Order[];
}

export const useCreateEditReservationOrdersSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditReservationOrdersSidebar: React.FC<ComponentProps> = ({ reservationId, applicationId, onRefresh, companyLocationCustomerId }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { getReservationDetail, updateReservationOrders, getCreateEditReservationSettings } = useContext(ApiContext);
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const [settings, setSettings] = useState<ComputeReservationDurationConfiguration>();

    useLayoutEffect(() => {
      getCreateEditReservationSettings(companyLocationCustomerId, d => {
        setSettings(d);
      });
    }, []);

    useEffect(() => {
      const ac = new AbortController();

      getReservationDetail(
        reservationId,
        d => {
          if (ac.signal.aborted) return;

          form.overwriteValues({
            actionTypes: d.reservationActionTypes,
            orders:
              d.orders.length > 0
                ? d.orders.map(
                    t =>
                      ({
                        orderName: t.orderName,
                        orderValue: t.orderValue,
                        orderAmount: t.orderAmount,
                        orderDescription: t.orderDescription,
                        orderNote: t.orderNote,
                        eanCodes: t.loadedEans,
                        items: t.items.map(i => ({
                          amount: i.amount,
                          cargoTemplateCode: i.cargoTemplate?.code,
                          stackable: i.stackable,
                          itemNote: i.itemNote,
                          decimalPlaces: i.cargoTemplate?.decimalPlaces,
                        })),
                      } as Order),
                  )
                : [
                    {
                      items: [],
                      orderAmount: null,
                      orderDescription: null,
                      orderName: null,
                      orderNote: null,
                      orderValue: null,
                    },
                  ],
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
      return () => {
        ac.abort();
      };
    }, [reservationId]);

    const form = useForm<Form>(yup.object().shape({ orders: orderSchema }), null, d => save(d), false, false, null, 'scroll');

    const mapOrder = (order: Order) => {
      return {
        orderName: order.orderName,
        orderValue: order.orderValue,
        cargoAmount: order.orderAmount,
        cargoDescription: order.orderDescription,
        orderNote: order.orderNote,
        items: order.items.map(i => ({
          amount: i.amount,
          cargoTemplateCode: i.cargoTemplateCode,
          stackable: i.stackable,
          itemNote: i.itemNote,
          decimalPlaces: i.decimalPlaces,
        })),
      };
    };

    const save = (data: Form) => {
      updateReservationOrders(
        reservationId,
        {
          orders: data.orders.map(mapOrder),
          actionTypes: data.actionTypes.map(t => ActionTypesEnum[t]),
        },
        () => {
          setVisible(false);
          onRefresh();
        },
        null,
        { onValidationFailed: setBeValidationErrors },
      );
    };

    /*const addOrder = () => {
      form.setFieldValue(form.names.orders, [...form.values.orders, emptyOrder(false)]);
    };

    const deleteOrder = (orderIndex: number) => {
      form.setFieldValue(
        form.names.orders,
        form.values.orders.filter((_, i) => i !== orderIndex),
      );
    };

    const addOrderItem = (orderIndex: number) => {
      form.setFieldValue(`orders[${orderIndex}].items`, [
        ...form.values.orders[orderIndex].items,
        { itemNote: null, amount: null, cargoTemplateCode: null, stackable: false },
      ]);
    };

    const deleteOrderItem = (orderIndex: number, itemIndex: number) => {
      form.setFieldValue(
        `orders[${orderIndex}].items`,
        form.values.orders[orderIndex].items.filter((_, i) => i !== itemIndex),
      );
    };*/

    const isOwner = loggedUser.companyId === companyLocationCustomerId;
    const requiredOderItems = isOwner ? settings?.ownerManipulationItemsRequired : settings?.counterPartyManipulationItemsRequired;

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
            <RowWithSpaceBetween>
              <div>{tr('EditReservationOrdersSidebar.title', 'Edit reservation orders')}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map(t => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}
        >
          {isNotNullOrUndefined(form.values) && (
            <>
              <BackendValidationComponent />
              <ColWithGap>
                <VerticalFieldAndValueWithIcon
                  label={tr(`EditReservationSlotSidebar.forReservation`, `For reservation`)}
                  valueText={applicationId}
                  iconPath={'/images/icons/ringil3/forExpedition.svg'}
                />
                <HorizontalLine variant={'tertiary'} />
                <COHeadline variant={'h3'} title={tr(`EditReservationOrdersSidebar.cargo`, `Cargo`)}></COHeadline>
                <VerticalFieldAndValue label={tr(`EditReservationOrdersSidebar.manipulationType`, `Manipulation type`)} labelMode={'normal'}>
                  <InputManipulationType
                    loadingTypes={form.values.actionTypes}
                    onChange={v => {
                      form.setFieldValue(form.names.actionTypes, v);
                    }}
                  />
                </VerticalFieldAndValue>
                <OrderTabs
                  tabs={form.values.orders.map(
                    (_, oIndex) =>
                      ({
                        label: `${oIndex + 1}.`,
                        icon: null,
                        active: false,
                        anchorId: `order_${oIndex}`,
                        className: 'ordersTabs',
                      } as TabProps),
                  )}
                  contentSelectorClassName={'ordersTabsContent'}
                ></OrderTabs>
                <ColOdd>
                  <OrdersComponent
                    orders={form.values.orders}
                    config={generateTimeslotOrderConfig(requiredOderItems)}
                    onChange={v =>
                      form.useProduce(d => {
                        d.orders = v;
                      })
                    }
                    validationErrors={form.validationErrors}
                  />
                </ColOdd>
              </ColWithGap>
            </>
          )}
        </RingilSidebar>
      </>
    );
  };

  return { EditReservationOrdersSidebar, setVisible, visible };
};
