import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { isNotNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';
import { SplitButton } from 'primereact/splitbutton';
import { AdditionalCostsSidebar } from '@components/obsolete/AdditionalCostsSidebar/AdditionalCostsSidebar.tsx';
import ManuallyAssignWinCarrierSidemodal from '@components/obsolete/ManuallyAssignWinCarrier/ManuallyAssignWinCarrier.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import DuplicateExpeditionDialog from '@components/obsolete/DuplicateExpedition/DuplicateExpeditionDialog.tsx';
import { useCreateCancelExpeditionSidebar } from '@components/ringil3/Features/Expedition/Sidebars/CancelExpeditionSidebar.tsx';

interface ComponentProps {
  customerId: string;
  applicationId: string;
  isExpeditionCanceled: boolean;
  onCanceledExpedition: () => void;
  onSwitchedToManualExpedition: (newApplicationId: string) => void;
  onAddedExtraCosts: () => void;
  isDisabledByManualMode: boolean;
  currency: string;
  showExtraCosts: boolean;
  inStateNew: boolean;
  onManuallyAssignedCarrier: () => void;
  assignedDispatcherEmail?: string;
  isArchived: boolean;
  onArchivedChanged: () => void;
  canBeFinished: boolean;
  isDistributionCreated: boolean;
  expeditionId: string;
  linkedToSN: boolean;
}

export const ButtonMoreNavigation: React.FC<ComponentProps> = ({
  customerId,
  applicationId,
  isExpeditionCanceled,
  onCanceledExpedition,
  onSwitchedToManualExpedition,
  isDisabledByManualMode,
  currency,
  onAddedExtraCosts,
  showExtraCosts,
  inStateNew,
  onManuallyAssignedCarrier,
  assignedDispatcherEmail = '',
  isArchived,
  onArchivedChanged,
  canBeFinished,
  isDistributionCreated,
  expeditionId,
  linkedToSN,
}) => {
  const { tr } = useTranslationLgs();
  const { cuCancelExpedition, changeArchiveStateForExpedition, finishExpedition, reindexOneExpedition } = useContext(ApiContext);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [visibleConfirmArchiveWithSNDialog, setVisibleConfirmArchiveWithSNDialog] = useState(false);
  const [visibleDuplicateDialog, setVisibleDuplicateDialog] = useState(false);

  const [cancelExpeditionId, setCancelExpeditionId] = useState(undefined);
  const [switchToManualExpedition, setSwitchToManualExpedition] = useState(undefined);
  const [visibleAdditionalCosts, setVisibleAdditionalCosts] = useState(false);
  const [visibleManuallyAssignCarrier, setVisibleManuallyAssignCarrier] = useState(false);
  const [visibleFinishDialog, setVisibleFinishDialog] = useState(false);

  const { CancelExpeditionSidebar, setCancelationRequest, cancelationRequest } = useCreateCancelExpeditionSidebar();

  const text = `Dobr%C3%BD%20den%2C%0D%0A%0D%0Adovolujeme%20si%20v%C3%A1m%20ozn%C3%A1mit%2C%20%C5%BEe%20z%C3%A1silka%20%C4%8D%C3%ADslo%20${applicationId}%20byla%20doru%C4%8Dena%20dne%20(!!!DOPLNIT)%20ve%20stavu%3A%20po%C5%A1kozen%C3%A1%20%2F%20nekompletn%C3%AD.%0D%0A%0D%0AZ%C3%A1silku%20aktu%C3%A1ln%C4%9B%20zpracov%C3%A1v%C3%A1%20n%C3%A1%C5%A1%20sklad.%20Jakmile%20bude%20dokon%C4%8Dena%20kontrola%20produkt%C5%AF%2C%20sd%C4%9Bl%C3%ADme%20v%C3%A1m%20v%C3%BDsledek%20reklamace.%0D%0A%0D%0AS%20pozdravem`;

  const model = [
    {
      label: tr('AllExpedition.duplicateConfigurable', 'Duplikovat...'),
      icon: 'pi pi-refresh',
      command: () => setVisibleDuplicateDialog(true),
      visible: true,
    },
    /*{
      label: tr('AllExpedition.duplicate', 'Duplikovat'),
      icon: 'pi pi-refresh',
      command: (e) => {
        router.push(`/customer/expedition/duplicate/${applicationId}`);
      },
      visible: true,
    },
    {
      label: tr('AllExpedition.duplicateWithLink', 'Duplicate with link'),
      icon: 'pi pi-refresh',
      command: (e) => {
        router.push(`/customer/expedition/duplicate/${applicationId}?link=true`);
      },
      visible: linkedToSN,
    },*/
    {
      label: tr('AllExpedition.cancel', 'Cancel'),
      icon: 'pi pi-times',
      command: (e: any) => {
        setCancelationRequest({
          applicationId,
          customerId,
        });
      },
      visible: !isExpeditionCanceled && !isDisabledByManualMode,
    },
    {
      label: tr('AllExpedition.manualMode', 'Manuální režim'),
      icon: 'pi pi-times',
      command: (e: any) => {
        setSwitchToManualExpedition({
          applicationId: applicationId,
          companyId: customerId,
        });
      },
      visible: !isExpeditionCanceled && !isDisabledByManualMode,
    },
    {
      label: tr(`ButtonMoreNavigation.extraCosts`, `Vícenáklady`),
      icon: 'pi pi-money-bill',
      command: () => setVisibleAdditionalCosts(true),
      visible: showExtraCosts,
    },
    {
      label: tr(`ButtonMoreNavigation.assignManually`, `Priradit dopravce`),
      icon: 'pi pi-play',
      command: () => setVisibleManuallyAssignCarrier(true),
      visible: !isDistributionCreated,
    },
    {
      label: tr(`ButtonMoreNavigation.claim`, `Claim`),
      icon: 'pi pi-play',
      command: () => window.open(`mailto:${assignedDispatcherEmail ? assignedDispatcherEmail : ''}?subject=REKLAMACE%20PŘEPRAVY%20${applicationId}&body=${text}`),
      visible: true,
    },
    {
      label: tr(`ButtonMoreNavigation.notifyWarehouse`, `Notify warehouse`),
      icon: 'pi pi-play',
      command: () =>
        window.open(
          `mailto:?subject=Z%C3%81SILKA%20VYCLENA%20${applicationId}&body=V%C3%A1%C5%BEen%C3%AD%20kolegov%C3%A9%2C%0D%0A%0D%0AZ%C3%A1silka%20%C4%8D%3A%20%C4%8D%C3%ADslo%2F-a%20p%C5%99%C3%ADjmu%20z%20intern%C3%ADho%20syst%C3%A9mu%20(!!!DOPLNIT)%0D%0AOd%20dodavatele%3A%20%C4%8D%C3%ADslo%2F-a%20p%C5%99%C3%ADjmu%20z%20intern%C3%ADho%20syst%C3%A9mu%20(!!!DOPLNIT)%0D%0AByla%20vyclena%20a%20m%C5%AF%C5%BEete%20zah%C3%A1jit%20jej%C3%AD%20zpracov%C3%A1n%C3%AD%0D%0AIntern%C3%AD%20%C4%8D%C3%ADsla%20faktur%3A%20(!!!DOPLNIT)%0D%0A%0D%0AS%20pozdravem%0D%0A%0D%0AInbound%20team`,
        ),
      visible: true,
    },
    {
      label: tr(`ButtonMoreNavigation.archive`, `Archive`),
      icon: 'pi pi-play',
      command: () => setVisibleConfirmDialog(true),
      visible: !isArchived,
    },
    {
      label: tr(`ButtonMoreNavigation.archiveWithSN`, `Archive with SN`),
      icon: 'pi pi-play',
      command: () => setVisibleConfirmArchiveWithSNDialog(true),
      visible: !isArchived && linkedToSN,
    },
    {
      label: tr(`ButtonMoreNavigation.unarchive`, `Unarchive`),
      icon: 'pi pi-play',
      command: () => setVisibleConfirmDialog(true),
      visible: isArchived,
    },
    {
      label: tr(`ButtonMoreNavigation.finish`, `Finish`),
      icon: 'pi pi-play',
      command: () => setVisibleFinishDialog(true),
      visible: canBeFinished,
    },
    {
      label: tr(`ButtonMoreNavigation.index`, `Index`),
      icon: 'pi pi-play',
      command: () =>
        reindexOneExpedition(expeditionId, () => {
          // todo - prejmenovat na reload
          onCanceledExpedition();
        }),
      visible: true,
    },
  ];

  const cancelExpedition = () => {
    setCancelExpeditionId(undefined);
    onCanceledExpedition();
  };

  const archive = () => {
    changeArchiveStateForExpedition(
      customerId,
      applicationId,
      {
        archive: isArchived ? false : true,
        archiveSN: false,
      },
      () => {
        setVisibleConfirmDialog(false);
        onArchivedChanged();
      },
    );
  };

  const archiveWithSN = () => {
    changeArchiveStateForExpedition(
      customerId,
      applicationId,
      {
        archive: isArchived ? false : true,
        archiveSN: true,
      },
      () => {
        setVisibleConfirmArchiveWithSNDialog(false);
        onArchivedChanged();
      },
    );
  };

  const finish = () => {
    finishExpedition(customerId, applicationId, () => {
      setVisibleFinishDialog(false);
      onCanceledExpedition();
    });
  };

  return (
    <>
      <SplitButton model={model.filter(t => t.visible)} className="btn-more-info p-button-rounded p-button-outlined" />
      {isNotNullOrUndefined(cancelationRequest) && (
        <CancelExpeditionSidebar
          onContinue={() => {
            onCanceledExpedition();
          }}
        />
      )}

      {/*      <ConfirmDialog
        visible={!isNullOrUndefined(switchToManualExpedition)}
        dialogTitle={tr(`AllExpedition.switchToManualMode`, `Přepnout expedici do manuálního režimu`)}
        dialogMessage={tr(
          `AllExpedition.switchToManualModeDialogBody`,
          `Dejte pozor, po púootvrzeni už expedice nepújde přepnout zpět do automatického režimu.`,
        )}
        onCancel={() => setSwitchToManualExpedition(undefined)}
        onConfirm={() => createManualExpedition()}
        onAcceptButtonText={tr(`AllExpedition.yesToggle`, `Ano, přepnout`)}
        onCancelButtonText={tr(`AllExpedition.close`, `Zavřít`)}
        loading={true}
      />*/}
      {visibleAdditionalCosts && (
        <AdditionalCostsSidebar
          visible={visibleAdditionalCosts}
          onHide={() => setVisibleAdditionalCosts(false)}
          onComplete={() => {
            setVisibleAdditionalCosts(false);
            onAddedExtraCosts();
          }}
          customerId={customerId}
          currency={currency}
          applicationId={applicationId}
        />
      )}
      {visibleManuallyAssignCarrier && (
        <ManuallyAssignWinCarrierSidemodal
          visible={visibleManuallyAssignCarrier}
          onHide={() => setVisibleManuallyAssignCarrier(false)}
          onComplete={() => {
            setVisibleManuallyAssignCarrier(false);
            onManuallyAssignedCarrier();
          }}
          companyId={customerId}
          applicationId={applicationId}
        />
      )}
      <ConfirmDialog
        visible={visibleConfirmDialog}
        dialogTitle={
          isArchived
            ? tr(`ButtonMoreNavigation.unarchiveTitle`, `Do you want to unarchive this record?`)
            : tr(`ButtonMoreNavigation.archiveTitle`, `Do you want to archive this record?`)
        }
        dialogMessage={
          isArchived ? tr(`ButtonMoreNavigation.body1`, `Do you want to unarchive this record?`) : tr(`ButtonMoreNavigation.body2`, `Do you want to archive this record?`)
        }
        onCancel={() => setVisibleConfirmDialog(false)}
        onConfirm={() => archive()}
        onAcceptButtonText={isArchived ? tr(`ButtonMoreNavigation.yesUnarchive`, `Unarchive`) : tr(`ButtonMoreNavigation.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />
      <ConfirmDialog
        visible={visibleConfirmArchiveWithSNDialog}
        dialogTitle={
          isArchived
            ? tr(`ButtonMoreNavigation.unarchiveWithSNTitle`, `Do you want to unarchive this record?`)
            : tr(`ButtonMoreNavigation.archiveWithSNTitle`, `Do you want to archive this record?`)
        }
        dialogMessage={
          isArchived
            ? tr(`ButtonMoreNavigation.body1WithSN`, `Do you want to unarchive this record? `)
            : tr(`ButtonMoreNavigation.body2WithSN`, `Do you want to archive this record? Linked SN will be archived too.`)
        }
        onCancel={() => setVisibleConfirmArchiveWithSNDialog(false)}
        onConfirm={() => archiveWithSN()}
        onAcceptButtonText={isArchived ? tr(`ButtonMoreNavigation.yesUnarchive`, `Unarchive`) : tr(`ButtonMoreNavigation.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />
      <ConfirmDialog
        visible={visibleFinishDialog}
        dialogTitle={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        dialogMessage={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        onCancel={() => setVisibleFinishDialog(false)}
        onConfirm={() => finish()}
        onAcceptButtonText={tr(`ButtonMoreNavigation.finish`, `Finish`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />
      <ConfirmDialog
        visible={visibleFinishDialog}
        dialogTitle={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        dialogMessage={tr('ButtonMoreNavigation.doYouWantToFinishTheExpedition', 'Do you want to finish the expedition?')}
        onCancel={() => setVisibleFinishDialog(false)}
        onConfirm={() => finish()}
        onAcceptButtonText={tr(`ButtonMoreNavigation.finish`, `Finish`)}
        onCancelButtonText={tr(`ButtonMoreNavigation.close`, `Close`)}
        loading={true}
      />
      <DuplicateExpeditionDialog
        applicationId={applicationId}
        visible={visibleDuplicateDialog}
        onCancel={() => setVisibleDuplicateDialog(false)}
        isLinkedToSN={linkedToSN}
      />
    </>
  );
};
