import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Visibility } from '@components/framework/orders/validation.ts';

interface ComponentProps {
  visibility: Visibility;
  switchComponent: ReactElement;
  defaultOpen?: boolean;
}

const VisibitityComponent: React.FC<PropsWithChildren<ComponentProps>> = ({ 
  visibility, 
  switchComponent, 
  children,
  defaultOpen = false 
}) => {
  const { tr } = useTranslationLgs();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(visibility === 'always' || defaultOpen);
  }, [visibility, defaultOpen]);

  if (visibility === 'never') return <></>;

  const isVisible = visibility === 'always' || (visibility === 'optional' && visible);
  
  if (isVisible) {
    return <>{children}</>;
  }

  return React.cloneElement(switchComponent, { onClick: () => setVisible(true) });
};

export default VisibitityComponent;
