import React from 'react';
import styled from 'styled-components';
import SvgDiscrepancy from '@components/ringil3/icons/buttons/Discrepancy.tsx';

interface ComponentProps {
  onClick: () => void;
}

const DiscrepancyButton: React.FC<ComponentProps> = ({ onClick }) => {
  return (
    <ButtonInner onClick={e => onClick()}>
      <SvgDiscrepancy />
    </ButtonInner>
  );
};

const ButtonInner = styled.button`
  padding: 4px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: var(--ringil3-base-secondary-color);
  border: 1px solid var(--ringil3-gr2);
  box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.15);
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DiscrepancyButton;
