import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import { SplitButton } from 'primereact/splitbutton';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { isNotNullOrUndefined, openMailClientCustoms } from '@utils/utils.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser';
import { useCreateCancelSupplierNotificationSidebar } from '@app/pages/customer/supplier-notification/sidebars/CancelSupplierNotificationSidebar';

interface ComponentProps {
  archived: boolean;
  supplierNotificationId: string;
  supplierNotificationApplicationId: string;
  supplierLocationName: string;
  isEntityOwner: boolean;
  onChange: () => void;
  isDAP: boolean;
  onCanceledSupplierNotification: () => void;
}

export const MoreButtonsSupplierNotification: React.FC<ComponentProps> = ({
  archived,
  supplierNotificationId,
  supplierNotificationApplicationId,
  supplierLocationName,
  isEntityOwner,
  onChange,
  isDAP,
  onCanceledSupplierNotification,
}) => {
  const { tr } = useTranslationLgs();
  const { changeArchiveStateSupplierNotification, getCustomsForSupplierNotification } = useContext(ApiContext);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const nav = useNavigation();

  const openMailClientCustomsForSN = () => {
    openMailClientCustoms(getCustomsForSupplierNotification, supplierNotificationId, supplierLocationName, supplierNotificationApplicationId);
  };

  const { CancelSupplierNotificationSidebar, setCancelationRequest, cancelationRequest } = useCreateCancelSupplierNotificationSidebar();

  const { hasPermission } = useContext(AuthenticatedUserContext);

  const model = [
    {
      label: tr('MoreButtonsSupplierNotification.archive', 'Archive'),
      icon: 'pi pi-times',
      command: (e: any) => {
        setVisibleConfirmDialog(true);
      },
      visible: !archived && isEntityOwner,
    },
    {
      label: tr('MoreButtonsSupplierNotification.notifyWarehouse', 'Notify warehouse'),
      icon: 'pi pi-play',
      command: (e: any) => {
        openMailClientCustomsForSN();
      },
      visible: !archived,
    },
    {
      label: tr('MoreButtonsSupplierNotification.duplicate', 'Duplicate'),
      icon: 'pi pi-refresh',
      command: (e: any) => {
        nav.navigate(nav.urlFunctions.createDuplicateSupplierNotification(supplierNotificationId));
        //window.location.href = `/supplier-notifications/duplicate-supplier-notification?fromSupplierNotificationId=${supplierNotificationId}`;
      },
      visible: !isDAP,
    },
    {
      label: tr('SupplierNotificationMoreButton.cancel', 'Cancel supplier notification'),
      icon: 'pi pi-times-circle',
      command: () => {
        setCancelationRequest({
          supplierNotificationId,
        });
      },
      visible: hasPermission([PermissionTypesEnum.PERMISSION_SN_CANCEL]),
    },
  ];

  const archive = () => {
    changeArchiveStateSupplierNotification(supplierNotificationId, { archive: true }, () => {
      setVisibleConfirmDialog(false);
      onChange();
    });
  };

  return (
    <>
      {model.filter(t => t.visible).length > 0 && <SplitButton model={model.filter(t => t.visible)} className="btn-more-info p-button-rounded p-button-outlined" />}

      <ConfirmDialog
        visible={visibleConfirmDialog}
        dialogTitle={tr(`MoreButtonsSupplierNotification.archiveEntity`, `Do you want to archive this record?`)}
        dialogMessage={tr(`MoreButtonsSupplierNotification.switchToManualModeDialogBody`, `Do you want to archive this record?`)}
        onCancel={() => setVisibleConfirmDialog(false)}
        onConfirm={() => archive()}
        onAcceptButtonText={tr(`MoreButtonsSupplierNotification.yesArchive`, `Archive`)}
        onCancelButtonText={tr(`MoreButtonsSupplierNotification.close`, `Close`)}
        loading={true}
      />

      {isNotNullOrUndefined(cancelationRequest) && (
        <CancelSupplierNotificationSidebar
          onContinue={() => {
            onCanceledSupplierNotification();
          }}
        />
      )}
    </>
  );
};
