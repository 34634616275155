import React from 'react';
import { EntityTypeEnum, LovDTO } from '@api/logsteo-api.v2';
import { MultiSelect } from 'primereact/multiselect';
import useFolders from '@hooks/use-folders/useFolders.tsx';

interface ComponentProps {
  value: LovDTO[];
  onSelected: (value: LovDTO[]) => void;
  placeholder: string;
  entityType: EntityTypeEnum;
}

export const InputFolders: React.FC<ComponentProps> = ({ value, onSelected, placeholder, entityType }) => {
  const folders = useFolders(entityType);

  return (
    <>
      <MultiSelect
        filter={true}
        value={value}
        options={folders}
        onChange={e => {
          onSelected(e.value);
        }}
        showClear={true}
        optionLabel="name"
        optionValue="id"
        itemTemplate={(option: LovDTO) => (
          <>{option.active ? <span>{option.name}</span> : <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
        )}
        placeholder={placeholder}
      />
    </>
  );
};
