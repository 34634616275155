import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCargoItem from '@components/ringil3/icons/CargoItem.tsx';

interface ComponentProps {
  ladenFactor?: number;
}

const LadenFactorTag: React.FC<ComponentProps> = ({ ladenFactor }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <ButtonTag
        label={tr(`LadenFactorTag.title`, `Laden factor`)}
        value={tr('LadenFactorTag.text', '1 truck = {{ladenFactor}} MU', { ladenFactor })}
        icon={<SvgCargoItem />}
        readonly={true}
      />
    </>
  );
};

export default LadenFactorTag;
