import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { formatPieces, formatPrice, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  price: number;
  currency: string;
}

const PiecesBadge: React.FC<ComponentProps> = ({ price, currency }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(price) && (
        <Badge filled={false} value={formatPrice(price, currency)} highlightedValue={false} label={tr(`PriceBadge.totalPrice`, `Total price`)}></Badge>
      )}
    </>
  );
};

export default PiecesBadge;
