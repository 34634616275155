import React from 'react';
import { Calendar } from 'primereact/calendar';
import { setTime, shortDateFormat } from '@utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { InputMask } from 'primereact/inputmask';
import { getTime } from '@utils/utils';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
}

const InputDateAndTime: React.FC<ComponentProps> = ({ value, onChange }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <Calendar
        locale={i18n.language}
        dateFormat={shortDateFormat}
        value={value.toDate()}
        showIcon
        selectionMode="single"
        readOnlyInput
        onChange={e => {
          onChange(dayjs(e.value as Date));
        }}
        style={{ width: '100%' }}
      />
      <InputMask
        mask={'99:99'}
        style={{ width: '100%' }}
        value={getTime(value)}
        onComplete={e => {
          onChange(setTime(value, e.value));
        }}
      />
    </>
  );
};

export default InputDateAndTime;
