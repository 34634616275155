import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  manipulationUnits: number;
}

const RequestedManipulationUnitsBadge: React.FC<ComponentProps> = ({ manipulationUnits }) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      {isNotNullOrUndefined(manipulationUnits) && (
        <Badge filled={false}
               value={tr(`RequestedManipulationUnitsBadge.value`, `{{manipulationUnits}} MU`, { manipulationUnits: manipulationUnits })}
               highlightedValue={false}
               label={tr(`RequestedManipulationUnitsBadge.requestedManipulationUnits`, `Requested`)}></Badge>
      )}
    </>
  );
};

export default RequestedManipulationUnitsBadge;
