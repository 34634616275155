import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { createEmptyLocation, createEmptyOrder, createEmptyOrderItem, Location, NewExpeditionView, OrderItemViewData, OrderViewData, PageID, RouteType } from './types';
import {
  CargoTemplateWithoutIdDTO,
  CarrierGroupInDTO,
  CGLocationOutDTO,
  ContactTypeEnum,
  DistributionTypeEnum,
  ExpeditionTypeEnum,
  GlobalTimeslotsDTO,
  LoadingTypeEnum,
  LoadingTypesEnum,
  LovDTO,
  OpeningEntityTypeEnum,
  OpeningModeEnum,
  PartnerLocationContactPersonInDTO,
  TemplateCargoOutDTO,
  TemplateLocationOrderOutDTO,
  TemplateLocationOutDTO,
  UserLovOutDTO,
} from '@api/logsteo-api.v2';
import dayjs, { Dayjs } from 'dayjs';
import { Draft } from 'immer';
import { setTime } from '@utils/date';
import { isNullOrUndefined } from '@utils/utils';
import { stage2validation, stateStep2 } from './validation';
import { ValidationError } from 'yup';
import { mapToSaveExpedition } from './helpers';
import { validate } from '@utils/validation';
import { ApiContext } from '@api/api';
import useNavigation from '@hooks/useNavigation';

// TODO: mvlach - export in API
interface Callbacks {
  onError?: (error: Error) => void;
  onConflict?: (conflictInfo: any) => void;
  onValidationFailed?: (originalResponse: any) => void;
  onEntityNotFound?: (originalResponse: any) => void;
  onAdditionalAction?: (additionalAction: any) => void;
}

interface NewExpeditionContextType {
  state: NewExpeditionView;
  initializeNewExpedition: () => void;
  clickOnBreadCrumb: (page: PageID) => void;
  locationChanged: (locationIndex: number, fieldName: string, value: any) => void;
  removeLocation: (locationIndex: number) => void;
  addLocation: () => void;
  addLocationAtPosition: (position: number) => void;
  changeExpeditionType: (expeditionType: ExpeditionTypeEnum) => void;
  selectTruck: (truck: string) => void;
  selectManipulationTypes: (manipulationType: LoadingTypesEnum[]) => void;
  selectSpecialRequirement: (requirement: string[]) => void;
  selectCargoType: (cargoType: string) => void;
  changeOrderItemProperty: (locationIndex: number, orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => void;
  changeCarrierNote: (note: string) => void;
  changeADRWeight: (adrWeight: number) => void;
  changeRouteType: (routeType: RouteType) => void;
  addCustomExpeditionId: (applicationId: string) => void;
  deleteOrder: (orderInternalId: string) => void;
  addLoadingUnit: (locationIndex: number, orderIndex: number) => void;
  deleteLoadingUnit: (locationIndex: number, orderIndex: number, orderItemIndex: number) => void;
  addOrder: (locationIndex: number) => void;
  toggleLoading: (locationIndex: number) => void;
  toggleUnloading: (locationIndex: number) => void;
  changeOrderName: (locationIndex: number, orderIndex: number, orderName: string) => void;
  unloadOrder: (orderId: string, unloadingLocationId: number, unload: boolean) => void;
  toggleUnloadAll: (locationIndex: number, checked: boolean) => void;
  proceedToLoading: () => void;
  proceedToOverview: () => void;
  changeTimeslots: (locationIndex: number, timeslots: GlobalTimeslotsDTO) => void;
  toggleCreateDistribution: () => void;
  changeDistributionType: (distributionType: DistributionTypeEnum) => void;
  changeDistributionPrice: (price: number) => void;
  changeDistributionDate: (date: Dayjs) => void;
  changeDistributionPublishing: (value: boolean) => void;
  changeDistributionTime: (time: string) => void;
  changeDistributionSelectedItems: (items: CarrierGroupInDTO[]) => void;
  changeResponsiblePerson: (person: UserLovOutDTO) => void;
  setCollaborators: (collaborators: LovDTO[]) => void;
  changeCarrierCode: (locationIndex: number, code: string) => void;
  selectExpeditionTemplate: (templateId: string) => void;
  initializeNewExpeditionFromTemplate: () => void;
  applyDistributionTemplate: () => void;
  applyAddressBook: (locationIndex: number, partnerLocation: CGLocationOutDTO) => void;
  validation: ValidationError[];
  saveExpedition: (callBacks: Callbacks) => void;
  changeDistributionCurrency: (currency: string) => void;
  changeCargoItem: (locationIndex: number, orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => void;
  setIntenalNote: (note: string) => void;
  changeFlatProperty: (property: string, value: any) => void;
  toggleSpecifyOrderItems: (internalOrderId: string, value: boolean) => void;
  setOrderTotalWeight: (internalOrderId: string, totalWeight: number) => void;
  applyContactPerson: (locationIndex: number, contactPerson: PartnerLocationContactPersonInDTO) => void;
  applyAdditionalNotificationPersons: (locationIndex: number, contactPerson: PartnerLocationContactPersonInDTO[]) => void;
  changeLocalityName: (locationIndex: number, localityName: string) => void;
  applyTimeslotsForLocation: (locationIndex: number, slot: GlobalTimeslotsDTO) => void;
}

export const NewExpeditionViewContext = createContext<NewExpeditionContextType>(undefined);

interface ComponentProps {
  initialData: NewExpeditionView;
}

const recomputeUnloadAll = (draft: Draft<NewExpeditionView>): Draft<NewExpeditionView> => {
  const unloadingLocationId = draft.locations.findIndex(l => l.unloadAll);
  if (unloadingLocationId >= 0) {
    draft.locations
      .map(l => l.loadingOrders)
      .flat(1)
      .forEach(o => (o.unloadingLocationId = unloadingLocationId));
  }
  return draft;
};

const NewExpeditionProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ initialData, children }) => {
  const [state, setState] = useImmer<NewExpeditionView>(initialData);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
  const { cuGetTemplateData, cuSaveExpeditionV2 } = useContext(ApiContext);
  const navigation = useNavigation();

  const validateStep2 = (): boolean => {
    try {
      stateStep2.validateSync(state, {
        abortEarly: false,
        recursive: true,
        strict: false,
      });
      setValidationErrors([]);
      return true;
    } catch (err) {
      // @ts-ignore
      setValidationErrors(err.inner);
      return false;
    }
  };

  const validateOrderUnloading = (): ValidationError[] => {
    return state.locations
      .map((l, locationIndex) =>
        l.loadingOrders.map((lo, orderIndex) => {
          return {
            locationIndex,
            orderIndex,
            notUnloaded: isNullOrUndefined(lo.unloadingLocationId),
          };
        }),
      )
      .flat(1)
      .filter(a => a.notUnloaded)
      .map(o => {
        return {
          path: `locations[${o.locationIndex}].loadedOrders[${o.orderIndex}].unloadingLocationId`,
          type: 'notUnloaded',
        } as ValidationError;
      });
  };

  const validateSlots = (): ValidationError[] => {
    const validations = [];

    for (let locationIndex = 0; locationIndex < state.locations.length; locationIndex++) {
      const l = state.locations[locationIndex];
      const correctIntervalCount = 0;

      const notClosed = l.timeslots.dayWithInterval.filter(t => t.openingMode !== OpeningModeEnum.CLOSED);

      if (notClosed.length == 0) {
        validations.push({
          path: `locations[${locationIndex}].timeslots`,
          type: 'timeslots.are.invalid',
        } as ValidationError);
      }

      /*if (l.timeslots.showTimes) {
                    for (let dayIndex = 0; dayIndex < l.timeslots.timeslots.length; dayIndex++) {
                      let dayInterval = l.timeslots.timeslots[dayIndex];
                      if (!dayInterval.disabled) {
                        for (let intervalIndex = 0; intervalIndex < dayInterval.intervals.length; intervalIndex++) {
                          let interval = dayInterval.intervals[intervalIndex];
                          if (interval.from >= interval.to) {
                            validations.push({
                              path: `locations[${locationIndex}].timeslots.timeslots[${dayIndex}].intervals[${intervalIndex}].from`,
                              type: 'invalidValues',
                            } as ValidationError);
                            validations.push({
                              path: `locations[${locationIndex}].timeslots.timeslots[${dayIndex}].intervals[${intervalIndex}].to`,
                              type: 'invalidValues',
                            } as ValidationError);
                          } else {
                            correctIntervalCount++;
                          }
                        }
                      }
                    }
                    if (correctIntervalCount == 0)
                      validations.push({
                        path: `locations[${locationIndex}].timeslots`,
                        type: 'invalidValue',
                      } as ValidationError);
                  }*/
    }

    return validations;
  };

  const validateStep3 = (): boolean => {
    const yupValidations = validate(stage2validation, state);
    console.log(yupValidations);
    const unloadingValidation = validateOrderUnloading();
    const errors = [...yupValidations, ...unloadingValidation, ...validateSlots()];
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const toggleUnloadAll = (draft: Draft<NewExpeditionView>, locationIndex: number, checked: boolean): Draft<NewExpeditionView> => {
    draft.locations.filter((location, index, obj) => location.unloadAll).forEach(loc => (loc.unloadAll = false));
    draft.locations[locationIndex].unloadAll = checked;

    if (checked) {
      draft.locations
        .map(l => l.loadingOrders)
        .flat(1)
        .forEach(order => {
          if (order.loadingLocationId < locationIndex) order.unloadingLocationId = locationIndex;
        });
    }

    return draft;
  };

  const methods: NewExpeditionContextType = {
    state,

    initializeNewExpedition: () => {
      setState(draft => {
        draft.currentPage = PageID.STEP2;
        draft.locations = [createEmptyLocation(0, true, false), createEmptyLocation(1, false, true)];
        draft.expeditionType = ExpeditionTypeEnum.FULL_TRUCK_LOAD;
        draft.routeType = RouteType.DIRECT;
        draft.manipulationTypes = [LoadingTypesEnum.FROM_SIDE];
      });
    },

    clickOnBreadCrumb: (page: PageID) => {
      setState(draft => {
        draft.currentPage = page;
      });
    },

    locationChanged: (locationIndex: number, fieldName: string, value: any) => {
      setState(draft => {
        // @ts-ignore
        draft.locations[locationIndex][fieldName] = value;
      });
    },

    removeLocation: (locationIndex: number) => {
      setState(draft => {
        // all order that are unloaded on the removing location are not unloaded
        draft.locations
          .map(l => l.loadingOrders)
          .flat(1)
          .forEach(lo => {
            if (lo.unloadingLocationId === locationIndex) lo.unloadingLocationId = null;
            if (lo.unloadingLocationId > locationIndex) lo.unloadingLocationId--;
          });
        // lower all unloading order behind the removed order
        // draft.locations.map(l=>l.loadingOrders).flat(1).filter(t=>t.unloadingLocationId >= )
        draft.locations = draft.locations.filter((_, index) => index !== locationIndex);
        draft.locations.filter(l => l.id > locationIndex).forEach(l => l.id--);
      });
    },

    addLocation: () => {
      setState(draft => {
        if (state.routeType === RouteType.DIRECT) {
          const previouslyLastLocationIndex = draft.locations.length - 1;
          draft.locations.splice(draft.locations.length - 1, 0, createEmptyLocation(previouslyLastLocationIndex));
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.unloadingLocationId >= previouslyLastLocationIndex)
            .forEach(order => {
              order.unloadingLocationId = order.unloadingLocationId + 1;
            });
          draft.locations[draft.locations.length - 1].id++;
        } else {
          draft.locations.splice(draft.locations.length, 0, createEmptyLocation(draft.locations.length));
        }
      });
    },

    addLocationAtPosition: (newPosition: number) => {
      setState(draft => {
        if (state.routeType === RouteType.DIRECT) {
          const newLocation = createEmptyLocation(newPosition);

          // increment all unloading location according new added location
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.unloadingLocationId >= newPosition)
            .forEach(order => {
              order.unloadingLocationId = order.unloadingLocationId + 1;
            });
          // increment all loading location
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.loadingLocationId >= newPosition)
            .forEach(order => {
              order.loadingLocationId = order.loadingLocationId + 1;
            });

          draft.locations.splice(newPosition, 0, newLocation);
          draft.locations.forEach((t, index) => (t.id = index));

          /*const previouslyLastLocationIndex = draft.locations.length - 1;
                                                            draft.locations.splice(draft.locations.length - 1, 0, createEmptyLocation(previouslyLastLocationIndex));
                                                            draft.locations
                                                              .map((item) => item.loadingOrders)
                                                              .flat(1)
                                                              .filter((order) => order.unloadingLocationId >= previouslyLastLocationIndex)
                                                              .forEach((order) => {
                                                                order.unloadingLocationId = order.unloadingLocationId + 1;
                                                              });
                                                            draft.locations[draft.locations.length - 1].id++;*/
        } else {
          const newLocation = createEmptyLocation(newPosition);

          // increment all unloading location according new added location
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.unloadingLocationId >= newPosition)
            .forEach(order => {
              order.unloadingLocationId = order.unloadingLocationId + 1;
            });
          // increment all loading location
          draft.locations
            .map(item => item.loadingOrders)
            .flat(1)
            .filter(order => order.loadingLocationId >= newPosition)
            .forEach(order => {
              order.loadingLocationId = order.loadingLocationId + 1;
            });

          draft.locations.splice(newPosition, 0, newLocation);
          draft.locations.forEach((t, index) => (t.id = index));
        }
      });
    },

    changeExpeditionType: (expeditionType: ExpeditionTypeEnum) => {
      setState(draft => {
        draft.expeditionType = expeditionType;
      });
    },

    selectTruck: (truck: string) => {
      console.log(`select truck ${truck}`);
      setState(draft => {
        draft.truckType = truck;
      });
    },

    addCustomExpeditionId: (applicationId: string) => {
      setState(draft => {
        draft.applicationId = applicationId;
      });
    },
    selectManipulationTypes: (manipulationTypes: LoadingTypesEnum[]) => {
      setState(draft => {
        draft.manipulationTypes = manipulationTypes;
      });
    },

    selectSpecialRequirement: (requirement: string[]) => {
      setState(draft => {
        draft.specialRequirements = [...requirement];
      });
    },

    selectCargoType: (cargoType: string) => {
      setState(draft => {
        draft.cargoType = cargoType;
      });
    },

    changeOrderItemProperty: (locationIndex: number, orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => {
      setState(draft => {
        // @ts-ignore
        draft.locations[locationIndex].loadingOrders[orderIndex].items[orderItemIndex][propertyName] = value;
      });
    },

    changeCargoItem: (locationIndex: number, orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => {
      setState(draft => {
        const orderItem = draft.locations[locationIndex].loadingOrders[orderIndex].items[orderItemIndex];

        orderItem.cargoItemType = value;
        /*orderItem.viewDisabledProperties = [];*/
        if (!isNullOrUndefined(value.length)) {
          /*orderItem.viewDisabledProperties.push('length');*/
          orderItem.length = value.length;
        }
        if (!isNullOrUndefined(value.height)) {
          /*orderItem.viewDisabledProperties.push('height');*/
          orderItem.height = value.height;
        }
        if (!isNullOrUndefined(value.width)) {
          /*orderItem.viewDisabledProperties.push('width');*/
          orderItem.width = value.width;
        }
      });
    },

    changeCarrierNote: (note: string) => {
      setState(draft => {
        draft.carrierNote = note;
      });
    },
    changeADRWeight: (adrWeight: number) => {
      setState(draft => {
        draft.adrWeight = adrWeight;
      });
    },
    changeRouteType: (routeType: RouteType) => {
      setState(draft => {
        draft.routeType = routeType;
      });
    },

    deleteOrder: (orderInternalId: string) => {
      setState(draft => {
        const order = draft.locations
          .map(l => l.loadingOrders)
          .flat(1)
          .find(o => o.internalId === orderInternalId);
        draft.locations[order.loadingLocationId].loadingOrders = draft.locations[order.loadingLocationId].loadingOrders.filter(o => o.internalId !== orderInternalId);
      });
    },

    addLoadingUnit: (locationIndex: number, orderIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].items.push(createEmptyOrderItem());
      });
    },

    deleteLoadingUnit: (locationIndex: number, orderIndex: number, orderItemIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].items = draft.locations[locationIndex].loadingOrders[orderIndex].items.filter(
          (orderItem, index) => index !== orderItemIndex,
        );
      });
    },

    addOrder: (locationIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders.push(
          createEmptyOrder(locationIndex, draft.locations[locationIndex].name, draft.locations[locationIndex].loadingOrders.length + 1),
        );
        recomputeUnloadAll(draft);
      });
    },

    toggleLoading: (locationIndex: number) => {
      setState(draft => {
        const newState = !draft.locations[locationIndex].loadingLocation;
        draft.locations[locationIndex].loadingLocation = newState;
        if (newState) {
          // if there is no order in the loading map, then create first
          if (!(draft.locations[locationIndex].loadingOrders?.length > 0)) {
            draft.locations[locationIndex].loadingOrders = [createEmptyOrder(locationIndex, draft.locations[locationIndex].name, 1)];
          }
        } else {
          // delete all
          draft.locations[locationIndex].loadingOrders = [];
        }
      });
    },

    toggleUnloading: (locationIndex: number) => {
      setState(draft => {
        draft.locations[locationIndex].unloadingLocation = !draft.locations[locationIndex].unloadingLocation;
      });
    },

    changeOrderName: (locationIndex: number, orderIndex: number, orderName: string) => {
      setState(draft => {
        draft.locations[locationIndex].loadingOrders[orderIndex].name = orderName;
      });
    },

    unloadOrder: (orderId: string, unloadingLocationId: number, unload: boolean) => {
      setState(draft => {
        // cancel unload for all locations
        draft.locations.filter((location, index, obj) => location.unloadAll).forEach(loc => (loc.unloadAll = false));
        const unloadedOrder = draft.locations
          .map((loc, index) => loc.loadingOrders)
          .flat(1)
          .find(order => order.internalId === orderId);
        unloadedOrder.unloadingLocationId = unload ? unloadingLocationId : null;
      });
    },
    setCollaborators: (collaborators: LovDTO[]) => {
      setState(draft => {
        draft.subscribersList = collaborators;
      });
    },
    toggleUnloadAll: (locationIndex: number, checked: boolean) => {
      setState(draft => {
        return toggleUnloadAll(draft, locationIndex, checked);
      });
    },

    proceedToLoading: () => {
      if (validateStep2()) {
        setState(draft => {
          // check if the first loading location have order
          const hasOrder = draft.locations[0].loadingOrders?.length > 0;
          /*          if (!hasOrder) {
                                                              draft.locations[0].loadingOrders = [createEmptyOrder(0, draft.locations[0].name, 1)];
                                                            }*/

          // check if it is roundtrip
          if (draft.routeType === RouteType.ROUND_TRIP) {
            const fl = draft.locations[0];

            draft.locations.push({
              timeslots: fl.timeslots,
              loadingOrders: [],
              unloadAll: false,
              name: fl.name,
              zipCode: fl.zipCode,
              locationId: fl.locationId,
              contactType: fl.contactType,
              contactEmail: fl.contactEmail,
              streetNr: fl.streetNr,
              country: fl.country,
              carrierCode: fl.carrierCode,
              id: draft.locations.length,
              contactPhone: fl.contactPhone,
              contactName: fl.contactName,
              city: fl.city,
              unloadingLocation: true,
              loadingLocation: false,
              contactPersons: [],
              enabledEmailNotificationForContactPerson: fl.enabledEmailNotificationForContactPerson,
              openingHoursCoordinated: fl.openingHoursCoordinated,
            });

            draft.routeType = RouteType.DIRECT;
          }
          recomputeUnloadAll(draft);

          if (state.locations.length == 2) {
            toggleUnloadAll(draft, 1, true);
          }
          draft.currentPage = PageID.STEP3;
        });
      }
    },

    proceedToOverview: () => {
      if (validateStep3()) {
        setState(draft => {
          draft.currentPage = PageID.STEP4;
        });
      }
    },

    changeTimeslots: (locationIndex: number, timeslots: GlobalTimeslotsDTO) => {
      setState(draft => {
        draft.locations[locationIndex].timeslots = timeslots;
      });
    },

    toggleCreateDistribution: () => {
      setState(draft => {
        draft.distribution.enabled = !draft.distribution.enabled;
        if (draft.distribution.enabled) {
          if (isNullOrUndefined(draft.distribution.responseDeadline)) {
            draft.distribution.responseDeadline = dayjs().add(8, 'hour');
          }
          if (isNullOrUndefined(draft.distribution.selectedItems)) draft.distribution.selectedItems = [];
          if (isNullOrUndefined(draft.distribution.distributionType)) draft.distribution.distributionType = DistributionTypeEnum.SPOT;
        }
      });
    },

    changeDistributionType: (distributionType: DistributionTypeEnum) => {
      setState(draft => {
        draft.distribution.distributionType = distributionType;
      });
    },
    changeDistributionPublishing: (value: boolean) => {
      setState(draft => {
        draft.distribution.isPublished = value;
      });
    },
    changeDistributionPrice: (price: number) => {
      setState(draft => {
        draft.distribution.price = price;
      });
    },

    changeDistributionDate: (date: Dayjs) => {
      setState(draft => {
        draft.distribution.responseDeadline = date;
      });
    },

    changeDistributionTime: (time: string) => {
      setState(draft => {
        const previous = draft.distribution.responseDeadline || dayjs();
        draft.distribution.responseDeadline = setTime(previous, time);
      });
    },

    changeDistributionSelectedItems: (items: CarrierGroupInDTO[]) => {
      setState(draft => {
        draft.distribution.selectedItems = items;
      });
    },

    changeResponsiblePerson: (person: UserLovOutDTO) => {
      setState(draft => {
        draft.responsiblePerson = person;
      });
    },

    changeCarrierCode: (locationIndex: number, code: string) => {
      setState(draft => {
        draft.locations[locationIndex].carrierCode = code;
      });
    },

    selectExpeditionTemplate: (templateId: string) => {
      setState(draft => {
        draft.expeditionTemplateId = templateId;
      });
    },
    initializeNewExpeditionFromTemplate: () => {
      cuGetTemplateData(state.expeditionTemplateId, data => {
        setState(draft => {
          // @ts-ignore
          draft.expeditionType = data.expeditionType;
          draft.responsiblePerson = data.responsiblePerson;
          draft.routeType = RouteType.DIRECT;
          draft.cargoType = data.cargoType.code;
          draft.carrierNote = data.note;
          draft.manipulationTypes = data.loadingTypes?.map(t => {
            // @ts-ignore
            return LoadingTypeEnum[t.code];
          });
          draft.specialRequirements = data.requirements?.map(r => r.code);
          if (data.expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD) draft.truckType = data.truckType.code;
          draft.locations = data.locations.map((location, index, locations) => mapFromTemplateToView(location, index, locations));
          draft.subscribersList = data.subscribersList || [];
          draft.currentPage = PageID.STEP3;
          draft.distribution = { enabled: false, isPublished: true };
          if (!isNullOrUndefined(data.distributionTemplate)) {
            draft.distributionTemplate = {
              currency: data.distributionTemplate.currency,
              distributionType: DistributionTypeEnum[data.distributionTemplate.distributionType],
              price: data.distributionTemplate.price,
              responseDeadline: dayjs().add(data.distributionTemplate.intervalHour, 'hour'),
              selectedItems: data.distributionTemplate.carriersAndGroups,
              isPublished: data.distributionTemplate.shouldExpeditionAutomaticallyPublished,
            };
          }
          draft.internalNote = data.internalCustomerNote;
          draft.labelCodes = data.labels ? data.labels.map(t => t.labelId) : [];
        });
      });
    },
    applyDistributionTemplate: () => {
      setState(draft => {
        draft.distribution = {
          ...draft.distributionTemplate,
          enabled: true,
          isPublished: draft.distributionTemplate.isPublished,
        };
        draft.distributionTemplateApplied = true;
      });
    },
    applyAddressBook: (locationIndex: number, partnerLocation: CGLocationOutDTO) => {
      setState(draft => {
        const location = draft.locations[locationIndex];
        location.name = partnerLocation.companyName + '-' + partnerLocation.locationName;

        location.country = partnerLocation.addressTemplate.country;
        location.city = partnerLocation.addressTemplate.city;
        location.streetNr = partnerLocation.addressTemplate.streetNr;
        location.zipCode = partnerLocation.addressTemplate.postalCode;
        location.contactEmail = partnerLocation.defaultContact.contactEmail;
        location.contactName = partnerLocation.defaultContact.contactName;
        location.contactPhone = partnerLocation.defaultContact.contactPhone;
        location.feKey = partnerLocation.feKey;
        location.locationId = partnerLocation.locationId;
        location.contactType = partnerLocation.contactType;
        location.contactPersons = partnerLocation.contactPersons;
        location.openingHoursCoordinated = {
          entityType: partnerLocation.contactType == ContactTypeEnum.COMPANY_LOCATION ? OpeningEntityTypeEnum.LOCATION : OpeningEntityTypeEnum.PARTNER_LOCATION,
          entityId: partnerLocation.locationId,
        };
      });
    },
    saveExpedition: (action: Callbacks) => {
      cuSaveExpeditionV2(
        mapToSaveExpedition(state),
        data => {
          // FIXME jahom
          // @ts-ignore
          const newExpeditionId = data.applicationId;
          /*router.push(`/customer/expedition/${newExpeditionId}`);*/
          navigation.navigate(navigation.urlFunctions.createCustomerExpeditionDetail(newExpeditionId));
        },
        null,
        action,
      );
    },
    changeDistributionCurrency: (currency: string) => {
      setState(draft => {
        draft.distribution.currency = currency;
      });
    },
    setIntenalNote: (note: string) => {
      setState(draft => {
        draft.internalNote = note;
      });
    },

    changeFlatProperty: (property: string, value: any) => {
      setState(draft => {
        // @ts-ignore
        draft[property] = value;
      });
    },
    toggleSpecifyOrderItems: (internalOrderId: string, value: boolean) => {
      setState(draft => {
        const order = draft.locations
          .map(t => t.loadingOrders)
          .flat(1)
          .find(t => t.internalId === internalOrderId);
        if (value) {
          order.items = [createEmptyOrderItem()];
        } else {
          order.items = [];
        }
      });
    },
    setOrderTotalWeight: (internalOrderId: string, totalWeight: number) => {
      setState(draft => {
        const order = draft.locations
          .map(t => t.loadingOrders)
          .flat(1)
          .find(t => t.internalId === internalOrderId);
        order.totalWeight = totalWeight;
      });
    },
    applyContactPerson: (locationIndex: number, contactPerson: PartnerLocationContactPersonInDTO) => {
      setState(draft => {
        draft.locations[locationIndex].contactEmail = contactPerson.contactEmail;
        draft.locations[locationIndex].contactName = contactPerson.contactName;
        draft.locations[locationIndex].contactPhone = contactPerson.contactPhone;
        draft.locations[locationIndex].appliedContactPerson = contactPerson;
      });
    },
    applyAdditionalNotificationPersons: (locationIndex: number, additionalNotificationPersons: PartnerLocationContactPersonInDTO[]) => {
      setState(draft => {
        draft.locations[locationIndex].additionalNotificationPersons = additionalNotificationPersons;
      });
    },
    applyTimeslotsForLocation: (locationIndex: number, slot: GlobalTimeslotsDTO) => {
      setState(draft => {
        draft.locations[locationIndex].timeslots = slot;
      });
    },
    validation: validationErrors,
    changeLocalityName: (locationIndex: number, localityName: string) => {
      setState(draft => {
        draft.locations[locationIndex].name = localityName;
      });
    },
  };

  return <NewExpeditionViewContext.Provider value={methods}>{children}</NewExpeditionViewContext.Provider>;
};

const mapFromTemplateToOrderItem = (oi: TemplateCargoOutDTO): OrderItemViewData => {
  return {
    weightMode: oi.weightMode,
    length: oi.length,
    width: oi.width,
    height: oi.height,
    quantityUnit: oi.quantityUnit,
    amount: oi.amount,
    weight: oi.weight,
    cargoItemType: oi.cargoTemplate,
    stackable: oi.stackable,
    itemNote: oi.itemNote,
  } as OrderItemViewData;
};

const mapFromTemplateToOrders = (lo: TemplateLocationOrderOutDTO): OrderViewData => {
  return {
    name: lo.orderName,
    unloadingLocationId: lo.unloadingLocationIndex,
    internalId: lo.id,
    loadingLocationId: lo.loadingLocationIndex,
    items: lo.items.map(c => mapFromTemplateToOrderItem(c)),
  } as OrderViewData;
};

const mapFromTemplateToView = (location: TemplateLocationOutDTO, index: number, locations: TemplateLocationOutDTO[]): Location => {
  return {
    id: index,
    loadingOrders: location.loadedOrders.map(l => mapFromTemplateToOrders(l)),
    unloadAll: false,
    name: location.name,
    zipCode: location.postalCode,
    streetNr: location.streetNr,
    country: location.country,
    carrierCode: location.pickupCode,
    contactPhone: location.contactPhone,
    contactName: location.contactName,
    city: location.city,
    unloadingLocation: locations
      .map(l => l.loadedOrders)
      .flat(1)
      .some(order => order.unloadingLocationId === location.id),
    contactEmail: location.contactEmail,
    loadingLocation: location.loadedOrders?.length > 0 || false,
    timeslots: { dayWithInterval: [] },
    contactType: ContactTypeEnum.PARTNER_LOCATION,
    locationId: location.fromAddressBookId,
    openingHoursCoordinated: {
      entityId: location.id,
      entityType: OpeningEntityTypeEnum.TEMPLATE_LOCATION,
    },
  } as Location;
};

export default NewExpeditionProvider;
