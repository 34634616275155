import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DocumentTypeEnum, EntityTypeEnum, GetAttachmentsOutDTO, SupplierNotificationTypeEnum, TypeEnum } from '@api/logsteo-api.v2';
import { dayJsToDate, mapFromAPIToDateLong, mapToAPIDateTime, shortDateFormat } from '@utils/date';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import useNavigation from '@hooks/useNavigation';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { proxiedPropertiesOf } from '@hooks/useForm/useForm.tsx';
import { Clickable } from '@components/ringil3/styles.tsx';
import InputEntityType from '@components/obsolete/Form/InputEntityType/InputEntityType.tsx';
import { InputDocumentType } from '@components/obsolete/Form/InputDocumentType/InputDocumentType.tsx';
import { Note, RowWithIcon } from '@components/styles.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';
import EditDocumentMetadata from '@components/ringil3/Features/Attachments/EditDocumentMetadata/EditDocumentMetadata.tsx';
import { useTranslation } from 'react-i18next';

interface ComponentProps {}

interface Filter {
  entityType?: EntityTypeEnum;
  documentType?: DocumentTypeEnum;
  applicationId?: string;
  since?: string;
  till?: string;
}

const AccountingList: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { getAttachments, downloadAttachment } = useContext(ApiContext);
  const [data, setData] = useState(undefined);
  const [documentId, setDocumentId] = useState(null);

  const navigation = useNavigation();

  const { Filter, applyFilterValue, onPage, page, applyPaging, filter } = useFilter<Filter>((page, filter) => {
    load(page, filter);
  });

  const names = proxiedPropertiesOf<Filter>();

  const downloadFile = (attachmentId: string) => {
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();
    });
  };

  const load = (page: any, filter: any) => {
    getAttachments(page, filter, data => {
      setData(data);
    });
  };

  const renderLink = (entityAppId: string, entityId: string, entityType: EntityTypeEnum) => {
    switch (entityType) {
      case EntityTypeEnum.EXPEDITION:
        return (
          <Clickable>
            {/*<a href={`/customer/expedition/${entityAppId}`}>{entityAppId}</a>*/}
            <a href={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(entityAppId))}>{entityAppId}</a>
          </Clickable>
        );
      case EntityTypeEnum.SHIPMENT:
        return (
          <Clickable>
            {/*<a href={`/shipments/${entityId}`}>{entityAppId}</a>*/}
            <a href={navigation.createNavigationLink(navigation.urlFunctions.createShipmentDetail(entityId))}>{entityAppId}</a>
          </Clickable>
        );
      case EntityTypeEnum.SUPPLIER_NOTIFICATION:
        return (
          <Clickable>
            {/*<a href={`/supplier-notifications/${entityId}`}>{entityAppId}</a>*/}
            <a href={navigation.createNavigationLink(navigation.urlFunctions.createSupplierNotificationDetail(entityId, SupplierNotificationTypeEnum.FCA))}>
              {entityAppId}
            </a>
          </Clickable>
        );
      default:
        return <>N/A</>;
    }
  };

  const { i18n } = useTranslation();

  return (
    <>
      {/*// @ts-ignore*/}
      <Card>
        <Filter />
        {filter && (
          <>
            <div className="p-mt-3 p-mb-2">
              <div className="p-formgroup-inline">
                <div className="p-field">
                  <InputEntityType value={filter?.entityType} onChange={v => applyFilterValue(names.entityType, v)} />
                </div>
                <div className="p-field">
                  <InputDocumentType
                    documentType={filter?.documentType}
                    onChange={e => applyFilterValue(names.documentType, e.documentType)}
                    placeholder={tr('AccountingList.documentTypePlaceholder', 'documentType')}
                  />
                </div>
                {/*<div className="p-field">
                <InputText
                  value={filter?.applicationId}
                  onChange={(e) => applyFilterValue(names.applicationId, e.target.value)}
                  placeholder={tr('AccountingList.filterAppID', 'Application ID')}
                />
              </div>*/}
                <div className="p-field">
                  <Calendar
                    dateFormat={shortDateFormat}
                    value={filter?.since ? dayJsToDate(dayjs(filter.since)) : null}
                    placeholder={tr('AccountingList.since', 'Since')}
                    onChange={e => applyFilterValue(names.since, mapToAPIDateTime(dayjs(e.value as Date)))}
                    style={{ width: '100%' }}
                    locale={i18n.language}
                    showButtonBar={true}
                    showIcon
                    onClearButtonClick={() => applyFilterValue(names.since, null)}
                  />
                </div>
                <div className="p-field">
                  <Calendar
                    dateFormat={shortDateFormat}
                    value={filter?.till ? dayJsToDate(dayjs(filter.till)) : null}
                    placeholder={tr('AccountingList.till', 'till')}
                    onChange={e => applyFilterValue(names.till, mapToAPIDateTime(dayjs(e.value as Date)))}
                    style={{ width: '100%' }}
                    locale={i18n.language}
                    showButtonBar={true}
                    showIcon
                    onClearButtonClick={() => applyFilterValue(names.till, null)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {data && (
          <>
            {/*// @ts-ignore*/}
            <DataTable
              value={data.data}
              rows={data.size}
              paginator={true}
              lazy={true}
              totalRecords={data.total}
              onPage={onPage}
              first={(data.page - 1) * data.size}
              emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}
            >
              <Column header={tr('AccountingList.entityType', 'Entity type')} body={(row: GetAttachmentsOutDTO) => <div>{row.attachedTo?.entityType}</div>} />
              <Column
                header={tr('AccountingList.appID', 'Application ID')}
                body={(row: GetAttachmentsOutDTO) => <div>{renderLink(row.attachedTo?.applicationId, row.attachedTo?.entityId, row.attachedTo?.entityType)}</div>}
              />
              <Column
                header={tr('AccountingList.userDate', 'User date')}
                body={(row: GetAttachmentsOutDTO) => (
                  <>
                    <div>{mapFromAPIToDateLong(row.userDate)}</div>
                    <Note>{row.userNote}</Note>
                  </>
                )}
              />
              <Column header={tr('AccountingList.entityType', 'Entity type')} body={(row: GetAttachmentsOutDTO) => <div>{row.attachedTo?.documentType}</div>} />
              <Column field="name" header={tr(`AccountingList.name`, `Name`)} />
              <Column
                body={(row: GetAttachmentsOutDTO) => (
                  <RowWithIcon>
                    <Clickable onClick={() => downloadFile(row.id)}>Download</Clickable>
                    <EditButton label={tr('AccountingList.edit', 'Edit')} onClick={() => setDocumentId(row.id)} />
                  </RowWithIcon>
                )}
              />
            </DataTable>
          </>
        )}
        <EditDocumentMetadata
          documentId={documentId}
          onHide={() => {
            load(page, filter);
            setDocumentId(null);
          }}
        />
      </Card>
    </>
  );
};

export default AccountingList;
