import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCarPlate from '@components/ringil3/icons/CarPlate.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import { InputText } from 'primereact/inputtext';

interface ComponentProps {
  carPlate: string;
  onChange: (carPlate: string) => void;
  readOnly: boolean;
}

const CarPlateTag: React.FC<ComponentProps> = ({ carPlate, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();
  const [carPlateInternal, setCarPlateInternal] = useState(carPlate);

  const carPlatePane = () => {
    return (
      <>
        <InputText value={carPlateInternal} onChange={e => setCarPlateInternal(e.target.value)} />
      </>
    );
  };

  const save = () => {
    onChange(carPlateInternal);
  };

  return (
    <ButtonTag
      label={tr(`CarPlateTag.title`, `Car plate`)}
      value={carPlate}
      icon={<SvgCarPlate />}
      actionPane={carPlatePane()}
      actionIcon={<SvgTagEdit />}
      onSave={(onSuccess) => {
        save();
        onSuccess();
      }}
      autoClose
      readonly={readOnly}
    />
  );
};

export default CarPlateTag;
