import { OrdersConfig } from '@components/framework/orders/validation.ts';

export const generateTimeslotOrderConfig = (requiredOrderItems: boolean): OrdersConfig => {
  return {
    orderName: {
      visibility: 'always',
      required: false,
    },
    cargo: {
      visibility: requiredOrderItems ? 'always' : 'optional',
      required: requiredOrderItems,
    },
    cargoDimensions: {
      visibility: 'never',
      required: true,
      items: {
        width: {
          required: true,
        },
        height: {
          required: true,
        },
        length: {
          required: true,
        },
        weight: {
          required: true,
        },
      },
    },
    cargoDetails: {
      visibility: 'never',
      required: true,
      items: {
        orderAmount: {
          required: true,
        },
        orderDescription: {
          required: true,
        },
        orderValue: {
          required: true,
        },
      },
    },
    totalWeight: {
      render: false,
    },
    unloadingLocation: {
      render: false,
    },
  };
};
