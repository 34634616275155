import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ApiContext } from '@api/api.tsx';
import ETransportEmpty from '@components/features/eTransport/ETransportEmpty.tsx';
import ETransportCreate from '@components/features/eTransport/ETransportCreate.tsx';
import ETransportDetail from '@components/features/eTransport/ETransportDetail.tsx';
import { ETransportDTO } from '@api/logsteo-api.v2.tsx';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  readonly: boolean;
}

type ETransportView = 'empty' | 'detail' | 'create' | 'edit';

const ETransport: React.FC<ComponentProps> = ({ reservationId, applicationId, readonly }) => {
  const { tr } = useTranslationLgs();
  const { getEtransportDetail } = useContext(ApiContext);
  const [data, setData] = useState<ETransportDTO>();

  const [currentView, setCurrentView] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    getEtransportDetail(reservationId, data => {
      setData(data);
      if (data) {
        setCurrentView('detail');
      } else {
        setCurrentView('empty');
      }
    });
  };

  if (currentView === 'create') {
    return (
      <ETransportCreate
        reservationId={reservationId}
        applicationId={applicationId}
        onCompleted={() => load()}
        initialData={{
          referenceNumber: null,
          transportCompany: null,
          licencePlate: null,
          document: null,
          transportDate: null,
          supplierName: '',
          transportMode: 0,
          originAddress: { borderId: null, regionId: null, town: '', street: '' },
          destinationAddress: { borderId: null, regionId: null, town: '', street: '' },
          products: [],
        }}
      />
    );
  }

  if (currentView === 'edit') {
    return <ETransportCreate reservationId={reservationId} onCompleted={() => load()} initialData={data} applicationId={applicationId} />;
  }

  if (currentView === 'detail') {
    return <ETransportDetail detail={data} onEdit={() => setCurrentView('edit')} readOnly={readonly} />;
  }

  if (currentView === 'empty') {
    return (
      <>
        {readonly === false && (
          <ETransportEmpty
            onCreateETransport={() => {
              setCurrentView('create');
            }}
          />
        )}
      </>
    );
  }

  return <>Loading....</>;
};

export default ETransport;
