import React, { useContext } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import DistributionValidTillTag from '@components/framework/tags/DistributionValidTillTag.tsx';
import ExpectedPriceTag from '@components/framework/tags/ExpectedPriceTag.tsx';
import RequestedPriceTag from '@components/framework/tags/RequestedPriceTag.tsx';
import SentBadge from '@components/ringil3/Badges/SentBadge';
import SpotQuoteTable from '@components/ringil3/Features/Expedition/QuoteTable/SpotQuoteTable';
import { ExpeditionDetailV4OutDTO, ExpeditionStatusEnum, FeaturesEnum } from '@api/logsteo-api.v2';
import PanelWithTextAndIcon from '@components/ringil3/Panels/PanelWithTextAndIcon';
import AddButton from '@components/ringil3/Buttons/AddButton';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import { useCreateAddMoreCarriers } from '@components/ringil3/Features/Expedition/Sidebars/AddMoreCarriersSidebar.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { createLocalDateTime } from '@utils/date.tsx';
import { ApiContext } from '@api/api.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import LadenFactorTag from '@components/framework/tags/LadenFactorTag.tsx';
import RequestedCarriersTag from '@components/framework/tags/RequestedCarriersTag.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV4OutDTO;
  onReload: () => void;
  heading?: string;
  showPrices?: boolean;
  showMessage: boolean;
  editMessages: boolean;
  canEdit?: boolean;
}

const TabExpeditionQuotes: React.FC<ComponentProps> = ({
                                                         expedition,
                                                         onReload,
                                                         heading,
                                                         canEdit,
                                                         showPrices,
                                                         editMessages,
                                                         showMessage,
                                                       }) => {
  const { tr } = useTranslationLgs();
  const { changeExpeditionDistributionDate } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const { AddMoreCarriers, setVisible: setVisibleAddMoreCarriers } = useCreateAddMoreCarriers();

  const freeManipulationUnits = React.useMemo(() => {
    if (!expedition?.handlingUnitCount || !expedition.ladenFactorExpeditions?.length) {
      return expedition?.handlingUnitCount || 0;
    }

    const ladenUnits = expedition.ladenFactorExpeditions.reduce((sum, ladenExpedition) => {
      return sum + (ladenExpedition.manipulationUnits || 0);
    }, 0);

    return Math.max(0, expedition.handlingUnitCount - ladenUnits);
  }, [expedition?.handlingUnitCount, expedition?.ladenFactorExpeditions]);

  const ladenFactorEnabled = loggedUser?.features?.some(t => t === FeaturesEnum.LADEN_FACTOR);


  return (
    <>
      <ColWithGap>
        <COHeadline
          variant={'h2'}
          title={
            heading ? heading : tr(`ExpeditionDetail.assignedCarriers`, `Selected carriers`)
          }
          icon={<SvgLkw />}
        ></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            <RequestedCarriersTag count={expedition.quotes.length} />
            <DistributionValidTillTag
              onChanged={v => {
                changeExpeditionDistributionDate(
                  expedition.id,
                  {
                    localDateTime: createLocalDateTime(v),
                    timeZone: v.timeZone,
                  },
                  () => onReload(),
                );
              }}
              readonly={!canEdit || freeManipulationUnits === 0}
              dateTimeResponse={expedition?.distribution?.validTill}
            />
            {expedition.distribution?.distributionType === 'SPOT' && (
              <ExpectedPriceTag
                price={expedition.distribution?.price}
                currency={expedition.distribution?.currency}
                expeditionId={expedition.id}
                onReload={onReload}
                readOnly={!canEdit || freeManipulationUnits === 0}
                showPrices={showPrices}
              />
            )}
            {expedition.distribution?.distributionType === 'FIXED_PRICE' && (
              <RequestedPriceTag price={expedition.distribution?.price} currency={expedition.distribution?.currency}
                                 showPrices={showPrices} readOnly={!canEdit} />
            )}
            {ladenFactorEnabled && expedition.ladenFactor && (
              <LadenFactorTag ladenFactor={expedition.ladenFactor} />
            )
            }
          </Row>
          <BadgeRow>
            <SentBadge fullName={expedition.distribution?.createdByName} sentAt={expedition.distribution?.createdAt} />
          </BadgeRow>
        </RowForTagsAndBadges>
        {expedition.quotes?.length > 0 && (
          <SpotQuoteTable
            quotes={expedition.quotes}
            onChanged={() => onReload()}
            expeditionId={expedition.id}
            companyId={expedition.customerId}
            showPrices={showPrices}
            showMessages={showMessage}
            editMessages={editMessages}
            editTransportation={canEdit}
            ladenfactor={expedition.ladenFactor}
            freeManipulationUnits={freeManipulationUnits}
          ></SpotQuoteTable>
        )}

        {expedition.expeditionStatus === ExpeditionStatusEnum.ASSIGNING && canEdit && freeManipulationUnits > 0 && (
          <PanelWithTextAndIcon
            onClick={() => {
              setVisibleAddMoreCarriers(true);
            }}
            text={tr(`ExpeditionDetail.addAnotherCarrier`, `Add another carrier`)}
            iconButton={<AddButton onClick={() => setVisibleAddMoreCarriers(true)} />}
          />
        )}
      </ColWithGap>
      <AddMoreCarriers
        applicationId={expedition.applicationId}
        customerId={expedition.customerId}
        onContinue={() => {
          onReload();
        }}
      />
    </>
  );
};

const BadgeRow = styled.div`
  display: flex;
  gap: var(--ringil-cyan-gap);
`;

export default TabExpeditionQuotes;
