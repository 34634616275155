import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Anchor, InputAndButtonGrid, OrderWrapper } from '@components/ringil3/styles.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { findValidationMessage, findValidationMessageRaw } from '@utils/validation.tsx';
import { InputTextarea } from 'primereact/inputtextarea';
import { RowWithGap } from '@components/styles.tsx';
import DeleteButton from '@components/ringil3/Buttons/DeleteButton.tsx';
import InputSKU from '@components/ringil3/Input/InputSKU/InputSKU.tsx';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix.tsx';
import { isNotNullOrUndefined, parseNumberOrUndefined } from '@utils/utils.tsx';
import InputStackable from '@components/ringil3/Input/InputStackable/InputStackable.tsx';
import InputNoteWithLink from '@components/ringil3/Input/InputNoteWithLink/InputNoteWithLink.tsx';
import AddOrderItemButton from '@components/ringil3/Features/Expedition/AddOrderItemButton/AddOrderItem.tsx';
import AddOrderButton from '@components/ringil3/Features/Expedition/AddOrderButton/AddOrderButton.tsx';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import { fullRequiredConfig, OrdersConfig } from '@components/framework/orders/validation.ts';
import VisibitityComponent from '@components/framework/orders/VisibitiyComponent.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import SvgLoadDown from '@icons/LoadDown.tsx';
import { Dropdown } from 'primereact/dropdown';
import produce from 'immer';
import { ValidationError } from 'yup';
import { InputNumber } from 'primereact/inputnumber';
import FoldableEanCodes from '@components/ringil3/FoldableEanCodes/FoldableEanCodes.tsx';

interface ComponentProps {
  config?: OrdersConfig;
  canBeEmpty?: boolean;
  generateOrderId?: boolean;
  onOrderAdded?: (orderId: string) => void;
  onDeleteOrder?: (orderId: string) => void;
  allUnloadingLocations?: { id: string; name: string }[];
  onChange?: (orders: Order[]) => void;
  orders: Order[];
  validationErrors: ValidationError[];
}

const OrdersComponent: React.FC<ComponentProps> = ({
  config = fullRequiredConfig,
  canBeEmpty = false,
  generateOrderId = false,
  onOrderAdded = (a: string) => {},
  onDeleteOrder = (a: string) => {},
  orders,
  onChange,
  validationErrors,
  allUnloadingLocations,
}) => {
  const { tr } = useTranslationLgs();

  const addOrder = () => {
    const order = emptyOrder(config.cargo.required, generateOrderId);
    onChange([...orders, order]);

    /*form.setFieldValue(form.names.orders, [...form.values.orders, order], () => {
      onOrderAdded(order.id);
    });*/
  };

  const deleteOrder = (orderIndex: number) => {
    onChange(orders.filter((_, i) => i !== orderIndex));
  };

  const addOrderItem = (orderIndex: number) => {
    onChange(
      produce(orders, draft => {
        draft[orderIndex].items = [
          ...draft[orderIndex].items,
          {
            itemNote: null,
            amount: null,
            cargoTemplateCode: null,
            stackable: false,
            decimalPlaces: 0,
          },
        ];
      }),
    );
  };

  const deleteOrderItem = (orderIndex: number, itemIndex: number) => {
    onChange(
      produce(orders, draft => {
        draft[orderIndex].items = draft[orderIndex].items.filter((_, i) => i !== itemIndex);
      }),
    );
  };

  const processChangeCargo = (orderIndex: number, orderItemIndex: number, v: CargoTemplateWithoutIdDTO) => {
    if (v) {
      onChange(
        produce(orders, draft => {
          draft[orderIndex].items[orderItemIndex].cargoTemplateCode = v?.code;
          draft[orderIndex].items[orderItemIndex].decimalPlaces = v?.decimalPlaces;
          draft[orderIndex].items[orderItemIndex].width = v.width;
          draft[orderIndex].items[orderItemIndex].height = v.height;
          draft[orderIndex].items[orderItemIndex].length = v.length;
          draft[orderIndex].items[orderItemIndex].weight = v.weight;
        }),
      );
    }
  };

  return (
    <div className={'flex flex-col gap-2'}>
      {orders?.map((order, orderIndex) => {
        return (
          <OrderWrapper key={orderIndex}>
            <Anchor id={`order_${orderIndex}`} className={'ordersTabsContent'} />
            <COHeadline variant={'h3'} title={tr(`CreateTimeslotSidebar.oder`, `Order`)}></COHeadline>
            <Field
              required={config.orderName.required}
              label={tr(`CreateTimeslotSidebar.order`, `Order`)}
              errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].orderName`, tr)}
            >
              <InputAndButtonGrid>
                <InputTextarea
                  value={order.orderName}
                  style={{ width: '100%' }}
                  onChange={e => {
                    onChange(
                      produce(orders, draft => {
                        draft[orderIndex].orderName = e.target.value.trim() ? e.target.value : null;
                      }),
                    );
                  }}
                />
                {(orders.length > 1 || canBeEmpty == true) && (
                  <RowWithGap>
                    <DeleteButton onClick={() => deleteOrder(orderIndex)} />
                  </RowWithGap>
                )}
              </InputAndButtonGrid>
            </Field>
            {config.cargo?.visibility !== 'never' && (
              <>
                {findValidationMessageRaw(validationErrors, `orders[${orderIndex}].items`) && (
                  <COInlineMessage variant={'error'}>{findValidationMessageRaw(validationErrors, `orders[${orderIndex}].items`)?.message}</COInlineMessage>
                )}
                {order.items.map((item, itemIndex) => {
                  return (
                    <React.Fragment key={itemIndex}>
                      <div className={'grid grid-cols-[2fr,1fr] gap-2'}>
                        <div className={'flex-grow'}>
                          <Field
                            required={config.cargo.required}
                            label={tr(`CreateTimeslotSidebar.manipulationUnit`, `Manipulation unit SKU`)}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].cargoTemplateCode`, tr)}
                          >
                            <InputSKU
                              fullWidth
                              value={item.cargoTemplateCode}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    if (isNotNullOrUndefined(draft[orderIndex].items)) {
                                      draft[orderIndex].items[itemIndex] = item;
                                    }
                                    draft[orderIndex].items[itemIndex].cargoTemplateCode = v;
                                  }),
                                );
                              }}
                              onChangeCargoTemplate={v => processChangeCargo(orderIndex, itemIndex, v)}
                            />
                          </Field>
                        </div>
                        <div className={'flex-grow-0'}>
                          <Field
                            required={config.cargo.required}
                            label={tr(`CreateTimeslotSidebar.amount`, `Amount`)}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].amount`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={tr('OrdersComponent.ks', 'ks')}
                              inputTextPosition={'right'}
                              type={item.cargoTemplateCode}
                              value={item.amount}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    draft[orderIndex].items[itemIndex].amount = parseNumberOrUndefined(v);
                                  }),
                                );
                              }}
                              button={(config.cargo.required ? itemIndex > 0 : true) && <DeleteButton onClick={() => deleteOrderItem(orderIndex, itemIndex)} />}
                            >
                              <InputNumber
                                value={item.amount}
                                onChange={v => {
                                  onChange(
                                    produce(orders, draft => {
                                      draft[orderIndex].items[itemIndex].amount = v.value;
                                    }),
                                  );
                                }}
                                maxFractionDigits={item.decimalPlaces}
                                className={'w-full outline-none no-spinner h-[42px]'}
                                style={{ margin: 0 }}
                              />
                            </InputTextWithSuffix>
                          </Field>
                        </div>
                      </div>
                      <InputStackable
                        stackable={item.stackable}
                        onChange={v => {
                          onChange(
                            produce(orders, draft => {
                              draft[orderIndex].items[itemIndex].stackable = v;
                            }),
                          );
                        }}
                      />
                      <InputNoteWithLink
                        note={item.itemNote}
                        onChanges={v => {
                          onChange(
                            produce(orders, draft => {
                              draft[orderIndex].items[itemIndex].itemNote = v;
                            }),
                          );
                        }}
                        noteLabel={tr(`CreateTimeslotSidebar.orderItemNote`, `Order item note`)}
                      />

                      <VisibitityComponent
                        visibility={config.cargoDimensions.visibility}
                        switchComponent={<LinkWithIcon label={tr('OrdersComponent.rozmeryAHmotnost', 'Dimensions and weight')} icon={<SvgIconPlus />} />}
                        defaultOpen={
                          config.cargoDimensions.visibility === 'optional' && (item.width != null || item.height != null || item.length != null || item.weight != null)
                        }
                      >
                        <div className={'grid grid-cols-2 gap-2'}>
                          <Field
                            label={tr('OrdersComponent.sirkaJednotky', 'Width of unit')}
                            required={config.cargoDimensions.items.width.required}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].width`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.width}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    draft[orderIndex].items[itemIndex].width = parseNumberOrUndefined(v);
                                  }),
                                );
                              }}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.vyskaJednotky', 'Height of unit')}
                            required={config.cargoDimensions.items.height.required}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].height`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.height}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    draft[orderIndex].items[itemIndex].height = parseNumberOrUndefined(v);
                                  }),
                                );
                              }}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.delka(celkova)', 'Length (total)')}
                            required={config.cargoDimensions.items.length.required}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].length`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'cm'}
                              fullWidth
                              type={'number'}
                              value={item.length}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    draft[orderIndex].items[itemIndex].length = parseNumberOrUndefined(v);
                                  }),
                                );
                              }}
                            />
                          </Field>
                          <Field
                            label={tr('OrdersComponent.hmotnost', 'Weight')}
                            required={config.cargoDimensions.items.weight.required}
                            errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].items[${itemIndex}].weight`, tr)}
                          >
                            <InputTextWithSuffix
                              suffix={'kg'}
                              fullWidth
                              type={'number'}
                              value={item.weight}
                              onChange={v => {
                                onChange(
                                  produce(orders, draft => {
                                    draft[orderIndex].items[itemIndex].weight = parseNumberOrUndefined(v);
                                  }),
                                );
                              }}
                            />
                          </Field>
                        </div>
                      </VisibitityComponent>
                    </React.Fragment>
                  );
                })}

                <AddOrderItemButton onClick={() => addOrderItem(orderIndex)} />
              </>
            )}
            <VisibitityComponent
              visibility={config.cargoDetails?.visibility}
              switchComponent={<LinkWithIcon label={tr('OrdersComponent.informaceOZbozi', 'Information about goods')} icon={<SvgIconPlus />}></LinkWithIcon>}
              defaultOpen={
                config.cargoDetails?.visibility === 'optional' && (order.orderAmount != null || order.orderDescription != null || order.orderValue?.price != null)
              }
            >
              <>
                <div className={'grid grid-cols-2 gap-2'}>
                  <Field
                    label={tr(`CreateTimeslotSidebar.productsInOrder`, `Products in order`)}
                    errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].orderAmount`, tr)}
                  >
                    <InputTextWithSuffix
                      suffix={'ks'}
                      type={'number'}
                      value={order.orderAmount}
                      onChange={v => {
                        onChange(
                          produce(orders, draft => {
                            draft[orderIndex].orderAmount = parseNumberOrUndefined(v);
                          }),
                        );
                      }}
                      fullWidth
                    />
                  </Field>
                  <Field
                    label={tr(`CreateTimeslotSidebar.productDescription`, `Description`)}
                    errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].orderDescription`, tr)}
                  >
                    <RingilInputText
                      value={order.orderDescription ? order.orderDescription : ''}
                      onChange={value => {
                        onChange(
                          produce(orders, draft => {
                            draft[orderIndex].orderDescription = value;
                          }),
                        );
                      }}
                    />
                  </Field>
                </div>
                <Field
                  label={tr('OrdersComponent.hodnotaZbozi', 'Value of goods')}
                  required={config.cargoDetails.items.orderValue.required}
                  errorMessage={findValidationMessage(validationErrors, `orders[${orderIndex}].orderValue.price`, tr)}
                >
                  <InputPriceWithCurrency
                    price={order.orderValue?.price}
                    currency={order.orderValue?.currency}
                    changePrice={v => {
                      onChange(
                        produce(orders, draft => {
                          draft[orderIndex].orderValue = {
                            currency: draft[orderIndex].orderValue?.currency,
                            price: v,
                          };
                        }),
                      );
                    }}
                    changeCurrency={v => {
                      onChange(
                        produce(orders, draft => {
                          draft[orderIndex].orderValue = {
                            currency: v,
                            price: draft[orderIndex].orderValue?.price,
                          };
                        }),
                      );
                    }}
                  />
                </Field>
              </>
            </VisibitityComponent>
            <InputNoteWithLink
              note={order.orderNote}
              onChanges={v => {
                onChange(
                  produce(orders, draft => {
                    draft[orderIndex].orderNote = v;
                  }),
                );
              }}
              noteLabel={tr(`CreateTimeslotSidebar.orderNote`, `Order note`)}
            />

            <FoldableEanCodes eanCodes={order.eanCodes} />

            {/*<HorizontalLine variant={'normal'} />*/}
            {config.totalWeight.render === true && (
              <>
                {order.items?.length > 0 ? (
                  <Field
                    readOnly={true}
                    label={tr(`CreateTimeslotSidebar.totalWeight`, `Total weight`)}
                    errorMessage={findValidationMessage(validationErrors, `totalWeight`, tr)}
                  >
                    <InputTextWithSuffix
                      suffix={'kg'}
                      readOnly={true}
                      value={order.items.reduce((acc, item) => acc + (item.weight || 0), 0)}
                      onChange={() => {}}
                      fullWidth
                      type={'number'}
                    />
                  </Field>
                ) : (
                  <Field
                    readOnly={false}
                    label={tr(`CreateTimeslotSidebar.totalWeight`, `Total weight`)}
                    errorMessage={findValidationMessage(validationErrors, `totalWeight`, tr)}
                  >
                    <InputTextWithSuffix
                      suffix={'kg'}
                      readOnly={true}
                      value={order.orderTotalWeight}
                      onChange={v => {
                        const newValue = produce(orders, draft => {
                          draft[orderIndex].orderTotalWeight = parseNumberOrUndefined(v);
                        });

                        onChange(newValue);
                      }}
                      fullWidth
                      type={'number'}
                    />
                  </Field>
                )}
              </>
            )}

            {config.unloadingLocation.render === true && (
              <>
                <COHeadline title={tr('OrdersComponent.vylozitObjednavku', 'Unload order')} variant={'h4'} icon={<SvgLoadDown />} />
                <Dropdown
                  value={order.unloadingLocationId}
                  options={allUnloadingLocations}
                  optionLabel={'name'}
                  optionValue={'id'}
                  onChange={e => {
                    onChange(
                      produce(orders, draft => {
                        draft[orderIndex].unloadingLocationId = e.value;
                      }),
                    );
                  }}
                />
              </>
            )}
            {orderIndex == orders.length - 1 && <AddOrderButton onClick={() => addOrder()} />}
          </OrderWrapper>
        );
      })}
    </div>
  );
};

export default OrdersComponent;
