import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { State } from '@api/logsteo-api.v2';
import { Lkw } from '@components/ringil3/icons/heading';
import SvgActive from '@components/ringil3/icons/workflow/Active';
import { dumpVars } from '@utils/utils';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation';
import SvgCustomer from '@components/ringil3/icons/Customer';
import NoteTag from '@components/framework/tags/NoteTag.tsx';

interface ComponentProps {
  note: string;
  onChange: (note: string) => void;
  readOnly: boolean;
}

const ReservationCarrierNote: React.FC<ComponentProps> = ({ note, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      <NoteTag
        label={tr(`ReservationCarrierNote.title`, `Note for carrier`)}
        note={note}
        onChangeNote={v => {
          onChange(v);
        }}
        readOnly={readOnly}
      />
    </>
  );
};

export default ReservationCarrierNote;
