import { DistributionView } from '@type/types';
import {
  CargoIn14DTO,
  CargoPreviewPlanOutDTO,
  DistributionInDTO,
  ExpeditionIn14DTO,
  LocationIn14DTO,
  LocationOrderIn14DTO,
  LovWithoutIdDTO,
  TruckTypeWithoutIdDTO,
  WeightModeEnum,
} from '@api/logsteo-api.v2';
import { Location, NewExpeditionView, OrderItemViewData, OrderViewData } from '@components/obsolete/customer/new-expedition/types';
import { mapToAPIDateTime } from '@utils/date';

export const findTruckType = (code: string, trucks: TruckTypeWithoutIdDTO[]): TruckTypeWithoutIdDTO => {
  return trucks.find(truckType => truckType.code === code);
};

export const returnTruckDimensions = (id: string, trucks: TruckTypeWithoutIdDTO[]) => {
  const truckType = findTruckType(id, trucks);
  return `${truckType.height || '-'} x ${truckType.width || '-'} x ${truckType.length || '-'} cm`;
};

export const returnTruckName = (id: string, trucks: TruckTypeWithoutIdDTO[]) => {
  const truckType = findTruckType(id, trucks);
  return `${truckType.name}`;
};

export const returnTruckLoadCapacity = (id: string, trucks: TruckTypeWithoutIdDTO[]) => {
  const truckType = findTruckType(id, trucks);

  return `${truckType.loadingCapacity || '-'}${truckType.loadingUnit}`;
};

export const resolveSpecialRequirement = (code: string, specialRequirements: LovWithoutIdDTO[]): LovWithoutIdDTO => {
  return specialRequirements.find(sr => sr.code === code);
};

export const mapCodeToCargoItem = (cargoTypes: LovWithoutIdDTO[], code: string): LovWithoutIdDTO => {
  return cargoTypes.find(ct => ct.code === code);
};

export const calculateOrderWeight = (orderItem: OrderItemViewData) =>
  orderItem.weightMode === WeightModeEnum.TOTAL_WEIGHT ? orderItem.weight : orderItem.weight * orderItem.amount;

export const calculateOrderWeightApi = (orderItem: CargoPreviewPlanOutDTO) =>
  orderItem.weightMode === WeightModeEnum.TOTAL_WEIGHT ? orderItem.weight : orderItem.weight * orderItem.amount;

function mapToDistribution(distribution: DistributionView): DistributionInDTO {
  return {
    distributionType: distribution.distributionType,
    selectAll: false,
    validTill: mapToAPIDateTime(distribution.responseDeadline),
    selectedItems: distribution.selectedItems,
    price: distribution.price,
    currency: distribution.currency || 'CZK',
    isPublish: distribution.isPublished,
  } as DistributionInDTO;
}

export const mapToSaveExpedition = (view: NewExpeditionView): ExpeditionIn14DTO => {
  const mapped: ExpeditionIn14DTO = {
    cargoType: view.cargoType,
    loadingTypes: view.manipulationTypes,
    note: view.carrierNote,
    truckType: view.truckType,
    requirements: view.specialRequirements,
    responsiblePerson: view.responsiblePerson?.id,
    expeditionType: view.expeditionType,
    internalNote: view.internalNote,
    subscribersList: view.subscribersList.map(t => t.id),
    locations: view.locations.map((location: Location, locationIndex) => mapToSaveExpeditionLocation(location, locationIndex)),
    distribution: view.distribution.enabled ? mapToDistribution(view.distribution) : null,
    invoiceDepartment: view.invoiceDepartment,
    invoicePartnerId: view.invoicePartnerId,
    invoiceRebillingType: view.invoiceRebillingType,
    invoiceState: view.invoiceState,
    invoiceUser: view.invoiceUserId,
    isRoundtrip: false,
    labelIds: view.labelCodes,
    templateFromId: view.expeditionTemplateId,
    fromSupplierNotificationId: view.fromSupplierNotificationId,
    fromSupplierManifestId: view.fromSupplierManifestId,
    pairingSymbol: view.pairingSymbol,
    adrWeight: view.adrWeight,
    duplicatedFromExpeditionId: view.duplicatedFromExpeditionId,
    copySNAttachments: view.copySNAttachments,
    linkToSN: view.linkToSN,
    copyAttachments: view.copyAttachments,
  };

  return mapped;
};

/*const generateTimeslots = (timeslots: TimeSlotView) => {
  if (timeslots.showTimes === false) {
    // we have to generate timeslots
    return daysInterval(timeslots.dateFrom, timeslots.dateTo).map((day) => {
      return {
        whenInUtc: day.format().substr(0, 10),
      } as DeliveryWindowIn14DTO;
    });
  } else {
    return timeslots.timeslots
      .filter((ts) => ts.disabled === false)
      .map((timeslot) => mapToSaveExpeditionTimeSlot(timeslot))
      .flat(1);
  }
};*/

const mapToSaveExpeditionLocation = (view: Location, locationIndex: number): LocationIn14DTO => {
  return {
    city: view.city,
    contactEmail: view.contactEmail,
    contactName: view.contactName,
    contactPhone: view.contactPhone,
    country: view.country,
    id: locationIndex,
    name: view.name,
    pickupCode: view.carrierCode,
    postalCode: view.zipCode,
    streetNr: view.streetNr,
    timeslots: view.timeslots,
    loadedOrders: view.loadingOrders.map(order => mapToSaveExpeditionOrders(order)),
    locationId: view.locationId,
    contactType: view.contactType,
    enabledEmailNotificationForContactPerson: view.enabledEmailNotificationForContactPerson,
    additionalNotificationContacts: view.additionalNotificationPersons
      ? view.additionalNotificationPersons.map(t => ({
          contactEmail: t.contactEmail,
          contactName: t.contactName,
          contactPhone: t.contactPhone,
        }))
      : [],
  };
};

/*const mapToSaveExpeditionTimeSlot = (view: TimeSlotItem): DeliveryWindowIn14DTO[] => {
  return view.intervals

    .map((interval) => {
      return { day: view.day, from: interval.from, to: interval.to };
    })
    .map((item) => {
      return {
        whenInUtc: setTime(item.day, item.from).utc().format('YYYY-MM-DD'),
        sinceInUtc: getTime(setTime(item.day, item.from).utc()),
        untilInUtc: getTime(setTime(item.day, item.to).utc()),
      };
    });
};*/

const mapToSaveExpeditionOrders = (order: OrderViewData): LocationOrderIn14DTO => {
  return {
    loadingLocationId: order.loadingLocationId,
    unloadingLocationId: order.unloadingLocationId,
    name: order.name,
    totalWeight: order.totalWeight,
    items: order.items.map(orderItem => mapToSaveExpeditionOrderItem(orderItem)),
  };
};

const mapToSaveExpeditionOrderItem = (orderItem: OrderItemViewData): CargoIn14DTO => {
  return {
    cargoItemTypeCode: orderItem.cargoItemType.code,
    amount: orderItem.amount,
    height: orderItem.height,
    length: orderItem.length,
    weight: orderItem.weight,
    quantityUnit: orderItem.quantityUnit,
    stackable: orderItem.stackable,
    weightMode: orderItem.weightMode,
    width: orderItem.width,
    itemNote: orderItem.itemNote,
  };
};
