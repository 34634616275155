import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { GetInfoCancelExpeditionResponseOutDTO, LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { ColWithGap } from '@components/styles';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { Trans } from 'react-i18next';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo.tsx';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { Dropdown } from 'primereact/dropdown';
import { Field } from '@components/framework/formfield/Field.tsx';
import { findValidationMessage } from '@utils/validation.tsx';

interface ComponentProps {
  onContinue: () => void;
}

interface ExpeditionCancelationRequest {
  customerId: string;
  applicationId: string;
}

interface Form {
  cancelationCode: string;
  cancelReservations: boolean;
}

export const useCreateCancelExpeditionSidebar = () => {
  const [cancelationRequest, setCancelationRequest] = useState<ExpeditionCancelationRequest>();

  const CancelExpeditionSidebar: React.FC<ComponentProps> = ({ onContinue }) => {
    const { tr } = useTranslationLgs();
    const { cuCancelExpedition, getInfoCancelExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [linkedReservations, setLinkedReservations] = useState<GetInfoCancelExpeditionResponseOutDTO>();

    useEffect(() => {
      if (isNotNullOrUndefined(cancelationRequest)) {
        getInfoCancelExpedition(cancelationRequest.applicationId, cancelationRequest.customerId, (d) => {
          setLinkedReservations(d);
        });
      }
    }, [cancelationRequest]);

    const cancellationReasons: LovWithoutIdDTO[] = [
    {
      name: tr('CancelExpeditionReasonHIGHER_POWERS', 'Vyssi moc'),
      code: 'HIGHER_POWERS'
    },
    {
      name: tr('CancelExpeditionReasonCANCELED_BY_CUSTOMER', 'Zruseno zakaznikem'),
      code: 'CANCELED_BY_CUSTOMER'
    },
    {
      name: tr('CancelExpeditionReasonCANCELED_BY_CARRIER', 'Zruseno dopravce'),
      code: 'CANCELED_BY_CARRIER'
    },
    {
      name: tr('CancelExpeditionReasonADDED_TO_ANOTHER_EXPEDITION', 'Doloženo do jiné přepravy'),
      code: 'ADDED_TO_ANOTHER_EXPEDITION'
    },
    {
      name: tr('CancelExpeditionReasonDUPLICITY', 'Duplicita'),
      code: 'DUPLICITY'
    },
    {
      name: tr('CancelExpeditionReasonOTHER_CARRIER', 'Jiný dopravce'),
      code: 'OTHER_CARRIER'
    },
    {
      name: tr('CancelExpeditionReasonLOCATION_CHANGED', 'Záměna lokací'),
      code: 'LOCATION_CHANGED'
    }];


    const schema = yup.object().shape({
      cancelationCode: yup.string().required()
    });

    const form = useForm<Form>(
      schema,
      {
        cancelationCode: null,
        cancelReservations: false
      },
      (d) => save(d),
      false,
      false
    );

    const save = (data: Form) => {
      cuCancelExpedition(
        cancelationRequest.customerId,
        cancelationRequest.applicationId,
        { reasonCode: data.cancelationCode, deleteReservations: data.cancelReservations },
        () => {
          onContinue();
          setCancelationRequest(null);
        }
      );
    };

    if (!isNotNullOrUndefined(cancelationRequest) || isNullOrUndefined(linkedReservations)) return null;

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(cancelationRequest)}
          onBack={() => setCancelationRequest(null)}
          onContinue={() => form.validateAndSend()}
          heading={tr("CancelExpeditionSidebar.zruseniPrepravy", "Zrušení přepravy")}>

          <BackendValidationComponent />
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr(`RequestCarrier.forExpedition`, `For expedition`)}
              valueText={cancelationRequest.applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'} />

            <COInlineMessage variant={'info'} icon={<SvgGrayInfo />}>
              <Trans
                i18nKey="CancelExpeditionSidebar.warning"
                defaults={
                'Zrušením této přepravy ji přesunete do stavu “Zrušeno”. Nebude možné provádět žádné další akce a všichni dopravci v této distribuci budou informování, že jejich cenové nabídky byly odmítnuty. '
                }>
              </Trans>
            </COInlineMessage>
            <Field label={tr("CancelExpeditionSidebar.duvodZruseni", "Důvod zrušení")} errorMessage={findValidationMessage(form.validationErrors, form.names.cancelationCode, tr)}>
              <Dropdown
                options={cancellationReasons}
                optionValue={'code'}
                optionLabel={'name'}
                value={form.values.cancelationCode}
                style={{ width: '100%' }}
                onChange={(e) => form.setFieldValue(form.names.cancelationCode, e.value)} />

            </Field>
            {linkedReservations?.isReservationPresent &&
            <InputCheckbox
              label={tr("CancelExpeditionSidebar.smazatPropojeneRezervace", "Smazat propojené rezervace")}
              value={form.values.cancelReservations}
              onChange={(_, v) => form.setFieldValue('cancelReservations', v)}
              note={linkedReservations?.isReservationPresent ? linkedReservations.reservations.map((t) => t.applicationId).join(', ') : null} />

            }
          </ColWithGap>
        </RingilSidebar>
      </>);

  };

  return { CancelExpeditionSidebar, setCancelationRequest, cancelationRequest };
};