import React, { useContext } from 'react';
import { ActionTypeEnum, ExpeditionRouteV4Response } from '@api/logsteo-api.v2';
import RingilDataTable from '@components/ringil3/RingilDataTable/RingilDataTable';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import styled, { css } from 'styled-components';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { isNullOrUndefined } from '@utils/utils';
import { RowWithGap } from '@components/styles';
import { Clickable } from '@components/ringil3/styles';
import LinkedResBadge from '@components/ringil3/Badges/LinkedResBadge';
import { useCreateCreateReservationDialog } from '@components/ringil3/Features/Expedition/Dialogs/CreateReservationDialog';
import { useCreateAskForCarrierReservation } from '@components/ringil3/Features/Expedition/Sidebars/AskForCarrierReservation';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar';
import CanMakeReservation from '@components/ringil3/Features/Reservation/Helper/CanMakeReservation';
import { ApiContext } from '@api/api';

interface ComponentProps {
  onChanged: () => void;
  routes: ExpeditionRouteV4Response[];
  applicationId: string;
  companyId: string;
  canEdit: boolean;
}

const RouteTable: React.FC<ComponentProps> = ({ onChanged, routes, applicationId, companyId, canEdit }) => {
  const { tr } = useTranslationLgs();
  const { sendExpeditionTimeslotReminder } = useContext(ApiContext);

  const { CreateReservationDialog, setCreateTimeslotRequest: setCreateTimeslotFirstRequest } = useCreateCreateReservationDialog();
  const { CreateTimeslotSidebar, setCreateTimeslotRequest, createTimeslotRequest } = useCreateTimeslotSidebar();
  const { setVisible: setVisibleAskForCarrierReservation } = useCreateAskForCarrierReservation();

  const renderPosition = (row: ExpeditionRouteV4Response, index: number): JSX.Element => {
    return <Position>{index + 1}.</Position>;
  };

  const renderPlace = (row: ExpeditionRouteV4Response): JSX.Element => {
    return (
      <>
        <div>
          {row.routeName} {row.companyName && <>({row.companyName})</>}
        </div>
        <div>{row.addressLine}</div>
      </>
    );
  };

  const renderAction = (row: ExpeditionRouteV4Response): JSX.Element => {
    return (
      <>
        <>
          {row.actionType.map((action, actionIndex) => (
            <COBadge key={actionIndex} type={action == ActionTypeEnum.LOADING ? 'loading' : 'unloading'} variant={'filled'}>
              {tr(`ExpeditionDetail.action.${action}`, action)}
            </COBadge>
          ))}
        </>
      </>
    );
  };

  const sendReminder = (locationId: string) => {
    sendExpeditionTimeslotReminder(companyId, applicationId, locationId, () => {});
  };

  const renderCarrierSlot = (row: ExpeditionRouteV4Response): JSX.Element => {
    return (
      <>
        <>
          <div>
            {row.selectedSlot && (
              <>
                <RowWithGap>
                  <CalendarIcon type={'planned'} />
                  <PlannedTime color={computeColor('planned')}>{row.selectedSlot.localDate}</PlannedTime>
                </RowWithGap>
                <RowWithGap>
                  <ClockIcon type={'planned'} />
                  <div>{`${row.selectedSlot.sinceHourString} - ${row.selectedSlot.tillHourString}`}</div>
                </RowWithGap>
              </>
            )}

            <CanMakeReservation companyLocationId={row.companyLocationId}>
              {isNullOrUndefined(row.linkedReservation) && canEdit === true && !row.isPartnerLocation && (
                <Clickable
                  onClick={() =>
                    setCreateTimeslotFirstRequest({
                      applicationId: applicationId,
                      companyLocationId: row.companyLocationId,
                      preferredDate: '',
                      companyLocationCustomerId: row.companyLocationCustomerId,
                      expeditionLocationId: row.expeditionLocationId,
                      expeditionId: row.expeditionId,
                    })
                  }
                >
                  {tr(`RouteTable.createReservation`, `Create reservation`)}
                </Clickable>
              )}
            </CanMakeReservation>

            {row.linkedReservation && <LinkedResBadge linkedReservation={row.linkedReservation} />}
          </div>
          <CreateReservationDialog
            onCreateReservationClick={v => {
              setCreateTimeslotFirstRequest(undefined);
              setCreateTimeslotRequest(v);
            }}
            onAskCarrierClick={() => {
              sendReminder(row.companyLocationId);
              setVisibleAskForCarrierReservation(true);
            }}
          />
        </>
      </>
    );
  };

  const renderActionTime = (row: ExpeditionRouteV4Response): JSX.Element => {
    return (
      <>
        {row.actionSlot && (
          <>
            <RowWithGap>
              <CalendarIcon type={row.isLate ? 'late' : 'on-time'} />
              <PlannedTime color={computeColor(row.isLate ? 'late' : 'on-time')}>{row.actionSlot.localDate}</PlannedTime>
            </RowWithGap>
            <RowWithGap>
              <ClockIcon type={row.isLate ? 'late' : 'on-time'} />
              <PlannedTime color={computeColor(row.isLate ? 'late' : 'on-time')}>{row.actionSlot.hourString}</PlannedTime>
            </RowWithGap>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <RingilDataTable
        data={routes}
        columns={[
          { name: '' },
          { name: tr('ExpeditionDetail.place', 'Place') },
          { name: tr('ExpeditionDetail.type', 'Type') },
          { name: tr('ExpeditionDetail.timeSlot', 'Time slot') },
          { name: tr('ExpeditionDetail.status', 'Status') },
        ]}
        renderers={[renderPosition, renderPlace, renderAction, renderCarrierSlot, renderActionTime]}
      ></RingilDataTable>

      {createTimeslotRequest && (
        <>
          <CreateTimeslotSidebar
            onContinue={() => {
              onChanged();
            }}
          />
        </>
      )}
    </>
  );
};

const computeColor = (type: string) => {
  return type === 'planned' ? 'var(--ringil3-content-tertiary-color)' : type === 'late' ? 'var(--ringil3-complementary-error-color)' : 'var(--ringil3-gr4)';
};

interface Props {
  type: 'planned' | 'late' | 'on-time';
}

const ClockIcon: React.FC<Props> = ({ type }) => {
  const fill = computeColor(type);
  return (
    <>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM7 3.5V8.91421L10.2929 12.2071L11.7071 10.7929L9 8.08579V3.5H7Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

const CalendarIcon: React.FC<Props> = ({ type }) => {
  const fill = computeColor(type);
  return (
    <>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0.5H3V2.5H1V5.5H15V2.5H13V0.5H10V2.5H6V0.5Z" fill={fill} />
        <path d="M15 7.5H1V15.5H15V7.5Z" fill={fill} />
      </svg>
    </>
  );
};

const PlannedTime = styled.div<{ color: string }>`
  ${props =>
    props &&
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const Position = styled.div`
  display: flex;
  color: var(--ringil3-brand-color);
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
`;

export default RouteTable;
