import React, { useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';
import { useImmer } from 'use-immer';
import dayjs, { Dayjs } from 'dayjs';
import { dateFormatOnlyDateShort, shortDateFormat } from '@utils/date';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import TimeslotsProgressInput from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInput.tsx';
import { Label } from '@components/styles.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';

export interface Slot {
  selectedDate?: Dayjs;
  isWholeDay: boolean;
  sinceTime?: string;
  tillTime?: string;
}

export interface LocationTimeSlots {
  city: string;
  zipCode: string;
  country: string;
  name: string;
  id: string;
  useCustomSlots: boolean;
  carrierSlot: Slot;
  customerSlots: Slot[];
}

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  locationsWithSlots: LocationTimeSlots[];
  onConfirm: (data: LocationTimeSlots[]) => void;
}

const EditSlotsComponent: React.FC<ComponentProps> = ({ visible, onHide, locationsWithSlots, onConfirm }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<LocationTimeSlots[]>(locationsWithSlots);

  useEffect(() => {
    if (visible) {
      clear();
      setState(draft => locationsWithSlots);
    }
  }, [visible]);

  const validationScheme = yup.array().of(
    yup.object().shape({
      carrierSlot: yup.object().when('useCustomSlots', {
        is: true,
        then: yup.object().shape({
          selectedDate: yup.object().defined().required(),
          sinceTime: yup.string().defined().required(),
          tillTime: yup.string().defined().required(),
        }),
        otherwise: yup.object().nullable(),
      }),
    }),
  );

  const { clear, validationErrors, validateAndSend } = useValidation(validationScheme, () => onConfirm(state));

  const { i18n } = useTranslation();

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal sidebar-modal-md sidebar-modal-timeslots"
        style={{ width: '750px' }}
      >
        <h3>{t('EditSlotsComponent.shipmentSlots', 'Shipment timeslot')}</h3>
        <div className="">
          {state.map((location, locationIndex) => {
            return (
              <div className="timeslotsProgress" key={locationIndex}>
                <TimeslotsProgressTitle
                  number={`${locationIndex + 1}`}
                  address={`${location.zipCode}, ${location.city}, (${location.country})`}
                  company={location.name}
                />

                <TimeslotsProgressInput
                  title={t('EditSlotsComponent.weAcceptTimeslotsForCustomer', 'Akceptovat časová okna zákazníka')}
                  checked={!location.useCustomSlots}
                  onCheck={() =>
                    setState(draft => {
                      draft[locationIndex].useCustomSlots = false;
                    })
                  }
                >
                  <div>
                    {location.customerSlots.map((customerSlot, slotIndex) => {
                      return (
                        <TimeListItem
                          key={slotIndex}
                          date={dateFormatOnlyDateShort(customerSlot.selectedDate)}
                          isWholeDay={customerSlot.isWholeDay}
                          time={`${customerSlot.sinceTime} - ${customerSlot.tillTime}`}
                        />
                      );
                    })}
                  </div>
                  {/*<div className="p-invalid-feedback">Hodnota musí být zadána</div>*/}
                </TimeslotsProgressInput>
                <TimeslotsProgressInput
                  title={t('EditSlotsComponent.weSpecifyOwnTimeslot', 'Zadáme vlastní čas. okna')}
                  checked={location.useCustomSlots}
                  onCheck={() =>
                    setState(draft => {
                      draft[locationIndex].useCustomSlots = true;
                      draft[locationIndex].carrierSlot = {
                        selectedDate: dayjs(),
                        tillTime: '',
                        sinceTime: '',
                        isWholeDay: false,
                      };
                    })
                  }
                >
                  {location.useCustomSlots && (
                    <>
                      <div className="p-mb-3">
                        <Calendar
                          locale={i18n.language}
                          className="p-mr-4"
                          showIcon
                          style={{ width: '100%' }}
                          disabled={!location.useCustomSlots}
                          dateFormat={shortDateFormat}
                          onChange={e => {
                            setState(draft => {
                              draft[locationIndex].carrierSlot.selectedDate = dayjs(e.value as Date);
                            });
                          }}
                          value={state[locationIndex].carrierSlot?.selectedDate?.toDate()}
                        />
                      </div>
                      <div>
                        <Label title={`${t('EditSlotsComponent.whatTime', 'Kdy')}*`} />
                        <div className="dropdown-wrapper-wdelimiter--half">
                          <div className="item p-mb-1">
                            <InputMask
                              mask={'99:99'}
                              value={state[locationIndex].carrierSlot?.sinceTime || ''}
                              className={'p-md-12'}
                              disabled={!location.useCustomSlots}
                              onComplete={e => {
                                setState(draft => {
                                  draft[locationIndex].carrierSlot.sinceTime = e.value;
                                });
                              }}
                            />
                            <ValidationDiv errors={validationErrors} path={`[${locationIndex}].carrierSlot.sinceTime`} />
                          </div>
                          <div className="delimiter"> —</div>
                          <div className="item p-mb-1">
                            <InputMask
                              mask={'99:99'}
                              value={state[locationIndex].carrierSlot?.tillTime || ''}
                              className={'p-md-12'}
                              disabled={!location.useCustomSlots}
                              onComplete={e => {
                                setState(draft => {
                                  draft[locationIndex].carrierSlot.tillTime = e.value;
                                });
                              }}
                            />
                            <ValidationDiv errors={validationErrors} path={`[${locationIndex}].carrierSlot.tillTime`} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </TimeslotsProgressInput>
              </div>
            );
          })}
        </div>
        <ButtonLayout>
          <Button type="button" label={t('wayPage.form.buttonContinue')} style={{ marginRight: '.25em' }} onClick={() => validateAndSend(state)} />
          <Button type="button" onClick={() => onHide()} label={t('wayPage.form.buttonBack')} className="p-button-text" />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditSlotsComponent;
