import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ExpeditionDetailV4OutDTO } from '@api/logsteo-api.v2';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import ArrangedQuoteTable from '@components/ringil3/Features/Expedition/ArrangedQuoteTable.tsx';
import PriceBadge from '@components/ringil3/Badges/PriceBadge.tsx';
import OrderedExpeditionsBadge from '@components/ringil3/Badges/OrderedExpeditionsBadge.tsx';
import TrophySvg from '@components/ringil3/icons/heading/TrophySvg.tsx';
import RequestedManipulationUnitsBadge from '@components/ringil3/Badges/RequestedManipulationUnitsBadge.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV4OutDTO;
  onReload: () => void;
}

const TabExpeditionArrangedQuotes: React.FC<ComponentProps> = ({ expedition }) => {
  const { tr } = useTranslationLgs();
  const totalPrice = expedition.ladenFactorExpeditions.reduce((sum, expedition) => sum + (expedition.price || 0), 0);
  const currency = expedition.ladenFactorExpeditions[0]?.currency || '';
  const totalManipulationUnits = expedition.ladenFactorExpeditions.reduce((sum, expedition) => sum + (expedition.manipulationUnits || 0), 0);

  return (
    <>
      <ColWithGap>
        <COHeadline
          variant={'h2'}
          title={tr(`TabExpeditionArrangedQuotes.title`, `Arranged quotes`)}
          icon={<TrophySvg />}
        ></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            <PriceBadge price={totalPrice} currency={currency} />
            <OrderedExpeditionsBadge expeditionCount={expedition.ladenFactorExpeditions.length}
                                     manipulationUnits={totalManipulationUnits} />
            <RequestedManipulationUnitsBadge manipulationUnits={expedition.handlingUnitCount} />
          </Row>
        </RowForTagsAndBadges>

        <ArrangedQuoteTable expeditions={expedition.ladenFactorExpeditions}></ArrangedQuoteTable>
      </ColWithGap>
    </>
  );
};

export default TabExpeditionArrangedQuotes;
