import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { NewExpeditionPageComponent } from '@components/obsolete/new-expedition/NewExpeditionPageComponent.tsx';
import { PageID } from '@components/obsolete/new-expedition/types.tsx';

const NewExpeditionPage = () => {
  return (
    <NewExpeditionPageComponent
      initialData={{
        currentPage: PageID.STEP1,
        distribution: { enabled: false, isPublished: true },
        subscribersList: [],
        fromSupplierManifestId: null,
        fromSupplierNotificationId: null,
        labelCodes: [],
        copyAttachments: false,
        copySNAttachments: false,
        linkToSN: false,
        newExp: true,
      }}
    />
  );
};

export default withAuthenticationRequired(NewExpeditionPage);
