import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Badge from '@components/ringil3/Badges/Badge';
import { isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  locationName: string;
}

const LocationToBadge: React.FC<ComponentProps> = ({ locationName }) => {
  const { tr } = useTranslationLgs();
  return (
    <>{isNotNullOrUndefined(locationName) && <Badge filled={false} value={locationName} highlightedValue={false} label={tr(`LocationFromBadge.to`, `To`)}></Badge>}</>
  );
};

export default LocationToBadge;
