import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PermissionTypesEnum } from '@api/logsteo-api.v2';
import Layout from '@app/layout/Layout.tsx';
import { TimeslotCalendar } from '@app/pages/timeslots/Calendar.tsx';
import { CalendarMultiLocation } from '@app/pages/timeslots/CalendarMultiLocation.tsx';

interface ComponentProps {}

const CalendarPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Layout
        title={tr(`calendar.calendar`, `Calendar`)}
        oneOfPermission={[PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARDMULTI_READ, PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARDMULTI_RW]}
      >
        <CalendarMultiLocation />
      </Layout>
    </>
  );
};

export default withAuthenticationRequired(CalendarPage);
