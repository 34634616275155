import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import {
  CargoPreviewPlanOutDTO,
  CargoTemplateWithoutIdDTO,
  ContactTypeEnum,
  LocationDetailPlanOutDTO,
  OpeningEntityTypeEnum,
  TimeSlotDTO,
  WeightModeEnum,
} from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import { mapFromAPIDateTime } from '@utils/date';
import { Dayjs } from 'dayjs';
import { dumpVars, isNullOrUndefined, minMax } from '@utils/utils';
import * as yup from 'yup';
import { findValidationMessage } from '@utils/validation';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { useTranslation } from 'react-i18next';
import { OrderItemViewData } from '@components/obsolete/customer/new-expedition/types.tsx';
import ManageTimeslot, { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import useValidation from '@hooks/validation-hook/useValidation.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper.tsx';
import CheckboxWithLabel from '@components/obsolete/CheckboxWithLabel/CheckboxWithLabel.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { SkuSelector } from '@components/obsolete/new-expedition/SkuSelector';
import { QuantitySelector } from '@components/obsolete/new-expedition/QuantitySelector';
import { DimensionsSelector } from '@components/obsolete/new-expedition/DimensionsSelector';
import { WeightSelector } from '@components/obsolete/new-expedition/WeightSelector';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { StackableWrapper } from '@components/obsolete/Stackable/StackableWrapper';
import { Stackable } from '@components/obsolete/Stackable/Stackable';
import { NewNote } from '@components/obsolete/NewNote/NewNote';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  data: LocationDetailPlanOutDTO;
  locationIndex: number;
  onComplete: (data: LocationDetailPlanOutDTO) => void;
}

const EditLocation: React.FC<ComponentProps> = ({ visible, onHide, data, locationIndex, onComplete }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<LocationDetailPlanOutDTO>(data);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    if (visible)
      setState(draft => {
        return data;
      });
  }, [visible]);

  function minMaxDates(timeslots: TimeSlotDTO[]): {
    min: Dayjs;
    max: Dayjs;
  } {
    const dates = timeslots.map(t => [mapFromAPIDateTime(t.sinceInUTC), mapFromAPIDateTime(t.tillInUTC)]).flat(1);
    return minMax(dates.filter(t => !isNullOrUndefined(t)));
  }

  /* const minMaxData = minMaxDates(state.timeslots);*/

  const mapToOrderViewData = (data: CargoPreviewPlanOutDTO): OrderItemViewData => ({
    amount: data.amount,
    weight: data.weight,
    length: data.length,
    width: data.width,
    quantityUnit: data.quantityUnit,
    height: data.height,
    weightMode: WeightModeEnum[data.weightMode],
    cargoItemType: { code: data.cargoItemTypeCode, name: '', quantity: 0, decimalPlaces: data.decimalPlaces },
    stackable: data.stackable,
    itemNote: data.itemNote,
    decimalPlaces: data.decimalPlaces,
  });

  const changeOrderItemProperty = (orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => {
    setState(draft => {
      // @ts-ignore
      draft.loadedOrders[orderIndex].items[orderItemIndex][propertyName] = value;
    });
  };

  const deleteLoadingUnit = (orderIndex: number, orderItemIndex: number) => {
    setState(draft => {
      draft.loadedOrders[orderIndex].items = draft.loadedOrders[orderIndex].items.filter((orderItem, index) => index !== orderItemIndex);
    });
  };

  const addLoadingUnit = (orderIndex: number) => {
    setState(draft => {
      draft.loadedOrders[orderIndex].items.push({
        quantityUnit: null,
        cargoItemTypeCode: null,
        height: null,
        length: null,
        weight: null,
        width: null,
        amount: null,
        weightMode: null,
        name: null,
        stackable: null,
        decimalPlaces: 0,
      });
    });
  };

  const [disabledProperties, setDisabledProperties] = useState<{
    [key: string]: string[];
  }>({});

  const changeCargoItem = (orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => {
    setState(draft => {
      const orderItem = draft.loadedOrders[orderIndex].items[orderItemIndex];

      orderItem.cargoItemTypeCode = value.code;
      // @ts-ignore
      orderItem.decimalPlaces = value.decimalPlaces;

      const properties = [];

      if (!isNullOrUndefined(value.length)) {
        properties.push('length');
        orderItem.length = value.length;
      }
      if (!isNullOrUndefined(value.height)) {
        properties.push('height');
        orderItem.height = value.height;
      }
      if (!isNullOrUndefined(value.width)) {
        properties.push('width');
        orderItem.width = value.width;
      }
      setDisabledProperties({
        ...disabledProperties,
        [`${orderIndex}-${orderItemIndex}`]: properties,
      });
    });
  };

  const validationSchema = yup.object().shape({
    contactName: yup.string().required(),
    contactEmail: yup.string().email().nullable(),
    loadedOrders: yup.array().of(
      yup.object().shape({
        items: yup.array().of(
          yup.object().shape({
            quantityUnit: yup.string().required(),
            amount: yup.number().required().min(1),
            length: yup.number().required().min(1),
            width: yup.number().required().min(1),
            height: yup.number().required().min(1),
            cargoItemTypeCode: yup.string().required(),
          }),
        ),
      }),
    ),
    timeslots: timeslotValidationSchema(),
  });

  const { clear, validationErrors, validateAndSend, find } = useValidation(validationSchema, () => onComplete(state));
  const { showToastMessage } = useContext(ToastContext);

  useEffect(() => {
    if (validationErrors && validationErrors.length > 0) {
      showToastMessage(
        tr('CreateSupplierNotification.errorOnThePage', 'Error on the page'),
        tr(`CreateSupplierNotification.errorOnThePageDetail`, `There are errors on the page that prevent the form from being submitted`),
        'error',
      );
    }
  }, [validationErrors]);

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal" blockScroll={true} style={{ width: '750px' }}>
        <h3 className="p-mb-4">{t(`EditLocation.upravitPlánLokality`, `Upravit plán lokality`)}</h3>
        <div className="p-mb-4">
          <div className="p-field">
            <Label title={t(`EditLocation.locality`, `Locality`)} />
            <div>{`${state.name}, ${state.address.city} (${locationIndex + 1}. ${t('EditLocation.stop', 'stop')})`}</div>
          </div>
          <div className="p-grid">
            <InputWrapper
              classNameWrapper="p-col-12"
              label={t(`EditLocation.contactPerson`, `Contact person`)}
              type="text"
              InputGroup
              value={state.contactName}
              onChange={value =>
                setState(draft => {
                  const targetValue = (value.target as HTMLInputElement).value;
                  draft.contactName = targetValue;
                })
              }
              error={findValidationMessage(validationErrors, 'contactName', t)}
            />
            <InputWrapper
              classNameWrapper="p-col-6"
              label={t('wayPage.form.labelContactEmail')}
              type="text"
              value={state.contactEmail}
              onChange={value =>
                setState(draft => {
                  const targetValue = (value.target as HTMLInputElement).value;
                  draft.contactEmail = targetValue;
                })
              }
              error={findValidationMessage(validationErrors, 'contactEmail', t)}
            />
            <InputWrapper
              classNameWrapper="p-col-6"
              label={t('wayPage.form.labelContactPhone')}
              type="text"
              value={state.contactPhone}
              onChange={value =>
                setState(draft => {
                  const targetValue = (value.target as HTMLInputElement).value;
                  draft.contactPhone = targetValue;
                })
              }
            />
          </div>
          <CheckboxWithLabel
            checkboxLabel={tr(`EditLocation.sendNotification`, `Send notification`)}
            value={state.enabledEmailNotificationForContactPerson}
            onChange={v =>
              setState(draft => {
                draft.enabledEmailNotificationForContactPerson = v;
              })
            }
            disabled={false}
          />
          <hr />
          {/* <TimeSlotComponent
            onChange={(data) => {
              if (!isInvalid(data)) {
                setState((draft) => {
                  draft.timeslots = mapTimeslots(data);
                });
              }
            }}
            data={{
              dateTo: minMaxData.max,
              dateFrom: minMaxData.min,
              showTimes: state.timeslots.some((t) => !t.isNotSpecifiedTime),
              timeslots: state.timeslots.some((t) => !t.isNotSpecifiedTime)
                ? state.timeslots.reduce((array, value) => {
                    const date = mapFromAPIDateTime(value.sinceInUTC);
                    let def = array.find((slot) => slot.day === date);
                    if (!def) {
                      def = {
                        day: date,
                        hidden: false,
                        disabled: false,
                        intervals: [],
                      } as TimeSlotItem;
                      array.push(def);
                    }

                    def.intervals.push({
                      from: getTime(mapFromAPIDateTime(value.sinceInUTC)),
                      to: getTime(mapFromAPIDateTime(value.tillInUTC)),
                    });

                    return array;
                  }, [] as TimeSlotItem[])
                : [],
            }}
            locationIndex={locationIndex}
            validation={[]}
          />*/}
          <ManageTimeslot
            entityId={state.locationId}
            entityType={
              state.contactType === ContactTypeEnum.COMPANY_LOCATION
                ? OpeningEntityTypeEnum.LOCATION
                : state.contactType === ContactTypeEnum.PARTNER_LOCATION
                ? OpeningEntityTypeEnum.PARTNER_LOCATION
                : null
            }
            timeslot={state.timeslots}
            onChange={data => {
              setState(draft => {
                draft.timeslots = data;
              });
            }}
            noOpeningHours={false}
            validationErrors={validationErrors}
            validationPrefix={`timeslots.`}
          />
        </div>
        {state.loadedOrders.map((loadedOrder, orderIndex, orders) => {
          // @ts-ignore
          return (
            <React.Fragment key={orderIndex}>
              <div className="p-grid p-mb-2 p-ai-start p-formgroup-width-input">
                <HorizontalFieldValue
                  label={t(`OrdersComponent.nazevObjednavky`, `Nazev objednavky`)}
                  value={
                    <InputText
                      value={loadedOrder.orderName}
                      onChange={e =>
                        setState(draft => {
                          draft.loadedOrders[orderIndex].orderName = e.target.value;
                        })
                      }
                    />
                  }
                />
                <HorizontalFieldValue
                  label={t(`OrdersComponent.hmotnostKg`, `Hmotnost [kg]`)}
                  value={
                    <InputNumber
                      value={
                        loadedOrder.items.length > 0
                          ? loadedOrder.items.reduce((curr, value) => {
                              return curr + (value.weightMode === WeightModeEnum.TOTAL_WEIGHT ? value.weight : value.weight * value.amount);
                            }, 0)
                          : loadedOrder.totalWeight
                      }
                      onChange={e => {
                        setState(draft => {
                          draft.loadedOrders[orderIndex].totalWeight = e.value;
                        });
                      }}
                      disabled={loadedOrder.items.length > 0}
                      minFractionDigits={0}
                      maxFractionDigits={2}
                      locale={'cs'}
                    />
                  }
                />
              </div>
              {loadedOrder?.items
                .map(it => mapToOrderViewData(it))
                .map((loadItem, orderItemIndex, orders) => (
                  <div key={orderItemIndex}>
                    <div className="loaded-order-wrapper with-label-height p-fluid p-formgrid p-grid p-flex">
                      <SkuSelector
                        sku={loadItem?.cargoItemType}
                        onChange={value => {
                          changeCargoItem(orderIndex, orderItemIndex, value);
                        }}
                        errorMessage={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].cargoItemTypeCode`)}
                      />
                      <QuantitySelector
                        qty={loadItem.amount}
                        selectedQtyUnitCode={loadItem.quantityUnit}
                        onChangeQty={qty => {
                          changeOrderItemProperty(orderIndex, orderItemIndex, 'amount', qty);
                        }}
                        onChangeQtyUnit={value => {
                          changeOrderItemProperty(orderIndex, orderItemIndex, 'quantityUnit', value);
                        }}
                        qtyUnitError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].quantityUnit`)}
                        qtyError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].amount`)}
                        decimalPlaces={loadItem.cargoItemType?.decimalPlaces}
                      />
                      <div className="p-d-flex p-jc-between p-ml-auto p-mb-3">
                        {orders.length > 1 && (
                          <Button
                            icon="pi pi-trash"
                            className="p-button-outlined p-button-rounded p-ml-auto"
                            onClick={() => {
                              deleteLoadingUnit(orderIndex, orderItemIndex);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-ai-end">
                      <DimensionsSelector
                        width={loadItem.width}
                        height={loadItem.height}
                        length={loadItem.length}
                        onChangeDimensions={(property, value) => changeOrderItemProperty(orderIndex, orderItemIndex, property, value)}
                        disabledInputs={disabledProperties[`${orderIndex}-${orderItemIndex}`]}
                        heightError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].height`)}
                        widthError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].width`)}
                        lengthError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].length`)}
                      />
                    </div>
                    <div className="p-fluid p-formgrid p-grid p-ai-end">
                      <WeightSelector
                        weight={loadItem.weight}
                        weightMode={loadItem.weightMode}
                        onChangeWeight={value => {
                          changeOrderItemProperty(orderIndex, orderItemIndex, 'weight', value);
                        }}
                        onChangeMode={value => {
                          changeOrderItemProperty(orderIndex, orderItemIndex, 'weightMode', value);
                        }}
                        weightError={find(`loadedOrders[${orderIndex}].items[${orderItemIndex}].weight`)}
                      />
                    </div>
                    <StackableWrapper wrapper="p-fluid">
                      <Label title={t('EditLocation.stackable', 'Stohovatelné')} required />
                      <Stackable
                        value={loadItem.stackable}
                        onChange={value => {
                          changeOrderItemProperty(orderIndex, orderItemIndex, 'stackable', value);
                        }}
                      />
                    </StackableWrapper>
                    <NewNote value={loadItem.itemNote} onChange={value => changeOrderItemProperty(orderIndex, orderItemIndex, 'itemNote', value)} />
                  </div>
                ))}
              <ButtonLayout>
                <Button label={t(`EditLocation.addHandlingUnit`, `+ Handling unit`)} className="p-button-outlined p-mr-2" onClick={() => addLoadingUnit(orderIndex)} />
              </ButtonLayout>
            </React.Fragment>
          );
        })}
        <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
          <Button type="button" label={t(`EditLocation.continue`, `Continue`)} style={{ marginRight: '.25em' }} onClick={() => validateAndSend(state)} />
          <Button type="button" label={t('wayPage.form.buttonBack')} className="p-button-text" onClick={onHide} />
        </ButtonLayout>
      </Sidebar>
    </>
  );
};

export default EditLocation;
