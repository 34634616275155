import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { ApiContext } from '@api/api.tsx';
import styled from 'styled-components';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import SvgYellowExclamation from '@components/ringil3/icons/YellowExclamation.tsx';
import SvgTagPlus from '@components/ringil3/icons/TagPlus.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';

interface ComponentProps {
  valueCodes: string[];
  onChange: (v: string[]) => void;
  readOnly?: boolean;
  companyId?: string;
  prefillEnabled?: boolean;
}

interface SpecialRequirementsProps {
}

const RequirementsTag: React.FC<ComponentProps> = ({
                                                     valueCodes,
                                                     onChange,
                                                     readOnly = false,
                                                     companyId = null,
                                                     prefillEnabled = false,
                                                   }) => {
  const { tr } = useTranslationLgs();
  const { lovSpecialRequirementsWithParentData } = useContext(ApiContext);
  const [allRequirements, setAllRequirements] = useState<LovWithoutIdDTO[]>();
  const [internalValue, setInternalValue] = useState(valueCodes);
  const acRef = useRef<AbortController>();

  useEffect(() => {
    acRef.current = new AbortController();

    lovSpecialRequirementsWithParentData(companyId, d => {
      if (acRef.current.signal.aborted) return;
      setAllRequirements(d);

      if (prefillEnabled) {
        const preselectedIds = d
          .filter((requirement) => requirement.isSelected)
          .map((requirement) => requirement.code);

        setInternalValue(preselectedIds)
        onChange(preselectedIds);
      }
    });

    return () => {
      acRef.current?.abort();
      acRef.current = null;
    };
  }, []);

  if (isNullOrUndefined(allRequirements)) return <></>;

  const SpecialRequirements: React.FC<SpecialRequirementsProps> = ({}) => {
    return (
      <>
        <Grid>
          {allRequirements?.map((req, index) => {
            return (
              <InputCheckbox
                key={index}
                value={isNotNullOrUndefined(internalValue?.find(t => t === req.code))}
                onChange={(e, checked) => {
                  if (checked) {
                    setInternalValue([...(internalValue || []), req.code]);
                  } else {
                    setInternalValue([...(internalValue || []).filter(t => t !== req.code)]);
                  }
                }}
                label={req.name}
              />
            );
          })}
        </Grid>
      </>
    );
  };

  const renderValue = () => {
    if (valueCodes?.length > 0) return <MultipleValues
      values={allRequirements?.filter(t => valueCodes.indexOf(t.code) >= 0).map(t => t.name)}></MultipleValues>;
    return null;
  };

  return (
    <>
      <ButtonTag
        icon={valueCodes?.length > 0 ? <SvgYellowExclamation /> : null}
        label={tr(`RequirementsTag.specialRequirements`, `Carrier requirements`)}
        value={renderValue()}
        actionIcon={valueCodes?.length > 0 ? <SvgTagEdit /> : <SvgTagPlus />}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        autoClose
        readonly={readOnly}
      >
        <SpecialRequirements />
      </ButtonTag>
    </>
  );
};

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.4rem;
    column-gap: 2rem;
`;

export default RequirementsTag;
