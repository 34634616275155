import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';
import SvgFlag from '@icons/Flag.tsx';
import { Dropdown } from 'primereact/dropdown';
import useForm from '@hooks/useForm/useForm.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import RingilCalendar from '@components/framework/input/RingilCalendar.tsx';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils.tsx';
import { CustomsStatusEnum } from '@api/logsteo-api.v2.tsx';

export interface CustomsStatusData {
  status: CustomsStatusEnum;
  localDate: string;
}

interface ComponentProps {
  value: CustomsStatusData; // comma separated values
  onChange: (value: CustomsStatusData) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

export const CustomsTagValidationSchema = () => {
  return yup.
  object().
  shape({
    status: yup.string().required(),
    localDate: yup.string().when('status', {
      is: 'CLEARED',
      then: yup.string().required(),
      otherwise: yup.string().nullable()
    })
  }).
  nullable(true);
};

const CustomsStatusTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationPrefix, validationErrors }) => {
  const { tr } = useTranslationLgs();

  const options: CustomsStatusEnum[] = [CustomsStatusEnum.CLEARED, CustomsStatusEnum.SUBMITTED, CustomsStatusEnum.WAITING4INVOICE, CustomsStatusEnum.WAITING4DELIVERY];

  const form = useForm<CustomsStatusData>(
    CustomsTagValidationSchema(),
    value,
    (d, onSuccess) => {
      if (isNotNullOrUndefined(onSuccess)) {
        onSuccess();
      }
      onChange(d);
    },
    false,
    false
  );

  const mapToText = (value: CustomsStatusEnum): string => {
    return tr(`CustomsStatusTag.${value}`, `${value}`);
  };

  const printValue = (value: CustomsStatusData) => {
    if (!value) {
      return null;
    }

    if (value.status === 'CLEARED') return <MultipleValues values={[tr(`CustomsStatusTag.${value.status}`, `${value.status}`), value.localDate]} />;

    return tr(`CustomsStatusTag.${value.status}`, `${value.status}`);
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={tr("CustomsStatusTag.status", "Status")}
        icon={<SvgFlag />}
        value={printValue(value)}
        readonly={readonly}
        errorMessage={hasError ? tr(`CustomsStatusTag.validationError`, `Validation error`) : undefined}
        onSave={(onSuccess) => {
          form.validateAndSendWithSuccess(onSuccess);
        }}
        autoClose>

        <>
          <Field label={tr("CustomsStatusTag.status", "Status")} errorMessage={findValidationMessage(validationErrors, 'status', tr)}>
            <Dropdown
              value={form.values?.status}
              options={options.map((t) => ({ label: mapToText(t), value: t }))}
              onChange={(e) => form.setFieldValue(form.names.status, e.value)} />

          </Field>
          {form.values?.status === 'CLEARED' &&
          <Field label={tr("CustomsStatusTag.datumProcleni", "Datum procleni")} errorMessage={findValidationMessage(form.validationErrors, 'localDate', tr)}>
              <RingilCalendar value={form.values?.localDate} onChange={(v) => form.setFieldValue(form.names.localDate, v)} />
            </Field>
          }
        </>
      </ButtonTag>
    </>);

};

export default CustomsStatusTag;