import React, { useContext, useEffect } from 'react';
import { SpecialRequirementsSelector } from '../common/SpecialRequirementsSelector';
import { TransportationTypeSelector } from '../common/TransportationTypeSelector';
import { TruckTypeSelector } from '../common/TruckTypeSelector';
import { LoadingTypeSelector } from '../common/LoadingTypeSelector';
import ParametrizedInputWrapper from './ParametrizedInputWrapper/ParametrizedInputWrapper';
import { ExpeditionTypeEnum, LoadingTypesEnum } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import HeaderPanel from '@components/obsolete/common/HeaderPanel.tsx';
import ContainerWrapper from '@components/obsolete/ContainerWrapper/ ContainerWrapper.tsx';
import { CargoTypeSelector } from '@components/obsolete/common/CargoTypeSelector.tsx';
import { TextArea } from '@components/obsolete/common/TextArea.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { dumpVars } from '@utils/utils.tsx';

interface ComponentProps {
  expeditionType: ExpeditionTypeEnum;
  truckType: string;
  manipulationTypes: LoadingTypesEnum[];
  specialRequirements: string[];
  cargoType: string;
  adrWeight?: number;
  carrierNote?: string;
  newExp?: boolean;
}

const NewExpeditionStep3Header: React.FC<ComponentProps> = ({ expeditionType, truckType, manipulationTypes, specialRequirements, cargoType, carrierNote, adrWeight, newExp = false }) => {
  const { t }: { t: any } = useTranslation('common');
  const { changeExpeditionType, selectTruck, selectManipulationTypes, selectSpecialRequirement, selectCargoType, changeCarrierNote, changeADRWeight, validation, state } =
    useContext(NewExpeditionViewContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ParametrizedInputWrapper validationPath={'expeditionType'} errors={validation} largePadding={12} mediumPadding={12}>
        <TransportationTypeSelector value={expeditionType} onChange={e => changeExpeditionType(e)} />
      </ParametrizedInputWrapper>
      <div className="p-mb-3">
        <Label title={t(`NewExpeditionStep3Header.truckType`, `Typ auta`)} />
        <ParametrizedInputWrapper validationPath={'truckType'} errors={validation} largePadding={12}>
          <TruckTypeSelector onChange={e => selectTruck(e)} value={truckType} required={expeditionType === ExpeditionTypeEnum.FULL_TRUCK_LOAD} />
        </ParametrizedInputWrapper>
      </div>
      <ParametrizedInputWrapper validationPath={'manipulationTypes'} errors={validation} largePadding={12}>
        <LoadingTypeSelector values={manipulationTypes} onChange={v => selectManipulationTypes(v)} />
      </ParametrizedInputWrapper>
      <SpecialRequirementsSelector selectedIds={specialRequirements} onChange={v => selectSpecialRequirement(v)} prefillEnabled={newExp} inheritorId={loggedUser.companyId}  />
      <HeaderPanel title={t('loadDetailsPage.panelAllAvailableEquipment.title')} />
      <ContainerWrapper wrapper="SM">
        <ParametrizedInputWrapper validationPath={'cargoType'} errors={validation} largePadding={12}>
          <CargoTypeSelector
            value={cargoType}
            onChange={v => selectCargoType(v)}
            errorMessages="Hodnota musí být zadána"
            adrWeight={adrWeight}
            onChangeAdrWeight={v => changeADRWeight(v)}
          />
        </ParametrizedInputWrapper>
        <TextArea value={carrierNote} onChange={v => changeCarrierNote(v)} displayFull />
      </ContainerWrapper>
    </>
  );
};

export default NewExpeditionStep3Header;
