import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import InputUnitPlain from '@components/obsolete/Form/InputUnitPlain/InputUnitPlain.tsx';

interface ComponentProps {
  selectedQtyUnitCode: string;
  qty: number;
  onChangeQtyUnit: (value: string) => void;
  onChangeQty: (value: number) => void;
  qtyError?: string;
  qtyUnitError?: string;
  decimalPlaces: number;
}

export const QuantitySelector: React.FC<ComponentProps> = ({ qty, onChangeQty, onChangeQtyUnit, selectedQtyUnitCode, qtyUnitError, qtyError, decimalPlaces }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="quantity-selector-wrapper p-field p-col-12 p-md-2">
        <Label title={t(`loadDetailsPage.panelBooking.labelQuantity`)} required />
        <InputNumber
          value={qty}
          onChange={e => {
            const targetValue = e.value;
            onChangeQty(targetValue);
          }}
          maxFractionDigits={decimalPlaces}
        />
        {qtyError && <div className="p-invalid-feedback">{qtyError}</div>}
      </div>
      <div className="quantity-units-selector-wrapper p-field p-col-12 p-md-2">
        <Label title={t(`QuantitySelector.unit`, `jednotka`)} required />
        <InputUnitPlain
          onChange={v => {
            onChangeQtyUnit(v);
          }}
          code={selectedQtyUnitCode}
          style={{ width: '100%' }}
        />
        {qtyUnitError && <div className="p-invalid-feedback">{qtyUnitError}</div>}
      </div>
    </>
  );
};
