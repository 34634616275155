import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { LovWithoutIdDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { ColWithGap } from '@components/styles';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { Trans } from 'react-i18next';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import { Dropdown } from 'primereact/dropdown';
import { Field } from '@components/framework/formfield/Field.tsx';
import { findValidationMessage } from '@utils/validation.tsx';

interface ComponentProps {
  onContinue: () => void;
}

interface NotificationCancelationRequest {
  supplierNotificationId: string;
}

interface Form {
  cancelationCode: string;
  cancelReservations: boolean;
}

export const useCreateCancelSupplierNotificationSidebar = () => {
  const [cancelationRequest, setCancelationRequest] = useState<NotificationCancelationRequest>();

  const CancelSupplierNotificationSidebar: React.FC<ComponentProps> = ({ onContinue }) => {
    const { tr } = useTranslationLgs();
    const { cancelSupplierNotification } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const cancellationReasons: LovWithoutIdDTO[] = [
      {
        name: tr('CancelExpeditionReasonHIGHER_POWERS', 'Higher powers'),
        code: 'HIGHER_POWERS',
      },
      {
        name: tr('CancelExpeditionReasonCANCELED_BY_CUSTOMER', 'Canceled by customer'),
        code: 'CANCELED_BY_CUSTOMER',
      },
      {
        name: tr('CancelExpeditionReasonCANCELED_BY_CARRIER', 'Canceled by carrier'),
        code: 'CANCELED_BY_CARRIER',
      },
      {
        name: tr('CancelExpeditionReasonADDED_TO_ANOTHER_EXPEDITION', 'Added to another expedition'),
        code: 'ADDED_TO_ANOTHER_EXPEDITION',
      },
      {
        name: tr('CancelExpeditionReasonDUPLICITY', 'Duplicity'),
        code: 'DUPLICITY',
      },
      {
        name: tr('CancelExpeditionReasonOTHER_CARRIER', 'Other carrier'),
        code: 'OTHER_CARRIER',
      },
      {
        name: tr('CancelExpeditionReasonLOCATION_CHANGED', 'Location change'),
        code: 'LOCATION_CHANGED',
      },
    ];

    const schema = yup.object().shape({
      cancelationCode: yup.string().required(),
    });

    const form = useForm<Form>(
      schema,
      {
        cancelationCode: null,
        cancelReservations: false,
      },
      d => save(d),
      false,
      false,
    );

    const save = (data: Form) => {
      cancelSupplierNotification(cancelationRequest.supplierNotificationId, { reasonCode: data.cancelationCode }, () => {
        onContinue();
        setCancelationRequest(null);
      });
    };

    if (!isNotNullOrUndefined(cancelationRequest)) return null;

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(cancelationRequest)}
          onBack={() => setCancelationRequest(null)}
          onContinue={() => form.validateAndSend()}
          heading={tr('CancelSupplierNotificationSidebar.cancelSupplierNotification', 'Cancel supplier notification')}
        >
          <BackendValidationComponent />
          <ColWithGap>
            <VerticalFieldAndValueWithIcon
              label={tr('CancelSupplierNotificationSidebar.forSupplierNotification', 'For supplier notification')}
              valueText={cancelationRequest.supplierNotificationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />

            <COInlineMessage variant={'info'} icon={<SvgGrayInfo />}>
              <Trans
                i18nKey="CancelSupplierNotificationSidebar.warning"
                defaults={'Canceled supplier notification will no longer be displayed in the list and will not be processed in reports.'}
              ></Trans>
            </COInlineMessage>
            <Field
              label={tr('CancelSupplierNotificationSidebar.reasonForCancellation', 'Reason for cancellation')}
              errorMessage={findValidationMessage(form.validationErrors, form.names.cancelationCode, tr)}
            >
              <Dropdown
                options={cancellationReasons}
                optionValue={'code'}
                optionLabel={'name'}
                value={form.values.cancelationCode}
                style={{ width: '100%' }}
                onChange={e => form.setFieldValue(form.names.cancelationCode, e.value)}
              />
            </Field>
          </ColWithGap>
        </RingilSidebar>
      </>
    );
  };

  return { CancelSupplierNotificationSidebar, setCancelationRequest, cancelationRequest };
};
