import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ArrowDown from '@icons/ArrowDown.tsx';

interface FoldableEanCodesProps {
  eanCodes: string[];
  translationKey?: string;
  defaultLabel?: string;
}

const FoldableEanCodes: React.FC<FoldableEanCodesProps> = ({
                                                             eanCodes,
                                                           }) => {
  const [showEans, setShowEans] = useState(false);
  const { tr } = useTranslationLgs();

  if (!eanCodes || eanCodes.length === 0) {
    return null;
  }

  return (
    <EanCodeContainer onClick={() => setShowEans(!showEans)}>
      <EanCodeHeader>
        <span>{tr('RingilOrder.eanCodes', 'EAN codes ({{eanCount}})', { eanCount: eanCodes.length })}</span>
        <ToggleIcon rotated={showEans}>
          <ArrowDown />
        </ToggleIcon>
      </EanCodeHeader>
      {showEans && (
        <EanCodeList>
          {eanCodes.join(', ')}
        </EanCodeList>
      )}
    </EanCodeContainer>
  );
};

const EanCodeContainer = styled.div`
  cursor: pointer;
  padding: 0.5rem;
`;

const EanCodeHeader = styled.div`
  display: flex;
  align-items: center;
  color: var(--content-brand);
  /* Basic Regular */
  font-family: "DM Sans", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ToggleIcon = styled.div<{ rotated: boolean }>`
  transform: ${props => props.rotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease;
  margin-left: 8px;
`;

const EanCodeList = styled.div`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  word-break: break-all;
  color: var(--content-primary);
  font-family: "DM Sans", serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default FoldableEanCodes;