import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import classNames from 'clsx';
import { resolveError } from '@utils/utils';
import { InputText } from 'primereact/inputtext';
import { WeightModeEnum } from '@api/logsteo-api.v2';
import { InputNumber } from 'primereact/inputnumber';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { OrderViewData } from '@components/obsolete/customer/new-expedition/types.tsx';
import { useTranslation } from 'react-i18next';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputYesNoPlain from '@components/obsolete/Form/InputYesNoPlain/InputYesNoPlain.tsx';
import { SkuSelector } from '@components/obsolete/Form/SkuSelector';
import { QuantitySelector } from '@components/obsolete/Form/QuantitySelector';
import { StackableWrapper } from '@components/obsolete/Stackable/StackableWrapper.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { Stackable } from '@components/obsolete/Stackable/Stackable.tsx';
import { DimensionsSelector } from '@components/obsolete/new-expedition/DimensionsSelector';
import { WeightSelector } from '@components/obsolete/new-expedition/WeightSelector';
import { NewNote } from '@components/obsolete/NewNote/NewNote.tsx';

interface ComponentProps {
  className?: any;
  locationIndex: number;
  orderIndex: number;
  order: OrderViewData;
}

export const OrdersComponent: React.FC<ComponentProps> = ({ className, order, locationIndex, orderIndex }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();

  const { deleteOrder, changeOrderName, changeOrderItemProperty, deleteLoadingUnit, validation, changeCargoItem, toggleSpecifyOrderItems, setOrderTotalWeight } =
    useContext(NewExpeditionViewContext);
  const classes = classNames(className);

  const error = validation.find(e => e.path === `locations[${locationIndex}].loadedOrders[${orderIndex}].unloadingLocationId`);

  return (
    <>
      <div className={classes}>
        <div className="p-flex-column p-mb-2 p-ai-start p-formgroup-width-input">
          {/* <OrderIdSelector
            orderName={order.name}
            onChangeOrderName={(orderName) => changeOrderName(locationIndex, orderIndex, orderName)}
          />*/}
          <div className="p-col-12 p-md-6">
            <div className="p-field">
              <HorizontalFieldValue
                label={t(`OrdersComponent.nazevObjednavky`, `Nazev objednavky`)}
                value={<InputText value={order.name} onChange={e => changeOrderName(locationIndex, orderIndex, e.target.value)} />}
              />
              <HorizontalFieldValue
                label={t(`OrdersComponent.hmotnostKg`, `Hmotnost [kg]`)}
                value={
                  <InputNumber
                    value={
                      order.items.length > 0
                        ? order.items.reduce((curr, value) => {
                            return curr + (value.weightMode === WeightModeEnum.TOTAL_WEIGHT ? value.weight : value.weight * value.amount);
                          }, 0)
                        : order.totalWeight
                    }
                    locale={'cs'}
                    onChange={e => setOrderTotalWeight(order.internalId, e.value)}
                    disabled={order.items.length > 0}
                    minFractionDigits={0}
                    maxFractionDigits={2}
                  />
                }
              />
            </div>
          </div>
          <div className="p-col-12 p-md-6">
            <div className="p-field">
              <HorizontalFieldValue
                label={tr(`OrdersComponent.doYouWantSpeficyLoad`, `Chcete specifikovat naklad?`)}
                value={<InputYesNoPlain value={order?.items.length != 0} onChange={v => toggleSpecifyOrderItems(order.internalId, v)} />}
              />
            </div>
          </div>
          {orderIndex > 0 && (
            <div className="p-col-12 p-d-flex p-jc-md-end">
              <Button
                label={t('loadDetailsPage.buttonDeleteReference')}
                className="p-button-outlined p-button-rounded"
                icon="pi pi-trash"
                onClick={() => deleteOrder(order.internalId)}
              />
            </div>
          )}
        </div>
        {order?.items?.map((loadItem, orderItemIndex) => (
          <div key={orderItemIndex}>
            <div className="p-fluid p-formgrid p-grid">
              <SkuSelector
                sku={loadItem?.cargoItemType}
                onChange={value => {
                  changeCargoItem(locationIndex, orderIndex, orderItemIndex, value);
                }}
              />
              <QuantitySelector
                qty={loadItem.amount}
                selectedQtyUnitCode={loadItem.quantityUnit}
                onChangeQty={qty => {
                  changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'amount', qty);
                }}
                onChangeQtyUnit={value => {
                  changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'quantityUnit', value);
                }}
                qtyError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].quantity`, validation, t)}
                qtyUnitError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].quantityUnit`, validation, t)}
                decimalPlaces={loadItem.cargoItemType?.decimalPlaces || 0}
              />
              <StackableWrapper wrapper="p-col-12 p-md-3">
                <Label title={t(`OrdersComponent.stackable`, `Stohovatelné`)} required />
                <Stackable value={loadItem.stackable} onChange={value => changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'stackable', value)} />
              </StackableWrapper>
              {orderItemIndex > 0 && (
                <div className="delete-btn-wrapper p-d-flex p-jc-between p-ml-auto p-mb-3">
                  <Button
                    icon="pi pi-trash"
                    className="p-button-outlined p-button-rounded p-ml-auto"
                    onClick={() => {
                      deleteLoadingUnit(locationIndex, orderIndex, orderItemIndex);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <DimensionsSelector
                width={loadItem.width}
                height={loadItem.height}
                length={loadItem.length}
                onChangeDimensions={(property, value) => changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, property, value)}
                widthError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].width`, validation, t)}
                heightError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].height`, validation, t)}
                lengthError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].length`, validation, t)}
                disabledInputs={[]}
              />
              <WeightSelector
                weight={loadItem.weight}
                weightMode={loadItem.weightMode}
                onChangeWeight={value => {
                  changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'weight', value);
                }}
                onChangeMode={value => {
                  changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'weightMode', value);
                }}
                weightError={resolveError(`locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].weight`, validation, t)}
              />
            </div>

            <div className="p-grid">
              <div className="p-col-12 p-xl-9">
                <NewNote value={loadItem.itemNote} onChange={value => changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'itemNote', value)} />
              </div>
            </div>
          </div>
        ))}
        {error && <small style={{ color: 'red' }}>{t(`validation.${error.type}`)}</small>}
      </div>
    </>
  );
};
