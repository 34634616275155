import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DistributionTypeEnum, EntityTypeEnum, TemplateExpeditionOutDTO } from '@api/logsteo-api.v2';
import { formatDistance, formatPrice, formatWeight, isNullOrUndefined, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import EditInternalInformationSidebar from './Sidebars/EditInternalInformationSidebar';
import { useTranslation } from 'react-i18next';
import TemplateEditHandlerProvider, { TemplateEditHandlerContext } from '@app/pages/customer/TemplateEdit/TemplateEditHandler.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import CardView from '@components/obsolete/CardView/CardView.tsx';
import RequirementCheckboxes from '@components/obsolete/RequirementCheckboxes/RequirementCheckboxes.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import DisplayOpeningHours from '@components/obsolete/OpeningHoursDefinition/DisplayOpeningHours.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';
import EditName from '@app/pages/customer/TemplateEdit/Sidebars/EditName.tsx';
import EditRequirementSidebar from '@app/pages/customer/TemplateEdit/Sidebars/EditRequirementSidebar.tsx';
import RequestCoverageSidebar from '@app/pages/customer/TemplateEdit/Sidebars/RequestCoverageSidebar.tsx';
import EditRouteSidebar from '@app/pages/customer/TemplateEdit/Sidebars/EditRouteSidebar.tsx';
import { useParams } from 'react-router-dom';
import { resolveLocationStopName } from '@utils/handlers.ts';
import CreateDistributionTemplate from '@components/obsolete/common/CreateDistributionTemplate.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {}

interface EditLocationProps {
  visible: boolean;
  locationId?: string;
}

const TemplateEditInternal = () => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { cuDeleteTemplate } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const {
    updateRequirements,
    updateTemplateName,
    updateCoverageRequest,
    updateTemplateLocation,
    toggleCreateDistribution,
    changeDistributionType,
    changeDistributionPrice,
    changeDistributionSelectedItems,
    changeDistributionIntervalHour,
    createDistributionTemplate,
    changeAutomaticPublishing,
    changeCurrency,
    refreshDataFromDB,
  } = useContext(TemplateEditHandlerContext);

  const [visibleEditTemplateName, setVisibleEditTemplateName] = useState(false);
  const [visibleEditRequirements, setVisibleEditRequirements] = useState(false);
  const [visibleEditCoverage, setVisibleEditCoverage] = useState(false);
  const [editLocationProps, setEditLocationProps] = useState<EditLocationProps>({ visible: false });
  const [visibleEditInternalInformation, setVisibleEditInternalInformation] = useState(false);

  // modal - delete template
  const [rejectModal, setRejectModal] = useState(false);
  const [position, setPosition] = useState('center');

  const { state } = useContext(TemplateEditHandlerContext);
  const nav = useNavigation();

  const customerTemplateDetailBreadCrumbs = () => {
    return [
      {
        label: t('labelTemplate'),
        url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionTemplates()),
      },
      {
        label: t('labelTemplateDetail'),
        disabled: true,
      },
    ];
  };

  const distributionValidation = yup.object().shape({
    enabled: yup.boolean().required(),
    currency: yup.string().required(),
    intervalHour: yup.number().required().min(1),
    selectedItems: yup.array().min(1),
    distributionType: yup.string().required(),
    price: yup
      .number()
      .nullable()
      .when('distributionType', {
        is: DistributionTypeEnum.FIXED_PRICE,
        then: yup.number().defined().min(0),
        otherwise: yup.number().nullable().min(0),
      }),
  });

  const { clear, validationErrors, validateAndSend } = useValidation(distributionValidation, () => createDistributionTemplate());

  if (isNullOrUndefined(state)) return <></>;

  return (
    <>
      <div className="p-grid p-jc-between p-ai-center">
        <div className="p-col-12 p-md-8">
          <BreadCrumbComponent items={customerTemplateDetailBreadCrumbs()} wrapper="p-mb-0" />
        </div>
        <div className="p-col-12 p-md-4 p-d-flex p-jc-md-end">
          <ButtonLayout wrapper="WITHOUT_PADDING_BOTTOM">
            {/*<Button label={t(`TemplateEdit.duplicate`, `Duplikovat`)} className="p-button-rounded p-mr-3" />*/}

            <Button
              label={t(`TemplateEdit.delete`, `Smazat`)}
              className="p-button-rounded"
              onClick={() => {
                setRejectModal(true);
              }}
            />
          </ButtonLayout>
        </div>
        <div className="p-col-12  p-d-flex p-jc-md-end">
          <Labels objectType={EntityTypeEnum.EXPEDITION_TEMPLATE} objectId={state.templateExpeditionId} resolveLabelsForType={EntityTypeEnum.EXPEDITION} />
        </div>
      </div>
      <NavTitle>
        <h2 className="h2-title p-m-0">{t(`TemplateEdit.aboutThisTemplate`, `Informace o trase`)}</h2>
        <Button
          label={t('labelChangeBtn')}
          className="p-button-outlined p-btn-edit"
          iconPos="left"
          icon="pi pi-pencil"
          onClick={() => setVisibleEditTemplateName(true)}
        />
      </NavTitle>
      <ListInfo>
        <ListInfoItem title={t(`TemplateEdit.templateName`, `Název trasy`)}>
          <p>
            <small>{state.templateName}</small>
          </p>
        </ListInfoItem>
      </ListInfo>
      <hr className="p-mt-3" />
      <h3>{t(`TemplateEdit.carrierRequirements`, `Požadavky na dopravce`)}</h3>
      <div className="p-grid p-ai-start p-jc-between p-mt-4 p-edit-panel" style={{ position: 'relative' }}>
        <div className="p-col-12 p-lg-9">
          <div className="p-d-flex p-flex-wrap">
            <CardView title={t(`TemplateEdit.expeditionTypeShort${state.expeditionType}`)} option={t(`TemplateEdit.expeditionTypeLong${state.expeditionType}`)} />
            <CardView
              title={
                state.loadingTypes &&
                state.loadingTypes
                  .map(loadingType => {
                    return loadingType.name;
                  })
                  .join(',')
              }
              option={t(`TemplateEdit.manipulationType`, `Manipulation type`)}
            />
            <CardView title={formatDistance(state.distance)} option={t(`TemplateEdit.routeLenght`, `Route lenght`)} />
            {state.distributionTemplate && (
              <CardView
                title={state?.distributionTemplate.shouldExpeditionAutomaticallyPublished ? t('global.yes', 'Yes') : t('global.no', 'No')}
                option={t(`TemplateEdit.automaticallyPublish`, `Automatically publish`)}
              />
            )}
          </div>

          <RequirementCheckboxes value={state.requirements} />

          <ListInfo>
            <ListInfoItem title={t(`TemplateEdit.cargoType`, `Typ nákladu`)}>
              <p>
                <small>{state.cargoType.name}</small>
              </p>
            </ListInfoItem>
            {state.maxWeight > 0 && (
              <ListInfoItem title={t(`TemplateEdit.maxWeight`, `Maximální váha`)}>
                <p>
                  <small>{formatWeight(state.maxWeight)}</small>
                </p>
              </ListInfoItem>
            )}

            {state.note && (
              <ListInfoItem title={t(`TemplateEdit.carrierNote`, `Poznámka pro dopravce`)}>
                <p>
                  <small>{state.note}</small>
                </p>
              </ListInfoItem>
            )}
          </ListInfo>
        </div>
        <div className="p-col p-lg-3 p-d-flex p-jc-md-end p-edit-panel--last">
          <Button
            label={t('labelChangeBtn')}
            className="p-button-outlined p-btn-edit"
            iconPos="left"
            icon="pi pi-pencil"
            onClick={() => setVisibleEditRequirements(true)}
          />
        </div>
      </div>
      {state.locations.map((location, locationIndex, locations) => {
        return (
          <React.Fragment key={locationIndex}>
            <hr className="p-mt-3" />
            <h3>{resolveLocationStopName(locationIndex, locations.length, t)}</h3>
            <div className="p-grid p-ai-start p-mb-2 p-edit-panel" style={{ position: 'relative' }}>
              <div className="p-col-12 p-sm-6 p-lg-4">
                <PanelWithIcon srcIcon="/images/contacts.svg">
                  <Label title={t(`TemplateEdit.locationAndAddress`, `Místo a adresa`)} />
                  <p>{`${location.name} ${location.streetNr}, ${location.postalCode} ${location.country}`}</p>
                  <div className="p-mt-3">
                    <Label title={t(`TemplateEdit.contactInformations`, `Kontaktní informace`)} />
                    <LoadInfoItem name={location.contactName} phone={location.contactPhone} email={location.contactEmail}></LoadInfoItem>
                  </div>
                </PanelWithIcon>
              </div>
              <div className="p-col-12 p-sm-6 p-lg-5">
                <PanelWithIcon srcIcon="/images/calendar.svg">
                  <Label title={t(`TemplateEdit.deliveryWindows`, `Časová okna`)} />
                  <DisplayOpeningHours openingHourDefinitions={location.openingHourDefinitions} />
                  {/*<TimeList>
                    {location.timeslots.map((timeslot, timeslotIndex) => {
                      return (
                        <TimeListItem
                          key={timeslotIndex}
                          date={t(`TemplateEdit.dayInWeek.${timeslot.dayOfWeek}`)}
                          isWholeDay={false}
                          time={`${timeslot.since}-${timeslot.till}`}
                        />
                      );
                    })}
                  </TimeList>*/}
                </PanelWithIcon>
              </div>
              <div className="p-col p-d-flex p-jc-md-end p-mb-2">
                <Button
                  label={t('labelChangeBtn')}
                  className="p-button-outlined p-btn-edit"
                  iconPos="left"
                  icon="pi pi-pencil"
                  onClick={() =>
                    setEditLocationProps({
                      visible: true,
                      locationId: location.applicationId,
                    })
                  }
                />
              </div>
            </div>
            <div className="p-grid p-ai-start p-mb-2 p-edit-panel" style={{ position: 'relative' }}>
              {location.loadedOrders.length > 0 && (
                <div className="p-col-12 p-sm-6 p-lg-4">
                  <PanelWithIcon srcIcon="/images/icon_pickup.png">
                    <Label title={t(`TemplateEdit.pickup`, `Nakládka`)} />
                    <div className="load-info-wrapper">
                      {location.loadedOrders
                        .map(lo => lo.items)
                        .flat(1)
                        .map(cargo => {
                          return (
                            <LoadInfoList
                              title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                              description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                              stackable={cargo.stackable}
                              itemNote={cargo.itemNote}
                            />
                          );
                        })}
                    </div>
                  </PanelWithIcon>
                </div>
              )}

              {state.locations
                .map(l => l.loadedOrders)
                .flat(1)
                .filter(lo => lo.unloadingLocationId === location.id).length > 0 && (
                <div className="p-col-12 p-sm-6 p-lg-4">
                  <PanelWithIcon srcIcon="/images/icon_dropoff.png">
                    <Label title={t(`TemplateEdit.dropoff`, `Vykladka`)} />
                    <div className="load-info-wrapper">
                      {state.locations
                        .map(l => l.loadedOrders)
                        .flat(1)
                        .filter(lo => lo.unloadingLocationId === location.id)
                        .map(lo => lo.items)
                        .flat(1)
                        .map((cargo, cargoIndex) => {
                          return (
                            <LoadInfoList
                              key={cargoIndex}
                              title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                              description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                              stackable={cargo.stackable}
                              itemNote={cargo.itemNote}
                            />
                          );
                        })}
                    </div>
                  </PanelWithIcon>
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
      {state.distributionTemplate && (
        <>
          <hr className="p-mt-0 p-mb-5" />
          <h3>{t(`TemplateEdit.demandForCarriers`, `Poptávka pro dopravce`)}</h3>
          <div className="p-grid p-ai-start p-mb-2 p-edit-panel" style={{ position: 'relative' }}>
            <div className="p-col-12 p-sm-6 p-lg-4">
              <ListInfo>
                {state.distributionTemplate.distributionType === DistributionTypeEnum.FIXED_PRICE && (
                  <ListInfoItem title={t(`TemplateEdit.fixedPrice`, `Pevná cena`)}>
                    <p>
                      <small>{formatPrice(state.distributionTemplate.price, state.distributionTemplate.currency)}</small>
                    </p>
                  </ListInfoItem>
                )}
                {state.distributionTemplate.distributionType === DistributionTypeEnum.SPOT && (
                  <ListInfoItem title={t(`TemplateEdit.spotPrice`, `Spot price`)}>
                    <p>
                      <small>{formatPrice(state.distributionTemplate.price, state.distributionTemplate.currency)}</small>
                    </p>
                  </ListInfoItem>
                )}

                <ListInfoItem title={t(`TemplateEdit.whoYouUsualyContacting`, `Who you usualy contacting`)}>
                  <p>
                    <small>{state.distributionTemplate.carriersAndGroups.map(c => c.name).join(', ')}</small>
                  </p>
                </ListInfoItem>
                <ListInfoItem title={t(`TemplateEdit.forHowLongShouldDistributionBeValid`, `For how long should distribution be valid?`)}>
                  <p>
                    <small>{`${state.distributionTemplate.intervalHour} h`}</small>
                  </p>
                </ListInfoItem>
              </ListInfo>
            </div>

            <div className="p-col p-d-flex p-jc-md-end p-mb-3 p-edit-panel--last">
              <Button label={t('labelChangeBtn')} className="p-button-outlined" iconPos="left" icon="pi pi-pencil" onClick={() => setVisibleEditCoverage(true)} />
            </div>
          </div>
        </>
      )}
      <hr className="p-mt-0 p-mb-5" />
      <h3>{tr(`TemplateEdit.internalInformation`, `Internal information`)}</h3>
      <div className={'p-grid p-ai-start p-jc-between p-mt-4 p-edit-panel'} style={{ position: 'relative' }}>
        <div className={'p-col-12 p-sm-6 p-lg-4'}>
          <ListInfoItem title={tr(`TemplateEdit.responsiblePerson`, `Responsible person`)}>
            <p>
              <small>{state.responsiblePerson?.fullName}</small>
            </p>
          </ListInfoItem>
          <ListInfoItem title={tr(`TemplateEdit.subscribers`, `Subscribers`)}>
            <p>
              <small>{state.subscribersList?.map(t => t.name).join(', ')}</small>
            </p>
          </ListInfoItem>
          <ListInfoItem title={tr(`TemplateEdit.internalNote`, `Internal note`)}>
            <p>
              <small>{state.internalCustomerNote}</small>
            </p>
          </ListInfoItem>
        </div>
        <div className="p-col p-d-flex p-jc-md-end p-mb-3 p-edit-panel--last">
          <Button
            label={t('labelChangeBtn')}
            className="p-button-outlined p-btn-edit"
            iconPos="left"
            icon="pi pi-pencil"
            onClick={() => setVisibleEditInternalInformation(true)}
          />
        </div>
      </div>
      {!state.distributionTemplate && (
        <>
          <hr className="p-mt-0 p-mb-5" />
          <PanelCard className="p-mt-4">
            <div className="p-sm-6 p-mb-4 p-d-flex p-ai-center">
              <InputSwitch checked={state.distribution?.enabled} onChange={v => toggleCreateDistribution(loggedUser?.defaultCurrencyIsoCode)} className="p-mr-2" />
              <label>{t(`TemplateEdit.iWantToCreateDistributionTemplate`, `Chci založit distribuční šablonu`)}</label>
            </div>
          </PanelCard>
          {state.distribution && state.distribution.enabled && (
            <>
              <CreateDistributionTemplate
                data={state.distribution}
                changeDistributionType={changeDistributionType}
                changeDistributionPrice={changeDistributionPrice}
                changeDistributionSelectedItems={changeDistributionSelectedItems}
                changeDistributionIntervalHour={changeDistributionIntervalHour}
                changeAutomaticPublishing={changeAutomaticPublishing}
                changeCurrency={changeCurrency}
                validationErrors={validationErrors}
              />
              <ButtonLayout wrapper="WITH_PADDING_TOP_4">
                <Button
                  label={t(`TemplateEdit.createDistributionTemplate`, `Vytvořit distribuční šablonu`)}
                  className="p-mr-2"
                  onClick={() => validateAndSend(state.distribution)}
                />
              </ButtonLayout>
            </>
          )}
        </>
      )}
      {/*
        // @ts-ignore*/}
      <Dialog
        header={t(`TemplateEdit.deleteThisTemplate`, `Smazání trasy`)}
        visible={rejectModal}
        className="modal-sm"
        onHide={() => {
          setRejectModal(false);
        }}
      >
        <div>
          <p className="p-mt-0">{t(`TemplateEdit.byDeletingThis`, `Smazáním šablony ji nebudete moc později použít.`)}</p>
        </div>

        <ButtonLayout wrapper="WITH_PADDING_TOP_4">
          <Button
            label={t(`TemplateEdit.delete`, `Smazat`)}
            className="p-mr-2 p-button-danger"
            onClick={() => {
              cuDeleteTemplate(state.templateExpeditionId, () => {
                setRejectModal(false);
                nav.navigate(nav.urlFunctions.createCustomerExpeditionTemplates());
              });
            }}
          />

          <Button
            label={t(`TemplateEdit.cancel`, `Zrušit`)}
            className="p-button-text"
            onClick={() => {
              setRejectModal(false);
            }}
          />
        </ButtonLayout>
      </Dialog>
      <EditName
        visible={visibleEditTemplateName}
        onHide={() => setVisibleEditTemplateName(false)}
        templateId={state.templateExpeditionId}
        onComplete={templateName => {
          updateTemplateName(state.templateExpeditionId, templateName, () => {
            setVisibleEditTemplateName(false);
          });
        }}
      />
      <EditRequirementSidebar
        visible={visibleEditRequirements}
        onHide={() => setVisibleEditRequirements(false)}
        onComplete={data => {
          updateRequirements(
            state.templateExpeditionId,
            {
              cargoType: data.cargoTypeCode,
              requirements: data.selectedRequirement,
              carrierNote: data.carrierNote,
              loadingTypes: data.manipulationTypes,
              truckType: data.truckTypeId,
              expeditionType: data.expeditionType,
            },
            () => setVisibleEditRequirements(false),
          );
        }}
        templateId={state.templateExpeditionId}
      />
      {state.distributionTemplate && (
        <RequestCoverageSidebar
          visible={visibleEditCoverage}
          onHide={() => setVisibleEditCoverage(false)}
          onComplete={data => {
            updateCoverageRequest(
              state.templateExpeditionId,
              {
                name: data.name,
                intervalHour: data.intervalHour,
                templateExpeditionId: data.distributionId,
                currency: data.currency,
                carriersAndGroups: data.carriersAndGroups,
                price: data.price,
                distributionType: data.distributionType,
                shouldExpeditionAutomaticallyPublished: data.shouldExpeditionAutomaticallyPublished,
              },
              () => {
                setVisibleEditCoverage(false);
              },
            );
          }}
          templateId={state.distributionTemplate.distributionId}
        />
      )}
      <EditRouteSidebar
        visible={editLocationProps.visible}
        onHide={() => setEditLocationProps({ visible: false })}
        onComplete={data =>
          updateTemplateLocation(state.templateExpeditionId, editLocationProps.locationId, data, () => {
            setEditLocationProps({ visible: false });
          })
        }
        templateExpeditionId={state.templateExpeditionId}
        locationId={editLocationProps.locationId}
      />
      <EditInternalInformationSidebar
        visible={visibleEditInternalInformation}
        onHide={() => setVisibleEditInternalInformation(false)}
        onComplete={() => {
          refreshDataFromDB(() => {
            setVisibleEditInternalInformation(false);
          });
        }}
        templateId={state.templateExpeditionId}
      />
    </>
  );
};

const TemplateEdit: React.FC = () => {
  const { id } = useParams();
  const { cuGetTemplateData } = useContext(ApiContext);
  const [templateData, setTemplateData] = useState<TemplateExpeditionOutDTO>(undefined);

  useEffect(() => {
    cuGetTemplateData(id as string, data => {
      setTemplateData(data);
    });
  }, [id]);

  return (
    <>
      {templateData && (
        <TemplateEditHandlerProvider initialData={templateData}>
          <TemplateEditInternal />
        </TemplateEditHandlerProvider>
      )}
    </>
  );
};

export default TemplateEdit;
