import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { ExpeditionStatusEnum } from '@api/logsteo-api.v2.tsx';
import Tag from '@components/framework/tags/Tag.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCarPlate from '@components/ringil3/icons/CarPlate.tsx';
import SvgDriverAndId from '@components/ringil3/icons/DriverAndId.tsx';
import { InputText } from 'primereact/inputtext';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';

interface ComponentProps {
  phone: string;
  onChange: (phone: string) => void;
  readOnly: boolean;
}

const PhoneTag: React.FC<ComponentProps> = ({ phone, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();
  const [phoneInternal, setPhoneInternal] = useState(phone);
  const [open, setOpen] = useState(false);

  const save = () => {
    setOpen(false);
    onChange(phoneInternal);
  };

  const pane = () => {
    return (
      <>
        <InputText value={phoneInternal} onChange={e => setPhoneInternal(e.target.value)} />
      </>
    );
  };

  return (
    <>
      <ButtonTag
        autoClose
        label={tr(`PhoneTag.title`, `Phone`)}
        value={phone}
        icon={<SvgDriverAndId />}
        actionIcon={<SvgTagEdit />}
        actionPane={pane()}
        onSave={() => save()}
        readonly={readOnly}
      />
    </>
  );
};

export default PhoneTag;
