import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import CurrencyDropdown from '@components/ringil3/Input/InputPriceWithCurrency/CurrencyDropdown';

interface ComponentProps {
  value: number;
  disabled?: boolean;
  changeValue: (value: number) => void;
}

const RingilInputNumber: React.FC<ComponentProps> = ({
                                                       value,
                                                       changeValue,
                                                       disabled = false,
                                                     }) => {
  return (
    <>
      <div className={'flex flex-row min-h-[40px]'}>
        <InputNumber
          className="p-text-right"
          value={value}
          onChange={e => changeValue(e.value)}
          mode="decimal"
          disabled={disabled}
          locale={'cs'}
          inputStyle={{ textAlign: 'right', width: '100%' }}
        />
      </div>
    </>
  );
};

export default RingilInputNumber;
