import { withAuthenticationRequired } from '@auth0/auth0-react';
import { isNullOrUndefined } from '@utils/utils';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { ContactTypeEnum, ExpeditionTypeEnum, LoadingTypesEnum, OpeningEntityTypeEnum, SupplierNotificationToExpeditionOutDTO } from '@api/logsteo-api.v2';
import { NewExpeditionPageComponent } from '@components/obsolete/new-expedition/NewExpeditionPageComponent.tsx';
import { PageID } from '@components/obsolete/customer/new-expedition/types.tsx';
import { RouteType } from '@components/obsolete/new-expedition/types.tsx';
import { useSearchParams } from 'react-router-dom';

const NewExpeditionPageFromSM = () => {
  const [searchParams] = useSearchParams();
  const fromSupplierNotification = searchParams.get('fromSupplierNotification');
  const [initialData, setInitialData] = useState<SupplierNotificationToExpeditionOutDTO>(null);
  const { cuGetSupplierNotificationForExpedition } = useContext(ApiContext);

  useEffect(() => {
    if (!isNullOrUndefined(fromSupplierNotification)) {
      cuGetSupplierNotificationForExpedition(fromSupplierNotification, d => {
        setInitialData(d);
      });
    }
  }, [fromSupplierNotification]);

  /*  const hasTimeslots = (deliveryWindow: DeliveryWindowIn14DTO[]) => {
        if (isNullOrUndefined(deliveryWindow)) return false;

        if (deliveryWindow.length === 0) return false;
        return true;
      };*/

  /*const mapTimeslots = (deliveryWindow: DeliveryWindowIn14DTO[], templateTimeslotDefinition) => {
        return {
          showTimes: hasTimeslots(deliveryWindow),
          templateTimeslotDefinition,
          dateFrom: hasTimeslots(deliveryWindow) ? dayjs(deliveryWindow[0].whenInUtc).set('hour', 12) : dayjs(),
          dateTo: hasTimeslots(deliveryWindow)
            ? dayjs(deliveryWindow[deliveryWindow.length - 1].whenInUtc).set('hour', 12)
            : dayjs(),
          timeslots: deliveryWindow.reduce((arr, item) => {
            const slotDay = arr.find((t) => t.day == dayjs(item.whenInUtc).set('hour', 12));

            if (isNullOrUndefined(slotDay))
              return [
                ...arr,
                {
                  day: dayjs(item.whenInUtc).set('hour', 12),
                  hidden: false,
                  disabled: false,
                  intervals: [{ from: item.sinceInUtc, to: item.untilInUtc }],
                },
              ];
          }, [] as TimeSlotItem[]),
        } as TimeSlotView;
      };*/

  if (initialData)
    return (
      <>
        <NewExpeditionPageComponent
          initialData={{
            currentPage: PageID.STEP2,
            fromSupplierNotificationId: fromSupplierNotification,
            fromSupplierManifestId: null,
            distribution: { enabled: false, isPublished: true },
            subscribersList: [],
            locations: initialData.locations.map((t, locationIndex) => {
              return {
                id: t.id,
                feKey: t.feKey,
                city: t.city,
                contactEmail: t.contactEmail,
                contactName: t.contactName,
                country: t.country,
                contactPhone: t.contactPhone,
                name: t.name,
                streetNr: t.streetNr,
                zipCode: t.postalCode,
                unloadAll: false,
                unloadingLocation: locationIndex === 1,
                loadingLocation: locationIndex === 0,
                carrierCode: t.pickupCode,
                contactPersons: t.contactPersons,
                timeslots: t.timeslots,

                loadingOrders:
                  t.loadedOrders?.map((t, orderIndex) => {
                    return {
                      internalId: `${orderIndex}`,
                      loadingLocationId: t.loadingLocationIndex,
                      name: t.orderName,
                      unloadingLocationId: t.unloadingLocationIndex,
                      totalWeight: null,
                      items: t.items.map(t => {
                        return {
                          weightMode: t.weightMode,
                          length: t.length,
                          width: t.width,
                          height: t.height,
                          quantityUnit: t.quantityUnit,
                          amount: t.amount,
                          weight: t.weight,
                          cargoItemType: t.cargoTemplate,
                          itemNote: t.itemNote,
                          stackable: t.stackable,
                          viewDisabledProperties: [],
                          decimalPlaces: t.cargoTemplate?.decimalPlaces,
                        };
                      }),
                    };
                  }) || [],
                enabledEmailNotificationForContactPerson: t.enabledEmailNotificationForContactPerson,
                additionalNotificationPersons: t.contactPersons.map(t => ({
                  contactName: t.contactName,
                  contactEmail: t.contactEmail,
                  contactPhone: t.contactPhone,
                })),
                contactType: ContactTypeEnum.COMPANY_LOCATION,
                locationId: t.partnerLocationId,
                openingHoursCoordinated: {
                  entityId: t.partnerLocationId,
                  entityType: OpeningEntityTypeEnum.LOCATION,
                },
              };
            }),
            cargoType: initialData.cargoType.code,
            expeditionType: ExpeditionTypeEnum.LESS_TRUCK_LOAD,
            carrierNote: initialData.note,
            internalNote: initialData.internalNote,
            manipulationTypes: [LoadingTypesEnum.FROM_BACK],
            routeType: RouteType.DIRECT,
            specialRequirements: initialData.requirements.map(t => t.code),
            responsiblePerson: null,
            labelCodes: [],
            copyAttachments: false,
            copySNAttachments: true,
            linkToSN: true,
            adrWeight: initialData.adrWeight,
          }}
        />
      </>
    );
  return <></>;
};

export default withAuthenticationRequired(NewExpeditionPageFromSM);
