import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { InputText } from 'primereact/inputtext';
import { Field } from '@components/framework/formfield/Field.tsx';
import { ValidationError } from 'yup';
import { hasValidationProblemForPrefix } from '@utils/validation.tsx';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';
import SvgNumber from '@icons/Number.tsx';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

const InternalInfoPairingSymbol: React.FC<ComponentProps> = ({ value, onChange, readonly, validationErrors, validationPrefix }) => {
  const [internalValue, setInternalValue] = useState<string>(value);
  const { tr } = useTranslationLgs();

  const printValue = (value: string) => {
    return value;
  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  return (
    <>
      <ButtonTag
        label={tr("InternalInfoPairingSymbol.parovaciSymbol", "Párovací symbol")}
        icon={<SvgNumber />}
        required={false}
        value={printValue(value)}
        readonly={readonly}
        onSave={(onSuccess) => {
          onChange(internalValue);
          onSuccess();
        }}
        popupWidth={'350px'}
        autoClose>

        <>
          <Field label={tr("InternalInfoPairingSymbol.parovaciSymbol", "Párovací symbol")}>
            <InputText value={internalValue} onChange={(e) => setInternalValue(e.target.value)} />
          </Field>
        </>
      </ButtonTag>
    </>);

};

export default InternalInfoPairingSymbol;