import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgTabMessages from '@components/ringil3/icons/TabMessages.tsx';

interface ComponentProps {
  count: number;
}

const RequestedCarriersTag: React.FC<ComponentProps> = ({ count }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      {isNotNullOrUndefined(count) && (
        <ButtonTag label={tr(`CarrierDemandTag.title`, `Requested carriers`)}
                   value={count}
                   icon={<SvgTabMessages />}
                   readonly={true} />
      )}
    </>
  );
};

export default RequestedCarriersTag;
