import i18next from 'i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(Locize)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'cs', 'de', 'hu', 'pl', 'fr'],
    detection: {
      order: ['path'],
    },
    backend: {
      projectId: '989c7799-4eae-4b1b-90db-cdae2f7441de',
      apiKey: '6fad0897-e6b0-4445-8288-80dabb5b2405',
      referenceLng: 'cs',
      private: false,
      version: 'latest',
      allowedAddOrUpdateHosts: ['localhost', 'logsteo-alpha.os.shipvio.com', 'alpha.ringil.com'],
      onSaved: (lng: string, ns: string) => {},
      addPath: 'https://api.locize.app/missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
      reloadInterval: 60000000,
    },
    ns: ['common'],
    debug: false,
    fallbackLng: 'cs',
    saveMissing: true,
  });
