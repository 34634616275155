import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { ContactTypeEnum, LoadingTypeEnum, OpeningEntityTypeEnum, TemplateCargoOutDTO, TemplateLocationOrderOutDTO, TemplateLocationOutDTO } from '@api/logsteo-api.v2';
import { Location, NewExpeditionView, OrderItemViewData, OrderViewData, PageID, RouteType } from '@components/obsolete/customer/new-expedition/types';
import { ApiContext } from '@api/api';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { useParams } from 'react-router-dom';
import { NewExpeditionPageComponent } from '@components/obsolete/new-expedition/NewExpeditionPageComponent.tsx';
import { isNotNullOrUndefined } from '@utils/utils.tsx';

const DuplicateExpeditionPage = () => {
  const { loggedUser, isReady } = useContext(AuthenticatedUserContext);

  const { id } = useParams();
  const { cuDuplicateExpedition } = useContext(ApiContext);

  const [initialData, setInitialData] = useState<NewExpeditionView>(undefined);

  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  const withLink = searchParams.link === 'true';
  const linkToSN = searchParams.linkToSN === 'true';
  const copyAttachments = searchParams.copyAttachments === 'true';
  const copySNAttachments = searchParams.copySNAttachments === 'true';

  useEffect(() => {
    if (isReady) {
      cuDuplicateExpedition(loggedUser.companyId, id as string, data => {
        const mappedData: NewExpeditionView = {
          // @ts-ignore
          expeditionType: data.expeditionType,
          responsiblePerson: data.responsiblePerson,
          routeType: RouteType.DIRECT,
          cargoType: data.cargoType.code,
          carrierNote: data.note,
          manipulationTypes: data.loadingTypes?.map(
            // @ts-ignore
            type => LoadingTypeEnum[type.code],
          ),
          specialRequirements: data.requirements?.map(r => r.code),
          truckType: data.truckType?.code,

          locations: data.locations.map((location, index, locations) => mapFromTemplateToView(location, index, locations)),
          subscribersList: data.subscribersList || [],
          currentPage: PageID.STEP3,
          distributionTemplate: { isPublished: true },
          distribution: { enabled: false, isPublished: true },
          fromSupplierManifestId: null,
          labelCodes: [],
          fromSupplierNotificationId: withLink ? (data.fromSupplierNotificationId as string) : null,
          adrWeight: data.adrWeight,
          duplicatedFromExpeditionId: data.duplicatedFromExpeditionId,
          linkToSN: linkToSN,
          copyAttachments: copyAttachments,
          copySNAttachments: copySNAttachments,
        };

        setInitialData(mappedData);
      });
    }
  }, [id, isReady]);

  return <>{initialData && <NewExpeditionPageComponent initialData={initialData} />}</>;
};

const mapFromTemplateToOrders = (lo: TemplateLocationOrderOutDTO): OrderViewData => {
  return {
    name: lo.orderName,
    unloadingLocationId: lo.unloadingLocationIndex,
    internalId: lo.id,
    loadingLocationId: lo.loadingLocationIndex,
    items: lo.items.map(c => mapFromTemplateToOrderItem(c)),
  } as OrderViewData;
};

const mapFromTemplateToOrderItem = (oi: TemplateCargoOutDTO): OrderItemViewData => {
  return {
    weightMode: oi.weightMode,
    length: oi.length,
    width: oi.width,
    height: oi.height,
    quantityUnit: oi.quantityUnit,
    amount: oi.amount,
    weight: oi.weight,
    cargoItemType: oi.cargoTemplate,
    stackable: oi.stackable,
  } as OrderItemViewData;
};

export const mapFromTemplateToView = (location: TemplateLocationOutDTO, index: number, locations: TemplateLocationOutDTO[]): Location => {
  return {
    id: index,
    fromAddressBookId: location.fromAddressBookId,
    locationId: location.companyLocationId,
    contactType: isNotNullOrUndefined(location.companyLocationId) ? ContactTypeEnum.COMPANY_LOCATION : ContactTypeEnum.PARTNER_LOCATION,
    loadingOrders: location.loadedOrders.map(l => mapFromTemplateToOrders(l)),
    unloadAll: false,
    name: location.name,
    zipCode: location.postalCode,
    streetNr: location.streetNr,
    country: location.country,
    carrierCode: location.pickupCode,
    contactPhone: location.contactPhone,
    contactName: location.contactName,
    city: location.city,
    unloadingLocation: locations
      .map(l => l.loadedOrders)
      .flat(1)
      .some(order => order.unloadingLocationId === location.id),
    contactEmail: location.contactEmail,
    loadingLocation: location.loadedOrders?.length > 0 || false,
    timeslots: { dayWithInterval: [] },
    contactPersons: [],
    enabledEmailNotificationForContactPerson: false,
    openingHoursCoordinated: {
      entityId: location.fromAddressBookId,
      entityType: OpeningEntityTypeEnum.TEMPLATE_LOCATION,
    },
  } as Location;
};

export default withAuthenticationRequired(DuplicateExpeditionPage);
