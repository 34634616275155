import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { findValidationMessage, hasValidationProblemForPrefix } from '@utils/validation.tsx';
import { Dropdown } from 'primereact/dropdown';
import useForm from '@hooks/useForm/useForm.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { dumpVars, formatPriceRequest, isNotBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import { CustomsValueEnum, CustomValueOutDTO } from '@api/logsteo-api.v2.tsx';
import SvgCoins from '@icons/Coins.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import DeleteIcon from '@app/pages/attachment/DeleteIcon.tsx';

interface ComponentProps {
  value: CustomValueOutDTO; // comma separated values
  onChange: (value: CustomValueOutDTO) => void;
  readonly?: boolean;
  validationErrors?: ValidationError[];
  validationPrefix?: string;
}

export const CustomsValueValidationSchema = () => {
  return yup.
  object().
  nullable().
  shape({
    price: yup.
    object().
    nullable().
    shape({
      price: yup.number().required(),
      currency: yup.string().required()
    }).
    default(null)
  });
};

const CustomsValuesTag: React.FC<ComponentProps> = ({ value, onChange, readonly, validationPrefix, validationErrors }) => {
  const { tr } = useTranslationLgs();

  const options: CustomsValueEnum[] = [CustomsValueEnum.UNPAID, CustomsValueEnum.PAID];

  const form = useForm<CustomValueOutDTO>(
    CustomsValueValidationSchema(),
    value,
    (d, onSuccess) => {
      if (isNotNullOrUndefined(onSuccess)) {
        onSuccess();
      }
      onChange(d);
    },
    false,
    false
  );

  const mapToText = (value: CustomsValueEnum): string => {
    return tr(`CustomsValuesTag.${value}`, `${value}`);
  };

  const printValue = (value: CustomValueOutDTO) => {
    if (isNullOrUndefined(value)) {
      return null;
    }

    return (
      <MultipleValues
        values={[isNotBlank(value.customsValue) ? tr(`CustomsValuesTag.${value.customsValue}`, `${value.customsValue}`) : '-', formatPriceRequest(value.price)]} />);


  };

  const hasError = validationErrors && validationPrefix ? hasValidationProblemForPrefix(validationErrors, validationPrefix) : false;

  const cleanupData = (v: CustomValueOutDTO) => {
    if (isNullOrUndefined(v)) return null;

    return {
      price: isNotNullOrUndefined(v.price?.price) || isNotNullOrUndefined(v.price?.currency) ? v.price : null,
      status: v.customsValue
    };
  };

  return (
    <>
      <ButtonTag
        label={tr("CustomsValueTag.clo", "Clo")}
        icon={<SvgCoins />}
        value={printValue(value)}
        readonly={readonly}
        popupWidth={'300px'}
        errorMessage={hasError ? tr(`CustomsValuesTag.validationError`, `Validation error`) : undefined}
        onSave={(onSuccess) => {
          form.validateAndSendWithSuccess(onSuccess, cleanupData(form.values));
        }}
        onClose={() => {
          form.clear();
        }}
        autoClose>

        <>
          <Field
            label={tr("CustomsValueTag.vyse", "Výše")}
            errorMessage={findValidationMessage(validationErrors, 'price', tr)}
            actionButtons={
            <DeleteIcon
              onClick={() => {
                form.setFieldValue('price', null);
              }} />

            }>

            <InputPriceWithCurrency
              price={form.values?.price?.price}
              currency={form.values?.price?.currency}
              changePrice={(v) => form.setFieldValue('price.price', v)}
              changeCurrency={(v) => form.setFieldValue('price.currency', v)} />

          </Field>
          <Field label={tr("CustomsValueTag.status", "Status")} errorMessage={findValidationMessage(validationErrors, 'status', tr)}>
            <Dropdown
              value={form.values?.customsValue}
              options={options.map((t) => ({ label: mapToText(t), value: t }))}
              onChange={(e) => form.setFieldValue(form.names.customsValue, e.value)} />

          </Field>
        </>
      </ButtonTag>
    </>);

};

export default CustomsValuesTag;