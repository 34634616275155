import React, { useContext, useMemo, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox';
import SvgNoteInfo from '@components/ringil3/icons/NoteInfo';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { ColWithGap } from '@components/styles.tsx';
import { Dropdown } from 'primereact/dropdown';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface SidebarData {
  selectedQuoteId: string;
  selectedCarrierName: string;
  applicationId: string;
  companyId: string;
  ladenFactor?: number;
  offeredManipulationUnits?: number;
  freeManipulationUnits?: number;
}

interface ComponentProps {
  onCompleted: () => void;
}

export const useCreateAcceptQuote = () => {
  const [sidebarData, setSidebarData] = useState<SidebarData>(undefined);

  const AcceptQuote: React.FC<ComponentProps> = ({ onCompleted }) => {
    const { tr } = useTranslationLgs();
    const { cuAcceptDemand } = useContext(ApiContext);
    const [isReconfirmedNeeded, setIsReconfirmedNeeded] = useState(false);
    const [acceptedManipulationUnits, setAcceptedManipulationUnits] = useState(0);
    const [loading, setLoading] = useState(false);
    const { BackendValidationComponent, setBeValidationErrors: onValidationFailed } = useBEValidation();

    const offeredUnitOptions = useMemo(() => {
      if (!sidebarData?.ladenFactor || !sidebarData?.offeredManipulationUnits || !sidebarData.freeManipulationUnits) return [];

      setAcceptedManipulationUnits(sidebarData.offeredManipulationUnits);

      const options = [];
      for (let i = sidebarData.ladenFactor; i <= Math.min(sidebarData.offeredManipulationUnits, sidebarData.freeManipulationUnits); i += sidebarData.ladenFactor) {
        options.push({ value: i, label: i.toString() });
      }
      return options;
    }, []);

    if (isNullOrUndefined(sidebarData)) return <></>;

    const save = () => {
      setLoading(true);
      cuAcceptDemand(
        sidebarData.companyId,
        sidebarData.applicationId,
        sidebarData.selectedQuoteId,
        {
          isReconfirmedNeeded: isReconfirmedNeeded,
          acceptedManipulationUnits: acceptedManipulationUnits,
        },
        () => {
          setLoading(false);
          setSidebarData(null);
          onCompleted();
        },
        null,
        { onValidationFailed },
      );
    };

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(sidebarData)}
          heading={tr(`AcceptQuote.selectACarrierQuote`, `Select a carrier quote`)}
          onContinue={() => save()}
          onBack={() => setSidebarData(null)}
          loading={loading}
        >

          <ColWithGap>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AcceptQuote.forExpedition`, `For expedition`)}
              valueText={sidebarData?.applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'} />

            <VerticalFieldAndValueWithIcon
              label={tr(`AcceptQuote.selectedCarrier`, `Selected carrier`)}
              valueText={sidebarData?.selectedCarrierName}
              iconPath={'/images/icons/ringil3/trucktype.svg'}
              showHr={true} />

            {offeredUnitOptions && offeredUnitOptions.length > 0 && (
              <div className="p-field">
                <Label title={tr('AcceptQuote.selectAcceptedUnitsHelp', 'How many units will be delivered')} />
                <div className="p-inputgroup">
                  <Dropdown
                    options={offeredUnitOptions}
                    optionValue="value"
                    optionLabel="label"
                    value={acceptedManipulationUnits}
                    onChange={(e) => {
                      setAcceptedManipulationUnits(e.value);
                    }}
                    style={{ width: '100%', color: 'var(--ringil3-gr4)' }}
                    placeholder={tr('AcceptQuote.selectAcceptedUnits', 'Select how much MU will be delivered')}
                  />
                </div>
              </div>
            )}

            <COInlineMessage icon={<SvgNoteInfo />} variant={'info'}>
              <p>Potvrzením vyberete dopravce k realizaci přepravy. Dopravce bude ihned informován e-mailem.</p>
              <p>Cenové nabídky ostatních dopravců budou odmítnuty a dopravci budou informováni e-mailem.</p>
            </COInlineMessage>
            <InputCheckbox
              value={isReconfirmedNeeded}
              onChange={(_, v) => setIsReconfirmedNeeded(v)}
              label={tr('AcceptQuote.pozadatDopravceOPrijetiPreravy', 'Požádat dopravce o příjetí přeravy')}
              note={'Dopravci bude odeslán potvzrující e-mail se žádost o přijetí přeravy. <br />\n' + '          Potvzení neblokuje další průběh přepravy'} />

          </ColWithGap>
        </RingilSidebar>
      </>);

  };

  return { AcceptQuote, setSidebarData };
};