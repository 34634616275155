import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { EntityTypeEnum, LovDTO } from '@api/logsteo-api.v2.tsx';
import InputFolder from '@components/framework/input/InputFolder/InputFolder.tsx';

interface ComponentProps {
  required?: boolean;
  readonly?: boolean;
  folder: LovDTO;
  onChange: (folder: LovDTO) => void;
  entityType: EntityTypeEnum;
}

const InternalInfoFolderTag: React.FC<ComponentProps> = ({ readonly, required, folder, onChange, entityType }) => {
  const [internalValue, setInternalValue] = useState(folder);
  const { tr } = useTranslationLgs();

  const renderText = (responsiblePersonId: LovDTO) => {
    return responsiblePersonId ? responsiblePersonId.name : null;
  };

  return (
    <>
      <ButtonTag
        label={tr('InternalInfoFolderTag.folder', 'Složka')}
        required={required}
        readonly={readonly}
        value={renderText(folder)}
        onSave={onSuccess => {
          onChange(internalValue);
          onSuccess();
        }}
        autoClose
      >
        <InputFolder folder={internalValue} onChange={setInternalValue} entityType={entityType} />
      </ButtonTag>
    </>
  );
};

export default InternalInfoFolderTag;
