import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { AddMoreCarrierInDTO, CGV2LocationOutDTO, ContactPerson, LocationPreviewV4OutDTO } from '@api/logsteo-api.v2';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import * as yup from 'yup';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import ReadonlyInput from '@components/framework/input/ReadonlyInput/ReadonlyInput.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation.tsx';
import { formatAddress } from '@utils/formators.tsx';
import InputContact from '@components/framework/InputContact/InputContact.tsx';
import { MultiSelect } from 'primereact/multiselect';
import { isNotNullOrUndefined } from '@utils/utils.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';

interface ComponentProps {
  applicationId: string;
  expeditionId: string;
  companyLocationId: string;
  locationId: string;
  onContinue: () => void;
  location: LocationPreviewV4OutDTO;
}

interface EditExpeditionLocationForm {
  loadingReference?: string;
  selectedPerson?: ContactPerson;
  additionalContacts?: ContactPerson[];
}

export const useCreateEditExpeditionLocation = () => {
  const [visible, setVisible] = useState(false);

  const EditExpeditionLocationSidebar: React.FC<ComponentProps> = ({ onContinue, applicationId, companyLocationId, locationId, expeditionId, location }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { getOneCGLocationV2, editCompanyLocationExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [loc, setLoc] = useState<CGV2LocationOutDTO>();

    useEffect(() => {
      if (isNotNullOrUndefined(companyLocationId)) {
        getOneCGLocationV2(companyLocationId, (d) => {
          setLoc(d);
        });
      }
    }, [companyLocationId]);

    const mapPerson = (p: ContactPerson) => {
      return { contactEmail: p.contactEmail, contactName: p.contactName, contactPhone: p.contactPhone };
    };

    const save = (data: EditExpeditionLocationForm) => {
      editCompanyLocationExpedition(
        expeditionId,
        locationId,
        {
          selectedPerson: mapPerson(form.values.selectedPerson),
          additionalContacts: form.values?.additionalContacts.map((t) => mapPerson(t)),
          loadingReference: form.values.loadingReference
        },
        () => {
          onContinue();
        }
      );
    };

    const schema = yup.object().shape({});
    const form = useForm<EditExpeditionLocationForm>(
      schema,
      {
        additionalContacts: location.additionalContacts,
        loadingReference: location.loadingReference,
        selectedPerson: location.selectedPerson
      },
      (d) => save(d),
      false,
      false
    );

    if (!visible) return null;

    return (
      <>
        <RingilSidebar visible={visible} onBack={() => setVisible(false)} onContinue={() => form.validateAndSend()} heading={tr("EditLocationAndContactSidebar.zmenaNaLokalite", "Změna na lokalitě")} ready={false}>
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AddMoreCarriers.forExpedition`, `For expedition`)}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'} />

            {isNotNullOrUndefined(loc) &&
            <>
                <Field label={tr("EditLocationAndContactSidebar.lokace", "Lokace")}>
                  <ReadonlyInput value={loc.addressTemplate.name} />
                </Field>

                <Field label={tr("EditLocationAndContactSidebar.adresa", "Adresa")}>
                  <ReadonlyInput
                  value={formatAddress(loc.addressTemplate.country, loc.addressTemplate.postalCode, loc.addressTemplate.city, loc.addressTemplate.streetNr)} />

                </Field>

                <COInlineMessage variant={'info'} icon={<SvgWarningExclamation />}>
                  Seznam kontaktních osob je součástí vybrané lokace. Změnu můžete provést v jejím nastavení.
                </COInlineMessage>

                <Field label={tr("EditLocationAndContactSidebar.hlavniKontakt", "Hlavní kontakt")} required>
                  <InputContact
                  contacts={loc?.contacts}
                  value={form.values.selectedPerson}
                  onChange={(v) => {
                    form.useProduce((draft) => {
                      draft.selectedPerson = v;
                    });
                  }}
                  fullWidth />

                </Field>
                <Field label={tr("EditLocationAndContactSidebar.notifikovatOPreprave", "Notifikovat o preprave")}>
                  <MultiSelect
                  options={loc?.contacts}
                  optionLabel={'contactName'}
                  value={form.values.additionalContacts}
                  onChange={(e) => {
                    form.useProduce((draft) => {
                      draft.additionalContacts = e.value;
                    });
                  }} />

                </Field>
                <Field label={tr("EditLocationAndContactSidebar.referenceProNakladku", "Reference pro nakládku")}>
                  <RingilInputText value={form.values.loadingReference} onChange={(v) => form.setFieldValue('loadingReference', v)} />
                </Field>
              </>
            }
          </div>
        </RingilSidebar>
      </>);

  };

  return { EditExpeditionLocationSidebar, setVisible, visible };
};