import React, { useContext } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { formatHandlingUnits, isNullOrUndefined, useLocalStorage } from '@utils/utils';
import { EntityTypeEnum, SupplierNotificationPreviewOutDTO, SupplierNotificationTypeEnum } from '@api/logsteo-api.v2';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import { Button } from 'primereact/button';
import useNavigation from '@hooks/useNavigation';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { Note } from '@components/styles.tsx';
import { Link } from 'react-router-dom';
import ExpeditionDetailOptions from '@components/obsolete/ExpeditionDetailOptions/ExpeditionDetailOptions.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';

interface ComponentProps {
  applicationId?: string;
  distance?: number;
  expeditionType: string;
  expeditionState: string;
  isExpeditionCanceled: boolean;
  expeditionToId: string;
  expeditionFromId: string;
  isManualExpedition: boolean;
  expeditionId: string;
  cancelationReasonText?: string;
  supplierNotificationLink?: SupplierNotificationPreviewOutDTO;
  handlingUnitCount: number;
}

const ExpeditionDetailHeader: React.FC<ComponentProps> = ({
  applicationId,
  distance,
  expeditionType,
  expeditionState,
  isExpeditionCanceled,
  expeditionToId,
  expeditionFromId,
  isManualExpedition,
  expeditionId,
  cancelationReasonText,
  supplierNotificationLink,
  handlingUnitCount
}) => {
  const { t }: {t: any;} = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { generatePDFOrderAndSave } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const navigation = useNavigation();

  const className = '';

  const isCanceled = isExpeditionCanceled;
  const isSwitchedToManual = isManualExpedition && !isNullOrUndefined(expeditionToId);
  const isManual = isManualExpedition && isNullOrUndefined(expeditionToId);
  const isAutomatic = !isManualExpedition && !isExpeditionCanceled;
  const [hasAdminFeatures] = useLocalStorage('RINGIL_ADMIN_FEATURES', false);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          <h2 className="h2-title p-mt-0">
            {t('labelExpedition')} <span>{applicationId}</span>
            <Note>{expeditionId}</Note>
          </h2>

          {supplierNotificationLink &&
          <span className={className}>
              {/*<Link href={`/supplier-notifications/${supplierNotificationLink.supplierNotificationId}`}>*/}
              <Link
              to={navigation.createNavigationLink(
                navigation.urlFunctions.createSupplierNotificationDetail(supplierNotificationLink.supplierNotificationId, SupplierNotificationTypeEnum.FCA)
              )}>

                {supplierNotificationLink.supplierNotificationApplicationId}
              </Link>
            </span>
          }

          {hasAdminFeatures && <Button label={tr("ExpeditionDetailHeader.generatePdf", "generate PDF")} onClick={(e) => generatePDFOrderAndSave(applicationId, () => {})} />}

          <div className="p-d-flex p-ai-center">
            <p className="p-mb-0 p-mt-0 p-mr-5">
              <>
                {isSwitchedToManual &&
                <span className={'text-blue'}>
                    {/*<Link href={`/customer/expedition/${expeditionToId}`}>*/}
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(expeditionToId))}>
                      {tr(`ExpeditionDetailHeader.switchedToManualModeWithApplicationId`, 'Přepnuto na manuální režim do Expedice {{applicationId}} ', {
                      applicationId: expeditionToId
                    })}
                    </Link>
                  </span>
                }
                {isManual &&
                <span className={'text-blue'}>
                    {/*<Link href={`/customer/expedition/${expeditionFromId}`}>*/}
                    <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCustomerExpeditionDetail(expeditionFromId))}>
                      {tr(`ExpeditionDetailHeader.replacedAtomaticExpedition`, 'Manuální verze automatické expedice {{expeditionFromId}} ', {
                      expeditionFromId: expeditionFromId
                    })}
                    </Link>
                  </span>
                }

                {isCanceled &&
                <span className={className}>
                    {t('CANCELED', 'CANCELED')} ({tr(`CancelExpeditionReason${cancelationReasonText}`, 'Vyssi moc')})
                  </span>
                }
                {isAutomatic && <span className={className}>{t(expeditionState, expeditionState)}</span>}
              </>
            </p>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-d-flex p-jc-between p-jc-md-end">
          <div className="details-wrapper">
            <ExpeditionDetailOptions value={`${distance || ' - '} km`} description={t('labelTotalDistance')} />
            <ExpeditionDetailOptions
              value={t(`expeditionTypeShort${expeditionType}`)}
              description={t(`expeditionTypeLong${expeditionType}`)}
              img="/images/local_shipping.png" />

            <ExpeditionDetailOptions
              value={formatHandlingUnits(handlingUnitCount, tr)}
              description={tr(`CarrierExpeditionDetailHeader.totalHandlingUnits`, `Total handling units`)} />

          </div>
        </div>
      </div>
      <LabelsWrapper>
        <Labels objectType={EntityTypeEnum.EXPEDITION} objectId={expeditionId} disabled={loggedUser.securityRoles.indexOf('ROLE_EXP_RW') == -1} />
      </LabelsWrapper>
    </>);

};

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export default ExpeditionDetailHeader;