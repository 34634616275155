import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { addLocale } from 'primereact/api';

import '@fontsource/dm-sans'; // Defaults to weight 400
import '@fontsource/dm-sans/400.css'; // Specify weight
import '@fontsource/dm-sans/500.css'; // Specify weight
import '@fontsource/dm-sans/700.css'; // Specify weight
import '@fontsource/dm-sans/400-italic.css'; // Specify weight and style
import './index.css';
//import "primereact/resources/themes/saga-blue/theme.css";
import '@styles/ringil/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
//import "primereact/resources/themes/lara-light-cyan/theme.css";
import './config/i18n.ts';
import { Routes } from '@routes';

addLocale('cs', {
  firstDayOfWeek: 1,
  dayNames: ['neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota'],
  dayNamesShort: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  dayNamesMin: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
  monthNames: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
  monthNamesShort: ['led', 'ún', 'bře', 'dub', 'kvě', 'čer', 'čvn', 'srp', 'zář', 'říj', 'lis', 'pro'],
  today: 'dnes',
  clear: 'Smazat',
});

addLocale('fr', {
  firstDayOfWeek: 1,
  dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
  dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
  monthNamesShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'],
  today: "Aujourd'hui",
  clear: 'Effacer',
});

// 📌 Přidání lokalizací
addLocale('sk', {
  firstDayOfWeek: 1,
  dayNames: ['nedeľa', 'pondelok', 'utorok', 'streda', 'štvrtok', 'piatok', 'sobota'],
  dayNamesShort: ['ne', 'po', 'ut', 'st', 'št', 'pi', 'so'],
  dayNamesMin: ['N', 'P', 'U', 'S', 'Š', 'P', 'S'],
  monthNames: ['január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'],
  monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'máj', 'jún', 'júl', 'aug', 'sep', 'okt', 'nov', 'dec'],
  today: 'Dnes',
  clear: 'Vymazať',
  dateFormat: 'dd.mm.yy',
});

addLocale('pl', {
  firstDayOfWeek: 1,
  dayNames: ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'],
  dayNamesShort: ['nie', 'pon', 'wt', 'śr', 'czw', 'pt', 'sob'],
  dayNamesMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
  monthNames: ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'],
  monthNamesShort: ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paź', 'lis', 'gru'],
  today: 'Dziś',
  clear: 'Wyczyść',
  dateFormat: 'dd.mm.yy',
});

addLocale('de', {
  firstDayOfWeek: 1,
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
  monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  clear: 'Löschen',
  dateFormat: 'dd.mm.yy',
});

addLocale('hu', {
  firstDayOfWeek: 1,
  dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
  dayNamesShort: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'],
  dayNamesMin: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Sz'],
  monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'],
  monthNamesShort: ['jan', 'feb', 'már', 'ápr', 'máj', 'jún', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
  today: 'Ma',
  clear: 'Törlés',
  dateFormat: 'yyyy.mm.dd',
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Routes />
  </StrictMode>,
);
