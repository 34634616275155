import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgDriverAndId from '@components/ringil3/icons/DriverAndId.tsx';
import { InputText } from 'primereact/inputtext';
import useForm from '@hooks/useForm/useForm.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import SvgTagEdit from '@components/ringil3/icons/TagEdit.tsx';
import * as yup from 'yup';

interface ComponentProps {
  driverName: string;
  driverId: string;
  onChange: (driverId: string, driverName: string) => void;
  readOnly: boolean;
}

interface Form {
  driverName: string;
  driverId: string;
}

const DriverAndIdTag: React.FC<ComponentProps> = ({ driverName, driverId, onChange, readOnly }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  const content = [driverName, driverId].filter(t => t != null).join(', ');

  const form = useForm<Form>(
    yup.object().shape({}),
    {
      driverName,
      driverId,
    },
    () => save(),
    false,
    false,
    null,
    'none',
  );

  const pane = () => {
    return (
      <>
        <VerticalFieldAndValue label={tr('DriverAndIdTag.driverName', 'Driver name')} labelMode={'normal'}>
          <InputText value={form.values.driverName} onChange={e => form.setFieldValue(form.names.driverName, e.target.value)} />
        </VerticalFieldAndValue>
        <VerticalFieldAndValue label={tr('DriverAndIdTag.driverId', 'Driver ID')} labelMode={'normal'}>
          <InputText value={form.values.driverId} onChange={e => form.setFieldValue(form.names.driverId, e.target.value)} />
        </VerticalFieldAndValue>
      </>
    );
  };

  const save = () => {
    setOpen(false);
    onChange(form.values.driverId, form.values.driverName);
  };

  return (
    <>
      <ButtonTag
        label={tr(`DriverAndIdTag.title`, `Driver and ID`)}
        value={content}
        icon={<SvgDriverAndId />}
        actionPane={pane()}
        onSave={() => save()}
        actionIcon={<SvgTagEdit />}
        autoClose
        readonly={readOnly}
      />
    </>
  );
};

export default DriverAndIdTag;
