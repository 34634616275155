import React from 'react';
import Badge from '@components/ringil3/Badges/Badge';
import { LadenFactorParentExpeditionOutDTO } from '@api/logsteo-api.v2';
import { isNotNullOrUndefined } from '@utils/utils';
import useNavigation from '@hooks/useNavigation.tsx';
import { Link } from 'react-router-dom';

interface ComponentProps {
  ladenFactorParentExpedition?: LadenFactorParentExpeditionOutDTO;
}

const LinkedLadenFactorParentExpeditionBadge: React.FC<ComponentProps> = ({ ladenFactorParentExpedition }) => {
  const navigation = useNavigation();

  return (
    <>
      {isNotNullOrUndefined(ladenFactorParentExpedition) && (
        <Link
          key={ladenFactorParentExpedition.expeditionApplicationId}
          to={navigation.createNavigationLink(navigation.urlFunctions.createExpeditionDetailV2(ladenFactorParentExpedition.expeditionId))}
        >
          <Badge filled={true} value={ladenFactorParentExpedition.expeditionApplicationId}
                   highlightedValue={true}></Badge>
        </Link>
      )}
    </>
  );
};

export default LinkedLadenFactorParentExpeditionBadge;
