import { Card } from 'primereact/card';
import NewExpeditionStep1 from './NewExpeditionStep1';
import React, { useContext, useEffect } from 'react';
import NewExpeditionStep2 from './NewExpeditionStep2';
import NewExpeditionStep3Header from './NewExpeditionStep3Header';
import NewExpeditionStep3Manipulation from './NewExpeditionStep3Manipulation';
import { NewExpeditionView, PageID } from './types';
import NewExpeditionStep4 from './NewExpeditionStep4';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import NewExpeditionProvider, { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import Layout from '@app/layout/Layout.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';

const NewExpeditionPageComponentInternal = () => {
  const { state, clickOnBreadCrumb, changeFlatProperty, validation } = useContext(NewExpeditionViewContext);
  const { t } = useTranslation();

  const newExpeditionBreadCrumb = (dispatch: (a: any) => void) => {
    return [
      {
        label: t(`NewExpeditionPageComponent.newExpedition`, `New expedition`),
        command: (key: any) => {
          dispatch(PageID.STEP1);
        },
        disabled: true,
      },
    ];
  };

  const routeBreadCrumb = (dispatch: (key: any) => void) => {
    return [
      {
        label: t('NewExpeditionPageComponent.newExpedition', 'New expedition'),
        command: (key: any) => {
          dispatch(PageID.STEP1);
        },
      },
      {
        label: t('NewExpeditionPageComponent.route', 'Route'),
        command: (key: any) => {
          dispatch(PageID.STEP2);
        },
        disabled: true,
      },
    ];
  };

  const loadBreadCrumb = (dispatch: (key: any) => void) => {
    return [
      {
        label: t('NewExpeditionPageComponent.newExpedition', 'New expedition'),
        command: (key: any) => {
          dispatch(PageID.STEP1);
        },
      },
      {
        label: t('NewExpeditionPageComponent.route', 'Route'),
        command: (key: any) => {
          dispatch(PageID.STEP2);
        },
      },
      {
        label: t('NewExpeditionPageComponent.load', 'Load'),
        command: (key: any) => {
          dispatch(PageID.STEP3);
        },
        disabled: true,
      },
    ];
  };

  const overviewBreadCrumb = (dispatch: (key: any) => void) => {
    return [
      {
        label: t('NewExpeditionPageComponent.newExpedition', 'New expedition'),
        command: (key: any) => {
          dispatch(PageID.STEP1);
        },
      },
      {
        label: t('NewExpeditionPageComponent.route', 'Route'),
        command: (key: any) => {
          dispatch(PageID.STEP2);
        },
      },
      {
        label: t('NewExpeditionPageComponent.load', 'Load'),
        command: (key: any) => {
          dispatch(PageID.STEP3);
        },
      },
      {
        label: t('NewExpeditionPageComponent.overview', 'Overview'),
        command: (key: any) => {
          dispatch(PageID.STEP4);
        },
        disabled: true,
      },
    ];
  };

  const { showToastMessage } = useContext(ToastContext);
  const { tr } = useTranslationLgs();

  useEffect(() => {
    if (validation && validation.length > 0) {
      showToastMessage(
        tr('CreateSupplierNotification.errorOnThePage', 'Error on the page'),
        tr(`CreateSupplierNotification.errorOnThePageDetail`, `There are errors on the page that prevent the form from being submitted`),
        'error',
      );
    }
  }, [validation]);

  return (
    <>
      {state.currentPage === PageID.STEP1 && (
        <Layout title={t(`NewExpeditionPageComponent.newExpedition`, `New expedition`)}>
          {/*
        // @ts-ignore*/}
          <Card>
            <BreadCrumbComponent items={newExpeditionBreadCrumb(page => clickOnBreadCrumb(page))} />
            <NewExpeditionStep1 />
          </Card>
        </Layout>
      )}
      {state.currentPage === PageID.STEP2 && (
        <Layout title={t(`NewExpeditionPageComponent.route`, `Route`)}>
          {/*
        // @ts-ignore*/}
          <Card>
            <BreadCrumbComponent items={routeBreadCrumb(page => clickOnBreadCrumb(page))} />
            <NewExpeditionStep2 locations={state.locations} routeType={state.routeType} />
          </Card>
        </Layout>
      )}
      {state.currentPage === PageID.STEP3 && (
        <Layout title={t(`NewExpeditionPageComponent.load`, `Load`)}>
          {/*
        // @ts-ignore*/}
          <Card title={t('loadDetailsPage.titlePage')}>
            <BreadCrumbComponent items={loadBreadCrumb(page => clickOnBreadCrumb(page))} />
            <NewExpeditionStep3Header
              expeditionType={state.expeditionType}
              truckType={state.truckType}
              manipulationTypes={state.manipulationTypes}
              specialRequirements={state.specialRequirements}
              cargoType={state.cargoType}
              carrierNote={state.carrierNote}
              adrWeight={state.adrWeight}
              newExp={state.newExp}
            />
          </Card>
          {/*
        // @ts-ignore*/}
          <Card title={t(`NewExpeditionPageComponent.manipulaceNaLokalitách`, `Manipulace na lokalitách`)} className="p-mt-4">
            <NewExpeditionStep3Manipulation locations={state.locations} expeditionType={state.expeditionType} />
          </Card>
        </Layout>
      )}
      {state.currentPage === PageID.STEP4 && (
        <Layout title={t(`NewExpeditionPageComponent.overview`, `Overview`)}>
          <BreadCrumbComponent items={overviewBreadCrumb(page => clickOnBreadCrumb(page))} />
          <NewExpeditionStep4 />
        </Layout>
      )}
    </>
  );
};

interface ComponentProps {
  initialData?: NewExpeditionView;
}

export const NewExpeditionPageComponent: React.FC<ComponentProps> = ({ initialData }) => (
  <NewExpeditionProvider initialData={initialData}>
    <NewExpeditionPageComponentInternal />
  </NewExpeditionProvider>
);
