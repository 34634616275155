import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ValidationError } from 'yup';
import ValidationDiv from '@utils/validation';
import { LovDTO } from '@api/logsteo-api.v2';
import { useTranslation } from 'react-i18next';
import { ExpeditionDetailHandlerContext } from '@app/pages/carrier/expedition-detail/ExpeditionDetailHandler.tsx';
import useDispatchers from '@hooks/dispatchers/dispatchers.tsx';
import useDrivers from '@hooks/drivers/drivers.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import EditableDropdown, { DropdownItem } from '@components/ringil3/EditableDropdown/EditableDropdown.tsx';
import styled from 'styled-components';
import useCarPlate from '@hooks/useCarPlate/useCarPlate.tsx';

interface ComponentProps {
  disabled?: boolean;
  validationErrors: ValidationError[];
}

const AssignVehicleComponent: React.FC<ComponentProps> = ({ disabled, validationErrors }) => {
  const { t } = useTranslation('common');
  const { state, changePlate, changeDriver, changeDispatcher } = useContext(ExpeditionDetailHandlerContext);
  const [selectedOptions, setSelectedOptions] = useState<DropdownItem[]>([]);

  const [plates, handleDeletePlate] = useCarPlate();

  const dispatchers = useDispatchers(state.header.id);
  const drivers = useDrivers(state.header.id);

  useEffect(() => {
    if (state.createReadyToShipment.carPlate) {
      const initialPlates = getInitialSelectedPlates(state.createReadyToShipment.carPlate, plates);
      setSelectedOptions(initialPlates);
    }
  }, [state.createReadyToShipment.carPlate, plates]);

  const getInitialSelectedPlates = (carPlate: string, plates: DropdownItem[]): DropdownItem[] => {
    if (!carPlate) return [];

    const plateNumbers = carPlate.split(' || ');
    return plateNumbers.map(plateNumber => {
      const existingPlate = plates.find(plate => plate.number === plateNumber);
      if (existingPlate) {
        return existingPlate;
      }
      // Create new DropdownItem if not found
      return {
        id: plateNumber,
        number: plateNumber,
      };
    });
  };

  // Handle plate selection
  const handleSelectPlate = (plates: DropdownItem[]) => {
    changePlate(plates.map(p => p.number).join(' || '));
  };

  // Handle plate deletion
  const handleDropDownDelete = (plate: DropdownItem) => {
    handleDeletePlate(plate.id);
  };

  console.log(state.createReadyToShipment.carPlate)

  return (
    <>
      <h4 className="p-mt-0" style={{ fontWeight: 'normal' }}>
        {t(`AssignVehicleComponent.assignAVehicle`, `Assign a vehicle`)}
      </h4>
      <div className="group-wrapper group-assign-vehicle">
        <div className="p-field">
          <EditableDropdown
            options={plates}
            selectedOptions={selectedOptions}
            onSelect={(plates: DropdownItem[]) => handleSelectPlate(plates)}
            onDelete={(plate: DropdownItem) => handleDropDownDelete(plate)}
            placeholder="Search car plates..."
            label="SPZ"
            multiSelect={true}
            mandatory={true}
            note={
              <Note>
                <b>{t(`InputPlate.note1`, `Máte auto s přívěsem?`)}</b>
                <p>{t(`InputPlate.note2`, `Např. tahač SPZ 0001 | 1. přívěs SPZ 0002 | 2. přívěs SPZ 00003`)} </p>
                <p>{t(`InputPlate.note3`, `Oddělte SPZ čárkami: SPZ 0001, SPZ 0002. SPZ 0003`)}</p>
              </Note>
            }
          />
          <ValidationDiv errors={validationErrors} path={'carPlate'} />
        </div>
        <div className="p-field">
          <Label title={t(`AssignVehicleComponent.driver`, `Driver`)} />
          <Dropdown
            options={drivers}
            onChange={e => changeDriver(e.value)}
            optionLabel="name"
            optionValue="id"
            value={state.createReadyToShipment.driverId}
            disabled={disabled}
            filter={true}
            itemTemplate={(option: LovDTO) => (
              <>{option.active ? <span>{option.name}</span> :
                <span style={{ textDecoration: 'line-through', color: 'gray' }}>{option.name}</span>}</>
            )}
          />
          <ValidationDiv errors={validationErrors} path={'driverId'} />
        </div>
        <div className="p-field">
          <Label title={t(`AssignVehicleComponent.dispatcher`, `Dispatcher`)} />
          <Dropdown
            options={dispatchers}
            optionValue="id"
            onChange={e => changeDispatcher(e.value)}
            optionLabel="name"
            filter={true}
            value={state.createReadyToShipment.dispatcherId}
            disabled={disabled}
          />
          <ValidationDiv errors={validationErrors} path={'dispatcherId'} />
        </div>
      </div>
    </>
  );
};

export default AssignVehicleComponent;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.78rem;

  b {
    font-weight: 700;
    color: black;
    margin-top: 0.3rem;
  }

  p {
    margin: 0;
  }
`;