import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import { Card } from 'primereact/card';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import {
  AllReservationsFilter,
  PageResponseReservationListPreviewOutDTO,
  PermissionTypesEnum,
  ReservationDataExportResponseOutDTO,
  ReservationListPreviewOutDTO,
} from '@api/logsteo-api.v2';
import { isNotNullOrUndefined, isNullOrUndefined, useLocalStorage, visualiseWorkflowStateState } from '@utils/utils';
import { Column } from 'primereact/column';
import { dayJsToDate, mapFromAPIDateTime, mapFromAPIToDateLong, mapFromAPIToDateShort, mapFromAPIToTime, mapToAPIDateTime, shortDateFormat } from '@utils/date';
import useTranslationLgs from '../../../hooks/i18n/useTranslation';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import dayjs from 'dayjs';
import { exportExcel } from '@utils/exports';
import { NoWrapText, RowWithGap } from '@components/styles';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useCreateTimeslotSidebar } from '@components/ringil3/Features/Reservation/Sidedbars/CreateTimeslotSidebar.tsx';
import { useFilter } from '@hooks/useFilter/Filter.tsx';
import { RecycleButton } from '@components/obsolete/Buttons/RecycleButton/RecycleButton.tsx';
import { Link } from 'react-router-dom';
import DeleteButton from '@components/obsolete/DeleteButton/DeleteButton.tsx';
import { useDuplicateTimeslot } from '@components/obsolete/timeslot/duplicate/duplicate.tsx';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import ReservationMoreButtons from '@components/ringil3/Features/Reservation/MoreButton/ReservationMoreButtons';

// Add this type declaration at the top of the file, after imports
declare module 'primereact/datatable' {
  interface DataTableProps {
    children?: React.ReactNode;
  }
}

// Interface for column configuration from API
interface ColumnConfig {
  id: string;
  field: string;
  header: string;
  headerDefault: string;
  width?: string;
  sortable?: boolean;
  showInMobile?: boolean;
  mobileOrder?: number;
}

interface ColumnConfigurationResponse {
  columns: ColumnConfig[];
}

interface ComponentProps {}

// Add this type extension after the imports
interface ExtendedReservationListPreviewOutDTO extends ReservationListPreviewOutDTO {
  isAttachmentExpanded?: boolean;
  locationId?: string;
  isPinnedOnLocRampTable?: boolean;
  driverInfo?: {
    driverPhone?: string;
  };
  hasTable?: boolean;
  linkedToExpCoords?: {
    expeditionId?: string;
  };
}

interface ExtendedPageResponseReservationListPreviewOutDTO extends PageResponseReservationListPreviewOutDTO {
  data: ExtendedReservationListPreviewOutDTO[];
}

const GlobalStyles = createGlobalStyle`
  .p-inputtext,
  .p-calendar,
  .p-button,
  .p-datatable .p-datatable-header,
  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td,
  .p-paginator {
    font-family: 'DM Sans', sans-serif !important;
  }

  .p-card {
    background-color: #F7F8F9;
    box-shadow: none;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: #E7EBEE !important;
    border-color: #E7EBEE !important;
  }

  .ringil-table {
    .p-datatable-tbody > tr > td {
      padding: 1rem 1rem;
      vertical-align: top;
    }

    .p-column-header-content {
      justify-content: flex-start;
    }

    .p-datatable-thead > tr > th:last-child {
      width: 4rem !important;
      min-width: 4rem !important;
      max-width: 4rem !important;
    }

    .p-datatable-tbody > tr > td:last-child {
      width: 4rem !important;
      min-width: 4rem !important;
      max-width: 4rem !important;
      padding: 0.5rem;
    }
  }
`;

export const ResponsiveListReservations: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  const navigation = useNavigation();

  const { getReservations, deleteReservation, exportReservations, downloadAttachment } = useContext(ApiContext);
  const { loggedUser, hasPermission } = useContext(AuthenticatedUserContext);
  const [storedFilter, setStoredFilter] = useLocalStorage('TIMESLOT_LIST_FILTER', undefined);

  const [data, setData] = useState<ExtendedPageResponseReservationListPreviewOutDTO>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItems, setAllItems] = useState<ExtendedReservationListPreviewOutDTO[]>([]);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [hasLoadedAll, setHasLoadedAll] = useState(false);

  // State for column configuration
  const [columnConfig, setColumnConfig] = useState<ColumnConfig[]>([]);
  // Flag to track if columns have been loaded
  const [columnsLoaded, setColumnsLoaded] = useState<boolean>(false);

  const [revealTabTexts, setRevealTabTexts] = useLocalStorage<boolean>('ringil_tags_expanded', false);

  const { setTimeslotIdForDuplication, DuplicateTimeslot } = useDuplicateTimeslot();
  const { CreateTimeslotSidebar, createTimeslotRequest, setCreateTimeslotRequest } = useCreateTimeslotSidebar();

  const { Filter, applyFilterValue, filter, applyPaging, page, onPage, applySorting, sort, applyFilter } = useFilter<AllReservationsFilter>(
    (page, filter) => {
      load();
    },
    { sortField: 'applicationId', sortDirection: 'DESC' },
  );

  useEffect(() => {
    if (isNotNullOrUndefined(storedFilter)) {
      applyFilter(storedFilter);
    }
  }, [storedFilter]);

  // Load column configuration when the component mounts or when the user changes
  useEffect(() => {
    if (loggedUser?.companyId && !columnsLoaded) {
      fetchColumnConfiguration();
    }
  }, [loggedUser?.companyId, columnsLoaded]);

  // Function to fetch column configuration from API
  const fetchColumnConfiguration = () => {
    // Use default column configuration since there is no API endpoint yet
    setColumnConfig(getDefaultColumnConfig());
    setColumnsLoaded(true);
  };

  const getAvailableColumns = (): ColumnConfig[] => {
    return [
      { id: 'applicationId', field: 'applicationId', header: 'ID', headerDefault: 'ID', width: '16rem', sortable: true, showInMobile: true, mobileOrder: 1 },
      { id: 'locationAndRamp', field: '', header: 'reservations.rampAndTime', headerDefault: 'Ramp and time', sortable: true, showInMobile: true, mobileOrder: 2 },
      { id: 'customer', field: '', header: 'reservations.customerAndCarrier', headerDefault: 'Customer and carrier', showInMobile: true, mobileOrder: 3 },
      { id: 'reference', field: '', header: 'reservations.reference', headerDefault: 'Reference', showInMobile: true, mobileOrder: 4 },
      { id: 'manipulationUnits', field: '', header: 'reservations.additionalInfo', headerDefault: 'Additional information', showInMobile: true, mobileOrder: 5 },
      { id: 'actions', field: '', header: '', headerDefault: '', showInMobile: false },
    ];
  };

  const getDefaultColumnConfig = (): ColumnConfig[] => {
    return [
      { id: 'applicationId', field: 'applicationId', header: 'ID', headerDefault: 'ID', width: '12rem', sortable: true, showInMobile: true, mobileOrder: 1 },
      {
        id: 'locationAndRamp',
        field: '',
        header: 'reservations.rampAndTime',
        headerDefault: 'Ramp and time',
        width: '20%',
        sortable: true,
        showInMobile: true,
        mobileOrder: 2,
      },
      { id: 'customer', field: '', header: 'reservations.customerAndCarrier', headerDefault: 'Customer and carrier', width: '25%', showInMobile: true, mobileOrder: 3 },
      { id: 'reference', field: '', header: 'reservations.reference', headerDefault: 'Reference', width: '20%', showInMobile: true, mobileOrder: 4 },
      {
        id: 'manipulationUnits',
        field: '',
        header: 'reservations.additionalInfo',
        headerDefault: 'Additional information',
        width: '25%',
        showInMobile: true,
        mobileOrder: 5,
      },
      { id: 'actions', field: '', header: '', headerDefault: '', width: '4rem', showInMobile: false },
    ];
  };

  const getColumnRenderer = (columnId: string) => {
    const renderFunctions: Record<string, (data: ExtendedReservationListPreviewOutDTO) => JSX.Element> = {
      applicationId: renderApplicationId,
      locationAndRamp: renderLocationAndRamp,
      customer: renderCustomerAndCarrier,
      reference: renderReference,
      manipulationUnits: renderManipulationUnits,
      actions: actionsButtons,
    };

    return renderFunctions[columnId] || (() => <></>);
  };

  const canEdit = (data: ExtendedReservationListPreviewOutDTO) => {
    /*alert(JSON.stringify({ since: data.timeslot?.since, after: dayjs().add(60, 'minutes') }));*/
    // jsem vlastnik
    if (data.customerId == loggedUser.companyId) return true;

    if (data.customerCode !== 'MAGNA-CARTECH') return true;

    // neni zadany datum
    if (isNullOrUndefined(data.timeslot)) return true;

    // neni mene jak 60 minut do zacatku akce
    if (data.customerCode === 'MAGNA-CARTECH' && mapFromAPIDateTime(data.timeslot?.since).isAfter(dayjs().add(60, 'minutes'))) return true;

    return false;
  };

  const load = () => {
    getReservations(
      {
        pageSize: page.pageSize,
        pageNumber: page.pageNumber,
        sortField: sort.sortField,
        sortDirection: sort.sortDirection,
      },
      filter,
      d => {
        setData(d);
      },
    );
  };

  const hasReservation = (data: ExtendedReservationListPreviewOutDTO): boolean => {
    if (isNullOrUndefined(data?.timeslot)) return false;
    if (isNullOrUndefined(data?.timeslot.since)) return false;
    if (isNullOrUndefined(data?.timeslot.till)) return false;

    return true;
  };

  const renderLocationAndRamp = (data: ExtendedReservationListPreviewOutDTO) => (
    <div className="flex flex-col">
      <div className="font-medium flex items-center gap-2">
        <i className="pi pi-map text-gray-600" />
        {data.rampName}
      </div>
      {hasReservation(data) && (
        <div className="mt-1">
          <div className="flex items-center gap-2">
            <i className="pi pi-calendar text-gray-600" />
            {mapFromAPIToDateShort(data.timeslot.since)}
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <i className="pi pi-clock text-gray-600" />
            {mapFromAPIToTime(data.timeslot.since)} - {mapFromAPIToTime(data.timeslot.till)}
          </div>
        </div>
      )}
    </div>
  );

  const renderCustomerAndCarrier = (data: ExtendedReservationListPreviewOutDTO) => (
    <div className="flex flex-col">
      <div className="font-medium flex items-center gap-2">
        <i className="pi pi-building text-gray-600" />
        {data.customerName}
      </div>
      <div className="text-gray-600 flex items-center gap-2">
        <i className="pi pi-user text-gray-600" />
        {data.carrierName}
      </div>
      {(data.driverName || data.carPlate) && <div className="text-gray-600">{[data.driverName, data.carPlate].filter(t => t != null).join(', ')}</div>}
    </div>
  );

  const renderReference = (data: ExtendedReservationListPreviewOutDTO) => (
    <div className="flex flex-col">
      <div className="font-medium">{data.reservationName}</div>
      <div className="text-gray-600">{data.orders.map(t => t.orderName).join(', ')}</div>
    </div>
  );

  const [expandedAttachments, setExpandedAttachments] = useState<Record<string, boolean>>({});

  const handleAttachmentClick = (reservationId: string) => {
    setExpandedAttachments(prev => ({
      ...prev,
      [reservationId]: !prev[reservationId],
    }));
  };

  const truncateFileName = (fileName: string, maxLength: number = 10): string => {
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.split('.').pop() || '';
    const nameWithoutExt = fileName.slice(0, -(extension.length + 1));

    if (nameWithoutExt.length <= maxLength) return fileName;

    const halfLength = Math.floor((maxLength - 3) / 2);
    const start = nameWithoutExt.slice(0, halfLength);
    const end = nameWithoutExt.slice(-halfLength);

    return `${start}...${end}.${extension}`;
  };

  const renderManipulationUnits = (data: ExtendedReservationListPreviewOutDTO) => {
    const palletInfo = data?.orders
      ?.map(t => t.items)
      .flat(1)
      .reduce((acc, val) => {
        if (acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code) === -1) {
          acc.push({
            cargoTemplate: val.cargoTemplate,
            amount: val.amount,
          });
        } else {
          const index = acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code);
          acc[index].amount += val.amount;
        }
        return acc;
      }, [])
      .map(t => `${t.amount}x ${t.cargoTemplate.name}`)
      .join(', ');

    return (
      <div className="flex flex-col">
        <div>{palletInfo}</div>
        {data?.attachmentInfo?.attachmentSize > 0 && (
          <div className="mt-2">
            {!expandedAttachments[data.id] && (
              <AttachmentTag isExpanded={expandedAttachments[data.id]} onClick={() => handleAttachmentClick(data.id)}>
                <i className="pi pi-file text-gray-600" />
                <span className="label">{tr('reservations.attachments', 'Attachments')}</span>
                <span>{data.attachmentInfo.attachmentSize}</span>
              </AttachmentTag>
            )}
            {expandedAttachments[data.id] && data.attachmentInfo.attachments && (
              <div className="mt-2 flex flex-wrap">
                {data.attachmentInfo.attachments.map((attachment, index) => (
                  <FileTag key={index} onClick={() => handleFileClick(attachment.id, attachment.name)}>
                    <i className="pi pi-file text-gray-600" />
                    <span title={attachment.name}>{truncateFileName(attachment.name)}</span>
                  </FileTag>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderApplicationId = (data: ExtendedReservationListPreviewOutDTO) => (
    <>
      <div className="flex items-center gap-2">
        {isMobile && <Checkbox checked={selectedItems.includes(data.id)} onChange={e => handleSelectItem(data.id)} className="mr-2" />}
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(data.id))}>
          <TextAndIcon>
            <span className={'text-blue'} style={{ cursor: 'pointer' }}>
              {data.applicationId}
              {data.hasETransportRO ? <>(E)</> : <></>}
            </span>
            {data?.customerId == loggedUser.companyId && <span>{visualiseWorkflowStateState(data?.state, tr, false)}</span>}
          </TextAndIcon>
          <div className="text-gray-600">{data.locationName}</div>
        </Link>
      </div>

      {loggedUser && loggedUser.companyId === data.customerId && (
        <LabelWrapper>
          {data.labels
            .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
            .map((t, tIndex) => (
              <LabelDiv
                color={t.labelColor}
                key={tIndex}
                onClick={e => {
                  setRevealTabTexts(v => !v);
                }}
              >
                {revealTabTexts && <div>{t.labelValue}</div>}
              </LabelDiv>
            ))}
        </LabelWrapper>
      )}
    </>
  );

  // const deleteRow = (reservationId: string) => {
  //   deleteReservation(reservationId, () => {
  //     load();
  //   });
  // };

  const actionsButtons = (data: ExtendedReservationListPreviewOutDTO) => (
    <>
      {canEdit(data) && (
        <TableMoreButtonWrapper>
          <ReservationMoreButtons
            reloadHandler={() => load()}
            locationId={data.locationId || ''}
            reservationId={data.id}
            reservationAppId={data.applicationId}
            isPinnedOnLocRampTable={data.isPinnedOnLocRampTable || false}
            hasPhone={data.driverInfo?.driverPhone != null}
            hasTable={data.hasTable || false}
            isOwner={data.customerId === loggedUser.companyId}
            onCopyReservation={(reservationId: string) => {
              setTimeslotIdForDuplication(reservationId);
            }}
            onChangeDuration={(reservationId: string) => {
              // This functionality is not available in the list view
            }}
            onDuplicate={() => {
              setCreateTimeslotRequest({
                applicationId: null,
                companyLocationId: null,
                preferredDate: '',
                companyLocationCustomerId: null,
                expeditionLocationId: null,
                expeditionId: data.linkedToExpCoords?.expeditionId,
                reservationId: data.id,
              });
            }}
            hasTimeslot={data.timeslot?.since != null}
            onNotifyDriver={(id: string) => {
              // This functionality is not available in the list view
            }}
          />
        </TableMoreButtonWrapper>
      )}
    </>
  );

  const exportExcelHandler = () => {
    // Filter the data to only include selected items
    const selectedData = allItems.filter(item => selectedItems.includes(item.id));
    exportReservations(filter, d => {
      const filteredData = d.filter(item => selectedItems.includes(item.id));
      exportExcel(filteredData.map(mapToExport), 'reservations');
    });
  };

  const mapToExport = (reservation: ReservationDataExportResponseOutDTO) => {
    return {
      applicationId: reservation.applicationId,
      locationName: reservation.locationName,
      since: mapFromAPIToDateLong(reservation?.since),
      till: mapFromAPIToDateLong(reservation?.till),
      carrierName: reservation.carrierName,
      reference: reservation.reference,
      carPlate: reservation.carPlate,
    };
  };

  const saveFilter = () => {
    setStoredFilter(filter);
  };

  const deleteFilter = () => {
    setStoredFilter(undefined);
    applyFilter({
      applicationId: null,
      locationName: null,
      timeslotDaySince: null,
      timeslotDayTill: null,
      carrierName: null,
      referenceOrderId: null,
      reservationName: null,
      driverName: null,
      carPlate: null,
    });
  };

  const { i18n } = useTranslation();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [expandedFilters, setExpandedFilters] = useState(false);

  const loadAllPages = async () => {
    setIsLoadingAll(true);
    const allData: ReservationListPreviewOutDTO[] = [];
    let currentPage = 1;
    let hasMore = true;

    while (hasMore) {
      const response = await new Promise<PageResponseReservationListPreviewOutDTO>(resolve => {
        getReservations(
          {
            pageSize: page.pageSize,
            pageNumber: currentPage,
            sortField: sort.sortField,
            sortDirection: sort.sortDirection,
          },
          filter,
          resolve,
        );
      });

      allData.push(...response.data);
      hasMore = currentPage * page.pageSize < response.total;
      currentPage++;
    }

    setAllItems(allData);
    setTotalItems(allData.length);
    setHasLoadedAll(true);
    setIsLoadingAll(false);
  };

  const handleSelectAll = async (checked: boolean) => {
    if (checked) {
      // Load all pages first
      await loadAllPages();
      // Then select all items
      const allIds = allItems.map(item => item.id);
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (id: string) => {
    setSelectedItems(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]));
  };

  const isAllSelected = () => {
    if (!data?.data) return false;

    // If we have loaded all items, check against those
    if (hasLoadedAll && allItems.length > 0) {
      return allItems.every(item => selectedItems.includes(item.id));
    }

    // Otherwise check current page
    return data.data.every(item => selectedItems.includes(item.id));
  };

  const isIndeterminate = () => {
    if (!data?.data) return false;
    return selectedItems.length > 0 && !isAllSelected();
  };

  // Reset hasLoadedAll when filter or sort changes
  useEffect(() => {
    setHasLoadedAll(false);
    setSelectedItems([]); // Clear selections when filter/sort changes
  }, [filter, sort.sortField, sort.sortDirection]);

  const handleFileClick = (attachmentId: string, fileName: string) => {
    downloadAttachment(attachmentId, data => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  if (isNullOrUndefined(filter)) return <>no filter</>;

  // Render columns dynamically based on configuration
  const renderColumns = () => {
    // If columns haven't been loaded yet, show a default set
    if (!columnsLoaded || columnConfig.length === 0) {
      const defaultColumns = getDefaultColumnConfig();
      return defaultColumns.map(column => (
        <Column
          key={column.id}
          body={getColumnRenderer(column.id)}
          header={tr(column.header, column.headerDefault)}
          style={column.width ? { width: column.width, minWidth: column.width } : undefined}
          bodyClassName="align-top"
          sortable={column.sortable}
          sortField={column.field || undefined}
        />
      ));
    }

    return columnConfig.map(column => (
      <Column
        key={column.id}
        body={getColumnRenderer(column.id)}
        header={tr(column.header, column.headerDefault)}
        style={column.width ? { width: column.width, minWidth: column.width } : undefined}
        bodyClassName="align-top"
        sortable={column.sortable}
        sortField={column.field || undefined}
      />
    ));
  };

  // Render mobile view cards based on column configuration
  const renderMobileCards = () => {
    if (!data?.data) return null;

    return (
      <div className="flex flex-col gap-4">
        {data.data.map((reservation, index) => (
          <div key={index} className="bg-white p-4 pb-6 shadow-[0_1px_0_0_rgba(229,231,235,1)] -mx-4 px-4 relative z-10">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <Checkbox checked={selectedItems.includes(reservation.id)} onChange={e => handleSelectItem(reservation.id)} className="mr-2" />
                <Link to={navigation.createNavigationLink(navigation.urlFunctions.createReservationDetail(reservation.id))}>
                  <span className="text-orange-500 font-medium">
                    {reservation.applicationId}
                    {reservation.hasETransportRO ? ' (E)' : ''}
                  </span>
                </Link>
                <i className="pi pi-flag text-gray-600" />
              </div>
              <div className="text-gray-700">{reservation.locationName}</div>
            </div>

            {loggedUser.companyId === reservation.customerId && (
              <LabelWrapper className="mt-2">
                {reservation.labels
                  .sort((a, b) => a.labelValue.localeCompare(b.labelValue))
                  .map((t, tIndex) => (
                    <LabelDiv color={t.labelColor} key={tIndex} onClick={e => setRevealTabTexts(v => !v)}>
                      {revealTabTexts && <div>{t.labelValue}</div>}
                    </LabelDiv>
                  ))}
              </LabelWrapper>
            )}

            <div className="flex flex-col gap-2 mt-3 text-sm">
              <div className="flex items-center text-gray-700">
                <i className="pi pi-map text-gray-600 mr-2" />
                <span>{reservation.rampName}</span>
              </div>

              {hasReservation(reservation) && (
                <div className="flex flex-col gap-2 mb-3">
                  <div className="flex items-center">
                    <i className="pi pi-calendar text-gray-600 mr-2" />
                    <div className="text-gray-700">{mapFromAPIToDateShort(reservation.timeslot.since)}</div>
                  </div>
                  <div className="flex items-center">
                    <i className="pi pi-clock text-gray-600 mr-2" />
                    <div className="text-gray-600">
                      {mapFromAPIToTime(reservation.timeslot.since)} - {mapFromAPIToTime(reservation.timeslot.till)}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center text-gray-700">
                <i className="pi pi-building text-gray-600 mr-2" />
                <span>{reservation.customerName}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <i className="pi pi-user text-gray-600 mr-2" />
                <span>{[reservation.carrierName, reservation.carPlate].filter(t => t != null).join(', ')}</span>
              </div>

              {/* <div className="text-gray-700 mt-3">{reservation.reservationName}</div> */}
              <div className="text-gray-700 mt-3">{reservation.orders.map(t => t.orderName).join(' ')}</div>
              {reservation?.orders && (
                <div className="text-gray-700 mt-3">
                  {reservation.orders
                    .map(t => t.items)
                    .flat(1)
                    .reduce((acc, val) => {
                      if (acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code) === -1) {
                        acc.push({
                          cargoTemplate: val.cargoTemplate,
                          amount: val.amount,
                        });
                      } else {
                        const index = acc.findIndex(t => t.cargoTemplate.code === val.cargoTemplate.code);
                        acc[index].amount += val.amount;
                      }
                      return acc;
                    }, [])
                    .map(t => {
                      return `${t.amount} x ${t.cargoTemplate.name}`;
                    })
                    .join(', ')}
                </div>
              )}

              {reservation?.attachmentInfo?.attachmentSize > 0 && (
                <div className="mt-4">
                  {!reservation.isAttachmentExpanded && (
                    <AttachmentTag isExpanded={reservation.isAttachmentExpanded} onClick={() => handleAttachmentClick(reservation.id)}>
                      <i className="pi pi-file text-gray-600" />
                      <span className="label">{tr('reservations.attachments', 'Attachments')}</span>
                      <span>{reservation.attachmentInfo.attachmentSize}</span>
                    </AttachmentTag>
                  )}
                  {reservation.isAttachmentExpanded && reservation.attachmentInfo.attachments && (
                    <div className="mt-2 flex flex-wrap">
                      {reservation.attachmentInfo.attachments.map((attachment, index) => (
                        <FileTag key={index} onClick={() => handleFileClick(attachment.id, attachment.name)}>
                          <i className="pi pi-file text-gray-600" />
                          <span title={attachment.name}>{truncateFileName(attachment.name)}</span>
                        </FileTag>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <GlobalStyles />
      {/*// @ts-expect-error*/}
      <Card>
        <div className="font-['DM_Sans']">
          <div className="flex justify-between items-center mb-6">
            <Heading className="!m-0">{tr(`reservations.reservations`, `Reservations`)}</Heading>
            {hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_RW]) && (
              <Button
                label={tr(`reservations.newReservation`, `New reservation`)}
                icon="pi pi-plus"
                onClick={e => {
                  setCreateTimeslotRequest({
                    applicationId: null,
                    companyLocationId: null,
                    preferredDate: '',
                    companyLocationCustomerId: null,
                    expeditionLocationId: null,
                    expeditionId: null,
                  });
                }}
                className="p-button-text !text-orange-500 hover:!text-orange-600 !inline-flex !items-center"
              />
            )}
          </div>

          <FilterRow>
            {/* Primary filters */}
            <div className="primary-filters">
              <div className="field">
                <InputText
                  value={filter?.applicationId ? filter?.applicationId : ''}
                  placeholder={tr('reservations.applicationId', 'Application ID')}
                  onChange={e => applyFilterValue('applicationId', e.target.value)}
                />
              </div>
              <div className="field">
                <InputText
                  value={filter?.locationName ? filter?.locationName : ''}
                  placeholder={tr('reservations.locality', 'Locality')}
                  onChange={e => applyFilterValue('locationName', e.target.value)}
                />
              </div>
              <div className="dates-group">
                <div className="field">
                  <Calendar
                    dateFormat={shortDateFormat}
                    value={filter.timeslotDaySince ? dayJsToDate(dayjs(filter.timeslotDaySince)) : null}
                    placeholder={tr('reservations.since', 'Since')}
                    onChange={(e: any) => applyFilterValue('timeslotDaySince', mapToAPIDateTime(dayjs(e.value as Date)))}
                    locale={i18n.language}
                    showButtonBar={true}
                    showIcon
                    onClearButtonClick={() => applyFilterValue('timeslotDaySince', null)}
                  />
                </div>
                <div className="field">
                  <Calendar
                    dateFormat={shortDateFormat}
                    value={filter.timeslotDayTill ? dayJsToDate(dayjs(filter.timeslotDayTill)) : null}
                    placeholder={tr('reservations.till', 'Till')}
                    onChange={(e: any) => applyFilterValue('timeslotDayTill', mapToAPIDateTime(dayjs(e.value as Date)))}
                    locale={i18n.language}
                    showButtonBar={true}
                    showIcon
                    onClearButtonClick={() => applyFilterValue('timeslotDayTill', null)}
                  />
                </div>
              </div>
              <div className="field">
                <InputText
                  value={filter?.reservationName ? filter?.reservationName : ''}
                  placeholder={tr('reservations.reservationName', 'Reservation name')}
                  onChange={e => applyFilterValue('reservationName', e.target.value)}
                />
              </div>
            </div>

            {/* Additional filters */}
            <div className={clsx('additional-filters', { hidden: !expandedFilters })}>
              <div className="field">
                <InputText
                  value={filter?.carrierName ? filter?.carrierName : ''}
                  placeholder={tr('reservations.carrierName', 'Carrier name')}
                  onChange={e => applyFilterValue('carrierName', e.target.value)}
                />
              </div>
              <div className="field">
                <InputText
                  value={filter?.referenceOrderId ? filter?.referenceOrderId : ''}
                  placeholder={tr('reservations.referenceID', 'Reference')}
                  onChange={e => applyFilterValue('referenceOrderId', e.target.value)}
                />
              </div>
              <div className="field">
                <InputText
                  value={filter?.driverName ? filter?.driverName : ''}
                  placeholder={tr('reservations.driverName', 'Driver name')}
                  onChange={e => applyFilterValue('driverName', e.target.value)}
                />
              </div>
              <div className="field">
                <InputText
                  value={filter?.carPlate ? filter?.carPlate : ''}
                  placeholder={tr('reservations.carPlate', 'Car plate')}
                  onChange={e => applyFilterValue('carPlate', e.target.value)}
                />
              </div>
            </div>

            {/* Action buttons */}
            <Row className="flex-col sm:flex-row mt-4 sm:mt-0">
              <div className="flex justify-between w-full items-center gap-2">
                <Button
                  label={expandedFilters ? tr('reservations.hideFilters', 'Hide filters') : tr('reservations.showFilters', 'Show filters')}
                  icon="pi pi-sliders-h"
                  onClick={() => setExpandedFilters(!expandedFilters)}
                  className="p-button-text !text-orange-500 hover:!text-orange-600 !inline-flex !items-center"
                />
                <div className="flex gap-2 items-center">
                  <Button
                    label={tr(`Reservations.saveFilter`, `Save filter`)}
                    icon="pi pi-save"
                    onClick={e => saveFilter()}
                    className="p-button-text !text-orange-500 hover:!text-orange-600 !inline-flex !items-center"
                  />
                  {isNotNullOrUndefined(storedFilter) && (
                    <Button
                      label={tr(`Reservations.clearFilter`, `Clear filter`)}
                      onClick={e => deleteFilter()}
                      className="p-button-text !text-orange-500 hover:!text-orange-600 !inline-flex !items-center"
                    />
                  )}
                </div>
              </div>
            </Row>
          </FilterRow>

          {/* Mobile sort selector and export */}
          {isMobile && (
            <div className="mt-4 -mx-4 bg-[#E7EBEE]">
              <div className="px-4 py-4">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <Checkbox checked={isAllSelected()} onChange={e => handleSelectAll(e.checked)} className="mr-2" disabled={isLoadingAll} />
                    <span className="text-sm text-gray-600">
                      {isLoadingAll ? tr('reservations.loading', 'Loading...') : `${selectedItems.length} ${tr('reservations.selected', 'selected')}`}
                    </span>
                  </div>
                  <Button type="button" icon="pi pi-file-excel" onClick={exportExcelHandler} className="p-button-success" data-pr-tooltip="XLS" />
                </div>
                <Dropdown
                  value={sort.sortField}
                  options={columnConfig
                    .filter(col => col.sortable)
                    .map(col => ({
                      label: tr(col.header, col.headerDefault),
                      value: col.field,
                    }))}
                  onChange={e => {
                    applySorting(e.value, sort.sortDirection);
                  }}
                  placeholder={tr('reservations.sortBy', 'Sort by')}
                  className="w-full"
                />
              </div>
            </div>
          )}

          {/* Desktop export section */}
          {!isMobile && (
            <div className="mt-4 bg-[#E7EBEE] p-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Checkbox checked={isAllSelected()} onChange={e => handleSelectAll(e.checked)} className="mr-2" disabled={isLoadingAll} />
                  <span className="text-sm text-gray-600">
                    {isLoadingAll ? tr('reservations.loading', 'Loading...') : `${selectedItems.length} ${tr('reservations.selected', 'selected')}`}
                  </span>
                </div>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcelHandler} className="p-button-success" data-pr-tooltip="XLS" />
              </div>
            </div>
          )}

          <TableWrapper>
            {data?.data && (
              <>
                {isMobile && (
                  <div className="bg-white -mx-4 p-4 pt-0">
                    <div className="-mx-4 bg-white">
                      <div className="px-4 py-4 shadow-[0_1px_0_0_rgba(229,231,235,1)]">
                        <div className="flex justify-between items-center">
                          <span className="text-orange-500 text-lg">{tr(`reservations.newReservation`, `New reservation`)}</span>
                          <Button
                            icon="pi pi-plus"
                            onClick={e => {
                              setCreateTimeslotRequest({
                                applicationId: null,
                                companyLocationId: null,
                                preferredDate: '',
                                companyLocationCustomerId: null,
                                expeditionLocationId: null,
                                expeditionId: null,
                              });
                            }}
                            className="!rounded-[4px] p-button-outlined !bg-white !border-gray-300 !text-gray-600 hover:!bg-gray-50 !w-10 !h-10"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">{renderMobileCards()}</div>
                    <div className="mt-4">
                      <div className="flex justify-between items-center px-4">
                        <div className="text-sm text-gray-600">
                          {tr('pagination.showing', 'Showing')} {(data.page - 1) * data.size + 1} - {Math.min(data.page * data.size, data.total)}{' '}
                          {tr('pagination.of', 'of')} {data.total}
                        </div>
                        <div className="flex gap-2">
                          <Button
                            icon="pi pi-chevron-left"
                            onClick={() => onPage({ page: data.page - 1, pageSize: data.size })}
                            disabled={data.page === 1}
                            className="p-button-text !text-orange-500 hover:!text-orange-600"
                          />
                          <Button
                            icon="pi pi-chevron-right"
                            onClick={() => onPage({ page: data.page + 1, pageSize: data.size })}
                            disabled={data.page * data.size >= data.total}
                            className="p-button-text !text-orange-500 hover:!text-orange-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!isMobile && (
                  <>
                    <DataTable
                      value={data.data}
                      rows={data.size}
                      paginator={true}
                      lazy={true}
                      totalRecords={data.total}
                      onPage={onPage}
                      first={(data.page - 1) * data.size}
                      sortMode={'single'}
                      onSort={e => {
                        applySorting(e.sortField, e.sortOrder === 1 ? 'ASC' : 'DESC');
                      }}
                      sortField={sort.sortField}
                      sortOrder={sort.sortDirection === 'ASC' ? 1 : -1}
                      className="ringil-table"
                    >
                      <Column
                        headerStyle={{ width: '3rem' }}
                        bodyStyle={{ width: '3rem' }}
                        className="text-center"
                        body={rowData => <Checkbox checked={selectedItems.includes(rowData.id)} onChange={e => handleSelectItem(rowData.id)} />}
                      />
                      {renderColumns()}
                    </DataTable>
                  </>
                )}
              </>
            )}
          </TableWrapper>
        </div>
      </Card>
      {/*<ConfirmDialog
          visible={reservation2Delete}
          dialogTitle={tr('reservations.confirmDeteleAction', 'Confirm detele action')}
          dialogMessage={tr('reservations.doYouWantToDeleteTheReservation', 'Do you want to delete the reservation?')}
          onConfirm={() => deleteRow(reservation2Delete)}
          onCancel={() => setReservation2Delete(undefined)}
          onAcceptButtonText={tr(`reservations.confirm`, `Confirm`)}
          onCancelButtonText={tr(`reservations.back`, `Back`)}
        />*/}
      <DuplicateTimeslot
        onComplete={() => {
          load();
        }}
      />
      {createTimeslotRequest && (
        <CreateTimeslotSidebar
          onContinue={reservation => {
            load();
          }}
          headerDisabled={false}
        />
      )}
    </>
  );
};

const TableMoreButtonWrapper = styled.div`
  details summary {
    border-color: #e7ebee !important;
    box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.15) !important;
    border-radius: 4px !important;

    svg path {
      fill: #616061;
    }

    &:hover {
      border-color: #d1d5db !important;
      box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.2) !important;
      svg path {
        fill: #4b5563;
      }
    }
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-family: 'DM Sans', sans-serif;

  .primary-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  .additional-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;

    &.hidden {
      display: none;
    }

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  .field {
    flex: 1;
    min-width: 200px;

    @media (max-width: 640px) {
      width: 100%;
      min-width: 100%;
    }

    .p-inputtext,
    .p-calendar {
      width: 100%;
    }
  }

  .dates-group {
    display: flex;
    gap: 0.5rem;
    min-width: 300px;
    flex: 2;

    @media (max-width: 640px) {
      width: 100%;
      min-width: 100%;
      flex-direction: row;

      .field {
        width: calc(50% - 0.25rem);
        min-width: unset;
      }
    }

    .field {
      min-width: unset;
    }
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  margin-top: 3px;
`;

const Heading = styled.h1`
  padding: 0;
  font-size: 26px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  font-family: 'DM Sans', sans-serif;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.1rem;
  font-family: 'DM Sans', sans-serif;
`;

const LabelDiv = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: row;
  padding: 0.3rem 1rem;
  color: white;
  background-color: ${props => props.color || 'black'};
  cursor: pointer;
  margin: 0.5rem 0;
  font-family: 'DM Sans', sans-serif;
  flex: 1;
  justify-content: center;
  border-radius: 0.25rem;
`;

const TextAndIcon = styled.div`
  display: flex;
  gap: 0.5rem;
  font-family: 'DM Sans', sans-serif;
`;

const AttachmentTag = styled.div<{ isExpanded?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border: 1px solid #ffeee5;
  border-radius: 4px;
  color: #616061;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  width: fit-content;
  min-width: fit-content;

  .label {
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    width: 0;
    overflow: hidden;
  }

  &:hover {
    padding-right: 1rem;
    .label {
      opacity: 1;
      transform: translateX(0);
      width: auto;
    }
  }

  ${props =>
    props.isExpanded &&
    `
    border-color: #ffd4c4;
  `}
`;

const FileTag = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  border: 1px solid #ffd4c4;
  border-radius: 4px;
  color: #ff6b00;
  font-size: 0.875rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  cursor: pointer;

  span {
    display: inline-block;
    white-space: nowrap;
  }

  &:hover {
    background-color: #fff5f0;
  }
`;
