import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import { formatPrice, isNotNullOrUndefined } from '@utils/utils.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgMoney from '@components/ringil3/icons/Money.tsx';

interface ComponentProps {
  price?: number;
  currency?: string;
  showPrices?: boolean;
  readOnly: boolean;
}

const ArrangedPriceTag: React.FC<ComponentProps> = ({ price, currency, readOnly, showPrices = true }) => {
  const { tr } = useTranslationLgs();

  return (
    <>
      {isNotNullOrUndefined(price) && (
        <ButtonTag label={tr(`ArrangedPriceTag.title`, `Arranged price`)}
                   value={showPrices ? formatPrice(price, currency) : '-'} icon={<SvgMoney />} readonly={readOnly} />
      )}
    </>
  );
};

export default ArrangedPriceTag;
