import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import { AdditionalFieldTypeEnum, EntityTypeEnum, State, WorkflowPossibleTransitionOutDTO, WorkflowTransitionAdditionalFieldInDTO } from '@api/logsteo-api.v2';
import SvgActive from '@components/ringil3/icons/workflow/Active';
import { dumpVars, isNotNullOrUndefined, isNullOrUndefined, visualiseWorkflowStateState } from '@utils/utils';
import SvgTagEdit from '@components/ringil3/icons/TagEdit';
import useForm from '@hooks/useForm/useForm';
import { Dropdown } from 'primereact/dropdown';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';

interface ComponentProps {
  state: State;
  reservationId: string;
  onReload: () => void;
  readOnly: boolean;
}

interface Form {
  state: string;
  additionalField?: WorkflowTransitionAdditionalFieldInDTO;
}

const ReservationWorkflowStateTag: React.FC<ComponentProps> = ({ state, reservationId, onReload, readOnly }) => {
  const { tr } = useTranslationLgs();
  const { getWorkflowPossibleTransition, performTransitionOnEntity } = useContext(ApiContext);
  const [workflowInfo, setWorkflowInfo] = useState<WorkflowPossibleTransitionOutDTO>();

  const form = useForm<Form>(
    yup.object().shape({}),
    {
      state: state?.name,
      additionalField: isNotNullOrUndefined(state.additionalField)
        ? { additionalFieldValue: state.additionalField?.additionalFieldValue, additionalFieldName: state.additionalField?.additionalFieldName }
        : null,
    },
    v => save(v.state),
    true,
    false,
  );

  useEffect(() => {
    const c = new AbortController();
    getWorkflowPossibleTransition(reservationId, 'TIMESLOT', d => {
      if (c.signal.aborted) return;
      setWorkflowInfo(d);
    });
    return () => {
      c.abort();
    };
  }, [state]);

  if (isNullOrUndefined(workflowInfo)) return <></>;

  const renderIcon = () => {
    if (state.icon === null) return <></>;
    switch (state.icon.name) {
      default:
        return <SvgActive fill={state.icon.color} />;
    }
  };

  const save = (state: string) => {
    performTransitionOnEntity(
      reservationId,
      EntityTypeEnum.TIMESLOT,
      {
        transitionToName: state,
        additionalField: isNotNullOrUndefined(workflowInfo.possibleTransitions.find(t => t.to.name === state)?.to.additionalField) ? form.values.additionalField : null,
      },
      () => {
        onReload();
      },
    );
  };

  const renderAdditionalField = (possibleState: string) => {
    const additionalField = workflowInfo.possibleTransitions.find(t => t.to.name === possibleState).to?.additionalField;

    if (isNullOrUndefined(additionalField)) return <></>;

    if (additionalField.additionalFieldType === AdditionalFieldTypeEnum.LIST_ENUMS) {
      return (
        <>
          <Dropdown
            options={additionalField.additionalFieldAvailableValues}
            value={form.values.additionalField?.additionalFieldValue}
            onChange={v => {
              form.useProduce(d => {
                if (isNullOrUndefined(d.additionalField)) {
                  d.additionalField = {
                    additionalFieldName: additionalField.additionalFieldName,
                    additionalFieldValue: v.value,
                  };
                } else {
                  d.additionalField.additionalFieldValue = v.value;
                  d.additionalField.additionalFieldName = additionalField.additionalFieldName;
                }
              });
            }}
            itemTemplate={d => tr(`ReservationWorkflowStateTag.additionalField.${additionalField.additionalFieldName}.${d}`, `${d}`)}
            valueTemplate={(d, props) =>
              d ? tr(`ReservationWorkflowStateTag.additionalField.${additionalField.additionalFieldName}.${d}`, `${d}`) : <span>{props.placeholder}</span>
            }
            placeholder={tr(`ReservationWorkflowStateTag.additionalFieldPlaceholder.${additionalField.additionalFieldName}`, `${additionalField.additionalFieldName}`)}
          />
        </>
      );
    }
  };

  const pane = (
    <>
      <Dropdown
        options={workflowInfo.possibleTransitions.map(t => t.to)}
        optionLabel={'name'}
        optionValue={'name'}
        itemTemplate={d => visualiseWorkflowStateState(d, tr)}
        valueTemplate={d => visualiseWorkflowStateState(d, tr)}
        value={form.values.state}
        onChange={v => {
          form.setFieldValue('state', v.value);
        }}
        placeholder={workflowInfo.currentState}
      />
      {renderAdditionalField(form.values.state)}
    </>
  );

  const resolveText = () => {
    if ((workflowInfo.currentState && isNullOrUndefined(state.additionalField)) || isNullOrUndefined(state.additionalField.additionalFieldValue))
      return tr(`CreateEditWorkflowStateComponent.stateName${state.name}`, `${state.name}`);
    return (
      <MultipleValues
        values={[
          tr(`CreateEditWorkflowStateComponent.stateName${state.name}`, `${state.name}`),
          tr(
            `ReservationWorkflowStateTag.additionalField.${state.additionalField.additionalFieldName}.${state.additionalField.additionalFieldValue}`,
            `${state.additionalField.additionalFieldValue}`,
          ),
        ]}
      />
    );
  };

  return (
    <>
      <ButtonTag
        label={tr(`ReservationWorkflowStateTag.state`, `State`)}
        value={resolveText()}
        icon={renderIcon()}
        actionIcon={<SvgTagEdit />}
        actionPane={pane}
        onSave={() => form.validateAndSend()}
        hideButtons={false}
        autoClose={true}
        readonly={readOnly}
      />
    </>
  );
};

interface Props {
  reservationId: string;
  form: any;
}

export default ReservationWorkflowStateTag;
