import React from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import AdminLayout from '@app/layout/Layout.tsx';
import { PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { ListReservations } from '@app/pages/reservations/ListReservations.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Intro from '@app/layout/Intro.tsx';
import ResponsiveLayout from '@app/layout/ResponsiveLayout.tsx';
import { ResponsiveListReservations } from '@app/pages/reservations/ResponsiveListReservations.tsx';
interface ComponentProps {}

const ReservationListPage: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();

  return (
    <>
      {/* <AdminLayout */}
      <ResponsiveLayout
        title={tr(`reservations.orderList`, `Order list`)}
        oneOfPermission={[PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_READ, PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_RW]}
      >
        {/* <ListReservations /> */}
        <ResponsiveListReservations />
      </ResponsiveLayout>
    </>
  );
};

export default withAuthenticationRequired(ReservationListPage, {
  onRedirecting: () => <Intro />,
});
