import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { ApiContext } from '@api/api';
import useForm from '@hooks/useForm/useForm';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { EditLoadingLocationOrderRequestInDTO } from '@api/logsteo-api.v2.tsx';
import { createOrdersComponentValidationSchema, fullRequiredConfig } from '@components/framework/orders/validation.ts';

interface ComponentProps {
  applicationId: string;
  expeditionId: string;
  localityName: string;
  locationId: string;
  onContinue: () => void;
  orders: Order[];
  allUnloadingLocations: { id: string; name: string }[];
}

interface EditExpeditionOrdersForm {
  orders: Order[];
}

export const useCreateEditLoadingOrderSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditLoadingOrderSidebar: React.FC<ComponentProps> = ({ onContinue, applicationId, locationId, expeditionId, orders, allUnloadingLocations }) => {
    const { tr } = useTranslationLgs();
    const { loggedUser } = useContext(AuthenticatedUserContext);
    const { editLoadingLocationOrderExpedition } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();

    const save = (data: EditExpeditionOrdersForm) => {
      editLoadingLocationOrderExpedition(
        expeditionId,
        locationId,
        {
          loadingLocationOrders: data.orders.map(
            t =>
              ({
                order: t,
                unloadingLocationId: t.unloadingLocationId,
              } as EditLoadingLocationOrderRequestInDTO),
          ),
        },
        () => {
          onContinue();
          setVisible(false);
        },
      );
    };

    const schema = createOrdersComponentValidationSchema(fullRequiredConfig, true, tr);
    const form = useForm<EditExpeditionOrdersForm>(schema, { orders: orders?.length > 0 ? orders : [emptyOrder(true, true)] }, d => save(d), false, false);

    if (!visible) return null;

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          onContinue={() => form.validateAndSend()}
          heading={tr('EditLoadingOrdersSidebar.ocekavanaManipulace', 'Očekávaná manipulace')}
          ready={false}
        >
          <div className={'flex flex-col gap-4'}>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr('EditLoadingOrdersSidebar.forExpedition', 'For expedition')}
              valueText={applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'}
            />
          </div>
          <>
            <OrdersComponent
              config={fullRequiredConfig}
              allUnloadingLocations={allUnloadingLocations}
              onChange={v =>
                form.useProduce(d => {
                  d.orders = v;
                })
              }
              orders={form.values.orders}
              validationErrors={form.validationErrors}
            />
          </>
        </RingilSidebar>
      </>
    );
  };

  return { EditLoadingOrderSidebar, setVisible, visible };
};
