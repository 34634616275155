import { DistributionView } from '@type/types';
import { DistributionInDTO } from '@api/logsteo-api.v2';
import { mapToAPIDateTime } from '@utils/date';

export const mapToDistributionInDTO = (distribution: DistributionView): DistributionInDTO => {
  return {
    price: distribution.price,
    validTill: mapToAPIDateTime(distribution.responseDeadline),
    selectAll: false,
    distributionType: distribution.distributionType,
    currency: distribution.currency,
    selectedItems: distribution.selectedItems,
    isPublish: distribution.isPublished,
    allowPartialDelivery: distribution.allowPartialDelivery,
  };
};
