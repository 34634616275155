import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { EditTimeslotCarrierInDTO, LocationsEditJobProgressOutDTO, LocationTimeslotFormInDTO } from '@api/logsteo-api.v2';
import { useImmer } from 'use-immer';
import { formatCarrierLocation, formatInterval, getTime, isNullOrUndefined, mapFromDateToDayjs } from '@utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import { dateFormatOnlyDateShort, mapFromAPIDateTime, mapToAPIDateTime, setTime, shortDateFormat } from '@utils/date';
import { InputMask } from 'primereact/inputmask';
import { ApiContext } from '@api/api';
import useValidation from '@hooks/validation-hook/useValidation';
import * as yup from 'yup';
import ValidationDiv from '@utils/validation';
import { useTranslation } from 'react-i18next';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import TimeslotsProgressInput from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInput.tsx';
import TimeslotsProgressInputSelect from '@components/obsolete/TimeslotsProgress/TimeslotsProgressInputSelect.tsx';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  companyId: string;
  applicationId: string;
  onUpdateSlot: (slots: EditTimeslotCarrierInDTO) => void;
}

interface Slot {
  locationId: string;
  since: Dayjs;
  till: Dayjs;
}

const EditCarrierTimeslot: React.FC<ComponentProps> = ({ visible, onHide, applicationId, companyId, onUpdateSlot }) => {
  const { t } = useTranslation('common');
  const { caGetEditTimeslots } = useContext(ApiContext);

  const [state, setState] = useImmer<LocationsEditJobProgressOutDTO[]>(undefined);
  const [newSlots, setNewSlots] = useState<Slot[]>([]);

  useEffect(() => {
    if (visible) {
      caGetEditTimeslots(companyId, applicationId, data => {
        setState(draft => {
          return data.locations;
        });
        setNewSlots([]);
      });
    }
  }, [visible]);

  const validationSchema = yup.array().of(
    yup.object().shape({
      test: yup.mixed().test({
        name: 'intervals.not.valid',
        message: 'intervals.not.valid',
        test: (value: any, testContext) => {
          const v = testContext.parent as Slot;
          if (isNullOrUndefined(v)) return false;
          // TODO: implement  better checks
          if (isNullOrUndefined(v.since)) return false;
          if (isNullOrUndefined(v.till)) return false;
          if (v.since > v.till) return false;
          return true;
        },
      }),
    }),
  );

  const { clear, validateAndSend, validationErrors, find } = useValidation(validationSchema, () => onContinue());

  const getNewSlot = (locationId: string): Slot | undefined => {
    return newSlots.find(s => s.locationId === locationId);
  };

  const createCustomSlot = (locationId: string) => {
    setNewSlots([...newSlots, { locationId, since: dayjs(), till: dayjs().add(30, 'minute') }]);
    setState(draft => {
      const locationInfo = draft.find(value => value.id == locationId);
      locationInfo.timeslots.forEach(s => (s.isSelected = false));
    });
  };

  const setCustomerSlot = (locationId: string, slotId: string) => {
    setNewSlots(newSlots.filter(s => s.locationId !== locationId));
    setState(draft => {
      const locationInfo = draft.find(value => value.id == locationId);
      locationInfo.timeslots.forEach(s => (s.isSelected = false));
      locationInfo.timeslots.find(s => s.id == slotId).isSelected = true;
    });
  };

  const changeCustomSlotDate = (locationId: string, selectedDay: Dayjs) => {
    const customSlot = newSlots.find(s => s.locationId === locationId);
    const ret = newSlots.filter(s => s.locationId !== locationId);
    setNewSlots([
      ...ret,
      {
        ...customSlot,
        since: selectedDay,
        till: selectedDay.add(30, 'minute'),
      },
    ]);
  };

  const changeSince = (locationId: string, time: string) => {
    const customSlot = newSlots.find(s => s.locationId === locationId);
    const ret = newSlots.filter(s => s.locationId !== locationId);
    setNewSlots([
      ...ret,
      {
        ...customSlot,
        since: setTime(customSlot.since, time),
        till: customSlot.till,
      },
    ]);
  };

  const changeTill = (locationId: string, time: string) => {
    const customSlot = newSlots.find(s => s.locationId === locationId);
    const ret = newSlots.filter(s => s.locationId !== locationId);
    setNewSlots([
      ...ret,
      {
        ...customSlot,
        since: customSlot.since,
        till: setTime(customSlot.till, time),
      },
    ]);
  };

  const onContinue = () => {
    //const ret = {locationTimeslotIds: } as EditTimeslotCarrierInDTO;

    const carrierSlots = newSlots.map(a => {
      return {
        locationId: a.locationId,
        newTimeslot: {
          timeslotSince: mapToAPIDateTime(a.since),
          timeslotTill: mapToAPIDateTime(a.till),
        },
      } as LocationTimeslotFormInDTO;
    });

    const customerSlots = state
      .map(location => {
        return {
          locationId: location.id,
          timeslots: location.timeslots.filter(lt => lt.isSelected),
        };
      })
      .filter(l => l.timeslots.length === 1)
      .map(l => {
        return {
          locationId: l.locationId,
          timeslotId: l.timeslots[0].id,
        } as LocationTimeslotFormInDTO;
      });

    const compinedSlots = [...carrierSlots, ...customerSlots];
    onUpdateSlot({ locationTimeslotIds: compinedSlots });
  };

  const { i18n } = useTranslation();

  return (
    <>
      {/*// @ts-ignore*/}
      <Sidebar visible={visible} position="right" baseZIndex={1000000} onHide={() => onHide()} className="sidebar-modal sidebar-modal-md" style={{ width: '750px' }}>
        {state && (
          <>
            <h3 className="p-mb-5" style={{ fontWeight: 'normal' }}>
              {t(`EditCarrierTimeslot.selectTimeslotsForLoadingAndUnloading`, `Select timeslots for loading and unloading`)}
            </h3>
            <div>
              {/* 1 */}
              {state.map((location, index) => {
                return (
                  <div className="timeslotsProgress" key={index}>
                    <TimeslotsProgressTitle
                      number={`${index + 1}`}
                      address={formatCarrierLocation(location.zipCode, location.city, location.country)}
                      company={location.name}
                    />
                    <div className="timeslotsProgress-wrapper">
                      {location.timeslots.map((timeslot, timeslotIndex) => {
                        return (
                          <TimeslotsProgressInput
                            key={timeslotIndex}
                            date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                            time={formatInterval(timeslot.isNotSpecifiedTime, timeslot.sinceInUTC, timeslot.tillInUTC, t)}
                            checked={timeslot.isSelected}
                            onCheck={() => setCustomerSlot(location.id, timeslot.id)}
                          />
                        );
                      })}

                      {getNewSlot(location.id) && (
                        <>
                          <TimeslotsProgressInputSelect inputName="dataTime1" inputValue="data-time-4" checked={true}>
                            <div className="timeslotsProgress-calendar">
                              <Calendar
                                locale={i18n.language}
                                value={getNewSlot(location.id).since.toDate()}
                                dateFormat={shortDateFormat}
                                onChange={e => changeCustomSlotDate(location.id, mapFromDateToDayjs(e.value as Date))}
                              />
                            </div>
                            <div className="p-fluid timeslotsProgress-input">
                              <InputMask mask={'99:99'} value={getTime(getNewSlot(location.id).since)} onComplete={e => changeSince(location.id, e.value)} />
                            </div>
                            <div className="delimiter"> —</div>
                            <div className="p-fluid timeslotsProgress-input">
                              <InputMask mask={'99:99'} value={getTime(getNewSlot(location.id).till)} onComplete={e => changeTill(location.id, e.value)} />
                            </div>
                            <ValidationDiv errors={validationErrors} path={`[${index}].test`} />
                          </TimeslotsProgressInputSelect>
                        </>
                      )}
                    </div>
                    {!getNewSlot(location.id) && (
                      <Button
                        icon="icon pi pi-plus"
                        className="btn-plus p-mt-2 p-button-outlined"
                        label={t(`EditCarrierTimeslot.pickASpecificTime`, `Pick a specific time`)}
                        onClick={() => createCustomSlot(location.id)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <ButtonLayout>
              <Button label={t(`EditCarrierTimeslot.continue`, `Continue`)} className="p-mr-2" onClick={() => validateAndSend(newSlots)} />
              <Button label={t(`EditCarrierTimeslot.back`, `Back`)} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default EditCarrierTimeslot;
