import React, { useContext, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { isNotBlank, isNotNullOrUndefined } from '@utils/utils.tsx';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import { ColWithGap } from '@components/styles.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation.tsx';
import InputPlus from '@components/framework/input/InputPlus/InputPlus.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';

interface ComponentProps {
  onCompleted: () => void;
}

interface SidebarData {
  selectedQuoteId: string;
  selectedCarrierName: string;
  applicationId: string;
  companyId: string;
}

export const useCreateRejectQuote = () => {
  const [sidebarData, setSidebarData] = useState<SidebarData>(undefined);

  const RejectQuote: React.FC<ComponentProps> = ({ onCompleted }) => {
    const { tr } = useTranslationLgs();
    const { cuRejectDemand } = useContext(ApiContext);
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [message, setMessage] = useState('');

    const rejectDemand = () => {
      cuRejectDemand(
        sidebarData.companyId,
        sidebarData.applicationId,
        sidebarData.selectedQuoteId,
        {
          message: isNotBlank(message) ? message : null
        },
        () => {
          onCompleted();
        }
      );
    };

    return (
      <>
        <RingilSidebar
          visible={isNotNullOrUndefined(sidebarData)}
          heading={tr("RejectQuote.odmitnoutCenovouNabidku", "Odmítnout cenovou nabídku")}
          onContinue={() => {
            rejectDemand();
            setSidebarData(null);
          }}
          onBack={() => setSidebarData(null)}>

          <ColWithGap>
            <BackendValidationComponent />
            <VerticalFieldAndValueWithIcon
              label={tr(`AcceptQuote.forExpedition`, `For expedition`)}
              valueText={sidebarData?.applicationId}
              iconPath={'/images/icons/ringil3/forExpedition.svg'} />

            <VerticalFieldAndValueWithIcon
              label={tr("RejectQuote.dopravce", "Dopravce")}
              valueText={sidebarData?.selectedCarrierName}
              iconPath={'/images/icons/ringil3/trucktype.svg'}
              showHr={true} />

            <COInlineMessage icon={<SvgWarningExclamation />} variant={'warning'}>
              Odmítnutím cenové nabídky společnosti už nebudete schopni akceptovat nabídku později. Dopravce bude informován e-mailem.
            </COInlineMessage>
            <InputPlus title={tr("RejectQuote.zpravaDopravci", "Zpráva dopravci")}>
              <Field label={tr("RejectQuote.zpravaDopravci", "Zpráva dopravci")}>
                <RingilTextArea
                  value={message}
                  onChange={(v) => {
                    setMessage(v);
                  }}
                  maxLength={255} />

              </Field>
            </InputPlus>
          </ColWithGap>
        </RingilSidebar>
      </>);

  };

  return { RejectQuote, setSidebarData };
};