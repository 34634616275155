import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import ReactDOM from 'react-dom';
import LocationSelectDropdown from '@components/framework/locationselect/LocationSelectDropdown.tsx';
import SvgChevronThin from '@icons/ChevronThin.tsx';
import SvgChevronThinUp from '@icons/ChevronThinUp.tsx';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { CGV2LocationOutDTO, CGV2SmallCompanyOutDTO, LocationContactTypeEnum } from '@api/logsteo-api.v2.tsx';
import { dumpVars, isNotBlank } from '@utils/utils.tsx';
import COText from '@app/pages/COText/COText.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';

interface ComponentProps {
  value: string;
  onChange: (companyLocation: CGV2LocationOutDTO) => void;
  fullWidth?: boolean;
}

const LocationSelect: React.FC<ComponentProps> = ({ value, onChange, fullWidth = false }) => {
  const { getCGLocationsV2, getOneCGLocationV2 } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [isLoading, setIsLoading] = useState(false);
  const [loadedValue, setLoadedValue] = useState<CGV2LocationOutDTO>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [locations, setLocations] = useState<CGV2SmallCompanyOutDTO[]>([]);

  // Zavření dropdownu při kliknutí mimo
  useEffect(() => {
    load('');
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && buttonRef.current !== event.target) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isNotBlank(value)) {
      getOneCGLocationV2(value, data => {
        setLoadedValue(data);
      });
    }
  }, [value]);

  const load = (query: string) => {
    getCGLocationsV2({ query }, data => {
      setLocations(data);
    });
  };

  // Funkce pro otevření dropdownu a nastavení jeho pozice
  const handleToggle = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
    setIsOpen(!isOpen);
  };

  const findAndFormatValue = (companyLocationId: string) => {
    if (loadedValue == null) return <div></div>;

    if (loadedValue.locationContactType == LocationContactTypeEnum.OUR_LOCATION) {
      return (
        <div className={'flex flex-row justify-between items-center grow pr-4'}>
          <div>
            {loadedValue.locationName} | {loadedValue.companyName}
          </div>
          <COBadge type={'processing'} variant={'outlined'}>
            <COText label={tr('LocationSelect.naseLokace', 'Naše lokace')} />
          </COBadge>
        </div>
      );
    }

    if (loadedValue.locationContactType == LocationContactTypeEnum.OUR_PARTNER_LOCATION) {
      return (
        <div className={'flex flex-row justify-between items-center grow pr-4'}>
          <div>
            {loadedValue.locationName} | {loadedValue.companyName}
          </div>
          <COBadge type={'processing'} variant={'outlined'}>
            <COText label={tr('LocationSelect.ourPartnerLocation', 'Partnerská lokace')} />
          </COBadge>
        </div>
      );
    }


    if (loadedValue.locationContactType == LocationContactTypeEnum.OUR_EXTERNAL_LOCATION) {
      return (
        <div className={'flex flex-row justify-between items-center grow pr-4'}>
          <div>
            {loadedValue.locationName} | {loadedValue.externalName}
          </div>
          <COBadge type={'draft'} variant={'outlined'}>
            <COText label={tr('LocationSelect.externi', 'Externi')} />
          </COBadge>
        </div>
      );
    }

    return (
      <div className={'flex flex-row justify-between items-center grow pr-4'}>
        <div>
          {loadedValue.locationName} | {loadedValue.companyName}
        </div>
        <COBadge type={'draft'} variant={'outlined'}>
          <COText label={tr('LocationSelect.partner', 'Partner')} />
        </COBadge>
      </div>
    );
  };

  const onSelectHandler = (companyLocationId: string) => {
    setIsOpen(false);
    getOneCGLocationV2(companyLocationId, data => {
      onChange(data);
    });
  };

  return (
    <>
      <div
        ref={buttonRef}
        className={twMerge(
          'flex flex-row px-2 h-[40px] justify-between border border-solid border-content-secondary items-center cursor-pointer text-content-secondary',
          clsx({ 'w-full': fullWidth }),
        )}
        onClick={handleToggle}
      >
        {findAndFormatValue(value)}
        {isOpen ? <SvgChevronThinUp /> : <SvgChevronThin />}
      </div>

      {isOpen &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
              zIndex: 1000,
              backgroundColor: 'white',
              border: '1px solid #e5e7eb',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <LocationSelectDropdown
              isLoading={isLoading}
              companyLocations={locations}
              onFilter={v => load(v)}
              onSelect={(companyLocationId: string) => {
                onSelectHandler(companyLocationId);
              }}
            />
          </div>,
          document.body,
        )}
    </>
  );
};

export default LocationSelect;
